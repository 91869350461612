import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Card from '../../../../components/Card';
import { media } from '../../../../global';

// prettier-ignore
const TeaCard = styled(Card)`
  text-align: left;
  
  ${p => media.up.lg`
    ${p.reverse && `
      text-align: right;
    `}
  `}

  ${media.down.lg`
    h3 {
      font-size: 32px;
    }
  `}

  ${media.down.sm`
    h3 {
      font-size: 28px;
    }
  `}
`;

// prettier-ignore
const Wrapper = styled.div`
  width: 250px;
  opacity: 0;
  padding: 0 10px 0 0;
  position: absolute;
  top: 25px;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
  z-index: 2;

  ${p => p.reverse ? `
    padding: 0 0 0 10px;
    right: calc(100% - 30px);
    transform: translateX(40px);
  ` : `
    left: calc(100% + 20px);
    transform: translateX(-40px);
  `}

  ${media.down.lg`
    left: 0
    min-width: 155px;
    opacity: 1;
    padding-left: 0;
    position: relative;
    right: 0;
    top: 0;
    transform: translateX(0);
    width: 50%;
  `}
`;

const Content = ({ className, description, name, reverse }) => (
  <Wrapper className={className} reverse={reverse}>
    <TeaCard bodyText={description} headline={name} reverse={reverse} />
  </Wrapper>
);

Content.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string.isRequired,
  reverse: PropTypes.bool
};

export default Content;
