import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Image } from '../../../elements';
import { getPercentValue, LocationMarker, media, P } from '../../../global';

const hoverTypes = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  STATIC: 'STATIC'
};

const containerHeight = 469;

const containerWidth = 703;

const LocationDetails = styled.div`
  left: 50%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translate(-49.5%, -50%);
  transition: opacity 0.15s ease-in-out, transform 0.25s ease-in-out;
  min-width: 300px;

  ${media.down.sm`
    min-width: 150px;
  `}
`;

// prettier-ignore
const Location = styled.div`
  color: ${p => p.theme.color.redPepper};
  cursor: pointer;
  left: ${p => getPercentValue(p.x, containerWidth)}%;
  position: absolute;
  top: ${p => getPercentValue(p.y, containerHeight)}%;
  transition: opacity 0.25s ease-in-out;

  ${p => p.current === hoverTypes.STATIC && `
    ${p.all ? `
      opacity: 1;
    ` : `
      opacity: ${p.year === '2018' ? '1' : '0'};
      pointer-events: ${p.year === '2018' ? 'auto' : 'none'};
    `}
  `}

  ${p => p.current === hoverTypes.INACTIVE && `
    pointer-events: none;

    ${p.all ? `
      opacity: 0.25;
    ` : `
      opacity: ${p.year === '2018' ? '0.25' : '0'};
    `}      
  `}

  ${p => p.current === hoverTypes.ACTIVE && `
    opacity: 1;

    ${LocationDetails} {
      opacity: 1;
      transform: translate(-49.5%, -100%);
    }
  `}
`;

const LocationLine = styled.div`
  border-right: 1.5px dashed ${p => p.theme.color.redPepper};
  display: inline-block;
  height: 35px;
`;

const LocationText = styled(P)`
  color: ${p => p.theme.color.redPepper};
`;

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 703px;
  position: relative;
  width: 100%;
`;

const getHoverType = (current, i) => {
  if (current === i) return hoverTypes.ACTIVE;
  if (current === null) return hoverTypes.STATIC;
  return hoverTypes.INACTIVE;
};

const MapContent = ({ active, locations }) => {
  const [current, setCurrent] = useState(null);

  return (
    <Wrapper>
      {locations.map((location, i) => (
        <Location
          all={active === '2019'}
          current={getHoverType(current, i)}
          key={i}
          onMouseEnter={() => setCurrent(i)}
          onMouseLeave={() => setCurrent(null)}
          x={location.x}
          y={location.y}
          year={location.year}
        >
          <LocationMarker width="19px" height="25px" />
          <LocationDetails>
            <LocationText>{location.location}</LocationText>
            <LocationLine />
          </LocationDetails>
        </Location>
      ))}
      <Image
        alt="Map of America"
        url="/images/components/companyoutlook/map.png"
      />
    </Wrapper>
  );
};

MapContent.propTypes = {
  active: PropTypes.string,
  locations: PropTypes.array
};

export default MapContent;
