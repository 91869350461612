import React, {
  // useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';

// import FindMySalataDropdown from './FindMySalataDropdown';
// import PopUpBanner from './PopUpBanner';
import TopNavigation from './TopNavigation';

function Header({ headerNavigation, orderNavigation, ...props }) {
  // remember to add the isDropdownVisible when re-enabling location search
  const [setDropdownVisible] = useState(false);
  // const [showBanner, setShowBanner] = useState(false);

  const toggleDropdown = () => setDropdownVisible(s => !s);

  // const closeBanner = () => {
  //   if (window) {
  //     sessionStorage.setItem('closeBanner', true);
  //     setShowBanner(false);
  //   }
  // };

  // useEffect(() => {
  //   if (window) {
  //     const sessionBanner = sessionStorage.getItem('closeBanner') === null;
  //     setShowBanner(sessionBanner);
  //   }
  // }, []);

  return (
    <>
      {/* <FindMySalataDropdown
        isVisible={isDropdownVisible}
        onFinderClose={toggleDropdown}
      /> */}
      <TopNavigation
        onFinderClick={toggleDropdown}
        menu={headerNavigation}
        order={orderNavigation}
        {...props}
      />
      {/* <PopUpBanner closeBanner={closeBanner} showBanner={showBanner} /> */}
    </>
  );
}

Header.propTypes = {
  headerNavigation: PropTypes.array.isRequired,
  orderNavigation: PropTypes.array
};

export default Header;
