import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, ButtonType } from '../../../elements';
import { H5, media, P, Wysiwyg } from '../../../global';

const ContactWysiwyg = styled(Wysiwyg)`
  margin-bottom: 40px;

  h4 {
    color: ${p => p.theme.color.kaleLeaf};
  }

  a {
    color: ${p => p.theme.color.redPepper};
  }
`;

const Paragraph = styled(P)`
  margin: 20px 0;
`;

const Wrapper = styled.div`
  padding: 60px 15px 60px 0;
  text-align: left;

  ${media.down.md`
    padding: 60px 0;
  `}
`;

const Contact = ({ bodyText, calloutText, getInTouch, link }) => (
  <Wrapper>
    <ContactWysiwyg dangerouslySetInnerHTML={{ __html: getInTouch }} />
    <H5>{calloutText}</H5>
    <Paragraph>{bodyText}</Paragraph>
    <Button {...link} />
  </Wrapper>
);

Contact.propTypes = {
  bodyText: PropTypes.string,
  calloutText: PropTypes.string,
  getInTouch: PropTypes.string.isRequired,
  link: PropTypes.shape(ButtonType)
};

export default Contact;
