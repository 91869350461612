import styled from 'styled-components';
import { cover } from 'polished';

import fonts from './fonts';

import { darkenColor, fontGen, toEms, media } from './utils';

// Converts a string fromCamelCase to-kebab-case
const kebabCase = str =>
  str.replace(/([A-Z]+)/g, match => `-${match.toLowerCase()}`);

export const contained = `
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  width: 100%;

  @media screen and (max-width: 650px) {
    margin-left: 25px;
    margin-right: 25px;
    max-width: calc(100% - 50px);
  }
`;

export const fontFaces = Object.keys(fonts)
  .filter(name => fonts[name])
  .map(name =>
    fonts[name]
      .map(
        font => `
          @font-face {
            font-display: fallback;
            font-family: ${name};
            font-weight: ${font.weight};
            font-style: ${font.style};
            src: url('${font.src}') format('woff');
          }
        `
      )
      .join('')
  )
  .join('');

export const H1 = styled.h1`
  ${p => fontGen(p.theme.type.h1)}
  margin: 0;
  color: ${p => (p.inverted ? p.theme.color.white : p.theme.color.kaleLeaf)};
`;

export const H2 = styled.h2`
  ${p => fontGen(p.theme.type.h2)}
  margin: 0;
  color: ${p => (p.inverted ? p.theme.color.white : p.theme.color.kaleLeaf)};
`;

export const H3 = styled.h3`
  ${p => fontGen(p.theme.type.h3)}
  margin: 0;
  color: ${p => (p.inverted ? p.theme.color.white : p.theme.color.peppercorn)};
`;

export const H4 = styled.h4`
  ${p => fontGen(p.theme.type.h4)}
  margin: 0;
  color: ${p => (p.inverted ? p.theme.color.white : p.theme.color.peppercorn)};
`;

export const H5 = styled.h5`
  ${p => fontGen(p.theme.type.h5)}
  margin: 0;
  color: ${p => (p.inverted ? p.theme.color.white : p.theme.color.kaleLeaf)};
`;

export const H6 = styled.h6`
  ${p => fontGen(p.theme.type.h6)}
  margin: 0;
  color: ${p => (p.inverted ? p.theme.color.white : p.theme.color.redPepper)};
`;

export const P = styled.p`
  ${p => fontGen(p.theme.type.p)}
  margin: 0;
  color: ${p => (p.inverted ? p.theme.color.white : p.theme.color.peppercorn)};

  a {
    color: ${p => p.theme.color.ecstasy};
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

// prettier-ignore
export const Wysiwyg = styled.div`
  font-family: ${p => p.theme.fontFamily.primary};

  h1, h2, h3, h4, h5, h6, li, p {
    color: ${p => p.inverted
    ? p.theme.color.white
    : p.theme.color.blackBean};
    margin-bottom: 15px;
    padding-top: 15px;

    ${p => p.contained && contained};
  }

  h1 {
    ${p => fontGen(p.theme.type.h1)}
  }

  h2 {
    ${p => fontGen(p.theme.type.h2)}
  }

  h3 {
    ${p => fontGen(p.theme.type.h3)}
  }

  h4 {
    ${p => fontGen(p.theme.type.h4)}
  }

  h5 {
    ${p => fontGen(p.theme.type.h5)}
  }

  h6 {
    ${p => fontGen(p.theme.type.h6)}
  }

  li, p {
    ${p => fontGen(p.theme.type.p)}
  }

  ul {
    margin-left: 30px;
    margin-bottom: 30px;

    li {
      list-style-position: inside;
      padding-left: 22px;
      text-indent: -22px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ol {
    counter-reset: item;
    list-style: none;
    margin-bottom: 15px;
    margin-left: 0;

    li {
      counter-increment: item;
      margin: 0 0 24px;
      padding: 0 0 0 25px;
      position: relative;

      &::before {
        border: 1px solid ${p => p.theme.color.redPepper};
        border-radius: 50%;
        color: ${p => p.theme.color.redPepper};
        content: counter(item);
        display: inline-block;
        font-size: 10px;
        left: 0;
        position: absolute;
        text-align: center;
        top: 5px;
        width: 17px;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ul {
    li {
      list-style-type: disc;
    }
  }

  strong {
    font-weight: ${p => p.theme.fontWeight.bold};
  }

  small {
    font-size: 12px;
  }

  img {
    margin-bottom: 20px;
    display: block;
    max-width: 100%;

    &.align-center {
      margin-left: auto;
      margin-right: auto;
    }

    &.align-right {
      float: right;
      margin-left: 20px;
    }

    &.align-left {
      float: left;
      margin-right: 20px;
    }
  }

  a {
    color: ${p => p.theme.color.redPepper};
    text-decoration: underline;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: ${p => darkenColor(0.04, p.theme.color.redPepper)};
    }

    &:active {
      color: ${p => p.theme.color.redPepperDark};
    }
  }

  > *:first-child {
    padding-top: 0;
    margin-top: 0;
  }

  > *:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  ${p => p.center && `
    text-align: center;
  `}

  /**
   * WordPress theme colors support
   */
  ${p =>
    Object
      .entries(p.theme.color)
      .map(([key, value]) => {
        const wpClass = kebabCase(key);
        return `
          .has-${wpClass}-color {
            color: ${value};
          }

          .has-${wpClass}-background-color {
            background-color: ${value};
          }
        `;
      })
      .join('')
}

  /**
   * WordPress Block Styles
   */

  .wp-block-separator {
    background: ${p => p.theme.color.peppercornLight};
    height: 1px;
    margin: 55px 0 40px;
  }

  .wp-block-image {
    margin-bottom: ${toEms(48)};
    margin-top: ${toEms(48)};

    figcaption {
      ${contained};
      color: ${p => p.theme.color.peppercorn};
      margin-top: ${toEms(13)};
      text-align: center;
    }

    img {
      display: block;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      width: 812px;
    }
  }

  .wp-block-quote p {
    ${p => fontGen(p.theme.type.h3)}
    color: ${p => p.theme.color.kaleLeaf};
    margin: ${toEms(28)} auto;
    max-width: 812px;
    padding: 0 25px;
    quotes: "“" "”" "‘" "’";
    text-align: center;
    width: 100%;

    ::before {
      content: open-quote;
    }

    ::after {
      content: close-quote;
    }

    ${p => media.down.sm`
      font-size: ${p.theme.fontSize.h5};
    `}
  }

  .wp-block-embed__wrapper {
    text-align: center;
  }

  .wp-block-gallery {
    .blocks-gallery-grid {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      max-width: 620px;
      width: 100%;

      li {
        flex: 0 0 33%;
        list-style-type: none;
        margin: 0;
        padding: 10px;

        img {
          margin: 0;
        }
      }
    }

    ${media.down.sm`
      padding: 15px;
        
      .blocks-gallery-grid li {
        flex: 0 0 50%;
      }
    `}
  }

  /* Image with Callout block */
  .image-with-callout {
    align-items: center;
    color: ${p => p.theme.color.peppercorn};
    display: flex;
    flex-direction: column;
    margin: 53px 0 30px;
    max-width: 100%;
    padding-bottom: 80px;
    position: relative;
    text-align: center;
    width: 1024px;

    ${media.up.lg`
      margin: 53px auto 130px;
      padding-bottom: 30px;
    `}
  }

  .image-with-callout__image {
    height: auto;
    max-width: 812px;
    width: 100%;

    ${media.up.lg`
      margin-right: 212px;
    `}
  }

  .image-with-callout__callout {
    bottom: 0px;
    font-size: 24px;
    font-weight: 900;
    height: 200px;
    position: absolute;
    right: 13px;
    text-align: left;
    width: 200px;

    ${media.up.sm`
      height: 247px;
      width: 247px;
    `}

    ${media.up.lg`
      bottom: -33px;
      font-size: 32px;
      height: 345px;
      right: 0;
      width: 345px;
    `}

    svg {
      ${cover()};
    }
  }

  .image-with-callout__caption {
    align-self: flex-start;
    /* Text is limited to space less the space taken up by the caption. */
    max-width: calc(100% - 267px);
    padding-left: 25px;
    text-align: left;
    width: 100%;

    ${media.up.sm`
      text-align: center;
    `}

    ${media.up.lg`
      max-width: 679px;
    `}
  }

  .image-with-callout__texture {
    height: 100%;
    position: absolute;
    transform: translate(35%, 3%);
    width: 100%;
    z-index: -1;

    ${media.down.lg`
      bottom: 0;
      max-height: 100px;
      top: auto;
      transform: translate(16%, 0);
    `}

    svg {
      height: 100%;
    }
  }

  .image-with-callout__callout__text {
    left: 35px;
    position: relative;
    top: 45px;
    width: 145px;

    ${media.down.sm`
      font-size: 22px;
    `}

    ${media.up.sm`
      left: 30px;
      line-height: 29px;
      top: 74px;
      width: 217px;
    `}

    ${media.up.lg`
      top: 75px;
      left: 75px;
      line-height: 40px;
    `}
  }

  /* Override settings for reversed, image-on-right layout */
  .image-with-callout.layout-right {
    .image-with-callout__image {
      ${media.up.lg`
        margin-left: 212px;
        margin-right: 0;
      `}
    }

    .image-with-callout__callout {
      left: 13px;

      ${media.up.lg`
        left: 0;
      `}
    }

    .image-with-callout__caption {
      align-self: flex-end;
      padding: 0 25px 0 0;
    }

    .image-with-callout__texture {
      transform: translate(-10%,28%);

      ${media.down.lg`
        transform: translate(-55%, 0);
      `}
    }
  }

  /* Image with Texture block */
  .image-with-texture {
    align-items: center;
    color: ${p => p.theme.color.peppercorn};
    display: flex;
    flex-direction: column;
    margin: 90px 0;
    text-align: center;

    .image-with-texture__image {
      max-width: 812px;
      position: relative;
      width: 100%;

      .image-with-texture__texture {
        left: 0;
        position: absolute;
        top: 0;
        transform: translate(-25%, -25%);
        z-index: -1;

        svg {
          height: 100%;
        }

        ${media.down.lg`
          transform: translate(-40%, -30%);

          svg {
            width: 100px;
          }
        `}

      }

      /* Override texture position based on CMS selection */
      .image-with-texture__texture.layout-top-right {
        left: auto;
        right: 0;
        transform: translate(25%, -25%);

        ${media.down.lg`
          transform: translate(40%, -30%);
        `}
      }

      .image-with-texture__texture.layout-bottom-left {
        bottom: 0;
        top: auto;
        transform: translate(-25%, 25%);

        ${media.down.lg`
          transform: translate(-40%, 30%);
        `}
      }

      .image-with-texture__texture.layout-bottom-right {
        bottom: 0;
        left: auto;
        right: 0;
        top: auto;
        transform: translate(25%, 25%);

        ${media.down.lg`
          transform: translate(40%, 30%);
        `}
      }
    }
  }
`;
