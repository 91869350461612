import React from 'react';
import PropTypes from 'prop-types';
import { PageType } from '../../global';

import ContentWithSidebar from '../../layouts/ContentWithSidebar';

import Ingredients from './Ingredients';
import Sidebar, { SidebarType } from './Sidebar';

const IngredientInfo = ({ acf, ingredientList, ...layoutProps }) => {
  const { nutritionalInfo } = acf;

  const mainContent = <Ingredients ingredients={ingredientList.data} />;
  const sidebarContent = <Sidebar {...nutritionalInfo} />;

  return (
    <ContentWithSidebar
      {...layoutProps}
      mainContent={mainContent}
      sidebarContent={sidebarContent}
    />
  );
};

IngredientInfo.propTypes = {
  ...PageType,
  acf: PropTypes.shape({
    nutritionalInfo: PropTypes.shape(SidebarType)
  })
};

export default IngredientInfo;
