import styled from 'styled-components';
import { media } from '../../global';

export const GridLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Callout = styled.span`
  color: ${p => p.theme.color.kaleLeaf};
  font-weight: ${p => p.theme.fontWeight.bold};
  line-height: 1.5;
`;

export const PolicyText = styled.div`
  align-items: center;
  color: ${p => p.theme.color.peppercornLight};
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  font-size: 0.9em;
  justify-content: center;
  margin-top: 56px;

  a {
    color: ${p => p.theme.color.peppercornLight};
    position: relative;
    transition: color 0.2s ease-in-out;

    &::before {
      background-color: ${p => p.theme.color.peppercornLight};
      bottom: -5px;
      content: '';
      height: 1px;
      position: absolute;
      transform: scaleX(0);
      transform-origin: left center;
      transition: background-color 0.2s ease-in-out, transform 0.2s linear;
      width: 100%;
    }

    &:hover,
    &:focus {
      &::before {
        transform: scaleX(1);
      }
    }
  }

  ${media.up.sm`
    flex-direction: row;
    text-align: left;
    > div {
      margin-right: 0.5em;
    }
  `}

  ${media.up.md`
    justify-content: center;
    margin-top: 80px;
  `}
`;

export const Wrapper = styled.footer`
  color: ${p => p.theme.color.blackBean};
  font-family: ${p => p.theme.fontFamily.primary};
`;
