import React from 'react';
import IconBase from './IconBase';

export default props => (
  <IconBase height="16" viewBox="0 0 30 16" width="30" {...props}>
    <path
      strokeWidth=".875"
      d="M19.018 1c2.164.123 4.287.236 6.408.368a4.646 4.646 0 0 1 2.414.865c.991.696 1.326 1.612 1.085 2.804-.32 1.582-.634 3.168-1.4 4.593-.443.822-.95 1.617-1.512 2.357-1.299 1.712-3.104 2.434-5.146 2.577-2.524.178-5.049.4-7.575.434-2.21.03-4.41-.255-6.516-1.037-1.786-.662-3.052-1.94-3.966-3.618-.475-.872-.92-1.76-1.39-2.635C.948 6.83.904 5.904 1.138 4.96c.202-.813.811-1.293 1.474-1.686a10.47 10.47 0 0 1 4.128-1.38c2.11-.249 4.233-.384 6.353-.536 1.986-.142 3.975-.243 5.925-.359zM1.908 6.973c.652 1.444 1.32 2.864 2.228 4.145a5.748 5.748 0 0 0 2.6 2.043c1.647.658 3.362 1.073 5.126 1.039 3.013-.06 6.027-.178 9.035-.36 1.708-.105 3.241-.699 4.424-2.109.716-.852 1.317-1.76 1.762-2.775.348-.796.682-1.608.811-2.54-.74.295-1.404.59-2.086.826-1.995.688-4.081.7-6.148.806-1.398.072-2.802.016-4.202.069-1.693.064-3.384.233-5.077.262a35.143 35.143 0 0 1-4.031-.17c-1.525-.15-3.065-.32-4.441-1.236zm7.3.776c2.412-.14 4.37-.28 6.332-.362 2.178-.09 4.358-.1 6.536-.195 1.818-.08 3.58-.453 5.195-1.374 1.33-.756 1.399-2.231.086-3.027-.586-.357-1.292-.634-1.963-.689-2.732-.222-5.466-.428-8.215-.236-2.469.173-4.945.227-7.414.384-1.666.106-3.34.266-4.926.834a11.89 11.89 0 0 0-2.462 1.212c-.803.522-.756 1.57.062 2.053.599.355 1.257.705 1.926.813 1.75.284 3.52.434 4.844.587z"
    />
  </IconBase>
);
