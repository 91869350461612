import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ColorOptions, matchColor } from '../../../global';

// prettier-ignore
const Wrapper = styled.div`
  position: ${p => (p.insetDividerTop || p.insetDividerBottom)
    ? 'absolute'
    : 'relative'};
  height: 6.97vw;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 0;

  ${p => p.top ? `
    top: 0;

    ${p.reverse ? `
      transform: rotate(180deg) scaleX(-1) translateY(2px);
      filter: FlipH;
    ` : `
      transform: rotate(180deg) translateY(2px);
    `}

    svg {
      top: 0;
    }
  ` : `
    bottom: 0;

    ${p.reverse ? `
      transform: scaleX(-1) translateY(2px);
      filter: FlipH;
    ` : `
      transform: translateY(2px);
    `}

    svg {
      bottom: 0;
    }
  `}

  svg {
    display: block;
    fill: ${p => matchColor(p.fillColor, p.theme.color)};
    position: relative;
    width: 100%;
    height: auto;
    left: 0;
    right: 0;
  }
`;

const Divider = ({
  className,
  fillColor,
  insetDividerBottom,
  insetDividerTop,
  reverse,
  top
}) => {
  return (
    <Wrapper
      className={className}
      fillColor={fillColor}
      insetDividerBottom={insetDividerBottom}
      insetDividerTop={insetDividerTop}
      reverse={reverse}
      top={top}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100">
        <path d="M0 50c240-66.7 480-66.7 720 0s480 66.7 720 0v50H0V50z" />
      </svg>
    </Wrapper>
  );
};

Divider.defaultProps = {
  insetDividerBottom: false,
  insetDividerTop: false,
  reverse: false,
  top: false
};

Divider.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.oneOf(ColorOptions).isRequired,
  insetDividerBottom: PropTypes.bool,
  insetDividerTop: PropTypes.bool,
  reverse: PropTypes.bool,
  top: PropTypes.bool
};

export default Divider;
