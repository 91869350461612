import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MailToLink, ReactRouterLink } from '../../global';

const Anchor = styled.a`
  color: inherit;
  cursor: pointer;
  display: inline-block;
`;

const AnchorLink = ({
  children,
  className,
  download,
  external,
  mailto,
  path,
  tel,
  text
}) => {
  const target = download || external ? '_blank' : '_self';
  const linkText = children || text;
  const telephone = `tel:${tel}`;

  let emailLink = null;

  if (mailto) {
    let subject;
    emailLink = `mailto:${mailto.email}`;

    if (mailto.subject) {
      subject = encodeURIComponent(mailto.subject);
      emailLink += `?subject=${subject}`;
    }

    if (mailto.body) {
      const body = encodeURIComponent(mailto.body);
      const addBody = subject ? `&body=${body}` : `?body=${body}`;
      emailLink += addBody;
    }
  }

  if (path) {
    return (
      <Anchor
        className={className}
        href={path}
        download={download}
        target={target}
      >
        {linkText}
      </Anchor>
    );
  } else if (telephone) {
    return (
      <Anchor className={className} href={telephone}>
        {linkText}
      </Anchor>
    );
  } else if (emailLink) {
    return (
      <Anchor className={className} href={emailLink}>
        {linkText}
      </Anchor>
    );
  }
};

export const AnchorLinkType = {
  children: PropTypes.node,
  className: PropTypes.string,
  download: PropTypes.bool,
  external: PropTypes.bool,
  mailto: PropTypes.shape(MailToLink),
  path: PropTypes.string,
  tel: PropTypes.string,
  text: PropTypes.string,
  to: PropTypes.shape(ReactRouterLink)
};

AnchorLink.propTypes = AnchorLinkType;

export default AnchorLink;
