import { FastField } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';

import { FormField } from '../../../components';
import {
  Disclaimer,
  ExpandedFields,
  FormLayout,
  RadioContainer,
  Required,
  Subtitle
} from '../components';
import {
  combineValidations,
  validateEmail,
  validatePhone,
  validateRequired,
  validateZipCode
} from '../../../components/FormField/utils';

import { form } from '../data';

const Field = props => <FastField component={FormField} {...props} />;

const generateTimeOption = t => {
  const period = t < 12 ? 'AM' : 'PM';
  const hour = Math.ceil((t % 48) % 12) || 12;
  const topHour = `${hour}:00 ${period}`;
  const halfPast = `${hour}:30 ${period}`;

  return (
    <React.Fragment key={t}>
      <option value={topHour}>{topHour}</option>
      <option value={halfPast}>{halfPast}</option>
    </React.Fragment>
  );
};

const Fields = ({
  active,
  disclaimers,
  showExpandedFields,
  setShowExpandedFields,
  titles
}) => {
  const handleExtraData = (state, arr, component) => {
    const Component = component;
    return arr.map((item, i) => {
      return (
        state === item.label && <Component key={i}> {item.value}</Component>
      );
    });
  };

  return (
    <FormLayout>
      {active !== null && (
        <>
          {disclaimers && handleExtraData(active, disclaimers, Disclaimer)}
          <Required>
            <span>* </span>Required
          </Required>
          {titles && handleExtraData(active, titles, Subtitle)}
          <Field
            label="Full Name"
            name={form.FULL_NAME}
            type="text"
            validate={validateRequired}
          />
          <Field
            columns={{ sm: 9 }}
            label="Email Address"
            name={form.EMAIL}
            type="email"
            validate={combineValidations(validateRequired, validateEmail)}
          />

          <Field
            columns={{ sm: 3 }}
            label="Phone Number"
            name={form.PHONE}
            type="tel"
            validate={validatePhone}
            placeholder="(555) 555-5555"
          />

          {active === 'Guest Feedback / Complaint' ? (
            <>
              <RadioContainer>
                <Field
                  checked={!showExpandedFields}
                  label="Did you visit a restaurant?"
                  onClick={() => setShowExpandedFields(false)}
                  name="showExpandedFields"
                  type="radio"
                  value="No"
                />

                <Field
                  checked={showExpandedFields}
                  onClick={() => setShowExpandedFields(true)}
                  name="showExpandedFields"
                  required={false}
                  type="radio"
                  value="Yes"
                />
              </RadioContainer>

              <ExpandedFields visible={showExpandedFields}>
                <Field
                  columns={{ sm: 6 }}
                  label="Date of Visit"
                  name={form.VISIT_DATE}
                  placeholder="mm/dd/yyyy"
                  required={false}
                  type="date"
                />

                <Field
                  columns={{ sm: 6 }}
                  label="Time of Visit"
                  name={form.VISIT_TIME}
                  placeholder="mm/dd/yyyy"
                  required={false}
                  type="select"
                >
                  <>
                    <option disabled label={'Select a time'} value="" />

                    {Array.from(Array(24).keys(), generateTimeOption)}
                  </>
                </Field>

                <Field
                  label="Location Visited"
                  name={form.LOCATION_VISITED}
                  required={false}
                  type="text"
                />
                <Field
                  label="Order Number"
                  name={form.ORDER_NUMBER}
                  required={false}
                  type="text"
                />
              </ExpandedFields>
            </>
          ) : active === 'Marketing' ? (
            <Field
              label="Nearest Location"
              name={form.NEAREST_LOCATION}
              type="text"
              validate={validateRequired}
            />
          ) : active === 'Donations' ? (
            <>
              <Field
                label="Organization or Company Name"
                name={form.COMPANY_NAME}
                type="text"
                validate={validateRequired}
              />
              <Field
                label="Description of Organization/Company"
                name={form.COMPANY_DESCRIPTION}
                required={false}
                rows="3"
                type="textarea"
              />
              <Field
                label="Website/Social Media"
                name={form.WEBSITE_SOCIAL}
                required={false}
                type="text"
              />
              <Subtitle>Event Details</Subtitle>
              <Field
                label="Event Location"
                name={form.EVENT_LOCATION}
                type="text"
                validate={validateRequired}
              />
              <Field
                columns={{ sm: 7 }}
                label="Date"
                name={form.EVENT_DATE}
                placeholder="Choose an option..."
                type="date"
                validate={validateRequired}
              />
              <Field
                columns={{ sm: 5 }}
                label="Time"
                name={form.EVENT_TIME}
                type="select"
                validate={validateRequired}
              >
                <>
                  <option disabled label={'Specify a time...'} value="" />

                  {Array.from(Array(24).keys(), generateTimeOption)}
                </>
              </Field>
              <Field
                columns={{ sm: 4 }}
                label="Estimated Attendance"
                name={form.EVENT_ATTENDANCE}
                placeholder="Ex. 100 people"
                required={false}
                type="text"
              />
              <Field
                columns={{ sm: 8 }}
                label="Description of who is attending"
                name={form.EVENT_ATTENDEES}
                placeholder="Ex. Company employees"
                required={false}
                type="text"
              />
              <Field
                label="Event Description"
                name={form.EVENT_DESCRIPTION}
                required={false}
                rows="3"
                type="textarea"
              />
              <Subtitle>Event Needs</Subtitle>
              <Field
                label="Nearest Restaurant Location"
                name={form.NEAREST_LOCATION}
                type="text"
                validate={validateRequired}
              />
              <Field
                label="Requested Donation"
                name={form.REQUESTED_DONATION}
                type="text"
                validate={validateRequired}
              />
            </>
          ) : active === 'Charity' ? (
            <>
              <Field
                label="Entity or Company Name"
                name={form.COMPANY_NAME}
                type="text"
                validate={validateRequired}
              />
            </>
          ) : active === 'Location Request' ? (
            <>
              <Field
                label="Street Address"
                name={form.STREET_ADDRESS}
                type="text"
                validate={validateRequired}
              />

              <Field
                label="Street Address 2"
                name={form.STREET_ADDRESS_2}
                required={false}
                type="text"
              />

              <Field
                columns={{ sm: 6 }}
                label="City"
                name={form.CITY}
                type="text"
                validate={validateRequired}
              />

              <Field
                columns={{ default: 6, sm: 3 }}
                label="State"
                name={form.STATE}
                type="state"
                validate={validateRequired}
                placeholder="Select a state"
              />

              <Field
                columns={{ default: 6, sm: 3 }}
                label="ZIP Code"
                name={form.ZIP}
                type="text"
                validate={combineValidations(validateRequired, validateZipCode)}
              />
            </>
          ) : (
            ''
          )}

          <Field
            label="Comments"
            name={form.COMMENTS}
            rows="4"
            placeholder="Enter message here..."
            type="textarea"
            validate={validateRequired}
          />
        </>
      )}
    </FormLayout>
  );
};

export const IntroType = {
  label: PropTypes.string,
  value: PropTypes.string
};

Fields.propTypes = {
  active: PropTypes.string,
  disclaimers: PropTypes.arrayOf(PropTypes.shape(IntroType)),
  setShowExpandedFields: PropTypes.func,
  showExpandedFields: PropTypes.bool,
  titles: PropTypes.arrayOf(PropTypes.shape(IntroType))
};

export default Fields;
