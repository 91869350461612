import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Image, ImageType } from '../../elements';
import { H5, media } from '../../global';

const Content = styled.div`
  flex: 0 0 60%;
  padding: 0 15px;
  text-align: left;

  ${media.down.lg`
    flex: 0 0 100%;
    padding: 0 0 50px;
  `}
`;

const PostImage = styled(Image)`
  flex: 0 0 40%;

  ${media.down.lg`
    flex: 0 0 100%;
    padding-bottom: 25px;
  `}
`;

const Title = styled(H5)`
  color: ${p => p.theme.color.peppercorn};
  margin-bottom: 15px;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 0 0 33.3%;
  flex-wrap: wrap;

  ${media.down.lg`
    flex: 0 0 100%;
  `}
`;

const Post = ({ image, path, title }) => (
  <Wrapper>
    <PostImage {...image} />
    <Content>
      <Title dangerouslySetInnerHTML={{ __html: title }} />
      <Button buttonType="secondary" inline title="Read More" url={path} />
    </Content>
  </Wrapper>
);

Post.propTypes = {
  image: PropTypes.shape(ImageType),
  path: PropTypes.string,
  title: PropTypes.string
};

export default Post;
