import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Hidden, Menu } from '../../../global';

import {
  LogoDesktop,
  LogoMobile,
  LogoWrapper,
  Section,
  Spacer
} from './components';
import Nav, { NavType } from './Nav';

const Inner = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
`;

const Wrapper = styled(Section)`
  background: ${p => p.theme.color.white};
  z-index: 5;
`;

const FranchiseeHeader = ({ menu, withSpacer = true }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [clicked, setClicked] = useState(false);

  const toggleExpanded = () => () => {
    setClicked(true);
    setIsExpanded(expanded => !expanded);
  };

  useEffect(() => {
    if (!isExpanded && clicked) setClicked(false);

    if (window) {
      const body = document.body;
      const html = document.getElementsByTagName('html')[0];

      if (body && html) {
        if (isExpanded) {
          body.style.overflow = 'hidden';
          html.style.overflow = 'hidden';
        } else {
          body.style.overflow = '';
          html.style.overflow = '';
        }
      }
    }
  }, [clicked, isExpanded]);

  return (
    <>
      <Wrapper noPadding>
        <Inner>
          <Hidden up="lg">
            <Menu
              clicked={clicked}
              isExpanded={isExpanded}
              onClick={toggleExpanded()}
            />
          </Hidden>
          <LogoWrapper>
            <a href="https://www.salata.com/">
              <Hidden down="lg">
                <LogoDesktop />
              </Hidden>
              <Hidden up="lg">
                <LogoMobile />
              </Hidden>
            </a>
          </LogoWrapper>
          <Nav
            isExpanded={isExpanded}
            linkProps={{ url: '/contact-form/' }}
            menu={menu}
          />
        </Inner>
      </Wrapper>
      {withSpacer && <Spacer />}
    </>
  );
};

FranchiseeHeader.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.shape(NavType)),
  withSpacer: PropTypes.bool
};

export default FranchiseeHeader;
