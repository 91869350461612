import PropTypes from 'prop-types';

import { FindMySalataType } from '../../sections';

import { IntroType } from '../../components';
import { ParticlesType } from '../../components/Section/Particles';

const imgPath = '/images/templates/home/';

export const findMySalataImages = {
  desktop: {
    alt: 'Peppers',
    url: `${imgPath}peppers.png`
  },
  mobile: {
    alt: 'Peppers',
    url: `${imgPath}peppersMobile.png`
  }
};

export const findMySalataParticles = [
  {
    component: 'Curves',
    fill: 'blueDark',
    position: 'TOP_LEFT'
  }
];

export const findMySalataSectionType = {
  content: PropTypes.shape(FindMySalataType),
  particles: PropTypes.shape(ParticlesType)
};

export const mantraParticles = [
  {
    component: 'Curves',
    fill: 'blueDark',
    position: 'BOTTOM_RIGHT'
  }
];

export const todayAtSalataLinkProps = {
  buttonType: 'secondary',
  inline: true
};

export const todayAtSalataType = {
  content: PropTypes.arrayOf(PropTypes.object),
  intro: PropTypes.shape(IntroType)
};
