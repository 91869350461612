import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Section } from '../../../components';
import { media, P, toEms } from '../../../global';

import Group, { GroupType } from './Group';
import { IngredientType } from './Group/Ingredient';

import { CheckboxType } from '../Navigation/AllergenFilter/Filters/Checkbox';

const Disclaimer = styled(P)`
  font-size: ${p => toEms(p.theme.fontSize.label)};
  padding: 35px;
`;

const Wrapper = styled(Section)`
  opacity: ${p => (p.animating ? 0 : 1)};
  overflow: hidden;
  transition: opacity 0.25s ease-in-out;

  & > div {
    padding-left: 0;
    padding-right: 0;
  }

  ${media.up.lg`
    padding-bottom: 85px;
    z-index: 0;
  `}

  ${media.down.lg`
    position: static;

    & > div {
      position: static;
    }
  `}

  ${media.down.md`
    padding-bottom: 60px;
  `}
`;

const Ingredients = ({
  activeRadio,
  activeTab,
  allergens,
  groups,
  ingredientsAnimating,
  measurements,
  selected,
  setSelected
}) => (
  <Wrapper animating={ingredientsAnimating} noTopPadding>
    {!ingredientsAnimating &&
      groups.map((group, i) => (
        <Group
          activeRadio={activeRadio}
          activeTab={activeTab}
          allergens={allergens}
          group={group}
          key={i}
          measurements={measurements}
          selected={selected}
          setSelected={setSelected}
        />
      ))}
    {activeTab === 'Drinks' && (
      <Disclaimer>
        Salata Signature Teas do not contain a significant amount of sugar,
        calories, fat, or nutritional additives.
      </Disclaimer>
    )}
  </Wrapper>
);

Ingredients.propTypes = {
  activeRadio: PropTypes.string,
  activeTab: PropTypes.string,
  allergens: PropTypes.arrayOf(PropTypes.shape(CheckboxType)),
  groups: PropTypes.arrayOf(PropTypes.shape(GroupType)),
  ingredientsAnimating: PropTypes.bool,
  measurements: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.shape(IngredientType))
  ]),
  setSelected: PropTypes.func
};

export default Ingredients;
