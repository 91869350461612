import React from 'react';
import PropTypes from 'prop-types';

import { Card, IntroType, Section } from '../../../components';
import { PageType } from '../../../global';
import BaseLayout from '../../../layouts/Base';
import {
  ContentBreakdown,
  FeaturedForm,
  Hero,
  HeroType,
  MultiFeature,
  MultiFeatureType
} from '../../../sections';
import { Form } from '../../components';

const Investment = ({
  acf,
  franchisingFooter,
  franchisingHeader,
  ...layoutProps
}) => {
  const { financial, hero, letsDoThis, whatItTakes } = acf;

  return (
    <BaseLayout
      {...layoutProps}
      franchisingFooter={franchisingFooter}
      franchisingHeader={franchisingHeader}
    >
      <Hero {...hero} dividerBottomColor="white" />
      <Section intro={whatItTakes} />
      <Section
        bgColor="pink"
        dividerBottomColor="white"
        dividerTopColor="white"
        insetDividerBottom
        insetDividerTop
        noPadding
      >
        <MultiFeature {...financial} columns={1} component={ContentBreakdown} />
      </Section>
      <Section dividerBottomColor="tan" dividerBottomReverse>
        <FeaturedForm content={Card} contentData={letsDoThis} form={Form} />
      </Section>
    </BaseLayout>
  );
};

Investment.propTypes = {
  ...PageType,
  acf: PropTypes.shape({
    financial: PropTypes.shape(MultiFeatureType),
    hero: PropTypes.shape(HeroType),
    whatItTakes: PropTypes.shape(IntroType)
  }).isRequired
};

export default Investment;
