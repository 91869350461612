import React from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DateContainer = styled.div.attrs(props => ({
  style: props.theme.type.p
}))`
  color: ${p => p.theme.color.peppercornLight};
  padding-top: 1em;
`;

const DateView = ({ date }) => (
  <DateContainer>{format(date, 'MMMM D, YYYY')}</DateContainer>
);

DateView.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
    .isRequired
};

export default DateView;
