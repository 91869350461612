import React from 'react';
import IconBase from './IconBase';

export default props => (
  <IconBase viewBox="0 0 18 18" {...props}>
    <path
      d="M11.496 6.504a5.358 5.358 0 0 1 1.371 2.426 5.435 5.435 0 0 1 0 2.742 5.128 5.128 0 0 1-1.37 2.39l-2.392 2.391a5.128 5.128 0 0 1-2.39 1.371 5.435 5.435 0 0 1-2.742 0 5.174 5.174 0 0 1-2.409-1.388 5.174 5.174 0 0 1-1.388-2.409 5.435 5.435 0 0 1 0-2.742 5.128 5.128 0 0 1 1.37-2.39l1.337-1.336c.164-.164.357-.2.58-.106a.563.563 0 0 1 .369.492 6.39 6.39 0 0 0 .352 1.864c.07.21.023.398-.141.562l-.457.492a2.484 2.484 0 0 0-.756 1.776c-.012.691.229 1.289.72 1.793.493.504 1.09.755 1.794.755.703 0 1.3-.246 1.793-.738l2.355-2.355c.492-.492.738-1.09.738-1.793 0-.703-.246-1.301-.738-1.793a2.53 2.53 0 0 0-.387-.317.473.473 0 0 1-.21-.421c-.024-.422.105-.774.386-1.055l.774-.738a.527.527 0 0 1 .334-.159.597.597 0 0 1 .369.088c.258.188.504.387.738.598zm4.957-4.957a5.358 5.358 0 0 1 1.371 2.426 5.435 5.435 0 0 1 0 2.742 5.128 5.128 0 0 1-1.37 2.39l-1.337 1.336c-.164.164-.357.2-.58.106a.563.563 0 0 1-.369-.492 6.39 6.39 0 0 0-.352-1.864.514.514 0 0 1 .141-.562l.457-.492a2.484 2.484 0 0 0 .756-1.776 2.417 2.417 0 0 0-.72-1.793 2.413 2.413 0 0 0-1.794-.755c-.703 0-1.3.246-1.793.738L8.508 5.906A2.441 2.441 0 0 0 7.77 7.7c0 .703.246 1.301.738 1.793.117.117.246.223.387.317.14.093.21.234.21.421.024.422-.105.774-.386 1.055l-.774.738a.527.527 0 0 1-.334.159.597.597 0 0 1-.369-.088 9.13 9.13 0 0 1-.738-.598A5.358 5.358 0 0 1 5.133 9.07a5.435 5.435 0 0 1 0-2.742 5.128 5.128 0 0 1 1.37-2.39l2.392-2.391a5.128 5.128 0 0 1 2.39-1.371 5.435 5.435 0 0 1 2.742 0 5.358 5.358 0 0 1 2.426 1.37z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </IconBase>
);
