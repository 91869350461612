import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Section } from '../../../components';
import { getColumnSpanSize, Hidden, P, shadeOf, toEms } from '../../../global';

const OFFSET_HEIGHT = 65;

const Category = styled(P)`
  color: inherit;
  flex-basis: calc(100% / ${p => p.length});
  flex-grow: 0;
  flex-shrink: 0;
  font-size: ${toEms('10px')};
  padding-right: 23px;
`;

const Categories = styled.div`
  display: flex;
  max-width: ${p => getColumnSpanSize(9, p.theme.gridSettings)}px;
  padding: 15px 0;
  text-align: left;
  width: 100%;

  @media only screen and (max-width: 1168px) {
    max-width: ${p => getColumnSpanSize(8, p.theme.gridSettings)}px;
  }
`;

const CategoriesContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Spacer = styled(Hidden)`
  height: 60px;
`;

// prettier-ignore
const Wrapper = styled(Section)`
  background: ${p => p.theme.color.feta};
  color: ${p => p.theme.color.peppercorn};
  cursor: auto;
  transition: opacity 0.25s ease-in-out;

  & > div {
    padding-right: 0;
    z-index: 0;
  }

  ${p => !p.menuVisible && `
    opacity: 0.5;
  `}

  ${p => p.isScrolling && `
    box-shadow: 0 0 10px ${shadeOf(p.theme.color.black, 0.25)};
    position: fixed;
    top: 65px;
    z-index: 1;
  `};
`;

const Legend = ({ activeTab, measurements, menuVisible }) => {
  const [isScrolling, setScrolling] = useState(false);
  const [legendHeight, setLegendHeight] = useState(null);

  const legendRef = useRef(null);

  const handleScroll = legend => {
    const height = window.pageYOffset;
    const legendHeight = legend - OFFSET_HEIGHT;
    if (height >= legendHeight) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  const rafEvent = legend =>
    requestAnimationFrame(() => {
      handleScroll(legend);
    });

  useEffect(() => {
    if (legendHeight !== null) {
      window.addEventListener('scroll', () => {
        rafEvent(legendHeight);
      });
      return () =>
        window.removeEventListener('scroll', () => {
          rafEvent(legendHeight);
        });
    }
  }, [legendHeight]);

  useEffect(() => {
    if (legendRef.current) {
      setLegendHeight(legendRef.current.offsetTop);
    }
  }, [activeTab, legendRef]);

  return (
    <Spacer down="lg">
      <Wrapper
        isScrolling={isScrolling}
        menuVisible={menuVisible}
        noPadding
        componentRef={legendRef}
      >
        <CategoriesContainer>
          <Categories>
            {measurements.map((measurement, i) => (
              <Category length={measurements.length} key={i}>
                {measurement}
              </Category>
            ))}
          </Categories>
        </CategoriesContainer>
      </Wrapper>
    </Spacer>
  );
};

Legend.propTypes = {
  activeTab: PropTypes.string,
  measurements: PropTypes.arrayOf(PropTypes.string),
  menuVisible: PropTypes.bool
};

export default Legend;
