import React, { cloneElement, useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Transition, TransitionGroup } from 'react-transition-group';
import {
  Actions,
  FormLayout,
  PageWrapper,
  Progress,
  Wrapper
} from './components';
import { Button } from '../../elements';

const directions = {
  BACKWARD: -1,
  FORWARD: 1
};

function MultiPartForm({
  children,
  className,
  hideSubmit = false,
  initialValues,
  onSubmit,
  showProgress
}) {
  const [pageIndex, setPageIndex] = useState(0);
  const [direction, setDirection] = useState(directions.FORWARD);
  const [values, setValues] = useState(initialValues);
  const [isTransitioning, setTransitioning] = useState(false);

  const previous = () => {
    setDirection(directions.BACKWARD);
    setPageIndex(Math.max(pageIndex - 1, 0));
  };
  const next = values => {
    setDirection(directions.FORWARD);
    setPageIndex(Math.min(pageIndex + 1, children.length - 1));
    setValues(values);
  };

  const activePage = React.Children.toArray(children)[pageIndex];
  const pageCount = React.Children.count(children);
  const isLastPage = pageIndex === pageCount - 1;
  const percentageComplete = ((pageIndex + 1) / pageCount) * 100;

  const validate = values =>
    activePage.props.validate ? activePage.props.validate(values) : {};

  const handleSubmit = (values, formikActions) => {
    if (isLastPage) {
      return onSubmit(values, formikActions);
    } else {
      formikActions.setTouched({});
      formikActions.setSubmitting(false);
      next(values);
    }
  };

  return (
    <Wrapper className={className}>
      <Formik
        initialValues={values}
        enableReinitialize={false}
        validate={validate}
        onSubmit={handleSubmit}
        render={({ handleSubmit, isSubmitting }) => (
          <PageWrapper isTransitioning={isTransitioning}>
            {showProgress && <Progress percentage={percentageComplete} />}

            <form onSubmit={handleSubmit}>
              <TransitionGroup
                childFactory={child => cloneElement(child, { direction })}
                component={null}
              >
                <Transition
                  direction={direction}
                  key={pageIndex}
                  // Add event listener so we know when the transition finishes
                  addEndListener={node => {
                    node.addEventListener(
                      'transitionend',
                      () => setTransitioning(false),
                      false
                    );
                  }}
                  // `onEnter` is called just before a transition begins
                  onEnter={() => setTransitioning(true)}
                  timeout={400}
                >
                  {(state, { direction }) => (
                    <FormLayout direction={direction} state={state}>
                      {activePage}
                    </FormLayout>
                  )}
                </Transition>
              </TransitionGroup>

              <Actions>
                {pageIndex > 0 && (
                  <Button onClick={previous} small title="Previous" />
                )}
                {!hideSubmit && (
                  <Button
                    disabled={isSubmitting}
                    small
                    style={{ marginLeft: 'auto' }}
                    title={isLastPage ? 'Submit' : 'Next'}
                    type="submit"
                  />
                )}
              </Actions>
            </form>
          </PageWrapper>
        )}
      />
    </Wrapper>
  );
}

MultiPartForm.Page = ({ children }) => children;

MultiPartForm.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hideSubmit: PropTypes.bool,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  showProgress: PropTypes.bool
};

export default MultiPartForm;
