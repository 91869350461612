import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Column, { componentType } from './Column';

// prettier-ignore
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: space-around;

  ${p => p.spaceBetween && `
    justify-content: space-between;
  `}

  ${p => p.center && `
    justify-content: center;
  `}
`;

const Grid = ({
  center,
  className,
  columns,
  component,
  componentData,
  dividers,
  linkProps,
  offsetColumns,
  spaceBetween
}) => (
  <Wrapper center={center} className={className} spaceBetween={spaceBetween}>
    {componentData.map((data, i) => (
      <Column
        center={center}
        columns={columns}
        component={component}
        data={data}
        dividers={dividers}
        key={i}
        linkProps={linkProps}
        offsetColumns={offsetColumns}
      />
    ))}
  </Wrapper>
);

export const GridType = {
  center: PropTypes.bool,
  className: PropTypes.string,
  columns: PropTypes.number.isRequired,
  component: PropTypes.oneOfType(componentType).isRequired,
  componentData: PropTypes.arrayOf(PropTypes.object).isRequired,
  dividers: PropTypes.bool,
  linkProps: PropTypes.object,
  offsetColumns: PropTypes.bool,
  spaceBetween: PropTypes.bool
};

Grid.defaultProps = {
  center: false,
  dividers: false,
  offsetColumns: false
};

Grid.propTypes = GridType;

export default Grid;
