import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { H2, P, Wysiwyg, media } from '../../../global';
import { Button, Image } from '../../../elements';

import imgStrawberries from './img/strawberries.png';
import imgSideStrawberries from './img/side-strawberries.png';

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  font-family: ${p => p.theme.fontFamily.primary};

  ${p => !p.unContain && `
    max-width: ${p.theme.grid.maxWidth};
    padding-left: ${p.theme.spacing.horizontalPadding};
    padding-right: ${p.theme.spacing.horizontalPadding};
  `}
`;

const Container = styled.div`
  display: flex;
  place-items: center;
  padding-top: 1rem;
  padding-bottom: 3rem;

  ${media.up.md`
    padding-top: 3rem;
    padding-bottom: 8rem;
  `}
`;

const ContentLeft = styled.div`
  position: relative;
  flex-basis: 100%;

  ${media.up.md`
    flex-basis: 55%;
  `}
`;

const ContentRight = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  place-items: flex-end;

  ${media.up.md`
    flex-basis: 45%;
    padding-left: 4rem;
  `}
`;

const SideImg = styled(Image)`
  position: absolute;
  z-index: -10;
  bottom: 0;
  transform: translateX( calc( -125% + 1rem ) ) translateY( 100% );
  img {
    max-width: 20rem;
  }
`;

const Headline = styled(H2)`
  margin-bottom: 1.5rem;
  color: ${p => p.theme.color.kaleLeafDark};
`;

const Paragraph = styled(Wysiwyg)`
  margin-bottom: 1.5rem;
`;

const RewardText = styled(P)`
  margin-top: 1rem;
  text-align: right;
`;

const RewardImg = styled(Image)`
  img {
    width: 100%;
    max-width: 20rem;
  }
`;

// for badge images
const imgPath = `${process.env.RAZZLE_ASSETS_CDN}/images/components/footer/`;

const Badges = styled.div`
  display: flex;
  flex-wrap: wrap;
  place-items: center;

  ${media.up.md`
    place-items: flex-end;
  `}
`;

const Badge = styled.a`
  margin: 20px 0 0 15px;
`;

const BadgeImg = styled(Image)`
  img {
    height: 50px;
    width: auto;
  }
`;

const Link = styled(Button)``;

const Callouts = ({ headline, content, link }) => {

  return (
    <Wrapper>
      <Container>
        <ContentLeft>
          <SideImg url={imgSideStrawberries} />
          {headline && <Headline>{headline}</Headline>}
          {content && (
            <>
              {/* dompurify kept running into issues, so removing for now */}
              <Paragraph dangerouslySetInnerHTML={{ __html: content }} />
            </>
          )}
          {link && (
            <Link
              title={link.title}
              url={link.url}
              target={link.target}
            />
          )}
        </ContentLeft>
        <ContentRight>
          <RewardImg url={imgStrawberries} />
          <RewardText>Earn rewards by downloading the Salata app</RewardText>
          <Badges>
            <Badge href="https://apps.apple.com/us/app/salata-salad-kitchen/id1514026040">
              <BadgeImg alt="Apple Store" url={`${imgPath}applestore.png`} />
            </Badge>
            <Badge href="https://play.google.com/store/apps/details?id=com.paytronix.client.android.app.salata">
              <BadgeImg alt="Google Play Store" url={`${imgPath}googleplay.png`} />
            </Badge>
          </Badges>
        </ContentRight>
      </Container>
    </Wrapper>
  );
};

Callouts.propTypes = {
  content: PropTypes.string,
  headline: PropTypes.string,
  link: PropTypes.shape({
    target: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string
  })
};

export default Callouts;
