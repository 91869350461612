import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, ButtonType, Image, ImageType } from '../../elements';
import { H2, H3, H4, H5, H6, P, parseContent, Wysiwyg } from '../../global';

const CardImage = styled.div`
  text-align: center;
  margin-bottom: 10px;
  max-height: 300px;
  max-width: 300px;
`;

const Copy = styled(P)`
  margin-top: 15px;
`;

const FeatureCopy = styled(P)`
  color: ${p => p.theme.color.kaleLeaf};
  font-weight: ${p => p.theme.fontWeight.bold};
`;

const Link = styled(Button)`
  margin-top: 30px;
`;

const SpecialCopy = styled(P)`
  color: ${p => p.theme.color.peppercorn};
  font-size: 16px;
  letter-spacing: 0.73px;
  line-height: 19px;
  margin-top: 15px;
`;

// prettier-ignore
const TextWrapper = styled.div`
  width: 100%;

  ${p => p.background && `
    background-color: ${p.theme.color.white};
    padding: 15px 0;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const WysiwygCopy = styled(Wysiwyg)`
  margin-top: 35px;
`;

const Card = ({
  background,
  bodyFeature,
  bodyText,
  calloutText,
  children,
  className,
  featuredText,
  headline,
  image,
  link,
  linkProps,
  mainHeadline,
  specialText,
  subHeadline,
  wysiwyg
}) => {
  const linkObject = { ...linkProps, ...link };
  return (
    <Wrapper className={className}>
      {image && (
        <CardImage>
          <Image {...image} />
        </CardImage>
      )}
      <TextWrapper background={background}>
        {mainHeadline && <H2>{mainHeadline}</H2>}
        {headline && <H3>{headline}</H3>}
        {subHeadline && <H4>{subHeadline}</H4>}
        {calloutText && <H5>{calloutText}</H5>}
        {featuredText && <H6>{featuredText}</H6>}
        {bodyFeature && <FeatureCopy>{bodyFeature}</FeatureCopy>}
        {bodyText && <Copy>{bodyText}</Copy>}
        {specialText && (
          <SpecialCopy dangerouslySetInnerHTML={parseContent(specialText)} />
        )}
        {wysiwyg && (
          <WysiwygCopy dangerouslySetInnerHTML={parseContent(wysiwyg)} />
        )}
        {link && <Link {...linkObject} />}
        {children && children}
      </TextWrapper>
    </Wrapper>
  );
};

Card.defaultProps = {
  background: false
};

export const CardType = {
  background: PropTypes.bool,
  bodyFeature: PropTypes.string,
  bodyText: PropTypes.string,
  calloutText: PropTypes.string,
  children: PropTypes.object,
  className: PropTypes.string,
  featuredText: PropTypes.string,
  headline: PropTypes.string,
  image: PropTypes.shape(ImageType),
  link: PropTypes.shape(ButtonType),
  linkProps: PropTypes.object,
  mainHeadline: PropTypes.string,
  specialText: PropTypes.string,
  subHeadline: PropTypes.string,
  wysiwyg: PropTypes.string
};

Card.propTypes = CardType;

export default Card;
