import styled from 'styled-components';

import { Container } from '../../../components';
import {
  Close as CloseIcon,
  media,
  Salata as SalataLogo,
  shadeOf
} from '../../../global';
import NavLinkBase from './NavLink';

// An `x` icon that closes the menu when pressed
export const Close = styled(CloseIcon)`
  height: 20px;
  position: absolute;
  left: 25px;
  top: 20px;
`;

// Keeps navigation items from flowing to the far edges of the screen
export const NavContainer = styled(Container)`
  align-items: center;
  color: ${p => p.theme.color.peppercorn};
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 25px;
`;

// A group of navigation items to space things within the navigation wrapper
export const NavGroup = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
`;

// The top-level list of navigation links
export const NavList = styled.ul`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  user-select: none;

  ${media.up.lg`
    align-items: center;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
    padding: 0;

  `}
`;

export const NavItem = styled.li`
  ${media.up.lg`
    align-items: center;
    display: flex;
    height: 100%;
    position: relative;
  `}
`;

// Get a hook to the NavLink className
export const NavLink = styled(NavLinkBase)`
  ${p => {
    // Hide 'Home' nav link on Desktop in favor of the Salata logo
    if (p.path === '/') {
      return media.up.lg`
        display: none;
      `;
    }
  }}
`;

// Nested navigation
export const NestedNavList = styled.ul`
  ${media.down.lg`
    padding-left: 1vw;

    > li {
      border-left: 2px solid;
      padding-left: 20px;
    }
  `}

  ${p => media.up.lg`
    background-color: ${p => p.theme.color.white};
    border-radius: 6px;
    box-shadow: 0 2px 10px 0 ${shadeOf(p.theme.color.black, 0.12)};
    min-width: 220px;
    opacity: 0;
    padding-bottom: 6px;
    padding-top: 6px;
    pointer-events: none;
    position: absolute;
    top: calc(100%);
    transform: translateY(-20%);
    transition: opacity 0.2s, transform 0.2s ease-in-out;
    left: 0;

    &:hover,
    li:hover > & {
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0);
    }

    // repeating above styles because focus-within does not work in IE
    // when used, it would break the IE hover state. so I separeted it for now
    &:focus-within {
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0);
    }
  `}
`;

// Salata logo
export const SiteLogo = styled(SalataLogo)`
  height: 33px;
  margin: 11px 0;
  width: calc(124px / 47 * 33);

  ${media.up.lg`
    height: 47px;
    margin: 7px 2.5vw 7px 0;
    width: 124px;
  `}
`;

// An optional empty space that pushes items down from under the navigation
export const Spacer = styled.div`
  display: block;
  height: 59px;

  ${media.up.lg`
    height: 65px;
  `}
`;

// Outermost navigation styles for the top bar
export const Wrapper = styled.div`
  background-color: ${p => p.theme.color.white};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  display: block;
  height: 59px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  // z-index needed to keep over other elements
  // A value of 10 is used because other page elements use z-index, too
  z-index: 10;
  ${p => p.fixed && p.theme.withElevation(2)};

  ${media.up.lg`
    height: 65px;
  `}
`;
