import React, { useState } from 'react';
import axios from 'axios';
import {
  Heading,
  PopularPosts,
  LoadMoreButtonWrapper,
  LoadMoreButton
} from './components';

import { Section } from '../../components';
import { PageType } from '../../global';
import BaseLayout from '../../layouts/Base';
import { Hero } from '../../sections';
import Post from './Post';
import TinyPost from './TinyPost';
import RecentPosts from './RecentPosts';

const fetchRecentPosts = (page = 1, id = null) => {
  return new Promise((resolve, reject) => {
    axios
      .get('/recent-posts', {
        params: { id: id, page: page, perPage: 6 }
      })
      .then(newPosts => {
        resolve(newPosts.data.recentPosts);
      })
      .catch(err => {
        console.log(err);
        reject(err);
      });
  });
};

const BlogLanding = ({
  acf,
  featuredPost,
  popularPosts,
  recentPosts,
  recentPostsTotalPages,
  ...layoutProps
}) => {
  const [localRecentPosts, setLocalRecentPosts] = useState(recentPosts);
  const [nextPage, setNextPage] = useState(2);
  const [loadingRecentPosts, setLoadingRecentPosts] = useState(false);
  const [hasMoreRecentPosts, setHasMoreRecentPosts] = useState(
    recentPostsTotalPages > 1
  );
  const { hero } = acf;

  const handleLoadMoreClick = () => {
    if (hasMoreRecentPosts && !loadingRecentPosts) {
      setLoadingRecentPosts(true);

      fetchRecentPosts(nextPage, featuredPost.id)
        .then(newPosts => {
          setLocalRecentPosts([...localRecentPosts, ...newPosts]);
          setNextPage(nextPage + 1);
        })
        .catch(err => console.log(err))
        .then(() => {
          setHasMoreRecentPosts(nextPage > recentPostsTotalPages);
          setLoadingRecentPosts(false);
        });
    }
  };

  return (
    <BaseLayout footerDivider {...layoutProps}>
      <Hero centered dividerBottomColor="white" overlap {...hero} />

      {featuredPost !== null && (
        <Section align="left" noBottomPadding>
          <Heading>Featured Post</Heading>
          <Post fullSize {...featuredPost} />
        </Section>
      )}

      {popularPosts !== null && (
        <Section align="left" noBottomPadding>
          <Heading>Popular Posts</Heading>
          <PopularPosts
            columns={3}
            component={Post}
            componentData={popularPosts}
            spaceBetween
          />
        </Section>
      )}

      {localRecentPosts !== null && (
        <Section align="left" halfTopPadding halfBottomPadding>
          <Heading>Recent Posts</Heading>

          <RecentPosts component={TinyPost} componentData={localRecentPosts} />

          {hasMoreRecentPosts && (
            <LoadMoreButtonWrapper>
              <LoadMoreButton
                disabled={loadingRecentPosts}
                onClick={() => handleLoadMoreClick()}
                title="Load More"
              />
            </LoadMoreButtonWrapper>
          )}
        </Section>
      )}
    </BaseLayout>
  );
};

BlogLanding.propTypes = {
  ...PageType
};

export default BlogLanding;
