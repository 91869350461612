import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LegalMenu, Section } from '../../components';
import { PageType, toEms, Wysiwyg } from '../../global';
import ContentWithSidebar from '../../layouts/ContentWithSidebar';

const LegalWysiwyg = styled(Wysiwyg)`
  h4,
  h6 {
    color: ${p => p.theme.color.kaleLeaf};
  }

  h6 {
    margin-bottom: 0;
  }

  a {
    color: ${p => p.theme.color.redPepper};
  }

  .date-last-modified {
    color: ${p => p.theme.color.peppercornLight};
    font-size: ${toEms(16)};
  }

  ol {
    list-style: decimal;
    margin-left: 30px;

    li {
      list-style: inherit;
      padding-left: 10px;

      &::before {
        display: none;
      }
    }
  }

  ul {
    margin-left: 10px;
  }
`;

const Legal = ({ content, legalNavigation, ...layoutProps }) => {
  const [height, setHeight] = useState(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setHeight(contentRef.current.getBoundingClientRect().height);
    }
  }, [contentRef]);

  const mainContent = (
    <Section align="left" halfTopPadding>
      <LegalWysiwyg
        dangerouslySetInnerHTML={{ __html: content }}
        ref={contentRef}
      />
    </Section>
  );

  const sidebarContent = (
    <LegalMenu menu={legalNavigation} contentHeight={height} />
  );

  return (
    <ContentWithSidebar
      {...layoutProps}
      center
      mainContent={mainContent}
      sidebarContent={sidebarContent}
      unContain
    />
  );
};

Legal.propTypes = {
  content: PropTypes.string.isRequired,
  legalNavigation: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  ...PageType
};

export default Legal;
