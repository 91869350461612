import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getColumnSpanSize, media, P } from '../../../../../global';

import { subFilters } from './data';

// prettier-ignore
const Input = styled.input`
  height: 18px;
  margin-right: 10px;
  position: relative;
  width: 18px;

  &::before {
    border: 1px solid ${p => p.theme.color.peppercornLight};
    border-radius: 100%;
    content: '';
    cursor: pointer;
    height: 18px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 0.2s ease;
    width: 18px;
  }

  ${p => p.active && `
    &::before {
      background: ${p.theme.color.kaleLeaf};
    }
  `}
`;

// prettier-ignore
const Label = styled(P)`
  color: ${p => p.theme.color.blackBean};
  transition: color 0.2s ease;
  white-space: nowrap;
  
  ${p => p.active && `
    color: ${p.theme.color.kaleLeaf};
  `}
`;

// prettier-ignore
const RadioItem = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1 1 auto;
  position: relative;

  ${media.down.md`
    padding: 0 20px;
  `}
`;

// prettier-ignore
const Wrapper = styled.form`
  display: flex;
  margin-top: 30px;
  max-width: ${p => getColumnSpanSize(6, p.theme.gridSettings)}px;
  overflow: auto;
  padding-bottom: 15px;
  width: 100%;

  ${media.down.md`
    max-width: none;
  `}

  ${media.down.sm`
    display: block;
    padding-bottom: 0;
  `}

  ${p => !p.visible && `
    height: 0;
    padding: 0;
  `}
`;

const RadioButtons = ({
  active,
  setActive,
  setIngredientsAnimating,
  visible
}) => {
  const handleChange = e => {
    e.preventDefault();
    const value = e.target.value;

    setIngredientsAnimating(true);
    setTimeout(() => {
      setActive(value);
      setIngredientsAnimating(false);
    }, 250);
  };

  return (
    <Wrapper visible={visible}>
      <RadioItem>
        <Label>Choose your style:</Label>
      </RadioItem>
      {subFilters.map((filter, i) => (
        <RadioItem key={i}>
          <Input
            active={active === filter.value}
            type="radio"
            name="Type"
            onClick={e => handleChange(e)}
            value={filter.value}
          />
          <Label active={active === filter.value}>{filter.name}</Label>
        </RadioItem>
      ))}
    </Wrapper>
  );
};

RadioButtons.propTypes = {
  active: PropTypes.string,
  setActive: PropTypes.func,
  setIngredientsAnimating: PropTypes.func,
  visible: PropTypes.bool
};

export default RadioButtons;
