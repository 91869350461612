import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon } from '../../../../elements';
import {
  Desserts,
  Drinks,
  Entrees,
  Extras,
  getColumnSpanSize,
  media,
  P,
  Soups
} from '../../../../global';

import RadioButtons from './RadioButtons';

// prettier-ignore
const TabIconName = styled(P)`
  margin: 10px 0 0;
  transition: color 0.25s ease-in-out;
  white-space: nowrap;
  position: relative;
  padding: 0 4.5% 15px 4.5%;

  &::after {
    background: ${p => p.theme.color.redPepper};
    bottom: 0;
    display: block;
    height: 4px;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.25s, transform 0.35s;
  }

  ${p => p.active && `
    color: ${p.theme.color.redPepper};

    &::after {
      opacity: 1;
      transform: scale(1);
    }
  `}

  ${media.down.sm`
    &::after {
      content: '';
    }
  `}

  ${media.down.xs`
    padding-left: 25px;
    padding-right: 25px;
  `}
`;

// prettier-ignore
const TabIcon = styled(Icon)`
  svg {
    fill: ${p => p.active
    ? p => p.theme.color.redPepper
    : p => p.theme.color.peppercorn};
    height: 30px;
    transition: fill 0.25s ease-in-out;
    width: 30px
  }
`;

// prettier-ignore
const NavItem = styled.div`
  cursor: pointer;
  display: inline-block;
  padding: 15px 0 0;
  text-align: center;
  flex: 1 1 auto;

  ${media.up.sm`
    width: 20%;
  `}

  ${p => media.up.lg`
    &:hover {
      ${TabIconName} {
        color: ${p.theme.color.redPepper};
      }

      ${TabIcon} svg {
        fill: ${p.theme.color.redPepper};
      }
    }
  `}

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

// prettier-ignore
const TabsWrapper = styled.div`
  align-items: center;
  border-bottom: 1px solid ${p => p.theme.color.peppercornLight};
  display: flex;
  max-width: ${p => getColumnSpanSize(5, p.theme.gridSettings)}px;
  overflow: hidden;
  position: relative;
  width: 100%;

  ${media.down.md`
    max-width: none;
  `}

  ${media.down.sm`
    overflow: auto;
  `}

  ${media.up.sm`
    &::after {
      content: '';
      background: ${p => p.theme.color.redPepper};
      bottom: 0;
      display: block;
      height: 4px;
      left: 0;
      position: absolute;
      transition: transform 0.25s;
      width: ${p => 100 / p.length}%;
      will-change: transform;

      ${p => p.active !== null ? `
        transform: translateX(${100 * p.active}%);
      ` : `
        transform: translateX(-101%);
      `};
    }
  `}
`;

const Wrapper = styled.div`
  flex: 1 1 auto;

  ${media.down.md`
    margin-bottom: 30px;
  `}
`;

const tabIconMap = {
  Desserts,
  Drinks,
  Entrees,
  Extras,
  Soups
};

const Tabs = ({
  activeRadio,
  activeTab,
  handleTabSelection,
  setActiveRadio,
  setIngredientsAnimating,
  tabs
}) => (
  <Wrapper>
    <TabsWrapper active={tabs.indexOf(activeTab)} length={tabs.length}>
      {tabs.map((tab, i) => {
        const icon = tabIconMap[tab];
        return (
          <NavItem key={i} onClick={() => handleTabSelection(tab, i)}>
            <TabIcon active={tabs.indexOf(activeTab) === i} icon={icon} />
            <TabIconName active={tabs.indexOf(activeTab) === i}>
              {tab}
            </TabIconName>
          </NavItem>
        );
      })}
    </TabsWrapper>
    <RadioButtons
      active={activeRadio}
      setActive={setActiveRadio}
      setIngredientsAnimating={setIngredientsAnimating}
      visible={activeTab === 'Entrees'}
    />
  </Wrapper>
);

Tabs.propTypes = {
  activeRadio: PropTypes.string,
  activeTab: PropTypes.string,
  handleTabSelection: PropTypes.func,
  setActiveRadio: PropTypes.func,
  setIngredientsAnimating: PropTypes.func,
  tabs: PropTypes.arrayOf(PropTypes.string)
};

export default Tabs;
