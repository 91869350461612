import React from 'react';
import PropTypes from 'prop-types';

import { Card, Section } from '../../components';
import { PageType } from '../../global';
import BaseLayout from '../../layouts/Base';
import {
  FeaturedContent,
  Hero,
  LocationGallery,
  MultiFeature
} from '../../sections';
// import Reviews from '../../sections/Reviews';
import { heroParticles } from './utils'; // Remember to re-import rockOurWorldParticles when re-enabling the Reviews component!

const Careers = ({ acf, ...layoutProps }) => {
  const {
    community,
    customizeMySalad,
    hero,
    noUsWithoutYou,
    // rockOurWorld,
    saladKitchen,
    whereWereGrowing
  } = acf;

  return (
    <BaseLayout {...layoutProps}>
      {hero && (
        <Hero
          dividerBottomColor="white"
          particlesData={heroParticles}
          {...hero}
        />
      )}
      {noUsWithoutYou && (
        <Section halfTopPadding>
          <FeaturedContent
            alignment="right"
            {...noUsWithoutYou}
            reverseImageMobile
          />
        </Section>
      )}
      {saladKitchen && (
        <Section
          bgColor="pink"
          dividerBottomColor="white"
          dividerTopColor="white"
          insetDividerBottom
          insetDividerTop
          noPadding
        >
          <MultiFeature {...saladKitchen} columns={3} component={Card} />
        </Section>
      )}
      {whereWereGrowing && (
        <Section>
          <LocationGallery {...whereWereGrowing} />
        </Section>
      )}
      {community && (
        <Section
          bgColor="blue"
          dividerTopColor="white"
          insetDividerTop
          halfBottomPadding
          noTopPadding
        >
          <FeaturedContent {...community} reverseImageMobile />
        </Section>
      )}
      {/* {rockOurWorld && (
        <Section
          dividerTopColor="blue"
          particlesData={rockOurWorldParticles}
          noBottomPadding
        >
          <Reviews intro={rockOurWorld.intro} reviews={rockOurWorld.content} />
        </Section>
      )} */}
      {customizeMySalad && (
        <Section
          contentBelow
          dividerTopColor="blue"
          dividerBottomColor="tan"
          dividerBottomReverse
          insetDividerBottom
          noBottomPadding
        >
          <FeaturedContent
            alignment="right"
            centerTextMobile
            {...customizeMySalad}
          />
        </Section>
      )}
    </BaseLayout>
  );
};

Careers.propTypes = {
  ...PageType,
  acf: PropTypes.shape({
    community: PropTypes.object,
    customizeMySalad: PropTypes.object,
    hero: PropTypes.object,
    noUsWithoutYou: PropTypes.object,
    rockOurWorld: PropTypes.object,
    saladKitchen: PropTypes.object,
    whereWereGrowing: PropTypes.object
  }).isRequired
};

export default Careers;
