import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const closeOne = keyframes`
  0% { transform: translateY(7px) rotate(135deg); }
  50% { transform: translateY(7px) rotate(0deg); }
  100% { transform: translateY(0); }
`;

const closeThree = keyframes`
  0% { transform: translateY(-7px) rotate(225deg); }
  50% { transform: translateY(-7px) rotate(0deg); }
  100% { transform: translateY(0); }
`;

const openOne = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(7px); }
  100% { transform: translateY(7px) rotate(135deg); }
`;

const openThree = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-7px); }
  100% { transform: translateY(-7px) rotate(225deg); }
`;

const Hamburger = styled.div`
  animation-play-state: ${p => (p.clicked ? 'running' : 'paused')};
  animation: ${p => (p.expanded ? openOne : closeOne)} 0.5s forwards 1;
  background: ${p => p.theme.color.peppercorn};
  height: 3px;
  position: absolute;
  top: 0;
  transform: rotate(0deg);
  transition: opacity 0.5s ease, transform 0.5s ease-in-out;
  width: 100%;
`;

// prettier-ignore
const HamburgerTwo = styled(Hamburger)`
  animation: none;
  top: 45%;

  ${p => p.expanded && `
    opacity: 0;
    transform: scale(0);
  `}
`;

const HamburgerThree = styled(Hamburger)`
  animation: ${p => (p.expanded ? openThree : closeThree)} 0.5s forwards 1;
  top: 90%;
`;

const Wrapper = styled.div`
  height: 15px;
  position: relative;
  width: 24px;
  z-index: 15;
`;

const Menu = ({ clicked, isExpanded, onClick }) => (
  <Wrapper onClick={onClick}>
    <Hamburger clicked={clicked} expanded={isExpanded} />
    <HamburgerTwo expanded={isExpanded} />
    <HamburgerThree expanded={isExpanded} />
  </Wrapper>
);

Menu.propTypes = {
  clicked: PropTypes.bool,
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Menu;
