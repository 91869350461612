import { FastField } from 'formik';
import axios from 'axios';
import React, { useRef, useState } from 'react';

import { FormField, Loading, MultiPartForm } from '../../../components';
import {
  combineValidations,
  selectedSomething,
  validateEmail,
  validateRequired,
  validatePhone,
  validateZipCode
} from '../../../components/FormField/utils';
import { ErrorText } from '../../../components/FormField/components';
import {
  FormLayout,
  PageInformation,
  Step,
  StyledForm,
  Subtitle,
  SuccessMessage,
  Title,
  Wrapper
} from './components';
import {
  businessOwnershipExperience,
  form,
  hearAboutOptions,
  initialValues,
  liquidityOptions,
  netWorthOptions,
  restaurantExperience,
  timeFrame
} from './data';

const Field = props => <FastField component={FormField} {...props} />;

const Form = () => {
  const [loading, setLoading] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const contentRef = useRef(null);

  const handleSubmit = (values, actions) => {
    values[form.PHONE] = values[form.PHONE].replace(/[()-\s]/g, '');
    setLoading(true);
    setErrorMessage('');

    axios
      .post('https://prod-18.westus.logic.azure.com:443/workflows/2822b8fa285442e0b29d070a086a0e09/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=cFuY3ObtRnheOKq0MXJOCaOfkazT9Jinsk8dIeD3FRE', {
        body: values
      })
      .then(res => {
        setLoading(false);
	    setMaxHeight(contentRef.current.clientHeight);
	    setTimeout(() => setSubmitted(true), 100);
	    actions.setSubmitting(false);
      })
      .catch(err => {
        if (err.response.data === 'DUPLICATES_DETECTED') {
          setErrorMessage('This data may have been submitted before.');
        } else {
          setErrorMessage('There was a problem submitting your information. Please contact itsupport@salata.com');
        }
        setLoading(false);
      });
  };

  return (
    <Wrapper maxHeight={maxHeight} ref={contentRef} submitted={submitted}>
      <StyledForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        submitted={submitted}
      >
        <MultiPartForm.Page>
          <Title>Franchise Request Form</Title>
          <PageInformation>
            <Subtitle>Contact Information</Subtitle>
            <Step>1 of 2</Step>
          </PageInformation>

          <FormLayout>
            <Field
              columns={{ sm: 6 }}
              label="First Name"
              name={form.FIRST_NAME}
              type="text"
              validate={validateRequired}
            />
            <Field
              columns={{ sm: 6 }}
              label="Last Name"
              name={form.LAST_NAME}
              type="text"
              validate={validateRequired}
            />
            <Field
              label="Street Address"
              name={form.STREET_ADDRESS}
              type="text"
              validate={validateRequired}
            />
            <Field
              columns={{ sm: 6 }}
              label="City"
              name={form.CITY}
              type="text"
              validate={validateRequired}
            />
            <Field
              columns={{ default: 6, sm: 3 }}
              label="State"
              name={form.STATE}
              type="state"
              placeholder="Select a state"
              validate={validateRequired}
            />

            <Field
              columns={{ default: 6, sm: 3 }}
              label="Zip Code"
              name={form.ZIP}
              type="text"
              validate={combineValidations(validateRequired, validateZipCode)}
            />
            <Field
              label="Email Address"
              name={form.EMAIL}
              type="email"
              validate={combineValidations(validateRequired, validateEmail)}
            />

            <Field
              label="Phone Number"
              name={form.PHONE}
              type="tel"
              validate={combineValidations(validateRequired, validatePhone)}
              placeholder="(555) 555-5555"
            />

            <Field
              label="How did you hear about us?"
              name={form.HEAR_ABOUT_US}
              type="select"
              validate={selectedSomething}
            >
              {hearAboutOptions.map((option, i) => {
                const { disabled, value } = option;
                return (
                  <option disabled={disabled} key={i} value={value}>
                    {value}
                  </option>
                );
              })}
            </Field>
          </FormLayout>
        </MultiPartForm.Page>

        <MultiPartForm.Page>
          <Title>Franchise Request Form</Title>
          <PageInformation>
            <Subtitle>Contact Information</Subtitle>
            <Step>2 of 2</Step>
          </PageInformation>

          <FormLayout>
            <Field
              label="Location of Interest"
              name={form.LOCATION_OF_INTEREST}
              type="text"
              validate={validateRequired}
            />

            <Field
              columns={{ sm: 6 }}
              label="Approximate Liquidity"
              name={form.LIQUIDITY}
              type="select"
              validate={selectedSomething}
            >
              {liquidityOptions.map((option, i) => {
                const { disabled, value } = option;
                return (
                  <option disabled={disabled} key={i} value={value}>
                    {value}
                  </option>
                );
              })}
            </Field>

            <Field
              columns={{ sm: 6 }}
              label="Approximate Net Worth"
              name={form.NET_WORTH}
              type="select"
              validate={selectedSomething}
            >
              {netWorthOptions.map((option, i) => {
                const { disabled, value } = option;
                return (
                  <option disabled={disabled} key={i} value={value}>
                    {value}
                  </option>
                );
              })}
            </Field>

            <Field
              columns={{ sm: 6 }}
              label="Restaurant Experience"
              name={form.RESTAURANT_EXPERIENCE}
              type="select"
              validate={selectedSomething}
            >
              {restaurantExperience.map((option, i) => {
                const { disabled, value } = option;
                return (
                  <option disabled={disabled} key={i} value={value}>
                    {value}
                  </option>
                );
              })}
            </Field>

            <Field
              columns={{ sm: 6 }}
              label="Business Ownership Experience"
              name={form.BUSINESS_OWNERSHIP_EXPERIENCE}
              type="select"
              validate={selectedSomething}
            >
              {businessOwnershipExperience.map((option, i) => {
                const { disabled, value } = option;
                return (
                  <option disabled={disabled} key={i} value={value}>
                    {value}
                  </option>
                );
              })}
            </Field>

            <Field
              label="Time Frame"
              name={form.TIME_FRAME}
              type="select"
              validate={selectedSomething}
            >
              {timeFrame.map((option, i) => {
                const { disabled, value } = option;
                return (
                  <option disabled={disabled} key={i} value={value}>
                    {value}
                  </option>
                );
              })}
            </Field>

            <Field
              label="Message"
              name={form.MESSAGE}
              placeholder="Enter a message..."
              rows="4"
              type="textarea"
              required={false}
            />
          </FormLayout>
        </MultiPartForm.Page>
      </StyledForm>
      <Loading isLoading={loading} />
      <SuccessMessage
        bodyText="Your message has been sent. We’ll be sure to reply as soon as we can."
        headline="got it"
        submitted={submitted}
      />
      <ErrorText>{errorMessage}</ErrorText>
    </Wrapper>
  );
};

export default Form;
