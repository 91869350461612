import { getOr } from 'unchanged';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { media, setColumnWidth, toEms } from '../../global';

/**
 * Adds `columns` prop allowing setting width based on an object.
 * @example <caption>100% width on smaller screens; 1/2 width on medium-sized screen</caption>
 * <Component columns={{ md: 6 }} />
 * @example <caption>8 columns on smaller screens; 2 columns starting at samm screen</caption>
 * <Component columns={{ default: 8, sm: 2 }} />
 */
const columns = css`
  width: ${p =>
    setColumnWidth(getOr(12, 'columns.default', p), p.theme.gridSettings)};

  ${({ columns = {}, theme }) =>
    Object.entries(columns).map(
      ([key, value]) =>
        key in media.up &&
        media.up[key]`
          width: ${setColumnWidth(value, theme.gridSettings)};
        `
    )};
`;

// prettier-ignore
export const ControlWrapper = styled.div`
  align-items: center;
  border-radius: 2px;
  border: 1px solid;
  border-color: ${p =>
    p.hasError ? p.theme.color.redPepper : p.theme.color.peppercornLight};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  ${p => p.type === 'radio' && `
    border: none;
    justify-content: flex-start;
  `}
`;

export const ErrorText = styled.div`
  ${p => p.theme.type.label};
  color: ${p => p.theme.color.redPepper};
`;

// prettier-ignore
export const Input = styled.input`
  border-radius: 2px;
  color: ${p => p.theme.color.peppercorn};
  font-family: ${p => p.theme.fontFamily.primary};
  height: ${toEms(44)};
  line-height: ${toEms(28)};
  padding: 0 ${toEms(12)};
  position: relative;
  width: 100%;

  :focus {
    box-shadow: 0 0 0 4px ${p => transparentize(0.5, p.theme.color.avocado)},
      0 0 0 2px ${p => p.theme.color.avocado};
  }

  ::placeholder {
    color: ${p => p.theme.color.peppercornLight};
  }

  ::before {
      border: 1px solid ${p => p.theme.color.peppercornLight};
      border-radius: 100%;
      content: '';
      height: 20px;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transform-origin: center center;
      transition: background-color 0.2s ease-in-out;
      width: 20px;
    }

  ${p => p.type === 'radio' && `
    height: ${toEms(30)};
    margin-right: 10px;
    width: 20px;

    :focus ::before {
      border: 1px solid ${p.theme.color.avocado};
    }

    :checked::before {
      background-color: ${p.theme.color.avocado};
    }
  `}
`;

// prettier-ignore
export const Label = styled.label`
  ${p => p.theme.type.label};
  color: ${p => p.theme.color.peppercorn};

  ${p => p.type === 'radio' && `
    color: ${p.theme.color.blackBean};
  `}
`;

export const Required = styled.span`
  color: ${p => p.theme.color.redPepper};
`;

export const Select = styled(Input).attrs({ as: 'select' })`
  cursor: pointer;
  /**
   * Allow 'SelectTriangle' to be in pressable area but not overlap content.
   * 36px is left/right margin for the Triangle + the Triangle's width
   */
  margin-right: -36px;
  padding-right: 36px;

  &::-ms-expand {
    display: none;
  }

  option {
    font-weight: normal;
  }
`;

export const SelectTriangle = styled.div`
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid ${p => p.theme.color.peppercorn};
  margin: 0 12px;
  pointer-events: none;
`;

export const TextArea = styled(Input).attrs({ as: 'textarea' })`
  height: auto;
  resize: vertical;
`;

// prettier-ignore
export const Wrapper = styled.div`
  ${columns};
  display: inline-block;
  margin-bottom: 25px;

  ${p => p.type === 'radio' && `
    margin-bottom: 0;
  `}
`;
