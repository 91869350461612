import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { media } from '../../../global';

import Content, { ContentType } from './Content';
import MapFeature, { MapFeatureType } from './MapFeature';

const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;

  ${media.down.lg`
    display: block;
  `}
`;

const Growing = ({ content, locations }) => {
  const [active, setActive] = useState('current');

  return (
    <Wrapper>
      <Content {...content} active={active} setActive={setActive} />
      <MapFeature {...locations} active={active} />
    </Wrapper>
  );
};

export const GrowingType = {
  content: PropTypes.shape(ContentType),
  locations: PropTypes.shape(MapFeatureType)
};

Growing.propTypes = GrowingType;

export default Growing;
