import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, ButtonType } from '../../../elements';
import { media, P } from '../../../global';

const FooterText = styled(P)`
  padding: 12px 35px 12px 0;
  text-align: left;
  width: 65%;

  ${media.down.xl`
    padding: 12px 0 30px;
    width: 100%;
  `}
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 32px 0;

  ${media.down.sm`
    padding: 32px 25px;
  `}
`;

const Footer = ({ bodyText, link }) => (
  <Wrapper>
    <FooterText>{bodyText}</FooterText>
    <Button buttonType="primary" {...link} />
  </Wrapper>
);

export const FooterType = {
  bodyText: PropTypes.string.isRequired,
  link: PropTypes.shape(ButtonType).isRequired
};

Footer.propTypes = FooterType;

export default Footer;
