import PropTypes from 'prop-types';
import React from 'react';

import { Hidden } from '../../../../global';

import {
  MenuText,
  NavButton,
  NavItem,
  NavList,
  NavWrapper
} from '../components';

const Nav = ({ isExpanded, linkProps, menu }) => (
  <NavWrapper isExpanded={isExpanded}>
    <NavList>
      {menu.map((item, i) => (
        <NavItem href={item.path} key={i} title={item.title}>
          {item.title}
        </NavItem>
      ))}
    </NavList>
    <Hidden up="lg">
      <MenuText>own the next salad kitchen</MenuText>
    </Hidden>
    <NavButton {...linkProps} title="Get Started" />
  </NavWrapper>
);

export const NavType = {
  id: PropTypes.number,
  order: PropTypes.number,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

Nav.propTypes = {
  isExpanded: PropTypes.bool,
  linkProps: PropTypes.object,
  menu: PropTypes.arrayOf(PropTypes.shape(NavType))
};

export default Nav;
