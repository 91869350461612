import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { H6, P } from '../../global';

const PADDING = 14;

const Wrapper = styled.div`
  border-bottom: 1px solid ${p => p.theme.color.peppercornLight};
  height: ${p => p.foldHeight}px;
  min-height: ${p => parseInt(p.theme.lineHeight.p) + (2 * PADDING)}px;
  overflow: hidden;
  transition: height 0.5s cubic-bezier(0.77, 0, 0.175, 1);
`;

const Heading = styled(H6)`
  align-items: center;
  color: ${p => p.theme.color.peppercorn};
  cursor: pointer;
  display: flex;
  font-weight: normal;
  justify-content: space-between;
  padding: ${PADDING}px 0;
  user-select: none;

  &:after {
    content: "";
    border: 2px solid transparent;
    border-top-color: ${p => p.theme.color.kaleLeaf};
    border-left-color: ${p => p.theme.color.kaleLeaf};
    flex: 0 0 auto;
    height: 12px;
    margin-left: 20px;
    right: 0px;
    top: ${p => (parseInt(p.theme.lineHeight.p) + (2 * PADDING)) / 2}px;
    transform: rotate(-135deg);
    transform-origin: 25% 25%;
    transition: transform 0.55s cubic-bezier(0.19, 1, 0.22, 1);
    width: 12px;

    ${p => p.active && `
      transform: rotate(45deg);
    `}
  }
`;

const Content = styled(P)`
  font-size: 16px;
  padding: 0 8% ${PADDING}px 2px;
`;

const Fold = ({ active, content, heading, onClick }) => {
  const [heights, setHeights] = useState({
    content: 0,
    heading: 0
  });

  const headingRef = useRef(null);
  const contentRef = useRef(null);

  // responding to page resize with hooks
  // https://gist.github.com/gaearon/cb5add26336003ed8c0004c4ba820eae
  const handleResize = () => {
    const curHeading = headingRef.current;
    const curContent = contentRef.current;

    if (curHeading && curContent) {
      setHeights({
        content: curContent.offsetHeight,
        heading: curHeading.offsetHeight
      });
    }
  };

  useEffect(() => handleResize(), []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const getHeight = () => {
    return active
      ? heights.content + heights.heading
      : heights.heading;
  };

  return (
    <Wrapper foldHeight={getHeight()}>
      <Heading ref={headingRef} active={active} onClick={onClick}>{heading}</Heading>
      <Content ref={contentRef}>{content}</Content>
    </Wrapper>
  );
};

Fold.propTypes = {
  active: PropTypes.bool,
  content: PropTypes.string,
  heading: PropTypes.string,
  onClick: PropTypes.func
};

export default Fold;
