import styled from 'styled-components';
import { Grid as GridBase } from '../../components';
import { Button } from '../../elements';
import { H6, media, shadeOf } from '../../global';

const Grid = styled(GridBase)`
  ${media.down.md`
    > :first-child {
      padding-top: 0;
    }
  `}
`;

export const Heading = styled(H6)`
  color: ${p => p.theme.color.kaleLeaf};
  margin: 16px 0;
  text-transform: uppercase;
`;

export const PopularPosts = styled(Grid)`
  border-bottom: 1px solid ${p => shadeOf(p.theme.color.peppercornLight, 0.5)};
  padding-bottom: 60px;

  ${media.down.md`
    & > *:last-child {
      padding-bottom: 20px;
    }
  `}
`;

export const LoadMoreButtonWrapper = styled.div`
  margin-top: 50px;
  text-align: center;
`;

export const LoadMoreButton = styled(Button)`
  ${p =>
    p.disabled &&
    `
    opacity: 0.5;

    & > * {
      cursor: wait;
    }
  `}
`;
