import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import styled from 'styled-components';
import { Intro, IntroType } from '../../components';
import { Image, ImageType } from '../../elements';
import { Arrow, media, P, setColumnWidth, slickCSS, toEms } from '../../global';

// prettier-ignore
const CarouselArrow = styled(Arrow)`
  cursor: pointer;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translate(-28px, -50%);
  z-index: 999;

  g {
    transform: translate(2px, 2px);
  }

  ${p => p.reverse && `
    left: auto;
    right: 0;
    transform: translate(28px, -50%);

    g {
      transform: rotate(180deg) translate(-46px, -46px);
    }
  `}
`;

const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: ${p => setColumnWidth(8, p.theme.gridSettings)};
  width: 100%;

  ${media.down.md`
    max-width: ${p => setColumnWidth(10, p.theme.gridSettings)};
  `}

  ${media.down.sm`
    max-width: 100%;
  `}

  ${slickCSS}

  .slick-slider {
    position: relative;

    .slick-track {
      display: flex;
      margin: 0 auto;

      .slick-slide {
        height: auto;
      }
    }

    .slick-dots {
      align-items: center;
      display: flex !important;
      justify-content: center;

      li {
        background: ${p => p.theme.color.peppercornLight};
        border-radius: 50%;
        height: 9px;
        margin: 0 1.5px;
        width: 9px;

        button {
          color: transparent;
        }
      }

      li.slick-active {
        background: ${p => p.theme.color.peppercorn};
        height: 12px;
        width: 12px;
      }
    }
  }
`;

const CornerImage = styled(Image)`
  max-width: ${p => setColumnWidth(5, p.theme.gridSettings)};
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(65%, -55%);

  ${media.down.sm`
    display: none;
  `}
`;

const Profile = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const ProfileDetails = styled.div`
  color: ${p => p.theme.color.blackBean};
  font-size: ${toEms('18px')};
  font-family: ${p => p.theme.fontFamily.primary};
  padding-left: 15px;
  text-align: ${p => (p.hasImage ? 'left' : 'center')};
`;

const Rating = styled.div`
  background-image: url('/images/svg/emptystars.svg');
  background-position: 0 0;
  background-size: 150px 27px;
  background-repeat: no-repeat;
  width: 150px;
  height: 27px;
  position: relative;
  display: inline-block;
  margin-top: 10px;

  &::before {
    content: '';
    background-image: url('/images/svg/stars.svg');
    width: calc(${p => (p.rating / 5) * 100}%);
    overflow: hidden;
    background-position: 0 0;
    background-size: 150px 27px;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
`;

const Review = styled.div`
  padding: 0 100px 60px;

  ${media.down.sm`
    padding: 0 30px 60px;
  `}
`;

const ReviewsImage = styled(Image)`
  width: 60px;
`;

const Quote = styled(P)`
  margin: 40px 0;
`;

const Wrapper = styled.div`
  ${media.up.md`
    padding-top: 70px;
  `}
`;

const sliderSettings = {
  dots: true,
  nextArrow: <CarouselArrow reverse />,
  prevArrow: <CarouselArrow />,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 500
};

const Reviews = ({ intro, noImage, reviews }) => (
  <Wrapper>
    <Intro {...intro} />
    <ContentWrapper>
      <Slider {...sliderSettings}>
        {reviews.map((review, i) => {
          const hasImage = !review.author.image.url === undefined;

          return (
            <Review key={i}>
              {review.rating && <Rating rating={review.rating} />}
              <Quote as="blockquote">{review.quote}</Quote>
              <Profile>
                {hasImage && <ReviewsImage {...review.author.image} />}
                <ProfileDetails hasImage={hasImage}>
                  {review.author.name}
                  <br />
                  {review.author.location}
                </ProfileDetails>
              </Profile>
            </Review>
          );
        })}
      </Slider>
    </ContentWrapper>
    {!noImage && <CornerImage url={'/images/components/reviews/peppers.png'} />}
  </Wrapper>
);

const review = {
  author: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape(ImageType)]),
    location: PropTypes.string,
    name: PropTypes.string
  }),
  quote: PropTypes.string.isRequired,
  rating: PropTypes.oneOf(['1', '1.5', '2', '2.5', '3', '3.5', '4', '4.5', '5'])
};

Reviews.propTypes = {
  intro: PropTypes.shape(IntroType),
  noImage: PropTypes.bool,
  reviews: PropTypes.arrayOf(PropTypes.shape(review))
};

export default Reviews;
