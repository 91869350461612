import React from 'react';
import IconBase from './IconBase';

export default props => (
  <IconBase height="14" viewBox="0 0 30 14" width="30" {...props}>
    <path
      strokeWidth=".875"
      d="M18.964 5.656c1.19-1.146 1.511-2.616 1.418-4.33 1.256-.14 2.374.116 3.479.598.203 1.117-.417 2.037-.469 3.133.942-.531 1.129-1.554 1.774-2.285 1.314.175 2.145 1.024 2.791 2.058.372.594.639 1.269.862 1.937.48 1.437-.006 2.781-1.301 3.605a8.411 8.411 0 0 1-1.74.856c-2.587.896-5.247 1.364-7.995 1.487-1.86.083-3.709.005-5.554-.08-3.037-.141-6.033-.609-8.864-1.806-.423-.179-.848-.393-1.207-.674-.96-.75-1.394-1.74-1.03-2.936a6.153 6.153 0 0 1 .83-1.737c1.272-1.8 2.964-3.126 4.929-4.113.266-.134.56-.214.96-.364.147.415.306.737.372 1.076.17.877.291 1.763.448 2.643.057.325.16.642.256 1.018.385-.458.601-1.48.549-2.278-.051-.785-.534-1.53-.207-2.433.87-.532 1.869-.62 2.883-.457.59.095.986.533 1.108 1.123.15.722.244 1.455.355 2.185.036.234.047.473.09.918 1.035-1.124 1.106-2.301.918-3.515.164-.107.264-.228.365-.228C16 1.053 17.026.99 18.03 1.104c.887.101 1.182.498 1.162 1.383-.017.763-.156 1.524-.26 2.284-.036.266-.127.525-.193.787l.224.098zm2.41-3.536c-.12.476-.215.827-.295 1.182-.21.947-.574 1.828-1.21 2.568-.509.592-1.11 1.043-2.05.94 0-.39-.053-.72.011-1.028.119-.571.324-1.125.458-1.694.155-.66.228-1.33-.01-2.037-.843-.442-1.72-.29-2.576-.237-.753 3.1-1.319 4.036-2.679 4.314-.479-1.491.129-3.197-.81-4.672-.736-.076-1.48-.257-2.267.22.065.349.108.679.191.999.29 1.119.07 2.208-.22 3.28-.149.55-.471 1.005-1.23.986-1.05-1.412-.766-3.232-1.36-4.822-.156.036-.284.038-.385.093-1.71.922-3.167 2.145-4.338 3.692-.257.339-.464.734-.61 1.134-.434 1.18-.082 2.145 1.035 2.723.71.367 1.46.679 2.223.917 2.585.807 5.252 1.138 7.952 1.207 1.484.039 2.97.02 4.455.013 2.781-.013 5.471-.55 8.078-1.492a6.396 6.396 0 0 0 1.61-.894c.694-.517 1.003-1.272.824-2.124-.274-1.298-.886-2.43-1.918-3.299-.197-.165-.45-.263-.713-.412-.222.345-.376.609-.553.856-.61.853-1.2 1.728-2.111 2.303-.16.101-.376.116-.58.175-.325-.734-.088-1.344.137-1.945a9.626 9.626 0 0 0 .588-2.67l-1.647-.276z"
    />
  </IconBase>
);
