import React from 'react';
import PropTypes from 'prop-types';

import states from '../../global/us-states';
import { Select } from './components';

const StateInput = ({ placeholder, ...props }) => {
  const hasPlaceholder = placeholder != null;

  return (
    <Select {...props}>
      {hasPlaceholder && (
        <option disabled value="">
          {placeholder}
        </option>
      )}
      {states.map(({ abbreviation, name }) => (
        <option key={abbreviation} value={abbreviation}>
          {name}
        </option>
      ))}
    </Select>
  );
};

StateInput.propTypes = {
  placeholder: PropTypes.string
};

export default StateInput;
