import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { Input } from '../components';
import { ControlWrapper } from './components';
import MonthSelectView from './MonthSelectView';

const MonthInput = ({
  className,
  displayFormat,
  initialDate = new Date(),
  onChange,
  onReset,
  value,
  ...props
}) => {
  const [isPickerVisible, setPickerVisibility] = useState(false);

  const contentRef = useRef(null);

  const handleClickOutside = e => {
    if (contentRef.current && !contentRef.current.contains(e.target)) {
      setPickerVisibility(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isPickerVisible]);

  return (
    <ControlWrapper className={className} ref={contentRef}>
      {/* Read-only input to retain styling while showing formatted value */}
      <Input
        onFocus={() => setPickerVisibility(true)}
        readOnly
        value={format(value, displayFormat)}
        {...props}
      />

      {isPickerVisible && (
        <MonthSelectView
          initialDate={
            initialDate instanceof Date ? initialDate : new Date(initialDate)
          }
          onChange={value => {
            setPickerVisibility(false);
            onChange(value);
          }}
          onReset={() => {
            setPickerVisibility(false);
            onReset();
          }}
        />
      )}
    </ControlWrapper>
  );
};

MonthInput.propTypes = {
  className: PropTypes.string,
  displayFormat: PropTypes.string.isRequired,
  initialDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.number,
    PropTypes.string
  ]),
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
    .isRequired
};

export default MonthInput;
