import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';

import { IntroType, Section } from '../../components';

import Begin from './Begin';
import Header from './Header';
import Ingredients from './Ingredients';
import Legend from './Legend';
import Navigation from './Navigation';
import Totals from './Totals';

import { filtersReducer, getGroups, selectedReducer } from './utils';

const NutritionBuilder = ({
  allergensList,
  data,
  intro,
  measurements,
  tabs
}) => {
  const [activeRadio, setActiveRadio] = useState('');
  const [activeTab, setActiveTab] = useState(null);
  const [allergens, setAllergens] = useReducer(filtersReducer, allergensList);
  const [displayedIngredients, setDisplayedIngredients] = useState([]);
  const [groups, setGroups] = useState([]);
  const [ingredientsAnimating, setIngredientsAnimating] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);
  // prettier-ignore
  const [selectedIngredients, setSelectedIngredients] = useReducer(selectedReducer, []);

  const handleTabSelection = (category, i) => {
    const categorizedArray = data[category];
    setActiveTab(category);

    if (!initialLoad) {
      setInitialLoad(true);
    }
    setIngredientsAnimating(true);
    setTimeout(() => {
      setDisplayedIngredients(categorizedArray);
      setIngredientsAnimating(false);
    }, 250);
  };

  useEffect(() => {
    const groupData = getGroups(activeTab, displayedIngredients, activeRadio);
    setGroups(groupData);
  }, [displayedIngredients, activeRadio, activeTab]);

  return (
    <>
      <Section halfBottomPadding>
        <Header {...intro} />
      </Section>
      <Navigation
        activeRadio={activeRadio}
        activeTab={activeTab}
        allergens={allergens}
        handleTabSelection={handleTabSelection}
        setActiveRadio={setActiveRadio}
        setAllergens={setAllergens}
        setIngredientsAnimating={setIngredientsAnimating}
        tabs={tabs}
      />
      <Legend
        activeTab={activeTab}
        measurements={measurements}
        menuVisible={initialLoad}
      />
      <Begin visible={!initialLoad} />
      <Ingredients
        activeRadio={activeRadio}
        activeTab={activeTab}
        allergens={allergens}
        groups={groups}
        ingredientsAnimating={ingredientsAnimating}
        measurements={measurements}
        selected={selectedIngredients}
        setSelected={setSelectedIngredients}
      />
      <Totals
        measurements={measurements}
        selected={selectedIngredients}
        setSelected={setSelectedIngredients}
      />
    </>
  );
};

NutritionBuilder.propTypes = {
  allergensList: PropTypes.array,
  data: PropTypes.any,
  intro: PropTypes.shape(IntroType),
  measurements: PropTypes.arrayOf(PropTypes.string),
  tabs: PropTypes.array
};

export default NutritionBuilder;
