import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  ColorOptions,
  Curves,
  DotGroup,
  Dots,
  media,
  Seeds,
  Stars
} from '../../../global';

// prettier-ignore
const ParticlesWrapper = styled.div`
  position: absolute;
  z-index: 1;

  ${p => ((p.position === 'TOP_LEFT') || (p.position === 'TOP_RIGHT')) && `
    top: -7%;

    ${(p.noPadding || p.noTopPadding) ? `
      top: -20%;
    ` : ``}
  `}

  ${p => ((p.position === 'BOTTOM_LEFT') || (p.position === 'BOTTOM_RIGHT')) && `
    bottom: -7%;

    ${(p.noBottomPadding || p.noPadding) ? `
      bottom: -20%;
    ` : ``}
  `}

  ${p => ((p.position === 'TOP_LEFT') || (p.position === 'BOTTOM_LEFT')) && `
    left: 0;
    transform: translateX(-200px);
  `}

  ${p => ((p.position === 'TOP_RIGHT') || (p.position === 'BOTTOM_RIGHT')) && `
    right: 0;
    transform: translateX(200px);
  `}

  ${p => media.down.md`
    ${((p.position === 'TOP_LEFT') || (p.position === 'BOTTOM_LEFT')) && `
      transform: translateX(-200px) scale(0.6);
    `}

    ${((p.position === 'TOP_RIGHT') || (p.position === 'BOTTOM_RIGHT')) && `
      transform: translateX(200px) scale(0.6);
    `}
  `}
`;

const particlesMap = {
  Curves,
  DotGroup,
  Dots,
  Seeds,
  Stars
};

const Particles = ({
  component,
  fill,
  noBottomPadding,
  noPadding,
  noTopPadding,
  position
}) => {
  const Particle = particlesMap[component];
  return (
    <ParticlesWrapper
      noBottomPadding={noBottomPadding}
      noPadding={noPadding}
      noTopPadding={noTopPadding}
      position={position}
    >
      <Particle fill={fill} />
    </ParticlesWrapper>
  );
};

export const ParticlesType = {
  component: PropTypes.string,
  fill: PropTypes.oneOf(ColorOptions),
  noBottomPadding: PropTypes.bool,
  noPadding: PropTypes.bool,
  noTopPadding: PropTypes.bool,
  position: PropTypes.string
};

Particles.propTypes = ParticlesType;

export default Particles;
