// GT Eesti Pro Display
const GTEestiRegular = '/fonts/GTEesti_Regular.woff'; // regular
const GTEestiBold = '/fonts/GTEesti_Bold.woff'; // bold
const GTEestiUltraBold = '/fonts/GTEesti_UltraBold.woff'; // ultra bold

export default {
  'GT Eesti': [
    {
      src: GTEestiRegular,
      style: 'normal',
      weight: '400'
    },
    {
      src: GTEestiBold,
      style: 'normal',
      weight: '700'
    },
    {
      src: GTEestiUltraBold,
      style: 'normal',
      weight: '900'
    }
  ]
};
