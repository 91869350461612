export const heroParticles = [
  {
    component: 'Curves',
    fill: 'redLight',
    position: 'BOTTOM_LEFT'
  }
];

export const rockOurWorldParticles = [
  {
    component: 'Dots',
    fill: 'green',
    position: 'TOP_LEFT'
  }
];
