import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import styled from 'styled-components';
import { Card, CardType } from '../../components';
import {
  Arrow,
  P,
  media,
  setColumnWidth,
  shadeOf,
  slickCSS
} from '../../global';

const ArrowBase = styled(Arrow)`
  height: 25px;
  width: 25px;

  ${media.down.sm`
    height: 17px;
    width: 17px;
  `}
`;

// prettier-ignore
const ArrowWrapper = styled.div`
  align-items: center;
  background: ${p => shadeOf(p.theme.color.black, 0.5)};
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  display: flex !important;
  height: 65px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 45%;
  transform: translateY(-45%);
  width: 48px;
  z-index: 999;

  ${ArrowBase} g {
    transform: translate(2px, 2px);
  }

  ${p => p.reverse && `
    left: auto;
    right: 0;
    transform: rotate(180deg) translateY(45%);
  `}

  ${media.down.sm`
    height: 45px;
    width: 33px;
  `}
`;

const Content = styled.div`
  max-width: ${p => setColumnWidth(5, p.theme.gridSettings)};
  order: ${p => (p.align === 'right' ? 1 : 0)};
  text-align: left;
  width: 100%;

  ${p => media.down.md`
    max-width: 100%;
    padding-${p.align === 'right' ? 'top' : 'bottom'}: 70px;
  `}
`;

const Gallery = styled.div`
  max-width: calc(
    ${p => setColumnWidth(6, p.theme.gridSettings)} +
      ${p => p.theme.gridSettings.gutterWidth}px
  );
  width: 100%;

  ${media.down.md`
    max-width: none;
  `}

  ${slickCSS}

  .slick-slider {
    position: relative;

    .slick-track {
      display: flex;
      margin: 0 auto;

      .slick-slide {
        height: 29vw;
        max-height: 420px;

        ${media.down.md`
          height: 57vw;
          max-height: none;
        `}

        div {
          height: 100%;
        }
      }
    }

    .slick-dots {
      align-items: center;
      display: flex !important;
      justify-content: center;
      margin-top: 20px;

      li {
        background: ${p => p.theme.color.peppercornLight};
        border-radius: 50%;
        height: 9px;
        margin: 0 1.5px;
        width: 9px;

        button {
          color: transparent;
          cursor: pointer;
        }
      }

      li.slick-active {
        background: ${p => p.theme.color.peppercorn};
        height: 12px;
        width: 12px;
      }
    }
  }
`;

const Location = styled(P)`
  background: ${p => shadeOf(p.theme.color.black, 0.75)};
  bottom: 0;
  left: 0;
  padding: 10px;
  position: absolute;
`;

const SliderImage = styled.div`
  background-image: url(${p => p.background});
  background-position: center center;
  background-size: cover;
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const sliderSettings = {
  dots: true,
  nextArrow: (
    <ArrowWrapper reverse>
      <ArrowBase />
    </ArrowWrapper>
  ),
  prevArrow: (
    <ArrowWrapper>
      <ArrowBase />
    </ArrowWrapper>
  ),
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 500
};

const LocationGallery = ({ alignContent = 'left', content, images }) => (
  <Wrapper>
    <Content align={alignContent}>
      <Card {...content} />
    </Content>
    <Gallery>
      <Slider {...sliderSettings}>
        {images.map((image, i) => (
          <SliderImage background={image.url} key={i}>
            {image.location && <Location inverted>{image.location}</Location>}
          </SliderImage>
        ))}
      </Slider>
    </Gallery>
  </Wrapper>
);

export const LocationGalleryType = {
  alignContent: PropTypes.oneOf(['left', 'right']),
  content: PropTypes.shape(CardType),
  images: PropTypes.arrayOf(
    PropTypes.shape({
      location: PropTypes.string,
      url: PropTypes.string
    })
  )
};

LocationGallery.propTypes = LocationGalleryType;

export default LocationGallery;
