import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AnchorLink, { AnchorLinkType } from '../AnchorLink';

const Img = styled.img`
  width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
`;

const Link = styled(AnchorLink)`
  display: block;
`;

const Image = ({ alt, className, link, url }) => {
  const hasLink =
    link && ((link.path && link.path !== '') || link.mailTo || link.tel);
  const imageEl = <Img alt={alt} src={url} />;
  const image = hasLink ? <Link {...link}>{imageEl}</Link> : imageEl;

  return <div className={className}>{image}</div>;
};

export const ImageType = {
  alt: PropTypes.string,
  className: PropTypes.string,
  link: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(AnchorLinkType)
  ]),
  url: PropTypes.string.isRequired
};

Image.propTypes = ImageType;

export default Image;
