import CountUp from 'react-countup';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Hidden, media, P, shadeOf, toEms } from '../../../../global';

// prettier-ignore
const Arrow = styled.div`
  height: 8px;
  position: relative;
  width: 8px;
  transform: rotate(-180deg);
  transition: transform 0.15s ease-in-out;

  &::before {
    background: ${p => p.theme.color.redPepper};
    border-top: 6px solid ${p => p.theme.color.redPepper};
    border-left: 4px solid ${p => p.theme.color.feta};
    border-right: 4px solid ${p => p.theme.color.feta};
    content: '';
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  ${p => p.expanded && `
    transform: rotate(0);
  `}
`;

const Calories = styled.span`
  font-weight: ${p => p.theme.fontWeight.bold};
  padding-left: 30px;
`;

const OptionsWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
`;

const ShowOptions = styled(P)`
  color: ${p => p.theme.color.redPepper};
  padding-right: 5px;

  ${media.down.lg`
    font-weight: ${p => p.theme.fontWeight.ultraBold};
    text-transform: uppercase;
  `}

  ${media.up.lg`
    font-size: ${toEms('16px')};
  `}
`;

const Totals = styled(P)`
  font-weight: ${p => p.theme.fontWeight.ultraBold};
  text-transform: uppercase;
`;

const Wrapper = styled.div`
  flex: 1 1 auto;
  padding-right: 40px;
  text-align: right;

  ${p => media.down.lg`
    background: ${p.theme.color.feta};
    box-shadow: 0 0 10px ${p => shadeOf(p.theme.color.black, 0.25)};
    display: flex;
    flex: 0 0 100%;
    justify-content: space-between;
    padding: 20px;
    position: fixed;
    text-align: left;
    width: 100%;
    z-index: 1;
  `}
`;

const Controls = ({ calories, expanded, mobileRef, setExpanded }) => {
  const prevCaloriesRef = useRef(calories);

  useEffect(() => {
    prevCaloriesRef.current = calories;
  }, [calories]);

  const prevCalories = prevCaloriesRef.current;

  return (
    <Wrapper ref={mobileRef}>
      <Hidden down="lg">
        <Totals>Totals</Totals>
      </Hidden>
      <OptionsWrapper onClick={() => setExpanded(!expanded)}>
        <Hidden down="lg">
          <ShowOptions>Show Options</ShowOptions>
        </Hidden>
        <Hidden up="lg">
          <ShowOptions>Totals</ShowOptions>
        </Hidden>
        <Arrow expanded={expanded} />
      </OptionsWrapper>
      <Hidden up="lg">
        <P>
          Total Calories
          <CountUp
            start={prevCalories}
            end={calories}
            delay={0}
            duration={0.5}
            useEasing={false}
          >
            {({ countUpRef }) => <Calories ref={countUpRef} />}
          </CountUp>
        </P>
      </Hidden>
    </Wrapper>
  );
};

Controls.propTypes = {
  calories: PropTypes.number,
  expanded: PropTypes.bool,
  mobileRef: PropTypes.any,
  setExpanded: PropTypes.func
};

export default Controls;
