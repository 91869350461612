import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AnchorLink } from '../../elements';
import { media, P, useHasMounted } from '../../global';

const OFFSET_HEIGHT = 40;

// prettier-ignore
const MenuItem = styled(AnchorLink)`
  border-left: 1px solid ${p => p.theme.color.peppercornLight};
  display: block;
  padding: 13px 0 13px 15px;
  position: relative;

  &::before {
    background: ${p => p.theme.color.redPepperLight};
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(-1px);
    width: 4px;
  }

  ${media.down.md`
    border-left: none;
    padding: 20px 30px;

    &::before {
      bottom: 0;
      height: 4px;
      top: auto;
      transform: translateY(1px);
      width: 100%;
    }
  `}

  ${p => p.isActive && `
    color: ${p.theme.color.redPepperLight};
    font-weight: ${p.theme.fontWeight.bold};

    &::before {
      content: '';
    }
  `}
`;

const Paragraph = styled(P)`
  a {
    text-decoration: none;
  }

  ${media.down.md`
    flex: 1 1 auto;
    font-size: ${p => p.theme.fontSize.label};
    text-align: center;
    white-space: nowrap;
  `}
`;

// prettier-ignore
const Wrapper = styled.div`
  border-bottom: 1px solid ${p => p.theme.color.peppercorn};
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;

  ${p => media.up.md`
    border-bottom: none;
    display: block;
    padding-left: 25px;
    padding-top: 60px;
    text-align: left;
    top: 0;

    ${p.isScrolling && `
      padding-top: 0;
      position: fixed;
      transform: translateY(85px);
    `};
  `}
`;

const LegalMenu = ({ contentHeight, menu }) => {
  const [isScrolling, setScrolling] = useState(false);

  const hasMounted = useHasMounted();
  const pathname =
    typeof window !== 'undefined' ? window.location.pathname : null;

  const handleScroll = () => {
    const height = window.pageYOffset;
    if (height >= OFFSET_HEIGHT && height <= contentHeight) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  const rafEvent = () => requestAnimationFrame(handleScroll);

  useEffect(() => {
    if (contentHeight) {
      window.addEventListener('scroll', rafEvent);
      return () => window.removeEventListener('scroll', rafEvent);
    }
  }, [contentHeight]);

  return (
    <Wrapper isScrolling={isScrolling}>
      {menu.map((item, i) => (
        <Paragraph key={i}>
          <MenuItem
            isActive={item.path.includes(pathname) && hasMounted}
            path={item.path}
            text={item.title}
          />
        </Paragraph>
      ))}
    </Wrapper>
  );
};

LegalMenu.propTypes = {
  contentHeight: PropTypes.number,
  menu: PropTypes.array
};

export default LegalMenu;
