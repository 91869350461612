import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { addYears, subYears, setMonth, startOfMonth } from 'date-fns';

import {
  ActionArea,
  Action,
  Arrow,
  Month,
  MonthPicker,
  YearPicker,
  Wrapper
} from './components';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

function MonthSelectView({ initialDate, onChange, onReset }) {
  const [date, setDate] = useState(initialDate);

  // Update state if `initialDate` changes
  useEffect(() => {
    setDate(initialDate);
  }, [initialDate, setDate]);

  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return (
    <Wrapper>
      <YearPicker>
        <Arrow onClick={() => setDate(d => subYears(d, 1))} />
        <div>{year}</div>
        <Arrow next onClick={() => setDate(d => addYears(d, 1))} />
      </YearPicker>

      <MonthPicker>
        {months.map((monthName, index) => (
          <Month
            isActive={index === monthIndex}
            key={monthName}
            onClick={() => setDate(d => setMonth(d, index))}
          >
            {monthName}
          </Month>
        ))}
      </MonthPicker>

      <ActionArea>
        <Action
          onClick={() => {
            setDate(initialDate);
            onReset();
          }}
        >
          Reset
        </Action>

        <Action primary onClick={() => onChange(startOfMonth(date))}>
          Search
        </Action>
      </ActionArea>
    </Wrapper>
  );
}

MonthSelectView.propTypes = {
  initialDate: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired
};

export default MonthSelectView;
