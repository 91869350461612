import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { contained, media, P, toEms } from '../../global';

const Avatar = styled.img`
  border-radius: 50%;
  flex: 0 0 auto;
  height: 70px;
  margin-bottom: ${toEms(25)};
  width: 70px;

  ${media.up.sm`
    margin-bottom: 0;
    margin-right: ${toEms(36)};
  `}
`;

const Bio = styled(P)`
  color: ${p => p.theme.color.peppercornLight};
`;

const Description = styled.div`
  flex: 1 1 auto;
`;

const Name = styled(P).attrs({ as: 'a' })`
  color: ${p => p.theme.color.blackBean};
`;

const Wrapper = styled.section`
  ${contained};
  border-color: ${p => transparentize(0.5, p.theme.color.peppercornLight)};
  border-style: solid;
  border-width: 1px 0;
  display: flex;
  flex-direction: column;
  padding-bottom: ${toEms(43)};
  padding-top: ${toEms(43)};

  ${media.up.sm`
    flex-direction: row;
  `}
`;

const Author = ({ avatar, bio, email, name }) => (
  <Wrapper>
    {avatar && <Avatar src={avatar} />}

    <Description>
      <Name
        dangerouslySetInnerHTML={{ __html: name }}
        href={`mailto:${email}`}
      />

      {bio && <Bio dangerouslySetInnerHTML={{ __html: bio }} />}
    </Description>
  </Wrapper>
);

Author.propTypes = {
  avatar: PropTypes.string,
  bio: PropTypes.string,
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default Author;
