import React from 'react';
import IconBase from './IconBase';

export default props => (
  <IconBase viewBox="0 0 20 20" {...props}>
    <g fill="currentColor" fillRule="evenodd">
      <path d="M3.636.808l15.556 15.556-2.828 2.828L.808 3.636z" />
      <path d="M16.364.808L.808 16.364l2.828 2.828L19.192 3.636z" />
    </g>
  </IconBase>
);
