import CountUp from 'react-countup';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getColumnSpanSize, media, P, toEms } from '../../../../global';
import { getSuffix } from '../../utils';

const Details = styled.div`
  display: flex;
  text-align: left;
  width: 100%;

  ${media.down.lg`
    flex-direction: column;
  `}
`;

const Metric = styled(P)`
  font-weight: ${p => p.theme.fontWeight.bold};
  padding-top: 5px;

  ${media.up.lg`
    flex-basis: calc(100% / ${p => p.length});
    flex-grow: 0;
    flex-shrink: 0;
  `}

  ${media.down.lg`
    padding: 14px 0;
    text-align: right;
  `}
`;

const Category = styled(Metric)`
  font-weight: ${p => p.theme.fontWeight.regular};
  padding-right: 23px;
  padding-top: 0;

  ${media.up.lg`
    font-size: ${toEms('10px')};
    font-weight: ${p => p.theme.fontWeight.bold};
  `}

  ${media.down.lg`
    padding: 14px 0;
    text-align: left;
  `}
`;

const Wrapper = styled.div`
  max-width: ${p => getColumnSpanSize(9, p.theme.gridSettings)}px;
  width: 100%;

  @media only screen and (max-width: 1168px) {
    max-width: ${p => getColumnSpanSize(8, p.theme.gridSettings)}px;
  }

  ${p => media.down.lg`
    background: ${p.theme.color.white};
    display: flex;
    flex: 0 0 100%;
    justify-content: space-between;
    max-width: none;
    opacity: ${p.expanded ? 1 : 0};
    padding: 25px 20px 0;
  `}
`;

const Measurements = ({ expanded, measurements, totals }) => {
  const prevTotalsRef = useRef(totals);

  useEffect(() => {
    prevTotalsRef.current = totals;
  }, [totals]);

  const prevTotals = prevTotalsRef.current;

  return (
    <Wrapper expanded={expanded}>
      <Details>
        {measurements.map((measurement, i) => (
          <Category key={i} length={measurements.length}>
            {measurement}
          </Category>
        ))}
      </Details>
      <Details>
        {totals.map((total, i) => {
          const suffix = getSuffix(i);
          return (
            <CountUp
              key={i}
              start={prevTotals[i]}
              end={total}
              delay={0}
              duration={0.5}
              decimal={'.'}
              decimals={1}
              useEasing={false}
              formattingFn={num => num}
            >
              {({ countUpRef }) => (
                <Metric length={totals.length}>
                  <span ref={countUpRef} />
                  {suffix}
                </Metric>
              )}
            </CountUp>
          );
        })}
      </Details>
    </Wrapper>
  );
};

Measurements.propTypes = {
  expanded: PropTypes.bool,
  measurements: PropTypes.arrayOf(PropTypes.string),
  totals: PropTypes.arrayOf(PropTypes.number)
};

export default Measurements;
