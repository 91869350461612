import React from 'react';
import PropTypes from 'prop-types';

import { Section } from '../../components';
import { PageType } from '../../global';
import BaseLayout from '../../layouts/Base';
import {
  Dressings,
  FeaturedContent,
  Hero,
  HowItWorks,
  MealMakers,
  Soups,
  Teas,
  Toppings
} from '../../sections';
import {
  dressingsParticles,
  letsGetStartedExtraData,
  matchToppingsToTabs,
  soupParticles,
  toppingsLinkProps,
  toppingsStaticData
} from './utils';

const Menu = ({ acf, toppings, ...layoutProps }) => {
  const {
    dressings,
    hero,
    howItWorks,
    letsGetStarted,
    mealMakers,
    soups,
    teas,
    toppings: toppingsContent
  } = acf;

  return (
    <BaseLayout {...layoutProps}>
      <Hero {...hero} dividerBottomColor="white" />
      <Section intro={howItWorks.intro} halfPadding>
        <HowItWorks {...howItWorks.content} counterIncrement />
      </Section>
      <Section halfTopPadding unContain>
        <Toppings
          linkProps={toppingsLinkProps}
          {...toppingsContent}
          {...matchToppingsToTabs(toppingsStaticData, toppings)}
        />
      </Section>
      <Section
        bgColor="blue"
        dividerBottomColor="white"
        dividerTopColor="white"
        halfPadding
        particlesData={dressingsParticles}
      >
        <Dressings {...dressings} />
      </Section>
      <Section unContain>
        <Teas {...teas} />
      </Section>
      <Section
        bgColor="orange"
        dividerBottomColor="white"
        dividerTopColor="white"
        halfPadding
        insetDividerBottom
        insetDividerTop
        particlesData={soupParticles}
      >
        <Soups {...soups} />
      </Section>
      <Section noBottomPadding>
        <MealMakers {...mealMakers} />
      </Section>
      <Section
        contentBelow
        dividerBottomColor="tan"
        dividerBottomReverse
        insetDividerBottom
        noBottomPadding
      >
        <FeaturedContent
          centerTextMobile
          {...letsGetStarted}
          {...letsGetStartedExtraData}
        />
      </Section>
    </BaseLayout>
  );
};

Menu.propTypes = {
  ...PageType,
  acf: PropTypes.shape({
    dressings: PropTypes.object,
    hero: PropTypes.object,
    howItWorks: PropTypes.object,
    letsGetStarted: PropTypes.object,
    liveHowYouWant: PropTypes.object,
    mealMakers: PropTypes.object,
    soups: PropTypes.object,
    teas: PropTypes.object,
    toppings: PropTypes.object
  }).isRequired
};

export default Menu;
