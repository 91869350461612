const imgPath = '/images/components/toppings/';

export const dressingsParticles = [
  {
    component: 'Stars',
    fill: 'blueDark',
    position: 'TOP_RIGHT'
  }
];

export const letsGetStartedExtraData = {
  alignment: 'right'
};

export const soupParticles = [
  {
    component: 'Dots',
    fill: 'redLight',
    position: 'TOP_RIGHT'
  }
];

export const toppingsLinkProps = {
  buttonType: 'secondary',
  inline: true
};

export const toppingsStaticData = {
  image: {
    alt: 'Red Pepper',
    url: `${imgPath}peppers.png`
  },
  tabs: [
    { icon: 'Base', name: 'Base' },
    { icon: 'Vegetables', name: 'Vegetables' },
    { icon: 'CheeseNuts', name: 'Cheese & Nuts' },
    { icon: 'Fruits', name: 'Fruits' },
    { icon: 'Protein', name: 'Protein' },
    { icon: 'Other', name: 'Other' }
  ]
};

/**
 * Matches toppings gathered from API call
 * into a "toppings" array within each tab
 * defined in toppingsStaticData
 */
export const matchToppingsToTabs = (toppingsStaticData, toppings) => {
  // deep copy tabs and modify copy
  // to contain empty toppings array
  const merged = [...toppingsStaticData.tabs].map(tab => {
    const tabCopy = { ...tab };
    tabCopy.toppings = [];
    return tabCopy;
  });

  // create object for matching toppings to tabs
  // without mapping over tabs for each topping,
  // populated with tab names as keys
  // and array indices as values
  const tabsLookup = {};

  merged.map((tab, i) => {
    tabsLookup[tab.name.toLowerCase()] = i;
  });

  // attempt to match toppings to tabs
  Object.keys(toppings).map(key => {
    try {
      const topping = toppings[key];
      const categoryIndex = tabsLookup[topping.category.toLowerCase()];
      const category = merged[categoryIndex];
      category.toppings.push(topping.name);
    } catch (e) {
      console.log('error mapping toppings to tabs:', e);
    }
  });

  return {
    image: { ...toppingsStaticData.image },
    tabs: [...merged]
  };
};
