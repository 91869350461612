import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, ButtonType, Image, ImageType } from '../../elements';
import { convertLh, H4, H5, media, setColumnWidth } from '../../global';

const Content = styled.div`
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  max-width: ${p => setColumnWidth(7, p.theme.gridSettings)};
  padding-left: 70px;
  padding-right: 100px;

  ${p => media.down.lg`
    margin: 0 auto;
    max-width: ${setColumnWidth(11, p.theme.gridSettings)};
    padding: 75px 0 65px;
  `}

  ${media.down.sm`
    max-width: 100%;
  `}
`;

const FeaturedButton = styled(Button)`
  color: ${p => p.theme.color.kaleLeaf};
  display: block;
  font-weight: ${p => p.theme.fontWeight.bold};
  padding-top: 40px;
  text-align: right;

  svg {
    fill: ${p => p.theme.color.kaleLeaf};
  }

  ${media.down.lg`
    text-align: center;
  `}
`;

const FeaturedImage = styled(Image)`
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  max-width: ${p => setColumnWidth(5, p.theme.gridSettings)};

  ${p => media.down.lg`
    margin: 0 auto -80px;
    max-width: ${setColumnWidth(6, p.theme.gridSettings)};
  `}

  ${media.down.sm`
    max-width: 100%;
  `}
`;

const FeaturedHeadline = styled(H4)`
  color: ${p => p.theme.color.kaleLeaf};
  font-weight: ${p => p.theme.fontWeight.ultraBold};
  padding-top: 10px;
`;

const Paragraph = styled(H5)`
  color: ${p => p.theme.color.peppercorn};
  font-weight: ${p => p.theme.fontWeight.regular};
  line-height: ${p => convertLh(p.theme.lineHeight.p, p.theme.fontSize.p)};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-around;
  text-align: left;
  padding: 80px 0 0;

  ${media.down.lg`
    flex-direction: column;
  `}
`;

const ContentCallout = ({ link, featuredHeadline, image, subHeadline }) => (
  <Wrapper>
    <Content>
      <Paragraph>{subHeadline}</Paragraph>
      <FeaturedHeadline>{featuredHeadline}</FeaturedHeadline>
      {link && <FeaturedButton {...link} />}
    </Content>
    <FeaturedImage {...image} />
  </Wrapper>
);

export const ContentCalloutType = {
  featuredHeadline: PropTypes.string,
  image: PropTypes.shape(ImageType),
  link: PropTypes.shape(ButtonType),
  subHeadline: PropTypes.string
};

ContentCallout.propTypes = ContentCalloutType;

export default ContentCallout;
