import { keyframes } from 'styled-components';

export const copyAnimationKeyframes = keyframes`
0% {
  opacity: 0;
  transform: translate(-50%, 50%);
}
15% {
  opacity: 1;
  transform: translate(-50%, -140%);
}
20% {
  transform: translate(-50%, -130%);
}
25% {
  transform: translate(-50%, -135%);
}
95% {
  opacity: 1;
  transform: translate(-50%, -135%);
}
100% {
  opacity: 0;
  transform: translate(-50%, 50%);
}
`;
