import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Card, CardType, Grid } from '../../components';
import { Button, ButtonType, CounterReset } from '../../elements';
import { media } from '../../global';

import { desktopImage, fourDesktopLine, mobileImage } from './data';

const ButtonContainer = styled.div`
  padding-top: 40px;
  text-align: center;

  ${media.down.md`
    padding-top: 50px;
  `}
`;

const GridCard = styled(Card)`
  counter-increment: section;
  justify-content: flex-start;

  &::before {
    border: 3px solid ${p => p.theme.color.redPepper};
    border-radius: 50%;
    color: ${p => p.theme.color.redPepper};
    font-family: ${p => p.theme.fontFamily.primary};
    font-size: 30px;
    font-weight: ${p => p.theme.fontWeight.bold};
    left: 0;
    line-height: 46px;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    transform: translate(2vw, 2vw);
  }

  h5 {
    color: ${p => p.theme.color.peppercorn};
  }

  img {
    width: auto;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    max-height: 265px;
  }

  div:not(:first-child) {
    background: ${p => p.theme.color.white};
  }

  ${media.down.md`
    margin: 0 auto;
    max-width: 400px;
    position: relative
  `}
`;

// prettier-ignore
const Wrapper = styled.div`
  counter-reset: card-counter;
  
  ${p => p.counterIncrement && `
    ${GridCard}::before {
      content: counter(section);
    }
  `}
  
  ${p => !p.noLine && `
    background-repeat: no-repeat;
    background-size: 100% auto;

    ${p.columns === 3 ? `
      background-image: url(${desktopImage});
      background-position: 0 105px;
    ` : `
      background-image: url(${fourDesktopLine});
      background-position: 0 55px;
    `}
  `}

  ${p => media.down.md`
    ${!p.noLine && p.columns === 3 ? `
      background-image: url(${mobileImage});
      background-position: center;
      background-size: auto 100%;
      padding: 10px 30px;
    ` : ``}
  `}

  ${p => media.down.sm`
    ${p.columns === 4 ? `
      background-image: none;
      padding: 10px 30px;
    ` : ``}
  `}
`;

const HowItWorks = ({
  columns = 3,
  counterIncrement = false,
  gridSteps,
  link,
  linkProps,
  noLine = false
}) => {
  const linkObject = { ...linkProps, ...link };

  return (
    <Fragment>
      <Wrapper
        columns={columns}
        counterIncrement={counterIncrement}
        noLine={noLine}
      >
        <CounterReset>
          <Grid
            center
            columns={columns}
            component={GridCard}
            componentData={gridSteps}
          />
        </CounterReset>
      </Wrapper>
      {link && (
        <ButtonContainer>
          <Button {...linkObject} />
        </ButtonContainer>
      )}
    </Fragment>
  );
};

HowItWorks.propTypes = {
  columns: PropTypes.oneOf([3, 4]),
  counterIncrement: PropTypes.bool,
  gridSteps: PropTypes.arrayOf(PropTypes.shape(CardType)).isRequired,
  link: PropTypes.shape(ButtonType),
  linkProps: PropTypes.object,
  noLine: PropTypes.bool
};

export default HowItWorks;
