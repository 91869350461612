// ************************************
// this is the Franchise Contact Form
// ************************************

import PropTypes from 'prop-types';
import React from 'react';

import { Card, Section } from '../../../components';
import { ImageType } from '../../../elements';
import { PageType } from '../../../global';
import BaseLayout from '../../../layouts/Base';
import { FeaturedForm, Hero, HeroType } from '../../../sections';
import { Form } from '../../components';

const ContactForm = ({
  acf,
  franchisingFooter,
  franchisingHeader,
  ...layoutProps
}) => {
  const { hero, letsDoThis } = acf;

  return (
    <BaseLayout
      {...layoutProps}
      franchisingFooter={franchisingFooter}
      franchisingHeader={franchisingHeader}
    >
      <Hero {...hero} dividerBottomColor="white" />
      <Section dividerBottomColor="tan" dividerBottomReverse>
        <FeaturedForm content={Card} contentData={letsDoThis} form={Form} />
      </Section>
    </BaseLayout>
  );
};

ContactForm.propTypes = {
  ...PageType,
  acf: PropTypes.shape({
    hero: PropTypes.shape(HeroType)
  }).isRequired
};

export default ContactForm;
