import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import styled from 'styled-components';

import { Card, CardType, Loading } from '../../components';

import { Arrow, media, slickCSS } from '../../global';

// prettier-ignore
const CarouselArrow = styled(Arrow)`
  cursor: pointer;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translate(-28px, -50%);
  z-index: 999;

  g {
    transform: translate(2px, 2px);
  }

  ${p => p.reverse && `
    left: auto;
    right: 0;
    transform: translate(28px, -50%);

    g {
      transform: rotate(180deg) translate(-46px, -46px);
    }
  `}
`;

const EmployeeCard = styled(Card)`
  display: block;
  text-align: center;

  div {
    max-width: none;
  }

  img {
    margin: 0 auto 30px;
    width: 85px;
  }
`;

const LoadingWrapper = styled.div`
  min-height: 250px;
  position: relative;
`;

const Wrapper = styled.div`
  width: 100%;

  ${slickCSS}

  .slick-track {
    .slick-slide {
      height: auto;
      padding: 0 25px;
    }

    ${media.up.lg`
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      width: 100% !important;

      .slick-slide {
          padding: 0 50px;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
      }
    `}
  }
`;

const sliderSettings = {
  dots: false,
  nextArrow: <CarouselArrow reverse />,
  prevArrow: <CarouselArrow />,
  responsive: [
    {
      breakpoint: 1023,
      settings: {
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 1
      }
    }
  ],
  slidesToScroll: 1,
  slidesToShow: 3,
  speed: 500
};

const EmployeeHighligts = ({ highlights }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [loading]);

  return (
    <Wrapper>
      {loading ? (
        <LoadingWrapper>
          <Loading isLoading={loading} />
        </LoadingWrapper>
      ) : (
        <Slider {...sliderSettings}>
          {highlights.map((highlight, i) => (
            <EmployeeCard {...highlight} key={i} />
          ))}
        </Slider>
      )}
    </Wrapper>
  );
};

EmployeeHighligts.propTypes = {
  highlights: PropTypes.arrayOf(PropTypes.shape(CardType)).isRequired
};

export default EmployeeHighligts;
