import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AnchorLink } from '../../elements';
import { media, setColumnWidth } from '../../global';

// prettier-ignore
const NavItem = styled(AnchorLink)`
  color: ${p =>
    p.hasChildren ? p.theme.color.kaleLeaf : p.theme.color.blackBean};
  font-weight: ${p => (p.hasChildren ? p.theme.fontWeight.bold : 'none')};
  padding-bottom: ${p => p.hasChildren ? '20px' : 0};
  pointer-events: ${p => p.hasChildren ? 'none' : 'auto'};

  ${p => !p.hasChildren && `
    position: relative;
    transition: color 0.2s ease-in-out;

    &::before {
      background: ${p.theme.color.blackBean};
      bottom: -5px;
      content: '';
      height: 1px;
      position: absolute;
      transform: scaleX(0);
      transform-origin: left center;
      transition: background 0.2s ease-in-out, transform 0.2s linear;
      width: 100%;
    }

    &:hover, &:focus {
      &::before{
        transform: scaleX(1);
      }
    }
  `}
`;

const NavChildWrapper = styled.div`
  padding-bottom: 20px;

  &:last-child {
    padding-bottom: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(
    ${p => setColumnWidth(2, p.theme.gridSettings)} +
      ${p => p.theme.gridSettings.gutterWidth}px
  );
  flex-direction: column;

  ${media.down.md`
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% / 3);
    margin-top: 56px;
  `}

  ${media.down.sm`
    flex: 0 0 50%;
  `}
`;

const Navigation = ({ menu }) => (
  <Fragment>
    {menu.map((item, i) => (
      <Wrapper key={i}>
        <NavItem
          className={item.classes}
          hasChildren={item.children}
          path={item.path}
        >
          {item.title}
        </NavItem>

        {item.children &&
          item.children.map((child, i) => (
            <NavChildWrapper key={i}>
              <NavItem path={child.path}>{child.title}</NavItem>
            </NavChildWrapper>
          ))}
      </Wrapper>
    ))}
  </Fragment>
);

Navigation.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Navigation;
