import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// prettier-ignore
const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;

  ${p => !p.unContain && `
    max-width: ${p.theme.grid.maxWidth};
    padding-left: ${p.theme.spacing.horizontalPadding};
    padding-right: ${p.theme.spacing.horizontalPadding};
  `}
`;

const Container = ({ children, className, unContain }) => (
  <Wrapper unContain={unContain} className={className}>
    {children}
  </Wrapper>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  unContain: PropTypes.bool
};

export default Container;
