import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Callout } from './components';
import { SocialIcon } from '../../elements';
import { media } from '../../global';

const SocialLink = styled.a`
  color: ${p => p.theme.color.avocado};
  display: inline-block;

  > svg {
    height: 42px;
    width: 42px;
  }

  :not(:last-of-type) {
    margin-right: 15px;
  }

  :hover,
  :focus {
    color: ${p => p.theme.color.avocadoLight};
  }
`;

const Wrapper = styled.form`
  flex: 0 0 100%;
  margin: 56px 0 0;

  ${media.up.sm`
    flex: 0 0 auto;
  `}

  ${media.up.md`
    margin: 80px 0 0;
  `}
`;

const Links = styled.div`
  margin-top: 15px;

  ${media.up.sm`
    align-items: center;
    display: flex;
    justify-content: center;
  `}
`;

const SocialLinks = ({ className }) => (
  <Wrapper className={className}>
    <Callout as="div">Stay Connected with Us</Callout>

    <Links>
      <SocialLink href="https://www.facebook.com/SalataSalads">
        <SocialIcon icon="facebook" />
      </SocialLink>

      <SocialLink href="https://twitter.com/salatasalads">
        <SocialIcon icon="twitter" />
      </SocialLink>

      <SocialLink href="https://instagram.com/salatasalads">
        <SocialIcon icon="instagram" />
      </SocialLink>
    </Links>
  </Wrapper>
);

SocialLinks.propTypes = {
  className: PropTypes.string
};

export default SocialLinks;
