import styled from 'styled-components';

import { MultiPartForm } from '../../components';
import { H5, P } from '../../global';

export const Directions = styled(P)`
  margin-bottom: 35px;
`;

export const FormLayout = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

// prettier-ignore
export const StyledForm = styled(MultiPartForm)`
  border: 1px solid ${p => p.theme.color.peppercorn};
  border-radius: 2px;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;

  ${p => p.isSubmitted && `
    opacity: 0;
    transform: translateX(-100%);
  `}
`;

export const Subtitle = styled(P)`
  font-weight: ${p => p.theme.fontWeight.bold};
  letter-spacing: 3.56px;
  text-transform: uppercase;
`;

export const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SubtitleStep = styled(P)`
  padding-left: 5px;
  white-space: nowrap;
`;

export const Title = styled(H5)`
  color: ${p => p.theme.color.peppercorn};
  margin-bottom: 35px;
  flex: 1;
  text-align: center;
`;

// prettier-ignore
export const Wrapper = styled.div`
  max-height: ${p => p.maxHeight === 0 ? 'none' : `${p.maxHeight}px`};
  position: relative;
  transition: max-height 0.3s ease;
  width: 100%;

  ${p => p.submitted && `
    max-height: 365px;
  `}
`;
