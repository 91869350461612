import React from 'react';
import IconBase from './IconBase';

export default props => (
  <IconBase viewBox="0 0 40 40" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FF864D"
        fillRule="nonzero"
        d="M20 40c-4.797 0-10.112-1.627-13.032-5.375C3.654 30.37 0 26.726 0 20.992 0 15.817 3.824 10.34 6.754 6.46 10.338 1.713 15.42 0 20.798 0s11.04 2.287 13.498 6.46C37 11.05 40 14.536 40 19.71c0 4.924-1.323 10.01-3.84 13.88C32.769 38.807 25.64 40 20 40"
      />
      <path
        fill="#FFF"
        d="M18.53 23.975c-.05-.47.04-1 .022-1.499-.04-1.118-.096-2.235-.163-3.352-.088-1.455-.229-2.907-.28-4.362-.04-1.163.02-2.33.033-3.495.004-.386-.004-.773 0-1.16.004-.578.007-1.495.026-1.736.02-.24.178-.515.239-.59.158-.195.25-.243.424-.423.143-.148.244-.18.42-.224 1.095-.274 2.077-.183 2.936.797a.71.71 0 0 1 .152.388c.042.536.066 1.075.088 1.613.03.699.08 1.4.067 2.098-.014.805-.119 1.61-.128 2.415-.01 1.026.04 2.053.055 3.08.006.426-.019.852-.02 1.277 0 .837.01 1.674.006 2.511 0 .178-.047.354-.055.532-.038.865-.07 1.73-.106 2.594-.002.068-.08.48-.218.624-.14.143-.165.56-.556.716-.261.106-.661.24-.935.252-.252.01-.51-.226-.772-.339-.54-.231-.997-.617-1.151-1.174 0 0-.058-.315-.083-.543zM20.5 33c-.6 0-1.264-.212-1.629-.7-.414-.555-.871-1.03-.871-1.777 0-.675.478-1.389.844-1.895.448-.618 1.084-.842 1.756-.842.672 0 1.38.298 1.687.842.338.599.713 1.053.713 1.728 0 .641-.165 1.304-.48 1.809-.424.68-1.315.835-2.02.835z"
      />
    </g>
  </IconBase>
);
