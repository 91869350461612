import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dropdown, Hidden, media, useHasMounted } from '../../../global';

// prettier-ignore
const Anchor = styled.a`
  color: ${p =>
    p.isActive
      ? p.theme.color.kaleLeaf
      : p.hasChildren
        ? p.theme.color.peppercornLight
        : 'inherit'};
  cursor: pointer;
  display: inline-block;
  font-family: ${p => p.theme.fontFamily.primary};
  font-size: ${p => (p.hasChildren ? 18 : 22)}px;
  padding: 16px 0.6vw 14px;
  text-transform: uppercase;
  transition: color 0.15s ease-in-out;

  span& {
    cursor: default;
  }

  :hover, :focus {
    color: ${p => p.theme.color.kaleLeaf};
  }

  ${media.up.lg`
    color: ${p => (p.isActive ? p.theme.color.kaleLeaf : 'inherit')};
    font-size: 16px;

    ${p => p.isNested && `
      text-transform: none;
      transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
      width: 100%;

      :hover {
        background-color: ${p.theme.color.avocado};
        color: ${p.theme.color.white};
      }
    `}
  `}
`;

function NavLink({ className, hasChildren, isNested, path, title }) {
  // A different class list on the server and client cannot be used initially
  // because the client will see a conflict and ignore changes.This waits until
  // the component has mounted to check if the link should be active.
  const hasMounted = useHasMounted();

  return (
    <Anchor
      className={className}
      hasChildren={hasChildren}
      href={path || undefined}
      isActive={hasMounted && window.location.pathname === path}
      isNested={isNested}
    >
      {title}
      {!isNested && hasChildren && (
        <Hidden as="span" down="lg">
          <Dropdown afterText />
        </Hidden>
      )}
    </Anchor>
  );
}

NavLink.propTypes = {
  className: PropTypes.string,
  hasChildren: PropTypes.bool,
  isNested: PropTypes.bool,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default NavLink;
