import React, { useState } from 'react';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { SocialIcon as SocialIconBase } from '../../elements';
import { isBrowser, media, P, shadeOf, toEms } from '../../global';

import { copyAnimationKeyframes } from './utils';

const currentUrl = isBrowser && window.location;

// NOTE: This uses `encodeURIComponent` because it will be a part of the URI
const encodedUrl = encodeURIComponent(currentUrl);

const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
const twitterShareUrl = `https://twitter.com/home?status=${encodedUrl}`;

const openInWindow = url => () => {
  window.open(url, 'newwindow', 'height=450,width=600');
};

const CopyAnimation = styled.div`
  animation: ${copyAnimationKeyframes} 1s 1;
  align-items: center;
  background: ${p => p.theme.color.redPepperLight};
  border-radius: 50px;
  box-shadow: 2px 7px 7px ${p => shadeOf(p.theme.color.black, 0.2)};
  display: flex;
  height: 50px;
  justify-content: center;
  left: 50%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translate(-50%, 50%);
  width: 215px;

  p {
    color: ${p => p.theme.color.white};
  }

  &::after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 10px solid ${p => p.theme.color.redPepperLight};
    content: '';
    left: 50%;
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
  }

  ${media.down.sm`
    bottom: 0;
    box-shadow: 0 0 15px ${p => shadeOf(p.theme.color.black, 0.5)};
    position: fixed
    top: auto;

    &::after {
      content: none;
    }
  `}
`;

const LinksWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

// prettier-ignore
const SocialIcon = styled(SocialIconBase)`
  color: ${p => p.theme.color.kaleLeaf};
  font-family: ${p => p.theme.fontFamily.primary};
  font-size: 13px;

  :hover {
    background-color: ${p => p.theme.color.kaleLeaf};
    border-color: ${p => p.theme.color.kaleLeaf};
    color: ${p => p.theme.color.white};
    opacity: 1;
  }
`;

const ShareLink = styled.a`
  color: ${p => p.theme.color.kaleLeaf};
  cursor: pointer;
  display: inline-block;
  position: relative;

  > svg {
    height: 42px;
    width: 42px;

    :hover {
      color: ${p => p.theme.color.kaleLeafLight};
    }
  }

  :not(:last-of-type) {
    margin-right: 12px;
  }
`;

const Text = styled(P)`
  color: ${p => p.theme.color.blackBean};
  /* This font is sized a bit smaller than the rest of the body text */
  font-size: ${toEms(16)};
  padding-bottom: ${toEms(16)};
`;

const Wrapper = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Share = () => {
  const [hasCopied, setHasCopied] = useState(false);

  const copyUrl = () => {
    setHasCopied(true);
    // the timeout is so that a user can copy again without reloading the page
    setTimeout(() => setHasCopied(false), 1500);
  };

  return (
    <Wrapper>
      <Text>Share this article</Text>

      <LinksWrapper>
        <ShareLink
          onClick={openInWindow(facebookShareUrl)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <SocialIcon icon="facebook" />
        </ShareLink>

        <ShareLink
          onClick={openInWindow(twitterShareUrl)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <SocialIcon icon="twitter" />
        </ShareLink>

        <CopyToClipboard onCopy={copyUrl} text={currentUrl}>
          <ShareLink>
            <SocialIcon icon="link" />
            {hasCopied && (
              <CopyAnimation>
                <P>Copied to Clipboard</P>
              </CopyAnimation>
            )}
          </ShareLink>
        </CopyToClipboard>
      </LinksWrapper>
    </Wrapper>
  );
};

export default Share;
