import React from 'react';

export default props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 44" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#8E908E"
        fillRule="nonzero"
        d="M4.994 36.98h-3.01v1.97h2.78v.98h-2.78V43H.944v-7h4.05v.98zM12.989 43l-2.04-2.91h-.63V43H9.28v-7h2.39c1.23 0 2.14.77 2.14 2.06 0 1.06-.65 1.81-1.63 1.99L14.3 43h-1.31zm-2.67-6.02v2.13h1.18c.84 0 1.19-.45 1.19-1.05 0-.62-.35-1.08-1.19-1.08h-1.18zM23.275 43l-.67-1.74h-3.06l-.67 1.74h-1.14l2.86-7h.96l2.86 7h-1.14zm-3.36-2.7h2.32l-1.16-3-1.16 3zM34.041 36v7h-1.09l-3.44-5.23h-.02V43h-1.04v-7h1.09l3.44 5.23H33V36h1.04zm8.085 7.15c-2.22 0-3.75-1.47-3.75-3.65 0-2.18 1.53-3.65 3.75-3.65 1.05 0 1.83.31 2.47.77l-.64.91c-.41-.3-1.04-.62-1.87-.62-1.47 0-2.57 1.01-2.57 2.59 0 1.58 1.1 2.59 2.57 2.59.83 0 1.46-.32 1.87-.62l.64.91c-.64.46-1.42.77-2.47.77zM53.572 36h1.04v7h-1.04v-3.19h-3.7V43h-1.04v-7h1.04v2.81h3.7V36zm6.786 0v7h-1.06v-7h1.06zm6.586-.15c.71 0 1.36.22 1.85.47l-.47.99c-.42-.24-.94-.41-1.41-.41-.56 0-.8.23-.8.6 0 .32.18.54.55.83l1.25.96c.72.55 1.08 1.1 1.08 1.9 0 1.28-.91 1.96-2.24 1.96-.89 0-1.71-.3-2.26-.65l.47-.98c.45.26 1.12.57 1.8.57.75 0 1.11-.37 1.11-.84 0-.39-.24-.68-.74-1.07l-1.19-.94c-.6-.47-.96-.98-.96-1.7 0-1.03.73-1.69 1.96-1.69zm7.445.15v7h-1.06v-7h1.06zm10.276 0v7h-1.09l-3.44-5.23h-.02V43h-1.04v-7h1.09l3.44 5.23h.02V36h1.04zm8.236 3.13h3.32v.42c0 1.8-1.14 3.6-3.52 3.6-2.11 0-3.7-1.42-3.7-3.63 0-2.15 1.5-3.67 3.71-3.67 1.19 0 2.09.43 2.69.94l-.71.87c-.45-.41-1.15-.75-2.03-.75-1.53 0-2.52 1.12-2.52 2.6 0 1.5 1.03 2.58 2.59 2.58 1.53 0 2.29-1.05 2.37-1.98h-2.2v-.98z"
      />
      <path
        fill="#96C121"
        fillRule="nonzero"
        d="M34.732 13.323l1.386-.22 2.336 14.699-1.386.22zM40.45 27.079l2.302-10.853 1.373.291-2.3 10.853z"
      />
      <path
        fill="#000002"
        d="M31.034 16.816c0-4.553-3.403-7.994-7.962-7.994-4.56 0-7.978 3.43-7.962 7.994a8.36 8.36 0 0 0 2.002 5.431c1.56 1.78 3.722 2.564 6.068 2.564 4.517 0 7.865-3.526 7.865-7.995h-.011zm-.1 5.168c-1.703 2.733-4.577 4.171-7.864 4.171-5.344 0-9.364-4.045-9.364-9.333 0-5.287 4.029-9.33 9.364-9.33a9.663 9.663 0 0 1 5.406 1.614 8.144 8.144 0 0 1 2.457 2.534V7.91h1.404v17.814h-1.404l.002-3.74zM63.13 16.834c0-4.555-3.402-7.996-7.961-7.996-4.56 0-7.978 3.43-7.962 7.993a8.35 8.35 0 0 0 2.006 5.43c1.56 1.782 3.722 2.566 6.068 2.566 4.517 0 7.865-3.526 7.865-7.993h-.016zm-.099 5.168c-1.703 2.733-4.577 4.171-7.865 4.171-5.344 0-9.364-4.045-9.364-9.33 0-5.286 4.03-9.333 9.364-9.333a9.663 9.663 0 0 1 5.407 1.614 8.09 8.09 0 0 1 2.456 2.534V7.926h1.405v17.813h-1.405l.002-3.737zM94.831 16.834c0-4.555-3.402-7.996-7.961-7.996-4.56 0-7.978 3.43-7.962 7.993-.001 1.991.709 3.916 2.002 5.43 1.56 1.782 3.721 2.566 6.068 2.566 4.516 0 7.865-3.526 7.865-7.993h-.012zm-.098 5.168c-1.704 2.735-4.578 4.173-7.866 4.173-5.343 0-9.364-4.045-9.364-9.33s4.03-9.333 9.364-9.333a9.663 9.663 0 0 1 5.407 1.614 8.144 8.144 0 0 1 2.468 2.523V7.926h1.404v17.813h-1.404l-.01-3.737zM1.413 20.703c0 2.526 1.666 4.135 4.169 4.135 2.283 0 3.973-1.685 3.908-3.966-.072-2.548-1.692-3.411-4.007-3.924-2.65-.761-4.748-1.573-4.852-4.719 0-2.908 2.12-4.741 4.944-4.719 2.825.023 4.818 1.84 4.818 4.755v.245H9v-.243c0-2.119-1.312-3.454-3.418-3.415-1.991 0-3.647 1.287-3.548 3.37.105 2.218 1.7 2.812 3.613 3.34 2.922.804 5.052 1.611 5.247 5.157.169 3.063-2.285 5.456-5.312 5.456-3.09 0-5.77-2.27-5.57-5.49l.013-.224h1.388v.242z"
      />
      <path
        fill="#000002"
        fillRule="nonzero"
        d="M38.816 0h1.404v25.735h-1.404z"
      />
      <path
        fill="#000002"
        d="M72.234 7.926h4.447v1.337h-4.447v16.476H70.83V9.263h-3.824V7.926h3.824V1.06h1.403z"
      />
      <path
        fill="#231F20"
        fillRule="nonzero"
        d="M97.726 7.809h-.45V7.64h1.086v.162h-.45v1.306h-.193zM99.775 8.47c-.011-.205-.024-.45-.022-.634-.05.173-.113.357-.187.557l-.258.713h-.144l-.236-.7a6.227 6.227 0 0 1-.17-.57c0 .184-.016.43-.028.65l-.04.629h-.18l.101-1.468h.243l.25.71c.06.18.112.342.148.495.036-.149.09-.31.155-.495l.25-.717h.242l.09 1.468h-.185l-.029-.638z"
      />
    </g>
  </svg>
);
