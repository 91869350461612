import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { P } from '../../../../../../global';

// prettier-ignore
const Input = styled.input`
  border: 1px solid ${p => p.theme.color.peppercorn};
  border-radius: 10%;
  cursor: pointer;
  height: 18px;
  margin: 12px 10px 12px 0;
  position: relative;
  transition: background-color 0.2s ease, border-color 0.2s ease;
  width: 18px;

  &::before,
  &::after {
    background: ${p => p.theme.color.white};
    content: '';
    height: 2px;
    position: absolute;
  }

  &::before {
    left: 0;
    top: 8px;
    transform: rotate(45deg);
    width: 8px;
  }

  &::after {
    right: 0;
    transform: rotate(-45deg);
    top: 7px;
    width: 12px;
  }

  &:checked {
    background: ${p => p.theme.color.avocado};
    border-color: ${p => p.theme.color.avocado};
  }
`;

// prettier-ignore
const Label = styled(P)`
  color: ${p => p.theme.color.blackBean};
  cursor: pointer;
  transition: color 0.2s ease;
  white-space: nowrap;

  ${p => p.checked && `
    color: ${p.theme.color.avocado};
  `}
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 50%;
`;

const Checkbox = ({ checked, id, name, setAllergens, value }) => (
  <Wrapper>
    <Input
      checked={checked}
      onChange={() => setAllergens({ payload: id, type: 'checked' })}
      name={name}
      type="checkbox"
      value={name}
    />
    <Label
      checked={checked}
      onClick={() => setAllergens({ payload: id, type: 'checked' })}
    >
      {name}
    </Label>
  </Wrapper>
);

export const CheckboxType = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
};

Checkbox.propTypes = {
  setAllergens: PropTypes.func.isRequired,
  ...CheckboxType
};

export default Checkbox;
