import moment from 'moment';

// Wufoo API requires fields to be named this way

export const form = {
  CATEGORY: 'Field21',
  CITY: 'Field8',
  COMMENTS: 'Field15',
  COMPANY_DESCRIPTION: 'Field39',
  COMPANY_NAME: 'Field12',
  EMAIL: 'Field4',
  EVENT_ATTENDANCE: 'Field27',
  EVENT_ATTENDEES: 'Field29',
  EVENT_DATE: 'Field30',
  EVENT_DESCRIPTION: 'Field33',
  EVENT_LOCATION: 'Field35',
  EVENT_TIME: 'Field36',
  FULL_NAME: 'Field1',
  LOCATION_VISITED: 'Field14',
  NEAREST_LOCATION: 'Field23',
  ORDER_NUMBER: 'Field44',
  PHONE: 'Field19',
  REQUESTED_DONATION: 'Field40',
  STATE: 'Field9',
  STREET_ADDRESS: 'Field6',
  STREET_ADDRESS_2: 'Field7',
  VISIT_DATE: 'Field17',
  VISIT_TIME: 'Field25',
  WEBSITE_SOCIAL: 'Field42',
  ZIP: 'Field10'
};

export const initialValues = {
  [form.CATEGORY]: 'Choose an option...',
  [form.CITY]: '',
  [form.COMMENTS]: '',
  [form.COMPANY_DESCRIPTION]: '',
  [form.COMPANY_NAME]: '',
  [form.EMAIL]: '',
  [form.EVENT_ATTENDANCE]: '',
  [form.EVENT_ATTENDEES]: '',
  [form.EVENT_DATE]: moment().format('M/D/YYYY'),
  [form.EVENT_DESCRIPTION]: '',
  [form.EVENT_LOCATION]: '',
  [form.EVENT_TIME]: '',
  [form.FULL_NAME]: '',
  [form.LOCATION_VISITED]: '',
  [form.NEAREST_LOCATION]: '',
  [form.ORDER_NUMBER]: '',
  [form.PHONE]: '',
  [form.REQUESTED_DONATION]: '',
  [form.STATE]: '',
  [form.STREET_ADDRESS]: '',
  [form.STREET_ADDRESS_2]: '',
  [form.VISIT_DATE]: moment().format('M/D/YYYY'),
  [form.VISIT_TIME]: '',
  [form.WEBSITE_SOCIAL]: '',
  [form.ZIP]: ''
};

export const options = [
  {
    disabled: true,
    value: 'Choose an option...'
  },
  {
    value: 'Guest Feedback / Complaint'
  },
  {
    value: 'Human Resources'
  },
  {
    value: 'Become a Vendor'
  },
  {
    value: 'Catering'
  },
  {
    value: 'Donations'
  },
  {
    value: 'Marketing'
  },
  {
    value: 'Charity'
  },
  {
    value: 'Location Request'
  },
  {
    value: 'Technical Support'
  }
];
