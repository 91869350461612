import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { H1, H2, H3, P, media, Wysiwyg } from '../../../global';
import { Button, Image } from '../../../elements';

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  font-family: ${p => p.theme.fontFamily.primary};

  ${p => !p.unContain && `
    max-width: ${p.theme.grid.maxWidth};
    padding-left: ${p.theme.spacing.horizontalPadding};
    padding-right: ${p.theme.spacing.horizontalPadding};
  `}
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  place-items: center;

  ${media.up.md`
    gap: 5rem;
  `}
`;

const ImageContainer = styled.div`
  display: flex;
  place-content: center;
  place-items: center;
  width: 100%;
  margin-bottom: 3rem;

  ${media.up.md`
    flex-basis: calc( 50% - 2.5rem );
  `}
`;

const ProductInfo = styled.div`
  text-align: left;

  ${media.up.md`
    flex-basis: calc( 50% - 2.5rem );
  `}
`;

const Headline = styled(H1)`
  margin-bottom: 1rem;
  color: ${p => p.theme.color.kaleLeafDark};
`;

const Calories = styled(P)`
  color: ${p => p.theme.color.peppercornLight};
`;

const BodyText = styled(Wysiwyg)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const AddonHeadline = styled(H3)`
  margin-top: 1.75rem;
  margin-bottom: 1rem;
  font-size: 2rem;
`;

const AddonOptions = styled(P)`
  margin-bottom: 1.75rem;
  font-weight: ${p => p.theme.fontWeight.bold};
`;

const Link = styled(Button)``;

const Product = ({ title, content, calories, addons, image }) => {

  return (
    <Wrapper>
      <Container>
        <ImageContainer>
          {image && <Image alt={title} url={image} />}
        </ImageContainer>

        <ProductInfo>
          <Headline>{title}</Headline>
          {calories && <Calories>{calories} calories</Calories>}
          {content && (
            <>
              {/* dompurify kept running into issues, so removing for now */}
              <BodyText
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </>
          )}
          {addons && (
            <>
              {addons.menu_item__addons__headline && (
                <AddonHeadline>{addons.menu_item__addons__headline}</AddonHeadline>
              )}
              {addons.menu_item__addons__options && (
                <AddonOptions>{addons.menu_item__addons__options}</AddonOptions>
              )}
            </>
          )}
          <Link title="Order now" url="https://order.salata.com/" />
        </ProductInfo>
      </Container>
    </Wrapper>
  );
};

Product.propTypes = {
  addons: PropTypes.shape({
    // eslint-disable-next-line camelcase
    menu_item__addons__headline: PropTypes.object,
    // eslint-disable-next-line camelcase
    menu_item__addons__options: PropTypes.number
  }),
  calories: PropTypes.number,
  content: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string
};

export default Product;
