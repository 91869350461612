import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getColumnSpanSize, H2, media, P } from '../../../global';

const Headline = styled(H2)`
  max-width: ${p => getColumnSpanSize(3, p.theme.gridSettings)}px;
  padding-right: 75px;
  width: 100%;
`;

const Paragraph = styled(P)`
  max-width: ${p => getColumnSpanSize(4, p.theme.gridSettings)}px;
  width: 100%;

  ${media.down.sm`
    max-width: none;
    padding-top: 30px;
  `}
`;

const Wrapper = styled.div`
  text-align: left;

  ${media.up.sm`
    display: flex;
  `}
`;

const Header = ({ bodyText, mainHeadline }) => (
  <Wrapper>
    <Headline>{mainHeadline}</Headline>
    <Paragraph>{bodyText}</Paragraph>
  </Wrapper>
);

Header.propTypes = {
  bodyText: PropTypes.string.isRequired,
  mainHeadline: PropTypes.string.isRequired
};

export default Header;
