import React from 'react';
import IconBase from './IconBase';

export default props => (
  <IconBase viewBox="0 0 20 17" {...props}>
    <path
      d="M19.383.95a7.8 7.8 0 0 1-2.525.989 3.914 3.914 0 0 0-2.9-1.289c-2.192 0-3.972 1.83-3.972 4.085 0 .32.034.631.103.93-3.303-.17-6.23-1.794-8.19-4.27a4.168 4.168 0 0 0-.539 2.057c0 1.417.702 2.667 1.768 3.4a3.887 3.887 0 0 1-1.8-.51v.05c0 1.98 1.37 3.633 3.188 4.007a3.778 3.778 0 0 1-1.047.143c-.256 0-.506-.024-.748-.072.506 1.623 1.973 2.804 3.712 2.835A7.831 7.831 0 0 1 .55 15a11.04 11.04 0 0 0 6.091 1.834c7.31 0 11.305-6.224 11.305-11.623 0-.178-.003-.355-.01-.53a8.173 8.173 0 0 0 1.982-2.114 7.766 7.766 0 0 1-2.282.643 4.078 4.078 0 0 0 1.747-2.26"
      fill="currentColor"
      fillRule="evenodd"
    />
  </IconBase>
);
