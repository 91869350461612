import { createGlobalStyle } from 'styled-components';
import { fontFaces } from './type';
import { resetCSS } from './reset';

export * from './datePicker';
export * from './props';
export * from './slick';
export * from './utils';
export * from './theme';
export * from './type';
export { default as IconBase } from './icons/IconBase';
export { default as Arrow } from './icons/Arrow';
export { default as Base } from './icons/Base';
export { default as BackgroundCircle } from './icons/BackgroundCircle';
export { default as CheckMark } from './icons/CheckMark';
export { default as CheeseNuts } from './icons/CheeseNuts';
export { default as Close } from './icons/Close';
export { default as Curves } from './icons/Curves';
export { default as Desserts } from './icons/Desserts';
export { default as DotGroup } from './icons/DotGroup';
export { default as Dots } from './icons/Dots';
export { default as Drinks } from './icons/Drinks';
export { default as Dropdown } from './icons/Dropdown';
export { default as Entrees } from './icons/Entrees';
export { default as Envelope } from './icons/Envelope';
export { default as Exclamation } from './icons/Exclamation';
export { default as Extras } from './icons/Extras';
export { default as Facebook } from './icons/Facebook';
export { default as FranchiseDesktop } from './icons/FranchiseDesktop';
export { default as FranchiseMobile } from './icons/FranchiseMobile';
export { default as Fruits } from './icons/Fruits';
export { default as Instagram } from './icons/Instagram';
export { default as Link } from './icons/Link';
export { default as LocationMarker } from './icons/LocationMarker';
export { default as MapMarker } from './icons/MapMarker';
export { default as Menu } from './icons/Menu';
export { default as Other } from './icons/Other';
export { default as Printer } from './icons/Printer';
export { default as Protein } from './icons/Protein';
export { default as Refresh } from './icons/Refresh';
export { default as Salata } from './icons/Salata';
export { default as Seeds } from './icons/Seeds';
export { default as SEO } from './seo';
export { default as Soups } from './icons/Soups';
export { default as Stars } from './icons/Stars';
export { default as Twitter } from './icons/Twitter';
export { default as UnitedStates } from './icons/UnitedStates';
export { default as Vegetables } from './icons/Vegetables';

export default createGlobalStyle`
  ${fontFaces}
  ${resetCSS}
  #root {
    height: 100%;

    .hidden {
      display: none;
    }
  }
`;
