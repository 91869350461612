import React from 'react';
import styled from 'styled-components';

import { Section } from '../../components';
import {
  contained,
  darkenColor,
  H1,
  H5,
  PageType,
  Wysiwyg
} from '../../global';
import BaseLayout from '../../layouts/Base';
import { Hero } from '../../sections';

import Author from './Author';
import Comments from './Comments';
import DateView from './DateView';
import Share from './Share';
import Post from './Post';

const Content = styled(Wysiwyg)`
  a {
    color: ${p => p.theme.color.kaleLeaf};

    &:hover {
      color: ${p => darkenColor(0.04, p.theme.color.kaleLeaf)};
    }

    &:active {
      color: ${p => p.theme.color.kaleLeafDark};
    }
  }
`;

const Heading = styled(H5)`
  padding-bottom: 25px;
  text-align: left;
  text-transform: uppercase;
`;

const RecentPosts = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ShareSection = styled(Section)`
  z-index: 2;
`;

const BlogPost = ({
  authorAvatar,
  authorBio,
  authorEmail,
  authorName,
  content,
  date,
  id,
  media,
  permalink,
  recentPosts,
  title,
  ...layoutProps
}) => (
  <BaseLayout footerDivider {...layoutProps}>
    <Hero
      background={media.medium}
      dividerBottomColor="white"
      overlap
      noPadding
    />

    <article>
      <Section align="left" css={contained} noBottomPadding unContain>
        <H1 dangerouslySetInnerHTML={{ __html: title }} />
        <DateView date={date} />
      </Section>

      <Section align="left" halfTopPadding noBottomPadding unContain>
        <main>
          <Content contained dangerouslySetInnerHTML={{ __html: content }} />
        </main>
      </Section>

      <ShareSection align="left" halfPadding unContain>
        <Share />
      </ShareSection>

      <Section align="left" halfPadding unContain>
        <Author
          avatar={authorAvatar}
          bio={authorBio}
          email={authorEmail}
          name={authorName}
        />
      </Section>
    </article>
    <Section halfTopPadding>
      <Comments id={id} path={permalink} title={title} />
    </Section>
    <Section noTopPadding>
      <Heading>Recent Posts</Heading>
      <RecentPosts>
        {recentPosts.map((post, i) => (
          <Post key={i} {...post} />
        ))}
      </RecentPosts>
    </Section>
  </BaseLayout>
);

BlogPost.propTypes = {
  ...PageType
};

export default BlogPost;
