import React from 'react';
import IconBase from './IconBase';

export default props => (
  <IconBase width="22" height="30" viewBox="0 0 22 30" {...props}>
    <path
      strokeWidth=".875"
      d="M19.282 27.793c-4.97 2.183-9.846.943-14.783-.12-1.3-1.966-2.139-4.115-2.565-6.392-.32-1.706-.382-3.462-.55-5.197-.068-.704.262-1.258.683-1.779.938-1.157 2.202-1.82 3.547-2.31 2.421-.886 4.903-1.303 7.49-.91 1.19.18 2.414.137 3.593.358.963.18 1.939.488 2.807.94 1.794.934 2.407 2.289 1.724 3.953-.322.782-.305 1.707-.439 2.567-.43 2.757-.859 5.515-1.294 8.272-.027.167-.11.325-.213.618zM2.838 17.846c-.037.273-.104.523-.101.772.034 2.688.884 5.137 2.15 7.463.274.505.71.722 1.25.818 1.274.226 2.538.514 3.815.71 2.359.36 4.72.426 7.052-.2.445-.12.87-.323 1.35-.505.463-2.97 1.115-5.84 1.359-8.888-5.73 1.75-11.277 1.27-16.875-.17zm-.212-2.133c.34.83.985 1.137 1.652 1.276 1.638.34 3.283.7 4.943.86 2.832.273 5.678.336 8.46-.447a6.325 6.325 0 0 0 1.744-.801c.649-.43 1.008-1.077.795-1.912-.184-.719-1.021-1.376-1.812-1.447-.34-.03-.685-.038-1.019-.103-1.602-.309-3.19-.707-4.803-.936-2.857-.407-5.563.198-8.105 1.538-.807.425-1.496 1.015-1.855 1.972zM8.909 6.56c1.12-1.523 2.835-.916 4.523-1.473-.572-.198-.855-.346-1.152-.389-.939-.135-1.891-.183-2.822-.356-.774-.145-1.66-.179-2.03-1.099 1.26-2.06 6.035-3.01 7.635-1.513-.18.642-.669.542-1.132.446-1.368-.284-2.653.089-3.932.5-.181.058-.337.2-.7.422 1.948.446 3.84.24 5.376 1.35.195.142.307.401.517.688-.922 1.657-2.62 1.134-4.18 1.498.858.507 1.823.777 1.755 1.84-.04.619-.095 1.324-.838 1.598-.812-.44-.147-1.144-.398-1.771-.621-.806-2.25-.25-2.622-1.741z"
    />
  </IconBase>
);
