import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media, getPercentValue } from '../../global';
import { setColumnWidth } from '../../components/Grid/Column/utils';

// prettier-ignore
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${media.down.md`
    justify-content: space-between;
  `}
`;

const PostWrapper = styled.div`
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex: 1 0 ${p => setColumnWidth(3, p.theme.gridSettings)};
  justify-content: center;
  padding: ${p =>
    `${getPercentValue(
      p.theme.gridSettings.gutterWidth / 2,
      p.theme.gridSettings.maxWidth
    )}%`};
  position: relative;
  max-width: calc(100% / 3);

  & :nth-child(3n) {
    padding-right: 0px;
  }

  & :nth-child(3n + 1) {
    padding-left: 0px;
  }

  ${media.down.md`
    flex 1 0 100%;
    max-width: 43vw;
    padding: 0 0 40px 0;
  `}

  ${media.down.sm`
    max-width: 40.5vw;
  `}
`;

const RecentPosts = ({ component, componentData }) => {
  const Component = component;

  return (
    <Wrapper>
      {componentData.map((data, i) => (
        <PostWrapper key={i}>
          <Component {...data} />
        </PostWrapper>
      ))}
    </Wrapper>
  );
};

const componentType = [PropTypes.func, PropTypes.object, PropTypes.string];

RecentPosts.propTypes = {
  component: PropTypes.oneOfType(componentType).isRequired,
  componentData: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default RecentPosts;
