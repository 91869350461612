import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Card, Section } from '../../components';

import { ImageType } from '../../elements';

import { PageType } from '../../global/props';
import { H2, P, Wysiwyg } from '../../global/type';
import { media, toEms } from '../../global/utils';

import ContentWithSidebar from '../../layouts/ContentWithSidebar';

import { FeaturedContent, FeaturedContentType, HeroType } from '../../sections';
import { Form } from '../../franchising/components';

const Disclaimer = styled(P)`
  margin-top: 45px;
  font-size: ${toEms('14px')};
  color: ${p => p.theme.color.peppercornLight};
`;

const Headline = styled(H2)`
  margin-bottom: 25px;
`;

const MainWrapper = styled.div`
  padding: 60px 0;
  text-align: left;

  ${media.down.md`
    padding: 0 0 60px;
  `}
`;

const SideWrapper = styled.div`
  padding: 60px 20px 60px 0;
  text-align: left;

  ${media.down.md`
    padding: 0 0 60px;
  `}
`;

const Franchisee = ({ acf, ...layoutProps }) => {
  const { becomeOwner, disclaimer, hero, letsTalk, theSalataFranchise } = acf;

  const mainContent = (
    <MainWrapper>
      <Form />
      <Disclaimer>{disclaimer.bodyText}</Disclaimer>
    </MainWrapper>
  );
  const sidebarContent = (
    <SideWrapper>
      <Headline>{letsTalk.headline}</Headline>
      <P>{letsTalk.bodyText}</P>
    </SideWrapper>
  );

  return (
    <ContentWithSidebar
      {...layoutProps}
      hero={{
        background: hero.background,
        image: hero.image,
        pageTitle: hero.pageTitle
      }}
      mainContent={mainContent}
      sidebarContent={sidebarContent}
    >
      <Section halfTopPadding noBottomPadding unContain>
        <FeaturedContent alignment="right" image={becomeOwner.image}>
          <Card {...becomeOwner.content} />
        </FeaturedContent>
      </Section>
      <Section halfBottomPadding unContain>
        <FeaturedContent image={theSalataFranchise.image}>
          <Wysiwyg
            dangerouslySetInnerHTML={{
              __html: theSalataFranchise.wysiwyg
            }}
          />
        </FeaturedContent>
      </Section>
    </ContentWithSidebar>
  );
};

Franchisee.propTypes = {
  ...PageType,
  acf: PropTypes.shape({
    becomeOwner: PropTypes.shape(FeaturedContentType),
    disclaimer: PropTypes.shape({
      bodyText: PropTypes.string
    }),
    hero: PropTypes.shape(HeroType),
    letsTalk: PropTypes.shape({
      bodyText: PropTypes.string,
      headline: PropTypes.string
    }),
    theSalataFranchise: PropTypes.shape({
      image: PropTypes.shape(ImageType),
      wysiwyg: PropTypes.string
    })
  }).isRequired
};

export default Franchisee;
