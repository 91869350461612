import React from 'react';
import PropTypes from 'prop-types';

import { Card, Grid, Section } from '../../components';
import { PageType } from '../../global';
import BaseLayout from '../../layouts/Base';
import {
  ContentCallout,
  ContentCalloutType,
  FindMySalata,
  Hero,
  HeroType
} from '../../sections';
import {
  findMySalataImages,
  findMySalataParticles,
  findMySalataSectionType,
  mantraParticles,
  todayAtSalataLinkProps,
  todayAtSalataType
} from './utils';

const Home = ({ acf, ...layoutProps }) => {
  const { findMySalata, hero, mantra, todayAtSalata } = acf;

  return (
    <BaseLayout {...layoutProps}>
      <Hero halfBottomPadding overlap {...hero} />
      <Section
        bgColor="orange"
        dividerBottomColor="white"
        dividerTopColor="white"
        insetDividerBottom
        insetDividerTop
        noPadding
        particlesData={mantraParticles}
      >
        <ContentCallout {...mantra} />
      </Section>
      <Section align="left" intro={todayAtSalata.intro} halfBottomPadding>
        <Grid
          center
          columns={3}
          component={Card}
          componentData={todayAtSalata.content}
          dividers
          linkProps={todayAtSalataLinkProps}
        />
      </Section>
      <Section
        dividerBottomColor="tan"
        dividerBottomReverse
        halfTopPadding
        insetDividerBottom
        noBottomPadding
        particlesData={findMySalataParticles}
        unContain
      >
        <FindMySalata {...findMySalataImages} {...findMySalata} />
      </Section>
    </BaseLayout>
  );
};

Home.propTypes = {
  ...PageType,
  acf: PropTypes.shape({
    findMySalata: PropTypes.shape(findMySalataSectionType),
    hero: PropTypes.shape(HeroType),
    mantra: PropTypes.shape(ContentCalloutType),
    todayAtSalata: PropTypes.shape(todayAtSalataType)
  }).isRequired
};

export default Home;
