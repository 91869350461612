import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media, P } from '../../../../global';

import Ingredient, { IngredientType } from './Ingredient';
import Modal from './Ingredient/Modal';

import { getMetrics, updateSelected } from '../../utils';
import { CheckboxType } from '../../Navigation/AllergenFilter/Filters/Checkbox';

// prettier-ignore
const List = styled.div`
  overflow: hidden;
  transition: max-height 0.25s ease;

  ${p => media.down.lg`
    border-top: none;
    max-height: ${p.maxHeight}px;

    ${!p.expanded && `
      border-top: 1px solid ${p.theme.color.white};
      max-height: 0;
    `}
  `}
`;

// prettier-ignore
const Name = styled(P)`
  background: ${p => p.theme.color.blueCheeseLight};
  font-weight: ${p => p.theme.fontWeight.bold};
  padding: 18px 0 18px 50px;
  position: relative;
  text-align: left;
  text-transform: uppercase;

  &::before,
  &::after {
    background: ${p => p.theme.color.peppercorn};
    height: 2px;
    position: absolute;
    right: 28px;
    top: 50%;
    transform: rotate(45deg) translateY(-50%);
    transition: transform 0.25s ease;
    width: 9px;
  }

  &::after {
    right: 21px;
    transform: rotate(-45deg) translateY(-50%);
  }

  ${p => media.down.lg`
    cursor: pointer;

    &::before,
    &::after {
      content: '';
    }

    ${p.expanded && `
      &::before{
        right: 26px;
        transform: rotate(-45deg) translateY(-50%);
      }

      &::after {
        right: 22px;
        transform: rotate(45deg) translateY(-50%);
      }
    `}
  `}
`;

const Wrapper = styled.div``;

const Group = ({
  activeRadio,
  activeTab,
  allergens,
  group,
  measurements,
  selected,
  setSelected
}) => {
  const [activeModal, setActiveModal] = useState({});
  const [empty, setEmpty] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);

  const activeAllergens = allergens.filter(allergen => allergen.checked);
  const contentRef = useRef(null);
  const useArrayStructure =
    activeTab !== 'Entrees' || (activeTab === 'Entrees' && activeRadio === '');

  // this is to prevent a group from rendering if it has no data
  const isEmptyArray = obj => {
    if (obj[activeRadio]) {
      setEmpty(obj[activeRadio].length < 1);
    }
  };

  useEffect(() => {
    isEmptyArray(group.ingredients);
  }, [activeRadio]);

  useEffect(() => {
    if (expanded) setMaxHeight(contentRef.current.clientHeight);
  }, [expanded, maxHeight]);

  useEffect(() => {
    if (!useArrayStructure) {
      group.ingredients[activeRadio].forEach(ingredient =>
        updateSelected(ingredient, selected, setSelected)
      );
    }
  }, [group]);

  return (
    <Wrapper>
      {!empty && (
        <>
          <Name expanded={expanded} onClick={() => setExpanded(!expanded)}>
            {group.group}
          </Name>
          <List expanded={expanded} maxHeight={maxHeight}>
            <div ref={contentRef}>
              {useArrayStructure
                ? group.ingredients.map((ingredient, i) => (
                  <Ingredient
                    activeAllergens={activeAllergens}
                    ingredient={ingredient}
                    key={i}
                    selected={selected}
                    setActiveModal={setActiveModal}
                    setSelected={setSelected}
                    setModalVisible={setModalVisible}
                  />
                ))
                : group.ingredients[activeRadio].map((ingredient, i) => (
                  <Ingredient
                    activeAllergens={activeAllergens}
                    ingredient={ingredient}
                    key={i}
                    selected={selected}
                    setActiveModal={setActiveModal}
                    setSelected={setSelected}
                    setModalVisible={setModalVisible}
                  />
                ))}
            </div>
            <Modal
              ingredient={activeModal}
              measurements={measurements}
              setVisible={setModalVisible}
              values={getMetrics(activeModal)}
              visible={modalVisible}
            />
          </List>
        </>
      )}
    </Wrapper>
  );
};

export const GroupType = {
  group: PropTypes.string,
  ingredients: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape(IngredientType)),
    PropTypes.shape({
      regular: PropTypes.arrayOf(PropTypes.shape(IngredientType)),
      small: PropTypes.arrayOf(PropTypes.shape(IngredientType)),
      wrap: PropTypes.arrayOf(PropTypes.shape(IngredientType))
    })
  ])
};

Group.propTypes = {
  activeRadio: PropTypes.string,
  activeTab: PropTypes.string,
  allergens: PropTypes.arrayOf(PropTypes.shape(CheckboxType)),
  group: PropTypes.shape(GroupType),
  measurements: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.shape(IngredientType))
  ]),
  setSelected: PropTypes.func
};

export default Group;
