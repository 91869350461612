import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from '../../../elements';
import { Dropdown, Hidden } from '../../../global';
import { NavItem, NavLink, NestedNavList } from './components';

const HoverButton = styled(Button)`
  margin-left: 26px;

  button {
    padding: 16px 16px 14px;
  }
`;

const OrderNowButton = ({ order }) => (
  <Hidden down="lg" style={{ height: '100%' }}>
    <NavItem>
      <NavLink as="span">
        <HoverButton
          title={
            <>
              Order Now
              <Dropdown afterText />
            </>
          }
        />
      </NavLink>

      <NestedNavList>
        {order.map((item, i) => (
          <NavItem key={i}>
            <NavLink isNested path={item.path} title={item.title} />
          </NavItem>
        ))}
      </NestedNavList>
    </NavItem>
  </Hidden>
);

export const orderNowType = {
  classes: PropTypes.string,
  id: PropTypes.number.isRequired,
  order: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

OrderNowButton.propTypes = {
  order: PropTypes.arrayOf(PropTypes.shape(orderNowType))
};

export default OrderNowButton;
