import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CardType } from '../../../../components';
import { ButtonType } from '../../../../elements';
import { P } from '../../../../global';

// prettier-ignore
const NavItem = styled(P)`
  cursor: pointer;
  margin: 0 5px;
  padding: 0 15px 15px;
  position: relative;
  text-align: center;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &::after {
    background: ${p => p.theme.color.redPepper};
    bottom: 0px;
    display: block;
    height: 4px;
    left: 0;
    position: absolute;
    transform: translateY(1px);
    width: 100%;
  }

  ${p => p.active && `
    color: ${p.theme.color.redPepper};

    &::after {
      content: '';
    }
  `}
`;

const Wrapper = styled.div`
  border-bottom: 1px solid ${p => p.theme.color.peppercorn};
  display: inline-flex;
  justify-content: center;
  margin-bottom: 75px;
`;

const Nav = ({ activeTab, corporate, handleTabChange, restaurant }) => (
  <Wrapper>
    <NavItem
      active={activeTab === restaurant}
      onClick={() => handleTabChange(restaurant)}
    >
      restaurant
    </NavItem>
    <NavItem
      active={activeTab === corporate}
      onClick={() => handleTabChange(corporate)}
    >
      home office
    </NavItem>
  </Wrapper>
);

export const PositionsType = {
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(ButtonType)]),
  positions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape(CardType)),
    PropTypes.bool
  ])
};

Nav.propTypes = {
  activeTab: PropTypes.shape(PositionsType),
  corporate: PropTypes.shape(PositionsType).isRequired,
  handleTabChange: PropTypes.func.isRequired,
  restaurant: PropTypes.shape(PositionsType).isRequired
};

export default Nav;
