import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Image, ImageType } from '../../../elements';
import { BackgroundCircle, P, media } from '../../../global';

import Content from './Content';

const $transition = 'opacity 0.25s ease-in-out';

const Background = styled(BackgroundCircle)`
  height: 195px;
  width: 210px;

  ${media.down.lg`
    height: 130px;
  `}
`;

const BackgroundWrapper = styled.div`
  left: 57%;
  opacity: 0;
  position: absolute;
  top: 25px;
  transform: translateX(-50%);
  transition: ${$transition};
  z-index: 0;

  ${media.down.lg`
    display: none;
  `}
`;

const ImageWrapper = styled.div`
  position: relative;
  z-index: 1;

  ${media.down.lg`
    padding: 0 10px;
  `}

  ${media.down.sm`
    padding-left: 0;
  `}
`;

const TeaContent = styled(Content)`
  ${media.down.sm`
    padding-right: 0;
  `}
`;

const TeaImage = styled(Image)`
  position: relative;
  z-index: 1;

  ${media.down.sm`
    width: 149px;
  `}
`;

const TeaName = styled(P)`
  padding: 20px 0 0 38px;
  text-align: center;
  transition: ${$transition};

  ${media.down.lg`
    display: none;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  transition: ${$transition};

  ${media.up.lg`
    cursor: pointer;
    max-width: 160px;

    &:hover {
      ${BackgroundWrapper} {
        opacity: 1;
      }

      ${TeaContent} {
        opacity: 1;
        transform: translateX(0);
      }
    }
  `}
`;

const Tea = ({ className, description, image, name, reverse }) => (
  <Wrapper className={className}>
    <ImageWrapper>
      <TeaImage {...image} />
      <TeaName>{name}</TeaName>
      <BackgroundWrapper>
        <Background />
      </BackgroundWrapper>
    </ImageWrapper>
    <TeaContent description={description} name={name} reverse={reverse} />
  </Wrapper>
);

export const TeaType = {
  className: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.shape(ImageType),
  name: PropTypes.string.isRequired,
  reverse: PropTypes.bool
};

Tea.propTypes = TeaType;

export default Tea;
