import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H4, media, P } from '../../../global';

const Download = styled(P).attrs({ as: 'a' })`
  color: ${p => p.theme.color.redPepper};
  text-decoration: underline;
`;

const Headline = styled(H4)`
  color: ${p => p.theme.color.kaleLeaf};
  margin-bottom: 30px;
`;

const Wrapper = styled.div`
  padding: 60px 20px 60px 0;
  text-align: left;

  ${media.down.md`
    padding: 60px 0;
  `}
`;

const Sidebar = ({ headline, pdf }) => (
  <Wrapper>
    <Headline>{headline}</Headline>
    <Download href={pdf.url}>{pdf.title}</Download>
  </Wrapper>
);

export const SidebarType = {
  headline: PropTypes.string.isRequired,
  pdf: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  })
};

Sidebar.propTypes = SidebarType;

export default Sidebar;
