import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Image, ImageType } from '../../../../../elements';
import { Hidden, P } from '../../../../../global';

const Legend = styled(P)`
  margin-top: 25px;
  text-align: center;
`;

const Location = styled(P)`
  color: ${p => p.theme.color.peppercornLight};
  margin-bottom: 25px;
`;

const MapWrapper = styled.div`
  cursor: auto;
  &:not(:last-of-type) {
    margin-bottom: 75px;
  }
`;

const MobileMaps = ({ locations }) => (
  <Hidden up="lg">
    {locations.map((location, i) => (
      <MapWrapper key={i}>
        <Location>{location.name}</Location>
        <Image {...location.image} />
        {i === 0 && (
          <Legend>
            *Size of density bubble represents number of locations
          </Legend>
        )}
      </MapWrapper>
    ))}
  </Hidden>
);

export const MobileMapsType = {
  image: PropTypes.shape(ImageType),
  name: PropTypes.string
};

MobileMaps.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape(MobileMapsType))
};

export default MobileMaps;
