import React from 'react';
import IconBase from './IconBase';

export default props => (
  <IconBase viewBox="0 0 17 21" {...props}>
    <path
      d="M8.5 21C2.833 15.693 0 11.493 0 8.4 0 3.76 3.806 0 8.5 0S17 3.76 17 8.4c0 3.093-2.833 7.293-8.5 12.6zm0-8.4c2.347 0 4.25-1.88 4.25-4.2 0-2.32-1.903-4.2-4.25-4.2S4.25 6.08 4.25 8.4c0 2.32 1.903 4.2 4.25 4.2z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </IconBase>
);
