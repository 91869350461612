import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { format } from 'date-fns';
import { H4, P, parseContent } from '../../../../global';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Download = styled(P).attrs({ as: 'a' })`
  color: ${p => p.theme.color.redPepper};
  display: block;
  margin-top: 20px;
  text-decoration: underline;
`;

const ExternalDate = styled(P)`
  color: ${p => p.theme.color.peppercornLight};
  font-weight: ${p => p.theme.fontWeight.bold};
  letter-spacing: 4px;
  margin-bottom: 15px;
  text-transform: uppercase;
`;

const Source = styled(P).attrs({ as: 'a' })`
  display: block;
  font-weight: ${p => p.theme.fontWeight.bold};
  margin-bottom: 20px;
`;

const Title = styled(H4).attrs({ as: 'a' })`
  color: ${p => p.theme.color.kaleLeaf};
  display: block;
  margin-bottom: 5px;
`;

const Wrapper = styled.div`
  animation: ${fadeIn} 0.25s ease-in;
  margin-top: 60px;
`;

const Article = ({ externalDate, pdf, snippit, source, title, url }) => {
  const date = format(externalDate, 'MMMM D[,] YYYY');

  return (
    <Wrapper>
      <ExternalDate>{date}</ExternalDate>
      <Title dangerouslySetInnerHTML={parseContent(title)} href={url} />
      <Source href={url}>{source}</Source>
      <P>{snippit}</P>
      {pdf && <Download href={pdf.url}>{pdf.title}</Download>}
    </Wrapper>
  );
};

Article.propTypes = {
  externalDate: PropTypes.string,
  pdf: PropTypes.oneOfType([
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    }),
    PropTypes.bool
  ]),
  snippit: PropTypes.string,
  source: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string
};

export default Article;
