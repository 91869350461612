import React from 'react';

export default props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 221 42" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#96C121"
        fillRule="nonzero"
        d="M34.732 18.323l1.386-.22 2.336 14.699-1.386.22zM40.45 32.079l2.302-10.853 1.373.291-2.3 10.853z"
      />
      <path
        fill="#000002"
        d="M31.034 21.816c0-4.553-3.403-7.994-7.962-7.994-4.56 0-7.978 3.43-7.962 7.994a8.36 8.36 0 0 0 2.002 5.431c1.56 1.78 3.722 2.564 6.068 2.564 4.517 0 7.865-3.526 7.865-7.995h-.011zm-.1 5.168c-1.703 2.733-4.577 4.171-7.864 4.171-5.344 0-9.364-4.045-9.364-9.333 0-5.287 4.029-9.33 9.364-9.33a9.663 9.663 0 0 1 5.406 1.614 8.144 8.144 0 0 1 2.457 2.534v-3.73h1.404v17.814h-1.404l.002-3.74zM63.13 21.834c0-4.555-3.402-7.996-7.961-7.996-4.56 0-7.978 3.43-7.962 7.993a8.35 8.35 0 0 0 2.006 5.43c1.56 1.782 3.722 2.566 6.068 2.566 4.517 0 7.865-3.526 7.865-7.993h-.016zm-.099 5.168c-1.703 2.733-4.577 4.171-7.865 4.171-5.344 0-9.364-4.045-9.364-9.33 0-5.286 4.03-9.333 9.364-9.333a9.663 9.663 0 0 1 5.407 1.614 8.09 8.09 0 0 1 2.456 2.534v-3.732h1.405v17.813h-1.405l.002-3.737zM94.831 21.834c0-4.555-3.402-7.996-7.961-7.996-4.56 0-7.978 3.43-7.962 7.993-.001 1.991.709 3.916 2.002 5.43 1.56 1.782 3.721 2.566 6.068 2.566 4.516 0 7.865-3.526 7.865-7.993h-.012zm-.098 5.168c-1.704 2.735-4.578 4.173-7.866 4.173-5.343 0-9.364-4.045-9.364-9.33s4.03-9.333 9.364-9.333a9.663 9.663 0 0 1 5.407 1.614 8.144 8.144 0 0 1 2.468 2.523v-3.723h1.404v17.813h-1.404l-.01-3.737zM1.413 25.703c0 2.526 1.666 4.135 4.169 4.135 2.283 0 3.973-1.685 3.908-3.966-.072-2.548-1.692-3.411-4.007-3.924-2.65-.761-4.748-1.573-4.852-4.719 0-2.908 2.12-4.741 4.944-4.719 2.825.023 4.818 1.84 4.818 4.755v.245H9v-.243c0-2.119-1.312-3.454-3.418-3.415-1.991 0-3.647 1.287-3.548 3.37.105 2.218 1.7 2.812 3.613 3.34 2.922.804 5.052 1.611 5.247 5.157.169 3.063-2.285 5.456-5.312 5.456-3.09 0-5.77-2.27-5.57-5.49l.013-.224h1.388v.242z"
      />
      <path
        fill="#000002"
        fillRule="nonzero"
        d="M38.816 5h1.404v25.735h-1.404z"
      />
      <path
        fill="#000002"
        d="M72.234 12.926h4.447v1.337h-4.447v16.476H70.83V14.263h-3.824v-1.337h3.824V6.06h1.403z"
      />
      <path
        fill="#231F20"
        fillRule="nonzero"
        d="M97.726 12.809h-.45v-.169h1.086v.162h-.45v1.306h-.193zM99.775 13.47c-.011-.205-.024-.45-.022-.634-.05.173-.113.357-.187.557l-.258.713h-.144l-.236-.7a6.227 6.227 0 0 1-.17-.57c0 .184-.016.43-.028.65l-.04.629h-.18l.101-1.468h.243l.25.71c.06.18.112.342.148.495.036-.149.09-.31.155-.495l.25-.717h.242l.09 1.468h-.185l-.029-.638z"
      />
      <path
        stroke="#8E908E"
        strokeLinecap="square"
        strokeWidth=".5"
        d="M114.5 1v40"
      />
      <path
        fill="#8E908E"
        fillRule="nonzero"
        d="M135.15 17.572h-4.214v2.758h3.892v1.372h-3.892V26h-1.456v-9.8h5.67v1.372zM142.144 26l-2.856-4.074h-.882V26h-1.456v-9.8h3.346c1.722 0 2.996 1.078 2.996 2.884 0 1.484-.91 2.534-2.282 2.786l2.968 4.13h-1.834zm-3.738-8.428v2.982h1.652c1.176 0 1.666-.63 1.666-1.47 0-.868-.49-1.512-1.666-1.512h-1.652zM152.344 26l-.938-2.436h-4.284L146.184 26h-1.596l4.004-9.8h1.344l4.004 9.8h-1.596zm-4.704-3.78h3.248l-1.624-4.2-1.624 4.2zm15.576-6.02V26h-1.526l-4.816-7.322h-.028V26h-1.456v-9.8h1.526l4.816 7.322h.028V16.2h1.456zm7.12 10.01c-3.108 0-5.25-2.058-5.25-5.11 0-3.052 2.142-5.11 5.25-5.11 1.47 0 2.562.434 3.458 1.078l-.896 1.274a4.398 4.398 0 0 0-2.618-.868c-2.058 0-3.598 1.414-3.598 3.626 0 2.212 1.54 3.626 3.598 3.626a4.398 4.398 0 0 0 2.618-.868l.896 1.274c-.896.644-1.988 1.078-3.458 1.078zM182.16 16.2h1.456V26h-1.456v-4.466h-5.18V26h-1.456v-9.8h1.456v3.934h5.18V16.2zm5.3 0V26h-1.484v-9.8h1.484zm5.02-.21c.994 0 1.904.308 2.59.658l-.658 1.386c-.588-.336-1.316-.574-1.974-.574-.784 0-1.12.322-1.12.84 0 .448.252.756.77 1.162l1.75 1.344c1.008.77 1.512 1.54 1.512 2.66 0 1.792-1.274 2.744-3.136 2.744a6.079 6.079 0 0 1-3.164-.91l.658-1.372c.63.364 1.568.798 2.52.798 1.05 0 1.554-.518 1.554-1.176 0-.546-.336-.952-1.036-1.498l-1.666-1.316c-.84-.658-1.344-1.372-1.344-2.38 0-1.442 1.022-2.366 2.744-2.366zm6.224.21V26h-1.484v-9.8h1.484zm10.186 0V26h-1.526l-4.816-7.322h-.028V26h-1.456v-9.8h1.526l4.816 7.322h.028V16.2h1.456zm7.33 4.382h4.648v.588c0 2.52-1.596 5.04-4.928 5.04-2.954 0-5.18-1.988-5.18-5.082 0-3.01 2.1-5.138 5.194-5.138 1.666 0 2.926.602 3.766 1.316l-.994 1.218c-.63-.574-1.61-1.05-2.842-1.05-2.142 0-3.528 1.568-3.528 3.64 0 2.1 1.442 3.612 3.626 3.612 2.142 0 3.206-1.47 3.318-2.772h-3.08v-1.372z"
      />
    </g>
  </svg>
);
