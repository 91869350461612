import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Image, ImageType, Button } from '../../elements';
import { H3, MapMarker, media } from '../../global';
// import { LocationSearch } from '../../components';

const Headline = styled(H3)`
  color: ${p => p.theme.color.kaleLeaf};

  ${media.down.xs`
    font-size: 28px;
  `}
`;

const HeadlineIcon = styled(MapMarker)`
  color: ${p => p.theme.color.kaleLeaf};
  height: 30px;
  margin-right: 10px;
  width: calc(17px / 21 * 30);
`;

const HeadlineWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Peppers = styled.div`
  position: relative;
  z-index: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  cursor: none;
  pointer-events: none;
`;

const ImageDesktop = styled(Image)`
  ${media.down.md`
    display: none;
  `}
`;

const ImageMobile = styled(Image)`
  display: none;

  ${media.down.md`
    display: block;
  `}

  ${media.down.xs`
    img {
      max-width: 160%
      transform: translateX(-100px);
      width: 160%;
    }
  `}
`;

const Search = styled.div`
  position: absolute;
  z-index: 1;
  right: 16%;
  top: calc(50% - 70px);
  transform: translateY(-50%);
  text-align: center;
  width: 40%;

  ${media.down.xl`
    left: auto;
    top: calc(50% - 50px);
  `}

  ${media.down.lg`
    top: calc(50% - 40px)
  `}

  ${media.down.md`
    padding-top: 2vw;
    right: 50%;
    top: 60%;
    transform: translateX(50%);
    width: 75%;
  `}

  ${media.down.sm`
    top: 55%;
  `}

  ${media.down.xs`
    padding: 0 20px;
    top: 60%;
    width: 100%;
  `}
`;

const Wrapper = styled.div`
  position: relative;

  ${media.down.md`
    padding-bottom: 200px;
  `}
`;

const FindMySalata = ({ desktop, mobile }) => (
  <Wrapper>
    <Peppers>
      <ImageDesktop {...desktop} alt="Peppers" />
      <ImageMobile {...mobile} alt="Peppers" />
    </Peppers>
    <Search>
      <HeadlineWrapper>
        <HeadlineIcon />
        <Headline>find my salata</Headline>
      </HeadlineWrapper>

      {/* <LocationSearch /> */}
      <Button
        title="See Locations"
        url="https://order.salata.com/?type=individual"
      />
    </Search>
  </Wrapper>
);

export const FindMySalataType = {
  desktop: PropTypes.shape(ImageType),
  mobile: PropTypes.shape(ImageType)
};

FindMySalata.propTypes = FindMySalataType;

export default FindMySalata;
