import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { H6, P, shadeOf } from '../../global';

const Label = styled(H6)`
  color: ${p => p.theme.color.kaleLeaf};
  margin-bottom: 25px;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;

const Paragraph = styled(P)`
  color: ${p => p.theme.color.peppercornLight};
`;

const Small = styled(Paragraph)`
  font-size: ${p => p.theme.fontSize.label};
  margin-top: 20px;
`;

const Wrapper = styled.div`
  text-align: left;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${p => shadeOf(p.theme.color.black, 0.25)};
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
`;

const ContentBreakdown = ({ disclaimer, items, label }) => (
  <Wrapper>
    <Label>{label}</Label>
    {items.map((item, i) => (
      <Item key={i}>
        <Paragraph>{item.text}</Paragraph>
        <P>
          <strong>{item.value}</strong>
        </P>
      </Item>
    ))}
    {disclaimer && <Small>{disclaimer}</Small>}
  </Wrapper>
);

export const ContentBreakdownType = {
  disclaimer: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  label: PropTypes.string.isRequired
};

ContentBreakdown.propTypes = {
  ...ContentBreakdownType
};

export default ContentBreakdown;
