import React from 'react';
import PropTypes from 'prop-types';
import {
  ControlWrapper,
  Input,
  Label,
  Select,
  SelectTriangle,
  TextArea
} from './components';
import DateInput from './DateInput';
import MonthInput from './MonthInput';
import StateInput from './StateInput';
import TelephoneInput from './TelephoneInput';

/**
 * NOTE: This looks similar to but is different from `<input />` types:
 * https://s.blakek.me/form-input-types
 * Some types, for example `text` and `password`, do map directly to an input
 * type. Others like `select` aren't input types and show a `<select />`.
 */
const controls = {
  // Fallback value
  date: DateInput,
  default: Input,
  email: Input,
  month: MonthInput,
  number: Input,
  password: Input,
  radio: Input,
  search: Input,
  select: Select,
  state: StateInput,
  tel: TelephoneInput,
  text: Input,
  textarea: TextArea
};

const showsDropdown = ['date', 'month', 'select', 'state'];

const FormControl = ({ hasError, type, value, ...props }) => {
  const Control = controls[type] || controls.default;

  return (
    <ControlWrapper hasError={hasError} type={type}>
      <Control maxLength="255" type={type} value={value} {...props} />
      {showsDropdown.includes(type) && <SelectTriangle />}
      {type === 'radio' && <Label type={type}>{value}</Label>}
    </ControlWrapper>
  );
};

FormControl.propTypes = {
  hasError: PropTypes.bool,
  type: PropTypes.oneOf(Object.keys(controls)),
  value: PropTypes.string
};

export default FormControl;
