import React, { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { P, Wysiwyg } from '../../../../../global';

const Collapse = styled.div`
  height: ${p => p.currentHeight}px;
  overflow: hidden;
  transition: height 0.25s ease-in-out;
`;

const Expanded = styled(Wysiwyg)`
  padding-top: 30px;

  p {
    color: ${p => p.theme.color.peppercorn};
  }
`;

const ShowMore = styled(P)`
  color: ${p => p.theme.color.redPepper};
  cursor: pointer;
  margin-top: 30px;
`;

const Title = styled(P)`
  font-weight: ${p => p.theme.fontWeight.bold};
  margin-top: 30px;
`;

const Wrapper = styled.div``;

const Ingredient = ({ acf, title }) => {
  const [expanded, setExpanded] = useState(false);
  const [height, setHeight] = useState(0);

  const { expandedContent, snippit } = acf.ingredients;
  const contentRef = useRef(null);

  const handleClick = () => {
    setHeight(contentRef.current.clientHeight);
    setExpanded(!expanded);
  };

  return (
    <Wrapper>
      <Title>{title}</Title>
      <P>{snippit}</P>
      {expandedContent.length > 0 && (
        <Fragment>
          <Collapse currentHeight={expanded ? height : 0}>
            <Expanded
              dangerouslySetInnerHTML={{ __html: expandedContent }}
              ref={contentRef}
            />
          </Collapse>
          <ShowMore onClick={() => handleClick()}>
            {expanded
              ? 'Hide Detailed Ingredients'
              : 'Show Detailed Ingredients'}
          </ShowMore>
        </Fragment>
      )}
    </Wrapper>
  );
};

const acfShape = {
  ingredients: PropTypes.shape({
    expandedContent: PropTypes.string,
    snippit: PropTypes.string.isRequired
  })
};

export const IngredientType = {
  acf: PropTypes.shape(acfShape),
  title: PropTypes.string.isRequired
};

Ingredient.propTypes = IngredientType;

export default Ingredient;
