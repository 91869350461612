import Proptypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { getColumnSpanSize, media, shadeOf } from '../../global';

const Close = styled.div`
  cursor: pointer;
  height: 20px;
  position: absolute;
  right: 30px;
  top: 30px;
  width: 20px;
  z-index: 1;

  &::before,
  &::after {
    background: ${p => p.theme.color.peppercorn};
    content: '';
    height: 2px;
    position: absolute;
    top: 9px;
    transform: rotate(45deg);
    width: 20px;
  }

  &::after {
    transform: rotate(-45deg);
  }

  ${media.down.sm`
    right: 20px;
  `}
`;

const ComponentWrapper = styled.div`
  background: ${p => p.theme.color.white};
  border-radius: 5px;
  margin: 0 auto;
  /* the 200px is based on the padding of the modal */
  max-height: calc(100vh - 200px);
  max-width: ${p => getColumnSpanSize(7, p.theme.gridSettings)}px;
  overflow-y: auto;
  position: relative;
  width: 100%;

  ${media.down.sm`
    // the 40px is based on the padding of the modal on mobile
    max-height: calc(100vh - 40px);
  `}
`;

const Wrapper = styled.div`
  background: ${p => shadeOf(p.theme.color.black, 0.75)};
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: ${p => (p.visible ? 1 : 0)};
  overflow-x: hidden;
  overflow-y: auto;
  padding: 100px 20px;
  pointer-events: ${p => (p.visible ? 'auto' : 'none')};
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.15s;
  width: 100%;
  z-index: 15;

  ${media.down.sm`
    padding: 20px;
  `}
`;

const Modal = ({ component, componentData, setVisible, visible }) => {
  const Component = component;
  const contentRef = useRef(null);

  const handleClickOutside = e => {
    if (contentRef.current && !contentRef.current.contains(e.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (window) {
      const body = document.body;
      const html = document.getElementsByTagName('html')[0];

      if (body && html) {
        if (visible) {
          body.style.overflow = 'hidden';
          html.style.overflow = 'hidden';
        } else {
          body.style.overflow = '';
          html.style.overflow = '';
        }
      }
    }
  }, [visible]);

  return (
    <Wrapper visible={visible}>
      <ComponentWrapper ref={contentRef}>
        <Close onClick={() => setVisible(false)} />
        <Component {...componentData} />
      </ComponentWrapper>
    </Wrapper>
  );
};

Modal.propTypes = {
  component: Proptypes.elementType,
  componentData: Proptypes.object,
  setVisible: Proptypes.func,
  visible: Proptypes.bool
};

export default Modal;
