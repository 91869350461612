import styled, { css } from 'styled-components';
import { media } from '../../global';

/* eslint-disable sort-keys */
/**
 * State transitions:
 * entering -> entered -> exiting -> exited -> unmounted
 */
const stateTransitions = {
  entering: css`
    opacity: 0;
    /* Don't affect layout until 'entered' state */
    position: absolute;
    /* 'direction' prop determines which way form should animate */
    transform: translateX(${p => 100 * p.direction}%);
  `,
  entered: css`
    opacity: 1;
    transform: translateX(0);
    transition: ${({ theme }) => `
      opacity ${theme.timing.default} ${theme.easing.ease},
      transform ${theme.timing.default} ${theme.easing.ease}
    `};
  `,
  exiting: css`
    opacity: 0;
    /* direction prop determines which way form should animate */
    transform: translateX(${p => -100 * p.direction}%);
    transition: ${({ theme }) => `
      opacity ${theme.timing.default} ${theme.easing.ease},
      transform ${theme.timing.default} ${theme.easing.ease}
    `};
  `
};
/* eslint-enable */

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
`;

export const FormLayout = styled.div`
  ${p => stateTransitions[p.state]};
`;

export const PageWrapper = styled.div`
  padding: 48px;
  position: relative;
  ${p => p.isTransitioning && 'overflow: hidden;'}

  ${media.down.sm`
    padding: 48px 20px;
  `}
`;

export const Progress = styled.div`
  background-color: ${p => p.theme.color.redPepperLight};
  height: 6px;
  position: absolute;
  top: 0;
  left: 0;
  transition: ${({ theme }) =>
    `width ${theme.timing.default} ${theme.easing.ease}`};
  width: ${p => p.percentage}%;
`;

export const Wrapper = styled.section``;
