import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Card, CardType } from '../../components';
import { Image, ImageType } from '../../elements';
import { AlignmentOptions, media, setColumnWidth } from '../../global';

const FeaturedCard = styled(Card)`
  flex: 1 1 100%;
  max-width: ${p => setColumnWidth(5, p.theme.gridSettings)};
  padding-top: ${p => (p.hasChildren ? '0' : '150px')};
  width: 100%;

  ${p => media.down.md`
    margin: 0 auto;
    max-width: ${setColumnWidth(9, p.theme.gridSettings)};
    order: ${p => (p.reverseImageMobile ? '1' : '')};
    padding-bottom: ${p => (p.reverseImageMobile ? '0' : '50px')};
    padding-top: ${p => (p.reverseImageMobile ? '50px' : '0')};
  `}

  ${media.down.sm`
    max-width: 100%;
  `}
`;

const FeaturedImage = styled(Image)`
  flex: 1 1 100%;
  text-align: ${p => (p.alignment ? 'right' : 'left')};
  max-width: ${p => setColumnWidth(7, p.theme.gridSettings)};
  width: 100%;

  img {
    max-width: 100%;
    width: auto;
  }

  ${p => media.down.md`
    margin: 0 auto;
    max-width: ${setColumnWidth(9, p.theme.gridSettings)};
    text-align: center;
  `}

  ${media.down.sm`
    max-width: 100%;
  `}
`;

// prettier-ignore
const Wrapper = styled.div`
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${p => p.alignment === 'center' && `
    text-align: center;
  `}

  ${p => p.alignment === 'right' && `
    flex-direction: row-reverse
  `}

  ${p => media.down.md`
    ${p.centerTextMobile ? `
      text-align: center;
    ` : ``}
  `}
`;

const FeaturedContent = ({
  alignment,
  background,
  centerTextMobile,
  children,
  content,
  image,
  linkProps,
  reverseImageMobile = false
}) => (
  <Wrapper alignment={alignment} centerTextMobile={centerTextMobile}>
    <FeaturedCard
      {...background}
      {...content}
      children={children}
      hasChildren={children !== undefined}
      linkProps={linkProps}
      reverseImageMobile={reverseImageMobile}
    />
    <FeaturedImage {...image} alignment={alignment !== 'right'} />
  </Wrapper>
);

export const FeaturedContentType = {
  alignment: PropTypes.oneOf(AlignmentOptions),
  background: PropTypes.objectOf(PropTypes.bool),
  centerTextMobile: PropTypes.bool,
  children: PropTypes.object,
  content: PropTypes.shape(CardType),
  image: PropTypes.shape(ImageType),
  linkProps: PropTypes.object,
  reverseImageMobile: PropTypes.bool
};

FeaturedContent.propTypes = FeaturedContentType;

export default FeaturedContent;
