import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BackgroundCircle } from '../../global';

const Background = styled.div`
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35px;
  height: 38px;
  opacity: 0.6;
`;

// prettier-ignore
const Wrapper = styled.div`
  position: relative;

  ${p => !p.active && `
    &:hover: {
      svg {
        fill: ${p.theme.color.redPepper};
      }
    }
  `}

  svg {
    height: auto;
    width: auto;
    display: inline-block;
    vertical-align: top;
    fill-rule: evenodd;
    position: relative;
    z-index: 1;
  }
`;

const Icon = ({ active, className, icon }) => {
  const Icon = icon;
  return (
    <Wrapper active={active} className={className}>
      <Icon />
      {active && (
        <Background>
          <BackgroundCircle />
        </Background>
      )}
    </Wrapper>
  );
};

Icon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.func.isRequired
};

export default Icon;
