export const form = {
  BUSINESS_OWNERSHIP_EXPERIENCE: 'Business_Ownership_Experience__c',
  CITY: 'MailingCity',
  EMAIL: 'Email',
  FIRST_NAME: 'FirstName',
  HEAR_ABOUT_US: 'LeadSource',
  LAST_NAME: 'LastName',
  LIQUIDITY: 'Liquidity__c',
  LOCATION_OF_INTEREST: 'Location_of_interest__c',
  MESSAGE: 'Message__c',
  NET_WORTH: 'Net_Worth__c',
  PHONE: 'MobilePhone',
  RESTAURANT_EXPERIENCE: 'Restaurant_Experience__c',
  STATE: 'MailingState',
  STREET_ADDRESS: 'MailingStreet',
  TIME_FRAME: 'Time_frame__c',
  ZIP: 'MailingPostalCode'
};

export const initialValues = {
  [form.BUSINESS_OWNERSHIP_EXPERIENCE]: '',
  [form.CITY]: '',
  [form.EMAIL]: '',
  [form.FIRST_NAME]: '',
  [form.HEAR_ABOUT_US]: '',
  [form.LAST_NAME]: '',
  [form.LIQUIDITY]: '',
  [form.LOCATION_OF_INTEREST]: '',
  [form.MESSAGE]: '',
  [form.NET_WORTH]: '',
  [form.PHONE]: '',
  [form.RESTAURANT_EXPERIENCE]: '',
  [form.STATE]: '',
  [form.STREET_ADDRESS]: '',
  [form.TIME_FRAME]: '',
  [form.ZIP]: ''
};

export const hearAboutOptions = [
  {
    disabled: true,
    value: ''
  },
  {
    value: 'Restaurant Visit'
  },
  {
    value: 'News Article'
  },
  {
    value: 'Friend/Family'
  },
  {
    value: 'Website'
  },
  {
    value: 'Franchise Referral'
  },
  {
    value: 'Other'
  }
];

export const liquidityOptions = [
  {
    disabled: true,
    value: ''
  },
  {
    value: 'Under $300K'
  },
  {
    value: '$300K-$500K'
  },
  {
    value: '$500K-$1M'
  },
  {
    value: '$1M-$3M'
  },
  {
    value: '$3M-$5M'
  },
  {
    value: '$5M+'
  }
];

export const netWorthOptions = [
  {
    disabled: true,
    value: ''
  },
  {
    value: '$500k-$1M'
  },
  {
    value: '$1M-$3M'
  },
  {
    value: '$3M-$5M'
  },
  {
    value: '$5M+'
  }
];

export const restaurantExperience = [
  {
    disabled: true,
    value: ''
  },
  {
    value: 'yes'
  },
  {
    value: 'no'
  }
];

export const businessOwnershipExperience = [
  {
    disabled: true,
    value: ''
  },
  {
    value: 'yes'
  },
  {
    value: 'no'
  }
];

export const timeFrame = [
  {
    disabled: true,
    value: ''
  },
  {
    value: '0 - 3 months'
  },
  {
    value: '3 - 6 months'
  },
  {
    value: '6+ months'
  }
];
