import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from '../../../global';

import { getCategorizedArrays } from './utils';

import Category from './Category';

const Wrapper = styled.div`
  padding: 60px 0 120px;
  text-align: left;

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    border-bottom: none;
  }

  ${media.down.md`
    padding: 0 0 60px;
  `}
`;

const Ingredients = ({ ingredients }) => {
  const categories = getCategorizedArrays(ingredients);

  return (
    <Wrapper>
      {categories.map((category, i) => (
        <Category key={i} category={category} />
      ))}
    </Wrapper>
  );
};

Ingredients.propTypes = {
  ingredients: PropTypes.array
};

export default Ingredients;
