import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H5, P } from '../../../../global';
import { IngredientType } from '../../Ingredients/Group/Ingredient';

const BodyTitle = styled(H5)`
  padding-bottom: 15px;
`;

const Img = styled.img`
  max-width: 131px;
  width: 100%;
`;

const TdFullWidth = styled.td`
  padding: 15px 0 0;
  text-align: left;
  width: 100%;

  p {
    padding-bottom: 0;
  }
`;

const TdLeft = styled.td`
  text-align: left;
  width: 50%;
`;

const Line = styled.td`
  border-bottom: 1px solid ${p => p.theme.color.peppercornLight};
  padding-top: 35px;
`;

const Paragraph = styled(P)`
  padding: 10px 0;
`;

const TdRight = styled(TdLeft)`
  text-align: right;

  p {
    font-weight: ${p => p.theme.fontWeight.bold};
  }
`;

const TableWrapper = styled.table`
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
`;

const TdWrapper = styled.td`
  padding: 35px 17px;
  width: 92%;
`;

const PrintTemplate = ({ selected, totals }) => (
  <TableWrapper>
    <tbody>
      <tr>
        <TdWrapper>
          <table cellSpacing="0" cellPadding="0" border="0" width="100%">
            <tbody>
              <tr>
                <TdLeft>
                  <Img src="http://thekatynews.com/wp-content/uploads/2018/08/Salata-logo.png" />
                </TdLeft>
                <TdRight>
                  <H5>nutrition builder</H5>
                </TdRight>
              </tr>
              <tr />
              <tr>
                <Line />
                <Line />
              </tr>
            </tbody>
          </table>
        </TdWrapper>
      </tr>
      <tr>
        <TdWrapper>
          <table cellSpacing="0" cellPadding="0" border="0" width="100%">
            <tbody>
              <tr>
                <TdLeft>
                  <BodyTitle>nutrition totals</BodyTitle>
                </TdLeft>
              </tr>
              {totals.map((total, i) => (
                <tr key={i}>
                  <TdLeft>
                    <Paragraph>{total.measurement}</Paragraph>
                  </TdLeft>
                  <TdRight>
                    <Paragraph>{total.total}</Paragraph>
                  </TdRight>
                </tr>
              ))}
              <tr>
                <TdFullWidth>
                  <Paragraph>
                    *2,000 calories a day is used for general nutrition advice,
                    but calorie needs vary. Additional nutrition information
                    available upon request.
                  </Paragraph>
                </TdFullWidth>
              </tr>
              <tr>
                <Line />
                <Line />
              </tr>
            </tbody>
          </table>
        </TdWrapper>
      </tr>
      <tr>
        <TdWrapper>
          <table cellSpacing="0" cellPadding="0" border="0" width="100%">
            <tbody>
              <tr>
                <TdLeft>
                  <H5>your ingredients</H5>
                </TdLeft>
              </tr>
              <tr>
                <TdFullWidth>
                  <Paragraph>
                    {selected.map((ingredient, i) => {
                      const commaSeparation =
                        i + 1 < selected.length ? ', ' : '';
                      return (
                        <span key={i}>
                          {ingredient.name}
                          {commaSeparation}
                        </span>
                      );
                    })}
                  </Paragraph>
                </TdFullWidth>
              </tr>
            </tbody>
          </table>
        </TdWrapper>
      </tr>
    </tbody>
  </TableWrapper>
);

PrintTemplate.propTypes = {
  selected: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.shape(IngredientType))
  ]),
  totals: PropTypes.arrayOf(
    PropTypes.shape({
      measurement: PropTypes.string,
      total: PropTypes.string
    })
  )
};

export default PrintTemplate;
