import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Section } from '../../../components';
import { getColumnSpanSize, media, P, shadeOf } from '../../../global';

const Paragraph = styled(P)`
  color: ${p => p.theme.color.peppercornLight};
  max-width: ${p => getColumnSpanSize(4, p.theme.gridSettings)}px;
  width: 100%;
`;

// prettier-ignore
const Wrapper = styled(Section)`
  padding: 60px 20px;
  z-index: 0;

  > div {
    align-items: center;
    background: ${p => shadeOf(p.theme.color.black, 0.02)};
    border: 1px solid ${p => shadeOf(p.theme.color.black, 0.06)};
    border-radius: 5px;
    display: flex;
    justify-content: center;
    max-width: ${p => getColumnSpanSize(8, p.theme.gridSettings)}px;
    padding: 100px 50px;
  }

  ${media.down.lg`
    padding: 0 20px 60px;
  `}

  ${p => !p.visible && `
    display: none;
  `}
`;

const Begin = ({ visible }) => (
  <Wrapper visible={visible}>
    <Paragraph>
      To begin, select your salad style and start adding your ingredients.
    </Paragraph>
  </Wrapper>
);

Begin.propTypes = {
  visible: PropTypes.bool
};

export default Begin;
