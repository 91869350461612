/* eslint-disable camelcase */
/* eslint-disable sort-keys */
export const acfTest = {
  intro: {
    bodyText:
      'Healthy is defined by you. See how your meal fits your definition of healthy by calculating it in our Nutrition Builder below. When you’re finished, save or print your creation so you can order it online or in store.',
    mainHeadline: 'nutrition builder'
  }
};

export const api = [
  {
    id: 10,
    name: 'Romaine Hearts',
    category: 'Entrees',
    image: {
      data: {
        full_url:
          'https://salata.dineengine.com/uploads/_/originals/bowl-lg-2.png'
      }
    },
    calories: 12,
    serving_size: '1 cup',
    grams_of_fat: 5,
    saturated_fats: 3,
    trans_fats: 23,
    cholesterol: 2,
    sodium: 3,
    carbohydrates: 54,
    dietary_fiber: 34,
    sugars: 55,
    protein: 5,
    allergens: [
      'Egg',
      'Fish',
      'Shellfish',
      'Treenuts',
      'Peanuts',
      'Wheat',
      'Soybeans',
      'Vegan',
      'Gluten'
    ],
    group: 'Base',
    style: 'Small Salad'
  },
  {
    id: 11,
    name: 'Romaine Hearts',
    category: 'Entrees',
    image: {
      data: {
        full_url:
          'https://salata.dineengine.com/uploads/_/originals/bowl-lg-2.png'
      }
    },
    calories: 55,
    serving_size: '1 cup',
    grams_of_fat: 5,
    saturated_fats: 3,
    trans_fats: 77,
    cholesterol: 2,
    sodium: 3,
    carbohydrates: 55,
    dietary_fiber: 34,
    sugars: 55,
    protein: 66,
    allergens: [
      'Dairy',
      'Egg',
      'Fish',
      'Shellfish',
      'Treenuts',
      'Peanuts',
      'Wheat',
      'Soybeans',
      'Vegan',
      'Gluten'
    ],
    group: 'Base',
    style: 'Regular Salad'
  },
  {
    id: 12,
    name: 'Buttermilk Ranch',
    category: 'Entrees',
    image: {
      data: {
        full_url:
          'https://salata.dineengine.com/uploads/_/originals/bowl-lg-2.png'
      }
    },
    calories: 55,
    serving_size: '1 cup',
    grams_of_fat: 5,
    saturated_fats: 3,
    trans_fats: 77,
    cholesterol: 2,
    sodium: 3,
    carbohydrates: 55,
    dietary_fiber: 34,
    sugars: 55,
    protein: 66,
    allergens: [
      'Dairy',
      'Egg',
      'Fish',
      'Shellfish',
      'Treenuts',
      'Peanuts',
      'Wheat',
      'Soybeans',
      'Vegan',
      'Gluten'
    ],
    group: 'Dressings',
    style: 'Regular Salad'
  },
  {
    id: 12,
    name: 'Buttermilk Ranch',
    category: 'Entrees',
    image: {
      data: {
        full_url:
          'https://salata.dineengine.com/uploads/_/originals/bowl-lg-2.png'
      }
    },
    calories: 16,
    serving_size: '1 cup',
    grams_of_fat: 4,
    saturated_fats: 3,
    trans_fats: 45,
    cholesterol: 2,
    sodium: 3,
    carbohydrates: 36,
    dietary_fiber: 34,
    sugars: 2,
    protein: 8,
    allergens: [
      'Dairy',
      'Egg',
      'Fish',
      'Shellfish',
      'Treenuts',
      'Peanuts',
      'Wheat',
      'Soybeans',
      'Vegan',
      'Gluten'
    ],
    group: 'Dressings',
    style: 'Small Salad'
  },
  {
    id: 12,
    name: 'Buttermilk Ranch',
    category: 'Entrees',
    image: {
      data: {
        full_url:
          'https://salata.dineengine.com/uploads/_/originals/bowl-lg-2.png'
      }
    },
    calories: 34,
    serving_size: '1 cup',
    grams_of_fat: 3,
    saturated_fats: 5,
    trans_fats: 66,
    cholesterol: 2,
    sodium: 3,
    carbohydrates: 23,
    dietary_fiber: 34,
    sugars: 55,
    protein: 18,
    allergens: [
      'Dairy',
      'Egg',
      'Fish',
      'Shellfish',
      'Treenuts',
      'Peanuts',
      'Wheat',
      'Soybeans',
      'Vegan',
      'Gluten'
    ],
    group: 'Dressings',
    style: 'Wrap'
  },
  {
    id: 13,
    name: 'Romaine Hearts',
    category: 'Entrees',
    image: {
      data: {
        full_url:
          'https://salata.dineengine.com/uploads/_/originals/bowl-lg-2.png'
      }
    },
    calories: 21,
    serving_size: '1 cup',
    grams_of_fat: 5,
    saturated_fats: 3,
    trans_fats: 32,
    cholesterol: 2,
    sodium: 3,
    carbohydrates: 25,
    dietary_fiber: 38,
    sugars: 55,
    protein: 16,
    allergens: [
      'Dairy',
      'Egg',
      'Fish',
      'Shellfish',
      'Treenuts',
      'Peanuts',
      'Wheat',
      'Soybeans',
      'Vegan',
      'Gluten'
    ],
    group: 'Base',
    style: 'Wrap'
  },
  {
    id: 14,
    name: 'Avocado',
    category: 'Extras',
    image: {
      data: {
        full_url:
          'https://salata.dineengine.com/uploads/_/originals/bowl-lg-2.png'
      }
    },
    calories: 55,
    serving_size: '1 cup',
    grams_of_fat: 5,
    saturated_fats: 3,
    trans_fats: 77,
    cholesterol: 2,
    sodium: 3,
    carbohydrates: 55,
    dietary_fiber: 34,
    sugars: 55,
    protein: 66,
    allergens: [
      'Dairy',
      'Egg',
      'Fish',
      'Shellfish',
      'Treenuts',
      'Peanuts',
      'Wheat',
      'Soybeans',
      'Vegan',
      'Gluten'
    ],
    group: 'Extras',
    style: ''
  },
  {
    id: 15,
    name: 'Sweet Tea',
    category: 'Drinks',
    image: {
      data: {
        full_url:
          'https://salata.dineengine.com/uploads/_/originals/bowl-lg-2.png'
      }
    },
    calories: 55,
    serving_size: '1 cup',
    grams_of_fat: 5,
    saturated_fats: 3,
    trans_fats: 77,
    cholesterol: 2,
    sodium: 3,
    carbohydrates: 55,
    dietary_fiber: 34,
    sugars: 55,
    protein: 66,
    allergens: [
      'Dairy',
      'Egg',
      'Fish',
      'Shellfish',
      'Treenuts',
      'Peanuts',
      'Wheat',
      'Soybeans',
      'Vegan',
      'Gluten'
    ],
    group: 'Drinks',
    style: ''
  },
  {
    id: 16,
    name: 'Peanut Butter Cookie',
    category: 'Desserts',
    image: {
      data: {
        full_url:
          'https://salata.dineengine.com/uploads/_/originals/bowl-lg-2.png'
      }
    },
    calories: 55,
    serving_size: '1 cup',
    grams_of_fat: 5,
    saturated_fats: 3,
    trans_fats: 77,
    cholesterol: 2,
    sodium: 3,
    carbohydrates: 55,
    dietary_fiber: 34,
    sugars: 55,
    protein: 66,
    allergens: [
      'Dairy',
      'Egg',
      'Fish',
      'Shellfish',
      'Treenuts',
      'Peanuts',
      'Wheat',
      'Soybeans',
      'Vegan',
      'Gluten'
    ],
    group: 'Desserts',
    style: ''
  },
  {
    id: 17,
    name: 'Tomato Basil',
    category: 'Soups',
    image: {
      data: {
        full_url:
          'https://salata.dineengine.com/uploads/_/originals/bowl-lg-2.png'
      }
    },
    calories: 55,
    serving_size: '1 cup',
    grams_of_fat: 5,
    saturated_fats: 3,
    trans_fats: 77,
    cholesterol: 2,
    sodium: 3,
    carbohydrates: 55,
    dietary_fiber: 34,
    sugars: 55,
    protein: 66,
    allergens: ['Dairy', 'Egg', 'Fish'],
    group: 'Soups',
    style: ''
  },
  {
    id: 18,
    name: 'Broccoli',
    category: 'Soups',
    image: {
      data: {
        full_url:
          'https://salata.dineengine.com/uploads/_/originals/bowl-lg-2.png'
      }
    },
    calories: 55,
    serving_size: '1 cup',
    grams_of_fat: 5,
    saturated_fats: 3,
    trans_fats: 77,
    cholesterol: 2,
    sodium: 3,
    carbohydrates: 55,
    dietary_fiber: 34,
    sugars: 55,
    protein: 66,
    allergens: ['Wheat', 'Soybeans', 'Vegan'],
    group: 'Soups',
    style: ''
  }
];

export const measurements = [
  'Total Calories',
  'Total Fat',
  'Saturated Fat',
  'Trans Fat',
  'Cholesterol',
  'Sodium',
  'Total Carbs',
  'Dietary Fiber',
  'Sugar',
  'Protein'
];

export const tabsArray = ['Entrees', 'Soups', 'Desserts', 'Drinks', 'Extras'];
