import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { H4, media, P, PageType } from '../../global';
import ContentWithSidebar from '../../layouts/ContentWithSidebar';
import { CateringQuoteForm, HeroType } from '../../sections';

const Headline = styled(H4)`
  color: ${p => p.theme.color.kaleLeaf};
  padding-bottom: 15px;
`;

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 60px 0 120px;
  text-align: left;
`;

const SideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 60px;

  ${media.up.md`
    padding: 60px 20px 0 0;
    text-align: left;
  `}
`;

const CateringQuote = ({ acf, ...layoutProps }) => {
  const { hero, cateringQuote } = acf;

  const mainContent = (
    <MainWrapper>
      <CateringQuoteForm />
    </MainWrapper>
  );

  const sidebarContent = (
    <SideWrapper>
      <Headline>{cateringQuote.headline}</Headline>
      <P>{cateringQuote.bodyText}</P>
    </SideWrapper>
  );

  return (
    <ContentWithSidebar
      {...layoutProps}
      hero={hero}
      mainContent={mainContent}
      sidebarContent={sidebarContent}
    />
  );
};

CateringQuote.propTypes = {
  ...PageType,
  acf: PropTypes.shape({
    hero: PropTypes.shape(HeroType),
    instructions: PropTypes.shape({
      bodyText: PropTypes.string,
      headline: PropTypes.string
    })
  })
};

export default CateringQuote;
