import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Button } from '../../elements';
import { Exclamation, media, P, shadeOf } from '../../global';
import Section from '../Section';

const Accept = styled(Button)`
  margin-right: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;

  ${media.down.xs`
    width: 100%;

    ${Accept},
    ${Accept} + div {
      flex: 1 1 auto;
      text-align: center;
    }
  `}
`;

const Icon = styled(Exclamation)`
  height: 40px;
  width: 40px;

  ${media.down.md`
    height: 22px;
    width: 22px;
  `}
`;

const Text = styled(P)`
  font-size: ${p => p.theme.fontSize.label};
  padding: 40px 30px;

  ${media.down.md`
    padding: 15px 0;
  `}
`;

// prettier-ignore
const Wrapper = styled(Section)`
  bottom: 0;
  box-shadow: 0 0 20px ${p => shadeOf(p.theme.color.black, 0.2)};
  left: 0%;
  position: fixed;
  transition: transform 0.25s ease;
  z-index: 10;

  ${p => p.closing && `
    transform: translateY(100%);
  `}
`;

// prettier-ignore
const InnerWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  text-align: left;

  ${media.down.md`
    align-items: flex-start;
    flex-direction: column;
    padding: 25px 0;
  `}
`;

const Link = styled.a`
  color: ${p => p.theme.color.redPepper};
`;

const CookieBanner = ({ acceptNotice, closeNotice, closing }) => (
  <Wrapper bgColor="white" closing={closing} noPadding>
    <InnerWrapper>
      <Icon />
      <Text>
        We use cookies on our website to provide you with an improved browsing
        experience. See our <Link href="/privacy">privacy policy</Link> for more
        information.
      </Text>
      <ButtonWrapper>
        <Accept onClick={acceptNotice} title="Accept" />
        <Button inverted onClick={closeNotice} title="Decline" />
      </ButtonWrapper>
    </InnerWrapper>
  </Wrapper>
);
CookieBanner.propTypes = {
  acceptNotice: PropTypes.func.isRequired,
  closeNotice: PropTypes.func.isRequired,
  closing: PropTypes.bool
};

export default CookieBanner;
