const units = [
  'calories',
  'grams_of_fat',
  'saturated_fats',
  'trans_fats',
  'cholesterol',
  'sodium',
  'carbohydrates',
  'dietary_fiber',
  'sugars',
  'protein'
];

// This takes the api array and get the values of the allergens property
// It puts those values into an array and removes the duplicates
export const getAllergens = arr => {
  const allergens = [];
  arr.forEach(item => {
    item.allergens
      .filter(allergen => allergen !== '')
      .forEach(allergen => allergens.push(allergen));
  });
  const uniqueAllergens = [...new Set(allergens)];
  const allergensObject = uniqueAllergens.map((allergen, i) => ({
    checked: false,
    id: i,
    name: allergen
  }));
  return allergensObject;
};

// Filters through the data object and returns an array of values for
// the property names that coincide with the units array declared above
export const getMetrics = data => {
  const keys = Object.keys(data)
    .filter(key => units.includes(key))
    .map(key => {
      return data[key];
    });
  return keys;
};

// This takes in an array of selectedIngredients (object) and gets the
// sum of the corresponding values
export const getTotals = arr => {
  const arrays = arr.map(item => getMetrics(item));

  // example return: getTotals([1,2,3],[4,5,6]) = [5,7,9]
  const sumArrays = (...arrays) => {
    if (arrays.length === 0) {
      return [...Array(11)].fill(0);
    }
    const n = arrays
      .map(xs => xs.length)
      .sort()
      .pop();
    const result = Array.from({ length: n });
    return result.map((_, i) =>
      arrays.map(xs => xs[i] || 0).reduce((sum, x) => sum + x, 0)
    );
  };
  return sumArrays(...arrays);
};

// This takes the api array and get the values of the category property
// It puts them values into an array and removes the duplicates
export const getUniqueArray = (arr, property) => {
  const items = arr.map(obj => obj[property]);
  return [...new Set(items)];
};

// Creates a new array of objects with a string and an empty array of ingredients
export const createGroupArray = (arr, category, ingredients) => {
  const notEntrees = category !== 'Entrees';
  const newArray = arr.map(item => {
    if (notEntrees) return { group: item, ingredients: [] };
    const uniqueStyles = getUniqueArray(ingredients, 'style').filter(
      style => style !== '' && style !== 'kids'
    );
    const styleObject = uniqueStyles.reduce((acc, style) => {
      acc[style] = [];
      return acc;
    }, {});
    return { group: item, ingredients: styleObject };
  });
  return [...new Set(newArray)];
};

// Creates array of objects with a unique category name as the key
// The value of those keys are the ingredients that have the matching category name
export const getCategoryObjects = (ingredients, property) => {
  const uniqueCategories = getUniqueArray(ingredients, property);
  const categoryObject = uniqueCategories.reduce((acc, category) => {
    const filteredIngredients = ingredients.filter(
      ingredient => ingredient.category === category
    );
    const uniqueGroups = getUniqueArray(filteredIngredients, 'group');
    const groupArray = createGroupArray(uniqueGroups, category, ingredients);

    groupArray.forEach(item => {
      ingredients.forEach(ingredient => {
        const group = item.group === ingredient.group;
        const notEntrees = ingredient.category !== 'Entrees';
        const notKids = ingredient.style !== 'kids';
        if (group && notEntrees) return item.ingredients.push(ingredient);
        if (group && notKids) {
          Object.keys(item.ingredients).forEach(key => {
            if (key === ingredient.style) {
              item.ingredients[key].push(ingredient);
            }
          });
        }
      });
    });

    acc[category] = groupArray;
    return acc;
  }, {});

  return categoryObject;
};

// the data structure for Entrees is different than the rest of the categories
// if a user clicks on Entrees and a style is not yet selected, we need to return an empty array
export const getGroups = (category, groups, style) => {
  if (category !== 'Entrees') return groups;
  if (style === '') return [];
  return groups;
};

// this cross references the selected ingredients with the newly rendered ingredients.
// if a selected ingredient name from the same category matches, it removes those matches
// from the selected ingredients array and replaces them with the matching new ingredients
export const updateSelected = (ingredient, selected, setSelected) => {
  selected.forEach(item => {
    const matchesSelected =
      item.name === ingredient.name && item.category === ingredient.category;
    if (matchesSelected) {
      const removalPayload = {
        payload: item,
        type: 'toggle'
      };
      const insertPayload = {
        payload: ingredient,
        type: 'toggle'
      };
      setSelected(removalPayload);
      setSelected(insertPayload);
    }
  });
};

// enum for getSuffix function
const suffix = {
  CALORIES: 0,
  CHOLESTEROL: 4,
  SODIUM: 5
};

/**
 * returns a suffix to attach to the end of an ingredient value
 * @param {number} i - number being checked
 * @return {string}
 */
export const getSuffix = i => {
  if (i === suffix.CALORIES) return '';
  if (i === suffix.CHOLESTEROL || i === suffix.SODIUM) return 'mg';
  return 'g';
};

export const filtersReducer = (state, action) => {
  switch (action.type) {
    case 'checked': {
      return state.map(item => {
        // returns a new state array with the updated checked value for the checkbox that is clicked
        if (item.id === action.payload) {
          return {
            ...item,
            checked: !item.checked
          };
        }
        return item;
      });
    }
    case 'clear': {
      // sets all state items checked property to false
      return state.map(item => ({ ...item, checked: false }));
    }
    default: {
      return state;
    }
  }
};

export const selectedReducer = (state, action) => {
  switch (action.type) {
    case 'toggle': {
      if (!state.includes(action.payload)) return [...state, action.payload];
      return state.filter(item => item !== action.payload);
    }
    case 'clear': {
      return [];
    }
    default: {
      return state;
    }
  }
};
