import React from 'react';
import styled from 'styled-components';

import { Callout } from './components';
import { Button } from '../../elements';
import { media, setColumnWidth } from '../../global';

const Paragraph = styled(Callout)`
  display: block;
  margin-bottom: 20px;
  padding-right: 10px;
`;

const Wrapper = styled.div`
  flex: 0 0 100%;
  margin-top: 56px;

  ${media.up.sm`
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% / 3 * 2);
  `}

  ${media.up.md`
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(${p => setColumnWidth(4, p.theme.gridSettings)} + ${p =>
  p.theme.gridSettings.gutterWidth}px);
    margin-top: 80px;
  `}
`;

const SignupForm = () => (
  <Wrapper>
    <Paragraph>Give Your Inbox a Taste Lift</Paragraph>
    <Button
      title="Sign Up"
      url="https://salata.myguestaccount.com/guest/eclub/enroll?card-template=gz6U71JdL9Y%3d"
    />
  </Wrapper>
);

export default SignupForm;
