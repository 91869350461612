import { FastField } from 'formik';
import axios from 'axios';
import moment from 'moment';
import React, { useRef, useState } from 'react';

import { FormField, Loading, MultiPartForm } from '../../components';
import {
  combineValidations,
  validateEmail,
  validateRequired,
  validatePhone,
  validateZipCode
} from '../../components/FormField/utils';
import Success from '../../components/FormField/Success';

import {
  Directions,
  FormLayout,
  StyledForm,
  Subtitle,
  SubtitleContainer,
  SubtitleStep,
  Title,
  Wrapper
} from './components';

const Field = props => <FastField component={FormField} {...props} />;

const form = {
  BUDGET: 'Field12',
  CITY: 'Field8',
  COMMENTS: 'Field13',
  EMAIL: 'Field2',
  EVENT_DATE: 'Field4',
  FULL_NAME: 'Field1',
  HEADCOUNT: 'Field5',
  PHONE: 'Field3',
  SALUTATION: 'Field',
  STATE: 'Field9',
  STREET_ADDRESS: 'Field6',
  STREET_ADDRESS_2: 'Field7',
  ZIP: 'Field10'
};

const initialValues = {
  [form.BUDGET]: '',
  [form.CITY]: '',
  [form.COMMENTS]: '',
  [form.EMAIL]: '',
  [form.EVENT_DATE]: moment().format('M/D/YYYY'),
  [form.FULL_NAME]: '',
  [form.HEADCOUNT]: '10-50',
  [form.PHONE]: '',
  [form.SALUTATION]: '',
  [form.STATE]: '',
  [form.STREET_ADDRESS]: '',
  [form.STREET_ADDRESS_2]: '',
  [form.ZIP]: ''
};

export default () => {
  const [loading, setLoading] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const [submitted, setSubmitted] = useState(false);

  const contentRef = useRef(null);

  const handleSubmit = (values, actions) => {
    setLoading(true);
    const id = 'w1ayrnkn0pbfrtl';

    // reformatting date and phone number for Wufoo
    values[form.EVENT_DATE] = moment(
      values[form.EVENT_DATE],
      'M/D/YYYY'
    ).format('YYYYMMDD');
    values[form.PHONE] = values[form.PHONE].replace(/[()-\s]/g, '');

    axios
      .post(`/forms/${id}`, {
        body: values
      })
      .then(res => {
        setLoading(false);
        if (res.data.Success === 1) {
          setMaxHeight(contentRef.current.clientHeight);
          setTimeout(() => setSubmitted(true), 100);
          actions.setSubmitting(false);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Wrapper maxHeight={maxHeight} ref={contentRef} submitted={submitted}>
      <StyledForm
        initialValues={initialValues}
        isSubmitted={submitted === true}
        onSubmit={handleSubmit}
        showProgress
      >
        <MultiPartForm.Page>
          <Title>Catering Custom Quote</Title>

          <SubtitleContainer>
            <Subtitle>General Information</Subtitle>
            <SubtitleStep>1 of 3</SubtitleStep>
          </SubtitleContainer>

          <Directions>
            Please fill out the form below. All fields are required.
          </Directions>

          <FormLayout>
            <Field
              label="Full Name"
              name={form.FULL_NAME}
              type="text"
              validate={validateRequired}
            />

            <Field
              columns={{ sm: 7 }}
              label="Email Address"
              name={form.EMAIL}
              type="email"
              validate={combineValidations(validateRequired, validateEmail)}
            />

            <Field
              columns={{ sm: 5 }}
              label="Phone Number"
              name={form.PHONE}
              type="tel"
              validate={combineValidations(validateRequired, validatePhone)}
              placeholder="(555) 555-5555"
            />

            <Field
              columns={{ sm: 6 }}
              label="Date of Event"
              name={form.EVENT_DATE}
              placeholder="mm/dd/yyyy"
              type="date"
            />

            <Field
              columns={{ sm: 6 }}
              label="Headcount"
              name={form.HEADCOUNT}
              type="select"
            >
              <option value="10-50">10-50 people</option>
              <option value="50-100">50–100 people</option>
              <option value="100+">100+ people</option>
            </Field>
          </FormLayout>
        </MultiPartForm.Page>

        <MultiPartForm.Page>
          <Title>Catering Custom Quote</Title>

          <SubtitleContainer>
            <Subtitle>Event Address</Subtitle>
            <SubtitleStep>2 of 3</SubtitleStep>
          </SubtitleContainer>

          <Directions>
            Please fill out the form below. All fields are required.
          </Directions>

          <FormLayout>
            <Field
              label="Street Address"
              name={form.STREET_ADDRESS}
              type="text"
              validate={validateRequired}
            />

            <Field
              label="Street Address 2"
              name={form.STREET_ADDRESS_2}
              type="text"
            />

            <Field
              columns={{ sm: 6 }}
              label="City"
              name={form.CITY}
              type="text"
              validate={validateRequired}
            />

            <Field
              columns={{ default: 6, sm: 3 }}
              label="State"
              name={form.STATE}
              type="state"
              validate={validateRequired}
              placeholder="Select a state"
            />

            <Field
              columns={{ default: 6, sm: 3 }}
              label="ZIP Code"
              name={form.ZIP}
              type="text"
              validate={combineValidations(validateRequired, validateZipCode)}
            />
          </FormLayout>
        </MultiPartForm.Page>

        <MultiPartForm.Page>
          <Title>Catering Custom Quote</Title>

          <SubtitleContainer>
            <Subtitle>Event Details</Subtitle>
            <SubtitleStep>3 of 3</SubtitleStep>
          </SubtitleContainer>

          <Directions>Please fill out the form below.</Directions>

          <FormLayout>
            <Field
              label="Estimated Budget"
              name={form.BUDGET}
              placeholder="Example: $500"
              type="text"
              validate={validateRequired}
            />

            <Field
              label="Comments"
              name={form.COMMENTS}
              placeholder="Enter message here…"
              rows="8"
              type="textarea"
            />
          </FormLayout>
        </MultiPartForm.Page>
      </StyledForm>

      <Loading isLoading={loading} />
      <Success
        bodyText="Thank you. Your submission has been received. A member of our catering team will be in touch with you within 24 hours."
        headline="request received"
        submitted={submitted}
      />
    </Wrapper>
  );
};
