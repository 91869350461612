import React from 'react';
import IconBase from './IconBase';

export default props => (
  <IconBase viewBox="0 0 20 20" {...props}>
    <path
      d="M19.534 18.535c0 .58-.47 1.05-1.048 1.05H13.65v-7.37h2.467l.37-2.874h-2.837V7.507c0-.832.23-1.398 1.42-1.398l1.517-.002V3.538a20.175 20.175 0 0 0-2.21-.113c-2.188 0-3.686 1.339-3.686 3.797v2.119H8.216v2.873h2.474v7.371H1.598a1.05 1.05 0 0 1-1.048-1.05V1.6C.55 1.02 1.019.55 1.598.55h16.888c.579 0 1.048.47 1.048 1.05v16.935"
      fill="currentColor"
      fillRule="evenodd"
    />
  </IconBase>
);
