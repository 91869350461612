import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonTypes } from '../../../global';

// prettier-ignore
const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  cursor: none;
  pointer-events: none;

  svg {
    transition: fill 0.2s ease, stroke 0.2s ease;
    width: 100%;
    height: 100%;
    ${p => p.inverted ? `
      fill: ${p.theme.color.transparent};
      stroke: ${p.buttonType === 'primary' ? p.theme.color.avocado : p.theme.color.redPepper};
      stroke-width: 2;

      path {
        transform: translateX(1px) scale(0.99);
      }
    ` : `
      fill: ${p.buttonType === 'primary' ? p.theme.color.avocado : p.theme.color.redPepper};
    `}
  }
`;

const Background = ({ buttonType, className, inverted }) => (
  <Wrapper className={className} inverted={inverted} buttonType={buttonType}>
    <svg
      viewBox="0 0 204 60"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M90.833 59.309c-4.516-.041-9.032.263-13.547.424-2.254.078-4.508.315-6.759.258-8.228-.211-16.455-.547-24.686-.775-6.164-.17-12.332-.279-18.5-.35-2.054-.024-4.109.212-6.165.223-3.691.018-7.387.05-11.077-.102-2.412-.1-4.386-1.532-5.736-3.32C2.382 53.04.626 50.045.941 46.64c.502-5.415.12-30.365-.819-35.572-.618-3.435 1.166-7.93 4.08-9.37C5.248 1.18 6.646.706 7.706.96c4.536 1.099 8.967.199 13.443-.128 2.027-.146 4.08-.02 6.12.013 4.522.076 9.092.612 13.558.174C50.68.055 60.543.06 70.42.072 81.944.087 93.467.041 104.99.005c10.079-.029 20.147.055 30.232.39 10.062.335 51.944 2.043 58.154 2.382 6.964.381 9.924 4.17 10.195 10.123.12 2.654.182 5.311.382 7.958.245 3.25-.55 26.324-.215 29.566.214 2.078.222 4.102-1.145 5.845-1.306 1.665-2.76 2.792-5.184 2.593-6.765-.558-71.178.944-84.966.98-7.2.02-14.405-.465-21.609-.533z"
        fillRule="evenodd"
      />
    </svg>
  </Wrapper>
);

Background.propTypes = {
  buttonType: PropTypes.oneOf(ButtonTypes).isRequired,
  className: PropTypes.string,
  inverted: PropTypes.bool
};

export default Background;
