import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Section } from '../../components';
import { Button } from '../../elements';
import { PageType } from '../../global';
import BaseLayout from '../../layouts/Base';

// prettier-ignore
const SvgButton = styled(Button)`
  flex: 0 0 33.3%;
  margin: 30px 10px;

  svg {
    fill: ${p => p.fillColor};
  }

  ${p => p.inline ? `
    color: ${p.fillColor};
  ` : `
    background: ${p.fillColor};
  `}

`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const ButtonTest = ({ acf, ...layoutProps }) => {
  const { buttons } = acf;

  return (
    <BaseLayout withoutFooter {...layoutProps}>
      <Section>
        <Wrapper>
          {buttons.map((button, i) => (
            <SvgButton {...button} buttonType={button.inverted ? 'secondary' : 'primary'} inline={button.inverted} key={i} />
          ))}
        </Wrapper>
      </Section>
    </BaseLayout>
  );
};

ButtonTest.propTypes = {
  ...PageType,
  acf: PropTypes.shape({
    buttons: PropTypes.arrayOf(PropTypes.object)
  })
};

export default ButtonTest;
