import React from 'react';
import styled from 'styled-components';

import { Intro, Section } from './components';

import { Image } from './elements';

import BaseLayout from './layouts/Base';

const ErrorIntro = styled(Intro)`
  max-width: 600px;

  a {
    color: ${p => p.theme.color.redPepper};
  }
`;

const intro = {
  mainHeadline: 'you must’ve reached the bottom of the bowl',
  wysiwyg: `<p>The page you are looking for couldn’t be found. Use the navigation or return to <a href="/">Salata.com</a> for a fresh start.</p>`
};

const F0F = ({ ...layoutProps }) => {
  return (
    <BaseLayout {...layoutProps}>
      <Section>
        <ErrorIntro {...intro} />
      </Section>
      <Section
        contentBelow
        dividerBottomColor="tan"
        dividerBottomReverse
        insetDividerBottom
        noPadding
      >
        <Image
          alt="Empty Salad Bowl"
          url="/images/templates/errorpages/404.png"
        />
      </Section>
    </BaseLayout>
  );
};

export default F0F;
