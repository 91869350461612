import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Image, ImageType } from '../../../elements';
import { media, P } from '../../../global';

const DressingImage = styled(Image)`
  ${media.down.sm`
    width: 30%;
    max-width: 68px;
  `}
`;

const DressingName = styled(P)`
  padding: 10px 0 0 10px;
  text-align: center;

  ${media.down.sm`
    padding: 0 0 0 40px;
    text-align: left;
    width: 70%;
  `}

  ${media.down.xs`
    padding-left: 20px;
  `}
`;

const Wrapper = styled.div`
  margin-bottom: 50px;
  padding: 0 10px;
  width: 20%;

  ${media.down.sm`
    align-items: center;
    display: flex;
    margin-bottom: 15px;
    padding: 0;
    width: 100%;
  `}
`;

const Dressing = ({ image, name }) => (
  <Wrapper>
    <DressingImage {...image} />
    <DressingName>{name}</DressingName>
  </Wrapper>
);

export const DressingType = {
  image: PropTypes.shape(ImageType).isRequired,
  name: PropTypes.string.isRequired
};

Dressing.propTypes = DressingType;

export default Dressing;
