import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from '../../../elements';
import { Hidden, media, shadeOf } from '../../../global';
import { NavItem, NavLink, NavList, NestedNavList } from './components';
import { orderNowType } from './OrderNowButton';

const OrderLink = styled(Button)`
  display: block;
  font-size: 22px;
  margin-top: 32px;
`;

// prettier-ignore
const Wrapper = styled.nav`
  color: ${p => p.theme.color.blackBean};
  display: flex;

  ${media.down.lg`
    background-color: ${p => p.theme.color.white};
    bottom: 0;
    box-shadow: 2px 0 15px 10px ${p => shadeOf(p.theme.color.peppercornLight, 0.5)};
    flex-direction: column;
    justify-content: space-between;
    left: 0;
    overflow: auto;
    padding: 76px 0 76px 55px;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(-110%);
    transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);

    ${p => p.isExpanded && `
      transform: translateX(0);
    `}
  `}

  ${media.up.lg`
    align-items: center;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
  `}
`;

// Sort menu items based on the `order` property
const sortMenuItems = (a, b) => a.order - b.order;

/**
 * Recursively create menu items
 * @param {Array} menu - The menu to create; see menu propType below
 * @param {Number} [maxDepth=1] - The maximum depth to recurse when creating menu items
 * @param {Number} [level=0] - The current level of navigation being created
 * @return {JSX.Element} an array of React Elements
 */
const createNavMarkup = (menu = [], maxDepth = 1, level = 0) =>
  menu.sort(sortMenuItems).map(menuItem => {
    const hasChildren = Boolean(menuItem.children);

    return (
      <NavItem key={menuItem.id}>
        <NavLink {...menuItem} hasChildren={hasChildren} isNested={level > 0} />

        {hasChildren && level < maxDepth && (
          <NestedNavList>
            {createNavMarkup(menuItem.children, maxDepth, level + 1)}
          </NestedNavList>
        )}
      </NavItem>
    );
  });

const Nav = ({ isExpanded, menu = [], order }) => (
  <Wrapper isExpanded={isExpanded} menu={menu}>
    <NavList>{createNavMarkup(menu)}</NavList>

    <Hidden up="lg">
      {order &&
        order.map((link, i) => (
          <OrderLink
            inline
            buttonType="secondary"
            key={i}
            title={link.title}
            url={link.path}
          />
        ))}
    </Hidden>
  </Wrapper>
);

const navItemShape = {
  id: PropTypes.number.isRequired,
  order: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

const menuItemType = PropTypes.shape({
  children: PropTypes.arrayOf(PropTypes.shape(navItemShape)),
  ...navItemShape
});

Nav.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  menu: PropTypes.arrayOf(menuItemType),
  order: PropTypes.arrayOf(PropTypes.shape(orderNowType))
};

export default Nav;
