/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Image } from '../../../../../elements';
import {
  getColumnSpanSize,
  media,
  P,
  shadeOf,
  toEms
} from '../../../../../global';
import { getMetrics, getSuffix } from '../../../utils';

const Calories = styled(P).attrs({ as: 'span' })`
  color: ${p => p.theme.color.peppercornLight};
  display: block;
  font-size: ${toEms('14px')};

  ${media.up.lg`
    display: none;
  `}
`;

const ClickBar = styled.div`
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;

const Details = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  padding: 15px 15px 15px 25px;
  position: relative;
`;

const Img = styled(Image)`
  margin: 0 5%;
  width: 40px;

  ${media.down.lg`
    margin: 0 3%;
  `}
`;

const Metrics = styled.div`
  display: flex;
  max-width: ${p => getColumnSpanSize(9, p.theme.gridSettings)}px;
  padding: 15px 0;
  text-align: left;
  width: 100%;

  @media only screen and (max-width: 1168px) {
    max-width: ${p => getColumnSpanSize(8, p.theme.gridSettings)}px;
  }

  ${media.down.lg`
    display: none;
  `}
`;

const MoreInfo = styled(P)`
  color: ${p => p.theme.color.peppercornLight};
  font-weight: ${p => p.theme.fontWeight.ultraBold};
  line-height: 1;
`;

const MoreInfoWrapper = styled.div`
  border: 1px solid ${p => p.theme.color.peppercornLight};
  border-radius: 100%;
  cursor: pointer;
  height: 19px;
  margin-right: 20px;
  padding-top: 1px;
  width: 19px;
  z-index: 2;

  ${media.up.lg`
    display: none;
  `}
`;

// prettier-ignore
const Name = styled(P)`
  font-size: ${toEms('16px')};
  text-align: left;
  transition: color 0.25s ease-in-out;

  ${p => media.up.lg`
    ${p.containsAllergen && `
      color: ${p.theme.color.corn};
    `}
  `}
`;

// prettier-ignore
const Select = styled.div`
  background: ${p => p.active ? p.theme.color.peppercorn : 'transparent'};
  border-radius: 100%;
  flex: 0 0 auto;
  height: 13px;
  position: relative;
  width: 13px;

  &::before,
  &::after {
    background: ${p => p.active ? p.theme.color.white : p.theme.color.peppercorn};
    content: '';
    height: 1px;
    left: 1px;
    position: absolute;
    top: 6px;
    transform: ${p => p.active ? 'rotate(45deg)' : 'rotate(180deg)'};
    transition: transform 0.25s ease;
    width: 11px;
  }

  &::after {
    transform: ${p => p.active ? 'rotate(-45deg)' : 'rotate(90deg)'};
  }
`;

const Unit = styled(P)`
  flex-basis: calc(100% / ${p => p.length});
  flex-grow: 0;
  flex-shrink: 0;
  font-size: ${toEms('16px')};

  ${media.up.lg`
    color: inherit;
  `}
`;

const Warning = styled(MoreInfo)`
  color: ${p => p.theme.color.white};
`;

// prettier-ignore
const WarningWrapper = styled.div`
  align-items: center;
  background: ${p => p.theme.color.corn};
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: ${p => p.containsAllergen ? 1 : 0};
  position: absolute;
  transform: ${p => p.containsAllergen ? 'scale(1)' : 'scale(0)'};
  transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
  width: 16px;
`;

const Wrapper = styled.div`
  align-items: center;
  background: ${p => (p.active ? p.theme.color.feta : 'transparent')};
  border-bottom: 1px solid ${p => p.theme.color.peppercornLight};
  color: ${p =>
    p.active ? p.theme.color.peppercorn : p.theme.color.peppercornLight};
  display: flex;
  justify-content: space-between;
  position: relative;
  transition: background 0.15s ease, color 0.15s ease;

  ${p => media.up.lg`
    border: 1px solid ${shadeOf(p.theme.color.peppercornLight, 0.25)};
    border-bottom-color: ${p.theme.color.peppercornLight};
    border-top: none;
  `}
`;

const Ingredient = ({
  activeAllergens,
  ingredient,
  selected,
  setActiveModal,
  setModalVisible,
  setSelected
}) => {
  const [containsAllergen, setContainsAllergen] = useState(false);
  const ingredientPayload = { payload: ingredient, type: 'toggle' };
  const metrics = getMetrics(ingredient);

  const handleModalChange = () => {
    setActiveModal(ingredient);
    setModalVisible(true);
  };

  useEffect(() => {
    const hasAllergens = ingredient.allergens.some(allergen => {
      return activeAllergens.some(
        activeAllergen => activeAllergen.name === allergen
      );
    });

    if (hasAllergens) setContainsAllergen(true);
    if (!hasAllergens) setContainsAllergen(false);
  }, [activeAllergens, containsAllergen]);

  return (
    <Wrapper active={selected.includes(ingredient)}>
      <ClickBar onClick={() => setSelected(ingredientPayload)} />
      <Details>
        <WarningWrapper containsAllergen={containsAllergen}>
          <Warning>!</Warning>
        </WarningWrapper>
        <Select active={selected.includes(ingredient)} />
        {ingredient.image && <Img {...ingredient.image.data.thumbnails[0]} />}
        <Name containsAllergen={containsAllergen}>
          {ingredient.name}
          <Calories>{metrics[0]} calories</Calories>
        </Name>
      </Details>
      <Metrics>
        {metrics.map((metric, i) => {
          const suffix = getSuffix(i);
          return (
            <Unit key={i} length={metrics.length}>
              {metric + suffix}
            </Unit>
          );
        })}
      </Metrics>
      <MoreInfoWrapper onClick={() => handleModalChange()}>
        <MoreInfo>i</MoreInfo>
      </MoreInfoWrapper>
    </Wrapper>
  );
};

export const IngredientType = {
  allergens: PropTypes.arrayOf(PropTypes.string),
  calories: PropTypes.number,
  carbohydrates: PropTypes.number,
  category: PropTypes.string,
  category_slug: PropTypes.string,
  cholesterol: PropTypes.number,
  dietary_fiber: PropTypes.number,
  grams_of_fat: PropTypes.number,
  id: PropTypes.number,
  image: PropTypes.object,
  ingredients: PropTypes.string,
  menu_id: PropTypes.number,
  name: PropTypes.string,
  name_slug: PropTypes.string,
  protein: PropTypes.number,
  saturated_fats: PropTypes.number,
  serving_size: PropTypes.string,
  sodium: PropTypes.number,
  sugars: PropTypes.number,
  trans_fats: PropTypes.number
};

Ingredient.propTypes = {
  activeAllergens: PropTypes.array,
  ingredient: PropTypes.shape(IngredientType).isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.shape(IngredientType))
  ]),
  setActiveModal: PropTypes.func,
  setModalVisible: PropTypes.func,
  setSelected: PropTypes.func
};

export default Ingredient;
