import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Container, Section } from '../../components';
import { ParticlesType } from '../../components/Section/Particles';
import { Button, ButtonType, Image } from '../../elements';
import {
  ColorOptions,
  getColumnSpanSize,
  getPercentValue,
  H1,
  media,
  P,
  parseContent,
  sizes,
  toEms
} from '../../global';

const Content = styled.div`
  flex: 1 0 auto;
  max-width: ${p => getColumnSpanSize(5, p.theme.gridSettings)}px;
  padding-right: ${p => p.theme.gridSettings.gutterWidth}px;
  width: 100%;

  ${media.down.lg`
    padding: 50px 0;
  `}
`;

// prettier-ignore
const ContentContainer = styled(Container)`
  align-items: center;
  display: flex;
  padding-bottom: 0;
  padding-top: 0;
  position: relative;

  ${media.up.lg`
    height: 495px;
  `}

  ${p => p.centered && `
    justify-content: center;
    text-align: center;
  `}

  ${p => media.down.lg`
    display: ${p.centered ? 'flex' : 'block'};
    min-height: 450px;
  `}

  &::after {
    content: '';
    min-height: inherit;
    font-size: 0;
  }
`;

const Link = styled(Button)`
  margin-top: 50px;

  ${media.down.xs`
    text-align: center;
    width: 100%;
  `}
`;

const ContentTitle = styled(H1)`
  ${media.down.sm`
    font-size: 48px;
  `}
`;

const ContentSubtitle = styled(P)`
  margin-top: ${toEms(42)};
`;

const FeaturedImage = styled(Image)`
  height: 670px;
  transform: translateY(65px);
  z-index: 1;

  img {
    height: 100%;
    width: auto;
  }

  ${media.down.lg`
    height: 435px;
    margin-bottom: -120px;
    position: relative;
    transform: translate(${p =>
    getPercentValue(
      getColumnSpanSize(4, p.theme.gridSettings),
      sizes.down.lg
    )}%, 0);
  `}

  ${media.down.xs`
    height: 268px;
    transform: translate(${p =>
    getPercentValue(
      getColumnSpanSize(1, p.theme.gridSettings),
      sizes.down.xs
    )}%, 0);
  `}
`;

// prettier-ignore
const Wrapper = styled(Section)`
  position: relative;
  z-index: 1;

  ${p => p.backgroundImage && `
    background-image: url(${p.backgroundImage});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  `}

  ${p => !p.overlap && p.hasImage && `
    + section {
      margin-top: 120px;
    }
  `}

  ${p => media.down.sm && !p.overlap && p.hasImage && `
    + section {
      margin-top: 60px;
    }
  `}
`;

const Hero = ({
  background,
  centered,
  className,
  link,
  linkProps,
  dividerBottomColor,
  halfBottomPadding,
  image,
  overlap,
  pageSubtitle,
  pageTitle,
  particlesData
}) => (
  <Wrapper
    align="left"
    backgroundImage={background}
    className={className}
    contentBelow
    dividerBottomColor={dividerBottomColor}
    halfBottomPadding={halfBottomPadding}
    hasImage={Boolean(image)}
    noPadding
    particlesData={particlesData}
    overlap={overlap}
    unContain
  >
    <ContentContainer
      centered={centered}
      hasDivider={dividerBottomColor != null}
      hasImage={Boolean(image)}
    >
      <Content>
        <ContentTitle dangerouslySetInnerHTML={parseContent(pageTitle)} />
        {pageSubtitle && (
          <ContentSubtitle
            dangerouslySetInnerHTML={parseContent(pageSubtitle)}
          />
        )}
        {link && <Link {...link} {...linkProps} />}
      </Content>
      {image && <FeaturedImage {...image} />}
    </ContentContainer>
  </Wrapper>
);

export const HeroType = {
  background: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  centered: PropTypes.bool,
  className: PropTypes.string,
  dividerBottomColor: PropTypes.oneOf(ColorOptions),
  halfBottomPadding: PropTypes.bool,
  image: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(ButtonType)]),
  linkProps: PropTypes.object,
  overlap: PropTypes.bool,
  pageSubtitle: PropTypes.string,
  pageTitle: PropTypes.string,
  particlesData: PropTypes.arrayOf(PropTypes.shape(ParticlesType))
};

Hero.defaultProps = {
  overlap: false
};

Hero.propTypes = HeroType;

export default Hero;
