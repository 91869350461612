import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Section } from '../components';

import { getColumnSpanSize, media } from '../global';
import BaseLayout from './Base';

import Hero, { HeroType } from '../sections/Hero';

const Content = styled.main`
  width: 100%;

  ${media.up.md`
    flex: 1 1 ${p => getColumnSpanSize(7, p.theme.gridSettings)}px;
    max-width: ${p => getColumnSpanSize(7, p.theme.gridSettings)}px;
  `}
`;

const Sidebar = styled.aside`
  width: 100%;

  ${media.up.md`
    flex: 0 1 ${p => getColumnSpanSize(4, p.theme.gridSettings)}px;
    max-width: ${p => getColumnSpanSize(4, p.theme.gridSettings)}px;
  `}
`;

// prettier-ignore
const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: ${p => (p.center ? 'center' : 'space-between')};

  ${p => p.reverseMobile && `
    flex-direction: column-reverse;
  `}

  ${media.up.md`
    flex-direction: row;
  `}
`;

const ContentWithSidebar = ({
  center = false,
  children,
  hero,
  mainContent,
  reverseMobile = false,
  sidebarContent,
  unContain = false,
  ...layoutProps
}) => (
  <BaseLayout footerDivider {...layoutProps}>
    {hero && <Hero {...hero} dividerBottomColor="white" />}
    <Section noPadding unContain={unContain}>
      {children && children}
      <Wrapper center={center} reverseMobile={reverseMobile}>
        <Sidebar>{sidebarContent}</Sidebar>

        <Content>{mainContent}</Content>
      </Wrapper>
    </Section>
  </BaseLayout>
);

ContentWithSidebar.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.node,
  hero: PropTypes.shape(HeroType),
  mainContent: PropTypes.node.isRequired,
  reverseMobile: PropTypes.bool,
  sidebarContent: PropTypes.node.isRequired,
  unContain: PropTypes.bool
};

export default ContentWithSidebar;
