import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Facebook, Instagram, Link, Twitter } from '../../global';

const Wrapper = styled.div`
  align-items: center;
  border: 2px solid currentColor;
  border-radius: 50%;
  display: flex;
  height: 42px;
  justify-content: center;
  overflow: hidden;
  width: 42px;

  > svg {
    height: auto;
    max-height: 20px;
    width: 20px;
  }

  :hover {
    opacity: 0.7;
  }
`;

const SocialIcon = ({ children, className, icon }) => {
  const icons = {
    facebook: <Facebook />,
    instagram: <Instagram />,
    link: <Link />,
    twitter: <Twitter />
  };

  return (
    <Wrapper className={className}>
      {icons[icon]}
      {children}
    </Wrapper>
  );
};

SocialIcon.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string.isRequired
};

export default SocialIcon;
