import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '../../elements';
import { MapMarker, media, shadeOf } from '../../global';

const Form = styled.form`
  display: flex;
  align-items: flex-end;
  justify-content: ${p => (p.inHeader ? 'flex-start' : 'center')};

  ${media.down.xs`
    align-items: center;
    flex-direction: column;
  `}
`;

const Label = styled.label`
  color: ${p => p.theme.color.peppercorn};
  display: flex;
  flex: 1 0 auto;
  font-family: ${p => p.theme.fontFamily.primary};
  font-weight: ${p => p.theme.fontWeight.regular};
  flex-direction: column;
  line-height: 1.3;
  max-width: 65%;
  text-align: left;
  width: 100%;

  ${media.down.xs`
    max-width: 100%;
  `}
`;

const SearchIcon = styled(MapMarker)`
  color: ${p => p.theme.color.kaleLeaf};
  height: calc(21px / 17 * 12);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-12px, -50%);
  width: 12px;
`;

const SearchInput = styled.input`
  background-color: ${p => p.theme.color.white};
  border-radius: 2px;
  border: 1px solid ${p => shadeOf(p.theme.color.peppercornLight, 0.5)};
  color: ${p => p.theme.color.blackBean};
  font-family: ${p => p.theme.fontFamily.primary};
  font-size: 16px;
  font-weight: ${p => p.theme.fontWeight.regular};
  line-height: normal;
  /* center input beside button */
  min-height: 44px;
  padding: 10px;
  transition: ${({ theme }) =>
    `box-shadow ${theme.timing.fast} ${theme.easing.ease}`};
  width: 100%;

  &::placeholder {
    color: ${p => shadeOf(p.theme.color.peppercornLight, 0.5)};
  }

  :focus {
    border-color: ${p => p.theme.color.kaleLeaf};
    box-shadow: 0 0 4px 0 ${p => shadeOf(p.theme.color.kaleLeaf, 0.3)};
  }
`;

const SearchInputWrapper = styled.div`
  margin-bottom: 2px;
  margin-right: 5px;
  position: relative;
  width: auto;

  ${media.down.xs`
    margin-bottom: 25px;
    width: 100%;
  `}
`;

const Submit = styled(Button)`
  flex: 0 1 auto;

  ${media.down.xs`
    display: ${p => (p.submitHiddenMobile ? 'none' : 'block')};
    width: 100%;
  `}
`;

const LocationSearch = ({
  defaultValue,
  inHeader,
  labelText,
  onChange,
  onSubmit,
  placeholder = 'Enter zip code, city or address…',
  submitHiddenMobile = false,
  value
}) => (
  <Form inHeader={inHeader} onSubmit={onSubmit}>
    <Label>
      {labelText}
      <SearchInputWrapper>
        <SearchInput
          defaultValue={defaultValue}
          onChange={onChange}
          placeholder={placeholder}
          type="text"
          value={value}
        />
        <SearchIcon />
      </SearchInputWrapper>
    </Label>

    <Submit
      buttonExtraCss="padding:16px 20px 14px"
      buttonType="primary"
      submitHiddenMobile={submitHiddenMobile}
      title="Search"
    />
  </Form>
);

LocationSearch.propTypes = {
  defaultValue: PropTypes.string,
  inHeader: PropTypes.bool,
  labelText: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  submitHiddenMobile: PropTypes.bool,
  value: PropTypes.string
};

export default LocationSearch;
