import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card } from '../../../components';
import { shadeOf, Wysiwyg } from '../../../global';

const ContactWysiwyg = styled(Wysiwyg)`
  border-bottom: 1px solid ${p => shadeOf(p.theme.color.peppercornLight, 0.4)};
  padding: 40px 0;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  p {
    color: ${p => p.theme.color.peppercorn};
    margin-bottom: 0;

    a {
      color: ${p => p.theme.color.redPepperLight};
    }
  }
`;

const Sidebar = ({ bodyText, franchising, headline, press }) => (
  <>
    <Card bodyText={bodyText} calloutText={headline} />
    <ContactWysiwyg dangerouslySetInnerHTML={{ __html: press }} />
    <ContactWysiwyg dangerouslySetInnerHTML={{ __html: franchising }} />
  </>
);

export const SidebarType = {
  bodyText: PropTypes.string,
  franchising: PropTypes.string,
  headline: PropTypes.string,
  press: PropTypes.string
};

Sidebar.propTypes = SidebarType;

export default Sidebar;
