import React from 'react';
import PropTypes from 'prop-types';

import { AppStore, Container } from '../../components';

import Address from './Address';
import Navigation from './Navigation';
import SignupForm from './SignupForm';
import SiteLogo from './SiteLogo';
import SocialLinks from './SocialLinks';
import { GridLayout, PolicyText, Wrapper } from './components';

const Footer = ({ menu }) => (
  <Wrapper>
    <Container>
      <GridLayout>
        <SiteLogo />
        <Navigation menu={menu} />
        <Address />
        <AppStore bodyText="Download the Salata app today." />
        <SignupForm />
        <SocialLinks />
        <PolicyText>
          <div>
            <a href="/sitemap">Site Map</a> |{' '}
            <a href="/privacy">Privacy Policy</a> &{' '}
            <a href="/terms">Terms of Service</a> |
          </div>
          <div>
            &copy; {new Date().getFullYear()} Salata, All Rights Reserved
          </div>
        </PolicyText>
      </GridLayout>
    </Container>
  </Wrapper>
);

Footer.propTypes = {
  menu: PropTypes.array.isRequired
};

export default Footer;
