import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from '../../../../elements';
import { darkenColor, Dropdown, media } from '../../../../global';

import Filters from './Filters';

import { CheckboxType } from './Filters/Checkbox';

const DropdownButton = styled(Button)`
  svg {
    fill: ${p => p.theme.color.corn};
  }

  ${media.down.xs`
    padding: 0 20px;
    width: 100%;
  `}
`;

const Wrapper = styled.div`
  position: relative;

  /* Added the hover/active states here for specificity */
  &:hover ${DropdownButton} {
    svg {
      fill: ${p => darkenColor(0.04, p.theme.color.corn)};
    }
  }

  ${media.down.sm`
    position: static;
  `}

  ${media.down.xs`
    padding: 0 20px;
  `}
`;

const AllergenFilter = ({ allergens, setAllergens }) => {
  const [showFilters, setShowFilters] = useState(false);
  const contentRef = useRef(null);

  const handleClick = () => {
    setShowFilters(!showFilters);
  };

  const handleClickOutside = e => {
    if (contentRef.current && !contentRef.current.contains(e.target)) {
      setShowFilters(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showFilters]);

  return (
    <Wrapper ref={contentRef}>
      <DropdownButton
        onClick={handleClick}
        title={
          <>
            Allergen Filter
            <Dropdown afterText />
          </>
        }
      />
      <Filters
        allergens={allergens}
        setAllergens={setAllergens}
        setVisible={handleClick}
        visible={showFilters}
      />
    </Wrapper>
  );
};

AllergenFilter.propTypes = {
  allergens: PropTypes.arrayOf(PropTypes.shape(CheckboxType)),
  setAllergens: PropTypes.func
};

export default AllergenFilter;
