import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, CardType, Grid, Intro, IntroType } from '../../components';
import { media, setColumnWidth, shadeOf } from '../../global';

const MealCard = styled(Card)`
  display: block;

  h3 {
    font-size: 30px;

    ${media.down.xs`
      font-size: 20px;
    `}
  }

  ${p => media.down.sm`
    align-items: center;
    border-bottom: 1px solid ${shadeOf(p.theme.color.peppercornLight, 0.2)};
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 20px 0;

    &:last-child {
      div {
        &:first-child {
          padding: 0;
          width: ${setColumnWidth(4, p.theme.gridSettings)};

          div {
            padding: 0;
            width: 100%;
          }
        }

        &:last-child {
          padding-left: 20px;
          text-align: left;
          width: ${setColumnWidth(8, p.theme.gridSettings)};

          > div {
            padding-left: 0;
          }
        }
      }
    }
  `}
`;

const MealGrid = styled(Grid)`
  margin-top: 0;

  /* removes padding from columns on sm breakpoint */
  ${media.down.sm`
    & > div {
      padding: 0;
    }
  `}
`;

const MealIntro = styled(Intro)`
  margin: 0;
  text-align: left;
`;

const MealMakers = ({ meals, intro }) => (
  <Fragment>
    <MealIntro {...intro} />
    <MealGrid
      columns={4}
      component={MealCard}
      componentData={meals}
      offsetColumns
    />
  </Fragment>
);

MealMakers.propTypes = {
  intro: PropTypes.shape(IntroType),
  meals: PropTypes.arrayOf(PropTypes.shape(CardType))
};

export default MealMakers;
