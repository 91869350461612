import { transparentize } from 'polished';
import styled from 'styled-components';

export const ActionArea = styled.div`
  align-items: center;
  border-top: 1px solid
    ${p => transparentize(0.5, p.theme.color.peppercornLight)};
  display: flex;
  justify-content: space-between;
  padding: 18px 28px;
`;

export const Action = styled.button`
  cursor: pointer;
  ${p =>
    p.primary &&
    `
    color: ${p.theme.color.avocado};
    font-weight: bold;
  `}
`;

export const Arrow = styled.div`
  cursor: pointer;
  padding: 12px;
  position: relative;

  ::before {
    border-color: ${p => p.theme.color.redPepper};
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: '';
    position: absolute;
    transform: rotate(${p => (p.next ? 45 : 225)}deg);
    top: 6px;
    ${p => (p.next ? 'right: 6px;' : 'left: 6px;')};
    height: 11px;
    width: 11px;
  }
`;

export const ControlWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const Month = styled.div`
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 44px;
  justify-content: center;
  user-select: none;
  width: 44px;

  ${p =>
    p.isActive &&
    `
    background-color: ${p.theme.color.avocado};
    color: ${p.theme.color.white};
  `}
`;

export const MonthPicker = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: auto;
  min-height: 122px;
  padding: 15px 20px 30px;
`;

export const YearPicker = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 20px 12px;
`;

export const Wrapper = styled.div`
  background-color: ${p => p.theme.color.white};
  border-radius: 5px;
  box-shadow: 0 8px 20px 0 ${p => transparentize(0.66, p.theme.color.black)};
  color: ${p => p.theme.color.blackBean};
  display: flex;
  flex-direction: column;
  font-family: ${p => p.theme.fontFamily.primary};
  font-weight: ${p => p.theme.fontWeight.regular};
  position: absolute;
  width: 250px;
`;
