import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CheckMark, H4, media, P } from '../../global';

const Paragraph = styled(P)`
  width: 100%;
`;

const Received = styled(H4)`
  color: ${p => p.theme.color.kaleLeaf};
  padding: 20px 0;
`;

// prettier-ignore
const Wrapper = styled.div`
  align-items: center;
  border: 1px solid ${p => p.theme.color.peppercornLight};
  border-radius: 2px;
  color: ${p => p.theme.color.redPepper};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 50%;
  opacity: 0;
  padding: 100px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(100%, -50%);
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
  width: 100%;

  ${p => p.isSubmitted && `
    opacity: 1;
    transform: translate(-50%, -50%);
  `}

  svg {
    height: 56px;
    width: 56px;
  }

  ${media.down.sm`
    padding: 50px 25px;
  `}
`;

const Success = ({ bodyText, className, headline, submitted }) => (
  <Wrapper className={className} isSubmitted={submitted}>
    <CheckMark />
    <Received>{headline}</Received>
    <Paragraph>{bodyText}</Paragraph>
  </Wrapper>
);

Success.propTypes = {
  bodyText: PropTypes.string,
  className: PropTypes.string,
  headline: PropTypes.string,
  submitted: PropTypes.bool
};

export default Success;
