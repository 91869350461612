import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, ButtonType } from '../../elements';
import { H2 } from '../../global';
import { media } from '../../global/utils';
import Fold from './Fold';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.down.md`
    flex-direction: column;
  `}
`;

const Intro = styled.div`
  padding-right: 10%;

  ${media.up.md`
    width: 50%;
  `}
`;

const Folds = styled.div`
  ${media.up.md`
    width: 50%;
  `}

  ${media.down.md`
    margin-top: 60px
  `}
`;

const Heading = styled(H2)`
  margin-bottom: 40px;
`;

const Accordion = ({ content, intro }) => {
  const [activeFold, setActiveFold] = useState(null);

  const handleClick = index => {
    setActiveFold(index === activeFold ? null : index);
  };

  return (
    <Wrapper>
      <Intro>
        <Heading>{intro.heading}</Heading>
        {intro.button && <Button {...intro.button} />}
      </Intro>
      <Folds>
        {content.map((faq, index) => (
          <Fold
            {...faq}
            active={activeFold === index}
            key={index}
            onClick={() => handleClick(index)}
          />
        ))}
      </Folds>
    </Wrapper>
  );
};

export const AccordionType = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      heading: PropTypes.string
    })
  ),
  intro: PropTypes.shape({
    button: ButtonType,
    heading: PropTypes.string
  })
};

Accordion.propTypes = AccordionType;

export default Accordion;
