import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Section } from '../../../components';
import { media, shadeOf } from '../../../global';

import { getTotals } from '../utils';

import { IngredientType } from '../Ingredients/Group/Ingredient';

import Controls from './Controls';
import ExpandedContent from './ExpandedContent';
import Measurements from './Measurements';

// prettier-ignore
const Expanded = styled.div`
  overflow: hidden;
  transition: max-height 0.15s cubic-bezier(0.82, 0.17, 0.32, 0.33);

  ${p => media.up.lg`
    max-height: ${p => p.maxHeight}px;

    ${!p.expanded && `
      border-top: 1px solid ${p.theme.color.white};
      max-height: 0;
    `}
  `}
`;

const Main = styled.div`
  display: flex;
  padding: 15px 0;

  ${p => media.down.lg`
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    position: relative;
  `}
`;

// prettier-ignore
const Wrapper = styled(Section)`
  bottom: 0;
  box-shadow: 0 0 10px ${p => shadeOf(p.theme.color.black, 0.25)};
  overflow: hidden;
  position: fixed;
  transition: max-height 0.15s cubic-bezier(0.82, 0.17, 0.32, 0.33);
  z-index: 9;

  & > div {
    padding-left: 0;
    padding-right: 0;
  }

  ${p => media.down.lg`
    background: ${p => p.theme.color.white};
    box-shadow: none;
    height: 100%;
    max-height: ${p.maxHeight}px;

    ${p.expanded && `
      max-height: 100vh;
      overflow: auto;
      z-index: 10;
    `}
  `}
`;

const Totals = ({ measurements, selected, setSelected }) => {
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [maxHeightDesktop, setMaxHeightDesktop] = useState(0);
  const [maxHeightMobile, setMaxHeightMobile] = useState(0);

  const desktopRef = useRef(null);
  const mobileRef = useRef(null);
  const totalsRef = useRef(null);
  const totals = getTotals(selected);

  const handleEmail = (input, printTotals, selected) => {
    axios
      .post('/email-totals', {
        email: input,
        selected: selected,
        totals: printTotals
      })
      .then(res => {
        if (res.status === 200) setEmailSubmitted(true);
      })
      .catch(err => console.log(err));
  };

  const handleClickOutside = e => {
    if (totalsRef.current && !totalsRef.current.contains(e.target)) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [expanded]);

  useEffect(() => {
    const mobileHeight = () => {
      const width = window.innerWidth;
      if (!expanded && width < 1024) {
        setMaxHeightMobile(mobileRef.current.clientHeight);
      }
    };
    mobileHeight();
    if (expanded) setMaxHeightDesktop(desktopRef.current.clientHeight);

    window.addEventListener('resize', mobileHeight);
    return () => {
      window.removeEventListener('resize', mobileHeight);
    };
  }, [expanded, maxHeightDesktop, maxHeightMobile]);

  return (
    <Wrapper
      bgColor="tan"
      expanded={expanded}
      maxHeight={maxHeightMobile}
      noPadding
      componentRef={totalsRef}
    >
      <Main>
        <Controls
          calories={totals[0]}
          expanded={expanded}
          mobileRef={mobileRef}
          setExpanded={setExpanded}
        />
        <Measurements
          expanded={expanded}
          measurements={measurements}
          totals={totals}
        />
      </Main>
      <Expanded expanded={expanded} maxHeight={maxHeightDesktop}>
        <ExpandedContent
          desktopRef={desktopRef}
          emailSubmitted={emailSubmitted}
          expanded={expanded}
          handleEmail={handleEmail}
          measurements={measurements}
          selected={selected}
          setEmailSubmitted={setEmailSubmitted}
          setExpanded={setExpanded}
          setSelected={setSelected}
          totals={totals}
        />
      </Expanded>
    </Wrapper>
  );
};

Totals.propTypes = {
  measurements: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.shape(IngredientType))
  ]),
  setSelected: PropTypes.func
};

export default Totals;
