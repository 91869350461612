import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { getPercentValue, media, P, shadeOf } from '../../../../../global';

const MIN_CITY = 11;
const MAX_CITY = 34;
// these are the base width and height of the container
// needed to calculate position of each City and be responsive
const CONTAINER_HEIGHT = 469;
const CONTAINER_WIDTH = 706;

const hoverTypes = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  STATIC: 'STATIC'
};

const Details = styled.div`
  background-color: ${p => shadeOf(p.theme.color.white, 0.85)};
  border-radius: 5px;
  box-shadow: 0 0 5px ${p => p.theme.color.peppercornLight};
  margin-bottom: 5px;
  padding: 15px;
  pointer-events: none;
  text-align: center;
`;

const Info = styled.div`
  left: 50%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  text-align: center;
  top: 0%;
  transform: translate(-50%, -75%);
  transition: all 0.15s ease-in-out;
`;

const InfoContainer = styled.div`
  position: relative;
  z-index: 1;
`;

const InfoLine = styled.div`
  border-right: 1.5px dashed ${p => p.theme.color.white};
  display: inline-block;
  height: 35px;
`;

const Name = styled(P)`
  font-size: ${p => p.theme.fontSize.label};
  font-weight: ${p => p.theme.fontWeight.bold};
  white-space: nowrap;
`;

const Number = styled(P)`
  color: ${p => p.theme.color.peppercornLight};
  font-size: ${p => p.theme.fontSize.label};
  white-space: nowrap;
`;

// prettier-ignore
const Wrapper = styled.div`
  background: ${p => p.theme.color.kaleLeaf};
  border-radius: 50%;
  box-shadow: 0 0 5px ${p => p.theme.color.peppercornLight};
  cursor: pointer;
  height: ${p => MIN_CITY + p.number}px;
  left: ${p => getPercentValue(p.x, CONTAINER_WIDTH)}%;
  max-height: ${MAX_CITY}px;
  max-width: ${MAX_CITY}px;
  position: absolute;
  top: ${p => getPercentValue(p.y, CONTAINER_HEIGHT)}%;
  transition: opacity 0.25s ease-in-out;
  width: ${p => MIN_CITY + p.number}px;

  ${p => p.current === hoverTypes.STATIC && `
    opacity: 1;
  `}

  ${p => p.current === hoverTypes.INACTIVE && `
    opacity: 0.25;
    pointer-events: none;     
  `}

  ${p => p.current === hoverTypes.ACTIVE && `
    opacity: 1;

    ${Info} {
      opacity: 1;
      transform: translate(-50%, -100%);
    }
  `}

  ${media.down.lg`
    display: none;
  `}
`;

// this let's you target all siblings when one component is being hovered
const getHoverType = (current, i) => {
  if (current === i) return hoverTypes.ACTIVE;
  if (current === null) return hoverTypes.STATIC;
  return hoverTypes.INACTIVE;
};

const Cities = ({ cities }) => {
  const [current, setCurrent] = useState(null);

  return (
    <>
      {cities.map((city, i) => (
        <Wrapper
          key={i}
          number={parseInt(city.numberOfLocations)}
          current={getHoverType(current, i)}
          onMouseEnter={() => setCurrent(i)}
          onMouseLeave={() => setCurrent(null)}
          x={city.x}
          y={city.y}
        >
          <InfoContainer>
            <Info>
              <Details>
                <Name>{city.name}</Name>
                <Number>{city.numberOfLocations} Locations</Number>
              </Details>
              <InfoLine />
            </Info>
          </InfoContainer>
        </Wrapper>
      ))}
    </>
  );
};

export const CityType = {
  name: PropTypes.string,
  numberOfLocations: PropTypes.string,
  x: PropTypes.string,
  y: PropTypes.string
};

Cities.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape(CityType))
};

export default Cities;
