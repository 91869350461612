import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from '../../global';
import { Image, ImageType } from '../../elements';

const Logo = styled(Image)`
  align-items: center;
  display: flex;
  flex: 0 0 25%;
  justify-content: center;
  padding: 10px;

  img {
    max-width: 100%;
    width: auto;
  }

  ${p => media.down.sm`
    flex: 0 0 50%;
    justify-content: ${p.odd ? 'flex-end' : 'flex-start'};
    padding-left: ${p.odd ? '0' : '10px'};
    padding-right: ${p.odd ? '10px' : '0'};
  `}
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${media.down.sm`
    justify-content: space-between;
  `}
`;

const Logos = ({ images }) => (
  <Wrapper>
    {images.map((image, i) => (
      <Logo {...image.image} odd={i % 2 === 0} key={i} />
    ))}
  </Wrapper>
);

export const LogosType = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape(ImageType)
    })
  )
};

Logos.propTypes = LogosType;

export default Logos;
