import axios from 'axios';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Loading } from '../../components';
import { media, PageType } from '../../global';
import BaseLayout from '../../layouts/Base';

import { NutritionBuilder } from '../../sections';
import {
  getAllergens,
  getCategoryObjects,
  getUniqueArray
} from '../../sections/NutritionBuilder/utils';

import { measurements, tabsArray } from './data';

const Base = styled(BaseLayout)`
  overflow-y: auto;

  ${media.down.lg`
    height: 100%;
  `}
`;

const Wrapper = styled.div`
  opacity: ${p => (p.animating ? 0 : 1)};
  transition: opacity 1s ease-in;
`;

const NutritionBuilderLanding = ({ acf, ...layoutProps }) => {
  const [animating, setAnimating] = useState(true);
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(true);

  const baseTabs = getUniqueArray(data, 'category');
  // reorders the base tabs to match the design
  const tabs = baseTabs.sort((a, b) => {
    return tabsArray.indexOf(a) - tabsArray.indexOf(b);
  });

  useEffect(() => {
    const id = 'nutritionspecialformat';
    axios(`/chepri-api/${id}`)
      .then(res => {
        if (res.data.error) return;
        setData(res.data);
        setFetching(false);
        setTimeout(() => {
          setAnimating(false);
        }, 250);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <Base withoutFooter {...layoutProps}>
      <Loading isLoading={fetching} />
      {!fetching && (
        <Wrapper animating={animating}>
          <NutritionBuilder
            {...acf}
            allergensList={getAllergens(data)}
            data={getCategoryObjects(data, 'category')}
            measurements={measurements}
            tabs={tabs}
          />
        </Wrapper>
      )}
    </Base>
  );
};

NutritionBuilderLanding.propTypes = {
  ...PageType,
  acf: PropTypes.object
};

export default NutritionBuilderLanding;
