import React from 'react';
import IconBase from './IconBase';

export default props => (
  <IconBase viewBox="0 0 19 25" {...props}>
    <defs>
      <path
        d="M721.5 454c-5.667-5.307-8.5-9.507-8.5-12.6 0-4.64 3.806-8.4 8.5-8.4s8.5 3.76 8.5 8.4c0 3.093-2.833 7.293-8.5 12.6zm0-8.4c2.347 0 4.25-1.88 4.25-4.2 0-2.32-1.903-4.2-4.25-4.2s-4.25 1.88-4.25 4.2c0 2.32 1.903 4.2 4.25 4.2z"
        id="locationB"
      />
      <filter
        x="-23.5%"
        y="-9.5%"
        width="135.3%"
        height="138.1%"
        filterUnits="objectBoundingBox"
        id="locationA"
      >
        <feOffset dx="-2" dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.478431373 0 0 0 0 0.576470588 0 0 0 0 0.68627451 0 0 0 1 0"
          in="shadowOffsetOuter1"
        />
      </filter>
    </defs>
    <g transform="translate(-711 -433)" fillRule="nonzero" fill="none">
      <use fill="#000" filter="url(#locationA)" xlinkHref="#locationB" />
      <use fill="currentColor" xlinkHref="#locationB" />
    </g>
  </IconBase>
);
