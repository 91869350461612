import React from 'react';
import PropTypes from 'prop-types';

import { Input } from './components';
import { formatUSPhoneNumber } from './utils';

const TelephoneInput = ({ value, ...props }) => (
  <Input
    maxLength="14"
    value={formatUSPhoneNumber(value).formatted}
    {...props}
  />
);

TelephoneInput.propTypes = {
  value: PropTypes.string
};

export default TelephoneInput;
