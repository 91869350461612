import React from 'react';
import PropTypes from 'prop-types';

const BackgroundCircle = ({ className }) => (
  <svg
    className={className}
    height="38"
    viewBox="0 0 35 38"
    width="35"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 38c-4.198 0-8.848-1.546-11.403-5.107C3.197 28.851 0 25.39 0 19.943c0-4.916 3.346-10.12 5.91-13.806C9.046 1.627 13.493 0 18.199 0s9.659 2.173 11.81 6.137C32.375 10.497 35 13.809 35 18.726c0 4.678-1.158 9.51-3.36 13.185C28.673 36.866 22.435 38 17.5 38"
      fill="#ffe9bf"
    />
  </svg>
);

BackgroundCircle.propTypes = {
  className: PropTypes.string
};

export default BackgroundCircle;
