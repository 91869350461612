import React from 'react';
import IconBase from './IconBase';

export default props => (
  <IconBase viewBox="0 0 22 22" {...props}>
    <g fill="none" fillRule="nonzero" transform="translate(1 1)">
      <path
        fill=""
        stroke="currentColor"
        strokeWidth="1.5"
        d="M4.37 0h11.26a.82.82 0 0 1 .819.82v4.508H3.551V.82a.82.82 0 0 1 .82-.82z"
      />
      <rect
        width="20"
        height="10.041"
        y="5.369"
        fill=""
        stroke="currentColor"
        strokeWidth="1.5"
        rx="1.639"
      />
      <rect
        width="12.898"
        height="9.467"
        x="3.551"
        y="10.533"
        fill=""
        stroke="currentColor"
        strokeWidth="1.5"
        rx=".82"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M5.96 13.247h8.08M5.96 16.075h8.08"
      />
      <ellipse cx="17.143" cy="7.992" fill="currentColor" rx="1" ry="1" />
    </g>
  </IconBase>
);
