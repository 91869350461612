import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { media, PageType } from '../../global';
import ContentWithSidebar from '../../layouts/ContentWithSidebar';
import { ContactForm, ContactFormType, HeroType } from '../../sections';

import Sidebar, { SidebarType } from './Sidebar';

const MainWrapper = styled.div`
  padding: 60px 0;
  text-align: left;

  ${media.down.md`
    padding: 0 0 60px;
  `}
`;

const SideWrapper = styled.div`
  padding: 60px 20px 60px 0;
  text-align: left;

  ${media.down.md`
    padding: 60px 0;
  `}
`;

const Contact = ({ acf, ...layoutProps }) => {
  const { contact, form, hero } = acf;

  const mainContent = (
    <MainWrapper>
      <ContactForm {...form} />
    </MainWrapper>
  );
  const sidebarContent = (
    <SideWrapper>
      <Sidebar {...contact} />
    </SideWrapper>
  );

  return (
    <ContentWithSidebar
      {...layoutProps}
      hero={hero}
      mainContent={mainContent}
      sidebarContent={sidebarContent}
    />
  );
};

Contact.propTypes = {
  ...PageType,
  acf: PropTypes.shape({
    contact: PropTypes.shape(SidebarType),
    form: PropTypes.shape(ContactFormType),
    hero: PropTypes.shape(HeroType)
  })
};

export default Contact;
