import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Card, CardType } from '../../components';
import { media, setColumnWidth } from '../../global';

import MapContent from './MapContent';
import Nav from './Nav';

const CompanyCard = styled(Card)`
  max-width: ${p => setColumnWidth(5, p.theme.gridSettings)};
  text-align: left;
  width: 100%;

  ${media.down.md`
    margin-bottom: 80px;
    max-width: 100%;
  `}
`;

const MapWrapper = styled.div`
  max-width: ${p => setColumnWidth(7, p.theme.gridSettings)};
  width: 100%;

  ${media.down.md`
    max-width: 100%;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CompanyOutlook = ({ card, locations }) => {
  const [active, setActive] = useState('2018');

  return (
    <Wrapper>
      <CompanyCard {...card} />
      <MapWrapper>
        <Nav active={active} setActive={setActive} />
        <MapContent active={active} locations={locations} />
      </MapWrapper>
    </Wrapper>
  );
};

CompanyOutlook.propTypes = {
  card: PropTypes.shape(CardType),
  locations: PropTypes.array
};

export default CompanyOutlook;
