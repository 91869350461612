import React, { useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormField } from '../../../../components';
import {
  combineValidations,
  validateEmail,
  validateRequired
} from '../../../../components/FormField/utils';
import { Button } from '../../../../elements';
import {
  getColumnSpanSize,
  media,
  P,
  Printer,
  Refresh,
  shadeOf
} from '../../../../global';
import { getSuffix } from '../../utils';
import { IngredientType } from '../../Ingredients/Group/Ingredient';
import PrintTemplate from '../PrintTemplate';

const Anchor = styled(P).attrs({ as: 'a' })`
  align-items: center;
  color: ${p => p.theme.color.kaleLeaf};
  cursor: pointer;
  display: flex;
  padding: 20px 0 0;

  svg {
    margin-right: 10px;
  }
`;

const Clear = styled.div`
  align-items: center;
  border-bottom: 1px solid ${p => p.theme.color.peppercornLight};
  color: ${p => p.theme.color.redPepper};
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 25px 0;

  ${media.down.lg`
    border: none;
    justify-content: flex-start;
    padding: 14px 0 20px 20px;
  `}
`;

const ClearText = styled(P)`
  color: inherit;
  padding-left: 10px;
`;

const Close = styled.div`
  background: ${p => p.theme.color.white};
  border-top: 1px solid ${p => shadeOf(p.theme.color.black, 0.25)};
  bottom: 0;
  cursor: pointer;
  opacity: ${p => (p.expanded ? 1 : 0)};
  padding: 20px 0;
  position: fixed;
  text-align: center;
  transform: ${p => (p.expanded ? 'translateY(0)' : 'translateY(100%)')};
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
  width: 100%;

  ${media.up.lg`
    display: none;
  `}
`;

const Disclaimer = styled(P)`
  color: ${p => p.theme.color.peppercornLight};
  font-size: ${p => p.theme.fontSize.label};
  max-width: ${p => getColumnSpanSize(3, p.theme.gridSettings)}px;
  width: 100%;

  ${p => media.down.lg`
    border-bottom: 1px solid ${p.theme.color.peppercornLight};
    padding: 10px 0 25px;
  `}
`;

// prettier-ignore
const Form = styled.form`
  align-items: flex-end;
  display: flex;
  text-align: left;
  transition: opacity 0.15s ease-out, transform 0.15s ease-out;

  ${media.up.sm`
    margin-right: 70px;
  `}

  ${p => p.submitted && `
    opacity: 0;
    transform: scale(0);
  `}
`;

const FormWrapper = styled.div`
  max-width: ${p => getColumnSpanSize(4, p.theme.gridSettings)}px;
  position: relative;
  width: 100%;
`;

const Information = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
  text-align: left;

  ${media.down.lg`
    flex-direction: column;
    padding: 25px 20px;
  `}
`;

// prettier-ignore
const Input = styled(FormField)`
  margin-bottom: 7px;
  margin-right: 10px;
  max-width: ${p => getColumnSpanSize(4, p.theme.gridSettings)}px;
  width: 100%;

  ${p => p.error && `
    label > div {
      border-color: ${p.theme.color.redPepper};
    }
    input:focus {
      box-shadow: 0 0 0 4px ${shadeOf(p.theme.color.redPepper, 0.5)}, 0 0 0 2px ${p.theme.color.redPepper};
    }
  `}
`;

const Links = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 25px;

  ${media.down.lg`
    padding: 0 20px 100px;
  `}

  ${media.down.sm`
    flex-direction: column;
  `}
`;

const HidePrintFromDOM = styled.div`
  height: 0;
  overflow: hidden;
`;

const Send = styled.div`
  max-width: ${p => getColumnSpanSize(4, p.theme.gridSettings)}px;
  width: 100%;

  ${media.down.lg`
    order: 1;
    padding: 25px 0;
  `}
`;

// prettier-ignore
const Submitted = styled(P)`
  opacity: 0;
  padding-top: 31px;
  position: absolute;
  transform: scale(0);
  transition: opacity 0.15s ease-in, transform 0.15s ease-in;
  transition-delay: 0.15s;

  ${p => p.submitted && `
    opacity: 1;
    transform: scale(1);
  `}
`;

const Wrapper = styled.div`
  margin-left: auto;
  max-width: ${p => getColumnSpanSize(9, p.theme.gridSettings)}px;
  width: 100%;

  @media only screen and (max-width: 1168px) {
    max-width: ${p => getColumnSpanSize(8, p.theme.gridSettings)}px;
  }

  ${p => media.down.lg`
    background: ${p.theme.color.white};
    max-width: none;
  `}
`;

const ExpandedContent = ({
  desktopRef,
  emailSubmitted,
  expanded,
  handleEmail,
  measurements,
  selected,
  setEmailSubmitted,
  setExpanded,
  setSelected,
  totals
}) => {
  const [error, setError] = useState(null);
  const [input, setInput] = useState(null);

  const printRef = useRef(null);

  // This takes two arrays and returns an array of objects
  // Example: [foo, bar] [1, 2] => [{ measurement: foo, total: 1 }, { measurement: bar, total: 2 }]
  const createTotalsObjectArray = (arr1, arr2) => {
    const object = arr1.map((key, i) => {
      const suffix = getSuffix(i);
      return { measurement: key, total: arr2[i] + suffix };
    });
    return object;
  };

  const handleChange = e => {
    setInput(e.target.value);

    const hasError = validateEmail(input) !== undefined;
    const isNull = error === null;

    if (!isNull && !hasError) setError(false);
  };

  const handleClear = () => {
    setSelected({ type: 'clear' });
    setEmailSubmitted(false);
  };

  const handleSubmit = e => {
    e.preventDefault();

    const hasError = validateEmail(input) !== undefined;
    if (hasError) return setError(true);

    setError(false);
    handleEmail(input, printTotals, selected);
  };

  const printTotals = createTotalsObjectArray(measurements, totals);

  return (
    <Wrapper ref={desktopRef}>
      <Clear onClick={() => handleClear()}>
        <Refresh height="14" width="14" />
        <ClearText>Clear Calculator</ClearText>
      </Clear>
      <Information>
        <Send>
          <P>
            Save your creation and nutrition information via printing or email
            below.
          </P>
        </Send>
        <Disclaimer>
          *2,000 calories a day is used for general nutrition advice, but
          calorie needs vary." & "Additional nutrition information available
          upon request.
        </Disclaimer>
      </Information>
      <Links>
        <FormWrapper>
          <Form onSubmit={e => handleSubmit(e)} submitted={emailSubmitted}>
            <Input
              columns={{ default: 3 }}
              error={error}
              label="Email Address"
              name="Email"
              onChange={e => handleChange(e)}
              type="email"
              validate={combineValidations(validateRequired, validateEmail)}
            />
            <Button title="Send" type="submit" />
          </Form>
        </FormWrapper>
        <Submitted submitted={emailSubmitted}>
          Your nutritional information has been sent.
        </Submitted>
        <ReactToPrint
          content={() => printRef.current}
          trigger={() => (
            <Anchor>
              <Printer height="22" width="22" /> Print Information
            </Anchor>
          )}
        />
      </Links>
      <Close expanded={expanded} onClick={() => setExpanded(false)}>
        <P>Close</P>
      </Close>
      <HidePrintFromDOM>
        <div ref={printRef}>
          <PrintTemplate totals={printTotals} selected={selected} />
        </div>
      </HidePrintFromDOM>
    </Wrapper>
  );
};

ExpandedContent.propTypes = {
  desktopRef: PropTypes.any,
  emailSubmitted: PropTypes.bool,
  expanded: PropTypes.bool,
  handleEmail: PropTypes.func,
  measurements: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.shape(IngredientType))
  ]),
  setEmailSubmitted: PropTypes.func,
  setExpanded: PropTypes.func,
  setSelected: PropTypes.func,
  totals: PropTypes.arrayOf(PropTypes.number)
};

export default ExpandedContent;
