import styled from 'styled-components';
import { FranchiseDesktop, media, P } from '../../../global';

import SocialLinks from '../../../sections/Footer/SocialLinks';
import { PolicyText } from '../../../sections/Footer/components';

export const Policy = styled(PolicyText)`
  flex: 1 0 50%;
  justify-content: flex-start;
  margin-top: 50px;

  ${media.down.md`
    flex: 0 0 100%;
    order: 1;
  `}

  ${media.up.md`
    // needed here to override original
    justify-content: flex-start;
  `}
`;

export const GridLayout = styled.footer`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-family: ${p => p.theme.fontFamily.primary};
  justify-content: space-between;
`;

export const Logo = styled(FranchiseDesktop)`
  height: 42px;
  margin: 7px 2.5vw 7px 0;
  width: 221px;

  ${media.down.md`
    height: 33px;
    margin: 11px 0;
    width: calc(221px / 42 * 33);
  `}
`;

export const LogoWrapper = styled.a`
  flex: 0 1 auto;
`;

export const NavItem = styled(P).attrs({ as: 'a' })`
  margin: 0 20px;
  position: relative;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &::before {
    background: ${p => p.theme.color.blackBean};
    bottom: -5px;
    content: '';
    height: 1px;
    position: absolute;
    transform: scaleX(0);
    transform-origin: left center;
    transition: background 0.2s ease-in-out, transform 0.2s linear;
    width: 100%;
  }

  &:hover,
  &:focus {
    &::before {
      transform: scaleX(1);
    }
  }

  ${media.down.sm`
    flex: 0 0 50%;
    margin: 15px 0;
  `}
`;

export const NavWrapper = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex: 0 0 50%;
  flex-wrap: wrap;
  justify-content: flex-end;

  ${media.down.md`
    flex: 0 0 100%;
    justify-content: flex-start;
    margin-top: 25px;
  `}
`;

export const Social = styled(SocialLinks)`
  > div:first-of-type {
    margin-right: 15px;
  }
  ${media.down.md`
    > div:last-of-type {
      justify-content: flex-start;
    }
  `}

  ${media.up.md`
    align-items: center;
    display: flex;
    flex: 0 0 50%;
    justify-content: flex-end;

    > div:last-of-type {
      margin-top: 0;
    }
  `}
`;
