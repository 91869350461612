import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ButtonTypes } from '../../../global';

// The height below is calculated by dividing the svg's original height by the original
// width then multiplied by the new width resulting in a proportional height. This was done for IE support
// prettier-ignore
const Wrapper = styled.span`
  display: inline-block;
  height: calc(11px / 16 * 20);
  margin-left: 20px;
  transition: transform 0.15s ease-in-out;
  width: 20px;

  svg {
    transition: fill 0.2s ease;
    ${p => p.inverted ? `
      fill: ${p.theme.color.white};
    ` : `
      fill: ${p.buttonType === 'primary'
    ? p.theme.color.avocado
    : p.theme.color.redPepper};
    `}
  }
`;

const Arrows = ({ buttonType, className, inverted }) => (
  <Wrapper className={className} inverted={inverted} buttonType={buttonType}>
    <svg viewBox="0 0 16 11" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 5.5L10.167 11 9 9.9l4.667-4.4L9 1.1 10.167 0zM7 5.5L1.167 11 0 9.9l4.667-4.4L0 1.1 1.167 0z" />
    </svg>
  </Wrapper>
);

Arrows.propTypes = {
  buttonType: PropTypes.oneOf(ButtonTypes).isRequired,
  className: PropTypes.string,
  inverted: PropTypes.bool
};

export default Arrows;
