import PropTypes from 'prop-types';

export const MailToLink = {
  body: PropTypes.string,
  email: PropTypes.string.isRequired,
  subject: PropTypes.string
};

export const PageType = {
  id: PropTypes.number.isRequired,
  // Note that the WordPress API curiously returns `false` instead of `null`
  media: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  permalink: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  template: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  yoast: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired
};

export const ReactRouterLink = {
  hash: PropTypes.string,
  pathname: PropTypes.string.isRequired
};

export const ButtonTypes = ['primary', 'secondary'];

export const ColorOptions = [
  'blue',
  'blueDark',
  'white',
  'pink',
  'tan',
  'transparent',
  'orange',
  'green',
  'greenLight',
  'red',
  'redLight'
];

export const AlignmentOptions = ['center', 'left', 'right'];
