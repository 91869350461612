import React from 'react';
import PropTypes from 'prop-types';

import { PageType } from '../../global';
import ContentWithSidebar from '../../layouts/ContentWithSidebar';
import { HeroType } from '../../sections';

import Articles from './Articles';
import Contact from './Contact';

const Newsroom = ({ acf, recentNews, ...layoutProps }) => {
  const { newsroomContact, hero } = acf;

  const mainContent = <Articles articles={recentNews.data} />;

  const sidebarContent = <Contact {...newsroomContact} />;

  return (
    <ContentWithSidebar
      {...layoutProps}
      hero={hero}
      mainContent={mainContent}
      reverseMobile
      sidebarContent={sidebarContent}
    />
  );
};

Newsroom.propTypes = {
  ...PageType,
  acf: PropTypes.shape({
    hero: PropTypes.shape(HeroType)
  })
};

export default Newsroom;
