import styled from 'styled-components';
import { MultiPartForm } from '../../../components';
import Success from '../../../components/FormField/Success';
import { H5, P } from '../../../global';

export const FormLayout = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  text-align: left;
`;

export const PageInformation = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Step = styled(P)`
  padding-left: 5px;
  white-space: nowrap;
`;

// prettier-ignore
export const StyledForm = styled(MultiPartForm)`
  border: 1px solid ${p => p.theme.color.peppercorn};
  border-radius: 5px;
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;

  ${p => p.submitted && `
    opacity: 0;
    transform: translateX(-100%);
  `}
`;

export const Subtitle = styled(P)`
  font-weight: ${p => p.theme.fontWeight.bold};
  letter-spacing: 3.56px;
  text-transform: uppercase;
`;

// prettier-ignore
export const SuccessMessage = styled(Success)`
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translateX(100%);
  transition: opacity 0.3s ease-in, transform 0.3s ease-in;

  ${p => p.submitted && `
    opacity: 1;
    transform: translateX(0);
  `}
`;

export const Title = styled(H5)`
  color: ${p => p.theme.color.peppercorn};
  margin-bottom: 50px;
  text-align: left;
`;

// prettier-ignore
export const Wrapper = styled.div`
  max-height: ${p => p.maxHeight === 0 ? 'none' : `${p.maxHeight}px`};
  overflow: hidden;
  position: relative;
  transition: max-height 0.3s ease;

  ${p => p.submitted && `
    max-height: 365px;
  `}
`;
