export const featuredContentLinkProps = {
  buttonType: 'secondary',
  inline: true
};

export const featuredContentParticles = [
  {
    component: 'Stars',
    fill: 'blueDark',
    position: 'BOTTOM_RIGHT'
  }
];

export const partyStartedParticles = [
  {
    component: 'DotGroup',
    fill: 'red',
    position: 'BOTTOM_LEFT'
  }
];
