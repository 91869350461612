import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, ButtonType } from '../../elements';
import {
  AlignmentOptions,
  getColumnSpanSize,
  media,
  H2,
  H3,
  P,
  parseContent,
  Wysiwyg
} from '../../global';

const Copy = styled(Wysiwyg)`
  margin-top: 15px;
`;

// prettier-ignore
const Link = styled(Button)`
  display: inline-block;
  margin: 10px;
  margin-top: 40px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin:  ${p => p.inline ? '20px' : '10px'} 0 0 0;
  }

  &:only-child {
    margin-top: 40px;
  }

  ${p => media.down.sm`
    margin-left: 0;
    margin-right: 0;

    ${p.inline ? `
      margin-top: 5px;
    ` : `
      margin-top: 10px;

      &:first-child {
        margin-top: 20px;
      }
    `}
  `}
`;

const Links = styled.div``;

const Title = styled.div`
  color: ${p => p.theme.color.kaleLeaf};
`;

// prettier-ignore
const Wrapper = styled.div`
  max-width: ${p => getColumnSpanSize(9, p.theme.gridSettings)}px;
  width: 100%;
  margin: auto;
  text-align: ${p => p.alignment ? p.alignment : 'center'};

  + div {
    margin-top: ${p => p.theme.spacing.verticalPaddingHalf};
  }

  ${p => media.down.md`
    max-width: ${getColumnSpanSize(7, p.theme.gridSettings)}px;
  `}

  ${media.down.sm`
    max-width: 100%;
  `}
`;

const Intro = ({
  alignment,
  bodyText,
  className,
  headline,
  linkProps,
  links,
  mainHeadline,
  wysiwyg
}) => (
  <Wrapper alignment={alignment} className={className}>
    {mainHeadline && <Title as={H2}>{mainHeadline}</Title>}
    {headline && <Title as={H3}>{headline}</Title>}
    {bodyText && <P>{bodyText}</P>}
    {wysiwyg && <Copy dangerouslySetInnerHTML={parseContent(wysiwyg)} />}
    {links && (
      <Links>
        {links.map((link, i) => {
          const inline = linkProps && linkProps.inline;
          const linkObject = { ...link, ...linkProps };
          return <Link inline={inline} {...linkObject} key={i} />;
        })}
      </Links>
    )}
  </Wrapper>
);

export const IntroType = {
  alignment: PropTypes.oneOf(AlignmentOptions),
  bodyText: PropTypes.string,
  className: PropTypes.string,
  headline: PropTypes.string,
  linkProps: PropTypes.object,
  links: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
  mainHeadline: PropTypes.string,
  wysiwyg: PropTypes.string
};

Intro.propTypes = IntroType;

export default Intro;
