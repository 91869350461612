import PropTypes from 'prop-types';
import React from 'react';
import { Card, Section } from '../../../components';
import { PageType } from '../../../global';
import BaseLayout from '../../../layouts/Base';
import {
  FeaturedContent,
  FeaturedContentType,
  Hero,
  HeroType,
  HowItWorks,
  Logos,
  LogosType,
  MultiFeature,
  MultiFeatureType
} from '../../../sections';
import {
  Disclaimer,
  Growing,
  GrowingType,
  LeadershipType
} from '../../components';

const linkProps = {
  buttonType: 'secondary',
  inline: true
};

const FranchisingOverview = ({
  acf,
  franchisingFooter,
  franchisingHeader,
  ...layoutProps
}) => {
  const {
    disclaimer,
    getToWork,
    greatOwner,
    hero,
    logos,
    nextSaladKitchen,
    howItWorks,
    whereWereGrowing,
    whySalata
  } = acf;

  return (
    <BaseLayout
      {...layoutProps}
      franchisingFooter={franchisingFooter}
      franchisingHeader={franchisingHeader}
    >
      {hero && (
        <Hero
          {...hero}
          dividerBottomColor="white"
          linkProps={{ url: '/contact-form/' }}
        />
      )}

      <Section>
        <FeaturedContent image={nextSaladKitchen.image} alignment="right">
          <Card {...nextSaladKitchen.content} />
        </FeaturedContent>
      </Section>

      <Section intro={logos.intro} noTopPadding>
        <Logos images={logos.images} />
      </Section>

      <Section
        bgColor="pink"
        dividerBottomColor="white"
        dividerTopColor="white"
        noPadding
        insetDividerBottom
        insetDividerTop
      >
        <MultiFeature {...whySalata} columns={2} component={Card} />
      </Section>
      <Section>
        <Growing {...whereWereGrowing} />
      </Section>
      <Section
        bgColor="greenLight"
        dividerBottomColor="white"
        dividerTopColor="white"
        halfPadding
        insetDividerTop
        particlesData={[
          {
            component: 'Curves',
            fill: 'green',
            position: 'BOTTOM_LEFT'
          }
        ]}
      >
        <FeaturedContent {...greatOwner} linkProps={linkProps} />
      </Section>

      <Section intro={howItWorks.intro} noBottomPadding>
        <HowItWorks
          {...howItWorks.content}
          columns={4}
          linkProps={{ url: '/contact-form/' }}
        />
      </Section>
      <Section halfPadding>
        <Disclaimer intro={disclaimer.intro} />
      </Section>
      {/**
       * Temporarily removed until client has updated data
       * @see https://gsandf.atlassian.net/browse/SWM-48
       */}
      {/*
      <Section
        bgColor="blue"
        intro={leadership.intro}
        dividerBottomColor="white"
        dividerTopColor="white"
        halfPadding
        particlesData={[
          {
            component: 'DotGroup',
            fill: 'orange',
            position: 'TOP_RIGHT'
          }
        ]}
      >
        <Leadership {...leadership} />
      </Section>
      */}

      <Section
        contentBelow
        dividerBottomColor="tan"
        dividerBottomReverse
        insetDividerBottom
        noBottomPadding
      >
        <FeaturedContent
          {...getToWork}
          alignment="right"
          centerTextMobile
          linkProps={{ url: '/contact-form/' }}
        />
      </Section>
    </BaseLayout>
  );
};

FranchisingOverview.propTypes = {
  ...PageType,
  acf: PropTypes.shape({
    disclaimer: PropTypes.object,
    getToWork: PropTypes.shape(FeaturedContentType),
    greatOwner: PropTypes.shape(FeaturedContentType),
    hero: PropTypes.shape(HeroType),
    howItWorks: PropTypes.object,
    leadership: PropTypes.shape(LeadershipType),
    logos: PropTypes.shape(LogosType),
    nextSaladKitchen: PropTypes.shape(FeaturedContentType),
    whereWereGrowing: PropTypes.shape(GrowingType),
    whySalata: PropTypes.shape(MultiFeatureType)
  }).isRequired
};

export default FranchisingOverview;
