import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Container } from '../../components';
import { Image, ImageType } from '../../elements';
import { media, setColumnWidth } from '../../global';

import Tabs from './Tabs';
import Header, { HeaderType } from './Header';
import Footer, { FooterType } from './Footer';
import { TabType } from './Tabs/Nav';

const FeaturedImage = styled(Image)`
  min-width: 800px;
  position: absolute;
  right: 100%;
  top: 0;
  transform: translateX(-70px);
  width: 100%;

  ${media.down.md`
    transform: translateX(-45px);
  `}
`;

const Inner = styled.div`
  max-width: ${p => setColumnWidth(6, p.theme.gridSettings)};
  width: 100%;
  position: relative;

  ${media.down.md`
    max-width: ${p => setColumnWidth(8, p.theme.gridSettings)};
  `}

  ${media.down.sm`
    max-width: 100%;
  `}
`;

// prettier-ignore
const Wrapper = styled(Container)`
  display: flex;
  justify-content: flex-end;

  ${media.down.sm`
    padding: 0;
  `}
`;

const Toppings = ({ footer, header, image, linkProps, tabs }) => (
  <Wrapper>
    <Inner>
      <Header linkProps={linkProps} {...header} />
      <FeaturedImage {...image} />
      <Tabs tabs={tabs} />
      <Footer {...footer} />
    </Inner>
  </Wrapper>
);

Toppings.propTypes = {
  footer: PropTypes.shape(FooterType).isRequired,
  header: PropTypes.shape(HeaderType).isRequired,
  image: PropTypes.shape(ImageType).isRequired,
  linkProps: PropTypes.object,
  tabs: PropTypes.arrayOf(PropTypes.shape(TabType)).isRequired
};

export default Toppings;
