export const tabs = [
  {
    definition: 'all of our locations across the nation',
    name: 'Current Locations',
    value: 'current'
  },
  {
    definition: 'existing markets that need more salad kitchens',
    name: 'Current Growth Markets',
    value: 'growth'
  },
  {
    definition: 'new markets we are looking into currently',
    name: 'Future Growth Markets',
    value: 'future'
  }
];
