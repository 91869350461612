import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '../../../../elements';
import {
  Base,
  CheeseNuts,
  Fruits,
  media,
  Other,
  P,
  Protein,
  Vegetables
} from '../../../../global';

// prettier-ignore
const TabIconName = styled(P)`
  margin: 10px 0 0;
  transition: color 0.25s ease-in-out;
  white-space: nowrap;
  position: relative;
  padding-bottom: 15px;

  &::after {
    content: '';
    background: ${p => p.theme.color.redPepper};
    bottom: 0;
    display: block;
    height: 4px;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    opacity: 0;
    transition: opacity 250ms;
  }

  ${p => p.active && `
    color: ${p.theme.color.redPepper};

    &::after {
      opacity: 1;
    }
  `}
`;

// prettier-ignore
const TabIcon = styled(Icon)`
  svg {
    height: 40px;
    fill: ${p => p.active
    ? p => p.theme.color.redPepper
    : p => p.theme.color.peppercorn};
    transition: fill 0.25s ease-in-out;
    width: 40px;
  }
`;

// prettier-ignore
const NavItem = styled.div`
  cursor: pointer;
  display: inline-block;
  padding: 15px 2.5% 0;
  text-align: center;
  flex: 1 1 auto;

  ${media.up.lg`
    &:hover {
      ${TabIconName} {
        color: ${p => p.theme.color.redPepper};
      }

      ${TabIcon} svg {
        fill: ${p => p.theme.color.redPepper};
      }
    }
  `}
`;

const Navigation = styled.div`
  ${media.down.sm`
    padding-left: 10px;
  `}
`;

const NavWrapper = styled.div`
  display: flex;
  overflow: auto;
`;

const tabIconMap = {
  Base,
  CheeseNuts,
  Fruits,
  Other,
  Protein,
  Vegetables
};

const Nav = ({ activeTab, tabs, handleTabChange }) => (
  <Navigation>
    <NavWrapper>
      {tabs.map((tab, index) => {
        const icon = tabIconMap[tab.icon];
        return (
          <NavItem key={index} onClick={handleTabChange(index)}>
            <TabIcon active={activeTab === index} icon={icon} />
            <TabIconName active={activeTab === index}>{tab.name}</TabIconName>
          </NavItem>
        );
      })}
    </NavWrapper>
  </Navigation>
);

export const TabType = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  toppings: PropTypes.arrayOf(PropTypes.string).isRequired
};

Nav.propTypes = {
  activeTab: PropTypes.number,
  handleTabChange: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape(TabType)).isRequired
};

export default Nav;
