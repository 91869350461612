import React from 'react';
import Disqus from 'disqus-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H4, media, shadeOf } from '../../global';

const Heading = styled(H4)`
  color: ${p => p.theme.color.peppercorn};

  ${media.down.sm`
    display: none;
  `}
`;

const Wrapper = styled.div`
  box-shadow: 0 0 15px ${p => shadeOf(p.theme.color.peppercorn, 0.2)};
  margin: 0 auto;
  max-width: 762px;
  padding: 35px 75px 20px;
  text-align: left;

  ${media.down.sm`
    padding: 20px;
  `}
`;

const Comments = ({ id, path, title }) => {
  const disqusShortname = 'salata-salad-kitchen';
  const disqusConfig = {
    identifier: id,
    title: title,
    url: process.env.RAZZLE_CLIENT_ENV + path
  };

  return (
    <Wrapper>
      <Heading>Comments</Heading>
      <Disqus.DiscussionEmbed
        shortname={disqusShortname}
        config={disqusConfig}
      />
    </Wrapper>
  );
};

Comments.propTypes = {
  id: PropTypes.number,
  path: PropTypes.string,
  title: PropTypes.string
};

export default Comments;
