import React from 'react';
import IconBase from './IconBase';

export default props => (
  <IconBase viewBox="0 0 14 14" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.649 0h-1.315c-.19 0-.34.16-.332.35l.11 2.298A6.854 6.854 0 0 0 6.875.222C3.087.222-.003 3.32 0 7.116.003 10.92 3.08 14 6.875 14a6.842 6.842 0 0 0 4.607-1.775.334.334 0 0 0 .014-.484l-.942-.944a.332.332 0 0 0-.454-.015A4.856 4.856 0 0 1 6.875 12a4.881 4.881 0 0 1-4.879-4.889 4.881 4.881 0 0 1 4.88-4.889 4.87 4.87 0 0 1 4.05 2.161L8.11 4.248a.333.333 0 0 0-.349.333v1.317c0 .184.15.333.333.333h5.554c.184 0 .333-.149.333-.333V.333A.333.333 0 0 0 13.649 0z"
    />
  </IconBase>
);
