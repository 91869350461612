import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { shadeOf } from '../../global';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Wrapper = styled.div`
  align-items: center;
  background: ${p => shadeOf(p.theme.color.white, 0.7)};
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: ${p => (p.isLoading ? 1 : 0)};
  pointer-events: none;
  position: absolute;
  top: 0%;
  transition: opacity 0.25s ease;
  width: 100%;
  z-index: 1;
`;

const Spinner = styled.div`
  animation: ${spin} 0.5s linear infinite;
  border: 16px solid ${p => p.theme.color.peppercornLight};
  border-radius: 50%;
  border-top: 16px solid ${p => p.theme.color.avocado};
  height: 120px;
  width: 120px;
`;

const Loading = ({ isLoading }) => (
  <Wrapper isLoading={isLoading}>
    <Spinner />
  </Wrapper>
);

Loading.propTypes = {
  isLoading: PropTypes.bool
};

export default Loading;
