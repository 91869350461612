import PropTypes from 'prop-types';
import React from 'react';

import { Container } from '../../../components';
import { GridLayout, Logo, LogoWrapper, Policy, Social } from './components';

import Nav, { NavType } from './Nav';

const Footer = ({ menu }) => (
  <Container>
    <GridLayout>
      <LogoWrapper href="https://salata.com/">
        <Logo />
      </LogoWrapper>
      <Nav menu={menu} />
      <Policy>
        <div>
          <a href="https://salata.com/privacy">Privacy Policy</a> &{' '}
          <a href="https://salata.com/terms">Terms of Service</a> | &copy;{' '}
          {new Date().getFullYear()} Salata, All Rights Reserved
        </div>
      </Policy>
      <Social />
    </GridLayout>
  </Container>
);

Footer.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.shape(NavType))
};

export default Footer;
