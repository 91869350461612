import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  ButtonTypes,
  darkenColor,
  MailToLink,
  ReactRouterLink
} from '../../global';
import AnchorLink from '../AnchorLink';
import Arrows from './Arrows';
import Background from './Background';
import { genButton } from './utils';

// used to style below
const ButtonBackground = styled(Background)``;

const Link = styled.button`
  position: relative;
  z-index: 1;
  cursor: pointer;
`;

// used to style below
const LinkArrows = styled(Arrows)``;

// prettier-ignore
const Wrapper = styled.div`
  transition: color 0.2s ease;

  ${p => genButton(p)}

  &:hover {
    ${ButtonBackground} {
      svg {
        ${p => p.inverted ? `
          stroke: ${p.buttonType === 'primary' ? darkenColor(0.04, p.theme.color.avocado) : darkenColor(0.04, p.theme.color.redPepper)};
        ` : `
          fill: ${p.buttonType === 'primary' ? darkenColor(0.04, p.theme.color.avocado) : darkenColor(0.04, p.theme.color.redPepper)};
        `}
      }
    }
  }

  ${p => p.focused && `
    ${ButtonBackground} {
      svg {
        ${p.inverted ? `
          stroke: ${p.buttonType === 'primary' ? darkenColor(0.04, p.theme.color.avocado) : darkenColor(0.04, p.theme.color.redPepper)};
        ` : `
          fill: ${p.buttonType === 'primary' ? darkenColor(0.04, p.theme.color.avocado) : darkenColor(0.04, p.theme.color.redPepper)};
        `}
      }
    }
  `}

  &:active {
    ${ButtonBackground} {
      svg {
        ${p => p.inverted ? `
          stroke: ${p.buttonType === 'primary' ? p.theme.color.avocadoDark : p.theme.color.redPepperDark};
        ` : `
          fill: ${p.buttonType === 'primary' ? p.theme.color.avocadoDark : p.theme.color.redPepperDark};
        `}
      }
    }
  }

  ${p => p.inline && `
    &:hover {
      color: ${p.buttonType === 'primary' ? darkenColor(0.04, p.theme.color.avocado) : darkenColor(0.04, p.theme.color.redPepper)};

      ${LinkArrows} {
        transform: translateX(10px);
        svg {
          fill: ${p.buttonType === 'primary' ? darkenColor(0.04, p.theme.color.avocado) : darkenColor(0.04, p.theme.color.redPepper)};
        }
      }
    }

    &:active {
      color: ${p.buttonType === 'primary' ? p.theme.color.avocadoDark : p.theme.color.redPepperDark};

      ${LinkArrows} {
        svg {
          fill: ${p.buttonType === 'primary' ? p.theme.color.avocadoDark : p.theme.color.redPepperDark};
        }
      }
    }

    ${p.focused && `
      color: ${p.buttonType === 'primary' ? darkenColor(0.04, p.theme.color.avocado) : darkenColor(0.04, p.theme.color.redPepper)};

      ${LinkArrows} {
        transform: translateX(10px);
        svg {
          fill: ${p.buttonType === 'primary' ? darkenColor(0.04, p.theme.color.avocado) : darkenColor(0.04, p.theme.color.redPepper)};
        }
      }
    `}
  `}
`;

const Button = ({
  buttonExtraCss,
  buttonType,
  className,
  download,
  external,
  inline,
  inverted,
  mailto,
  onClick,
  small,
  title,
  tel,
  to,
  type = 'button',
  url,
  ...props
}) => {
  const [focused, setFocused] = useState(false);

  const path = url;
  const text = title;
  const button = {
    link: (
      <Link
        as={AnchorLink}
        download={download}
        external={external}
        mailto={mailto}
        path={path}
        tel={tel}
        type={type}
        href={to}
      >
        {text}{' '}
        {inline && <LinkArrows buttonType={buttonType} inverted={inverted} />}
      </Link>
    ),
    text: (
      <Link type={type}>
        {text}{' '}
        {inline && <LinkArrows buttonType={buttonType} inverted={inverted} />}
      </Link>
    )
  };

  return (
    <Wrapper
      buttonExtraCss={buttonExtraCss}
      buttonType={buttonType}
      className={className}
      focused={focused}
      inline={inline}
      inverted={inverted}
      onBlur={() => setFocused(false)}
      onClick={onClick}
      onFocus={() => setFocused(true)}
      small={small}
      {...props}
    >
      {!inline && (
        <ButtonBackground inverted={inverted} buttonType={buttonType} />
      )}
      {path || mailto || to ? button.link : button.text}
    </Wrapper>
  );
};

export const ButtonType = {
  buttonExtraCss: PropTypes.string,
  buttonType: PropTypes.oneOf(ButtonTypes),
  className: PropTypes.string,
  download: PropTypes.bool,
  external: PropTypes.bool,
  inline: PropTypes.bool,
  inverted: PropTypes.bool,
  mailto: PropTypes.shape(MailToLink),
  onClick: PropTypes.func,
  small: PropTypes.bool,
  tel: PropTypes.string,
  title: PropTypes.node.isRequired,
  to: PropTypes.shape(ReactRouterLink),
  type: PropTypes.string,
  url: PropTypes.string
};

Button.defaultProps = {
  buttonType: 'primary'
};

Button.propTypes = ButtonType;

export default Button;
