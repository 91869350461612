import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Intro, IntroType } from '../../../../components';
import { Button, ButtonType } from '../../../../elements';
import { getColumnSpanSize, media, P } from '../../../../global';
import { tabs } from './data';

// prettier-ignore
const Caret = styled.div`
  border: 1px solid ${p => p.theme.color.kaleLeaf};
  border-radius: 100%;
  flex: 0 0 auto;
  height: 16px;
  position: relative;
  transform: scale(0) translateY(5px);
  transition: transform 0.25s;
  width: 16px;

  &::before, &::after {
    background: ${p => p.theme.color.kaleLeaf};
    content: '';
    height: 2px;
    left: 4px;
    position: absolute;
    transform: rotate(45deg);
    width: 7px;
  }

  &::before {
    top: 4px;
  }

  &::after {
    bottom: 4px;
    transform: rotate(-45deg);
  }

  ${p => p.active && `
    transform: scale(1) translateY(5px);
  `}
`;

const ContentButton = styled(Button)`
  margin: 40px 0;
`;

const ContentIntro = styled(Intro)`
  max-width: none;
  text-align: left;

  + div {
    margin: 40px 0;
  }
`;

// prettier-ignore
const Tab = styled(P)`
  cursor: pointer;
  font-weight: ${p => p.theme.fontWeight.bold};
  padding-left: 25px;
  text-align: left;
  transition: color 0.25s;

  ${p => p.active && `
    color: ${p.theme.color.kaleLeaf};
  `}

  ${p => !p.active && `
    &:hover {
      color: ${p.theme.color.kaleLeafLight};
    }
  `}


  span {
    color: ${p => p.theme.color.peppercorn};
    font-weight: ${p => p.theme.fontWeight.regular};
  }
`;

const TabWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;

  ${media.down.lg`
    display: none;
  `}
`;

const Wrapper = styled.div`
  max-width: ${p => getColumnSpanSize(5, p.theme.gridSettings)}px;
  order: 2;
  text-align: left;
  width: 100%;

  ${media.down.lg`
    margin-bottom: 100px;
    max-width: none;
  `}
`;

const Content = ({ active, intro, link, setActive }) => (
  <Wrapper>
    <ContentIntro {...intro} />
    <ContentButton {...link} buttonType="secondary" inline />
    {tabs.map((tab, i) => (
      <TabWrapper key={i}>
        <Caret active={active === tab.value} />
        <Tab active={active === tab.value} onClick={() => setActive(tab.value)}>
          {tab.name} <span>- {tab.definition}</span>
        </Tab>
      </TabWrapper>
    ))}
  </Wrapper>
);

export const ContentType = {
  intro: PropTypes.shape(IntroType),
  link: PropTypes.shape(ButtonType)
};

Content.propTypes = {
  active: PropTypes.string,
  setActive: PropTypes.func,
  ...ContentType
};

export default Content;
