const imgPath = '/images/';
const hiwPath = `${imgPath}components/howitworks/`;
const menuPath = `${imgPath}/templates/menu/`;

export const desktopImage = `${menuPath}desktopline.png`;
export const fourDesktopLine = `${menuPath}fourDesktopLine.png`;
export const mobileImage = `${menuPath}mobileline.png`;

export const threeGridSteps = [
  {
    background: true,
    bodyText: 'Regular · Small · Wrap',
    calloutText: 'Choose your style',
    image: {
      alt: 'Salad Bowl',
      url: `${hiwPath}saladstyle.png`
    }
  },
  {
    background: true,
    bodyText: 'Over 50 Fresh Toppings',
    calloutText: 'Pick your ingredients',
    image: {
      alt: 'Avocados',
      url: `${hiwPath}avocados.png`
    }
  },
  {
    background: true,
    bodyText: "This one's for You",
    calloutText: 'Enjoy your creation',
    image: {
      alt: 'Woman Smiling',
      url: `${hiwPath}woman.png`
    }
  }
];

export const fourGridSteps = [
  {
    background: true,
    bodyText:
      'We will discuss your interest, experience, and potential marketplace as well as answer any questions you may have.',
    calloutText: 'initial call with salata',
    image: {
      alt: 'Salad Bowl',
      url: `${hiwPath}one.png`
    }
  },
  {
    background: true,
    bodyText:
      'Once your application has been submitted, you’ll review our Franchise Disclosure Document detailing the offering.',
    calloutText: 'franchise application',
    image: {
      alt: 'Avocados',
      url: `${hiwPath}two.png`
    }
  },
  {
    background: true,
    bodyText:
      'You’ll be invited to meet with the leadership team in Houston, where you’ll learn more about our goals as a company.',
    calloutText: 'meeting with leadership',
    image: {
      alt: 'Woman Smiling',
      url: `${hiwPath}three.png`
    }
  },
  {
    background: true,
    bodyText:
      "Once you're approved as a franchisee and sign the necessary agreements, you'll officially be a member of the Salata family.",
    calloutText: 'final agreement forms',
    image: {
      alt: 'Woman Smiling',
      url: `${hiwPath}four.png`
    }
  }
];

export const linkData = {
  buttonType: 'primary',
  inline: false,
  title: 'Start Customizing',
  url: '/'
};
