import React from 'react';
import styled from 'styled-components';
import { Arrow } from '../../global';

// prettier-ignore
const CarouselArrow = styled(Arrow)`
  cursor: pointer;
  left: -9px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;

  g {
    transform: translate(2px, 2px);
  }

  ${p => p.reverse && `
    left: auto;
    right: -9px;

    g {
      transform: rotate(180deg) translate(-46px, -46px);
    }
  `}
`;

export const sliderSettings = {
  dots: false,
  nextArrow: <CarouselArrow reverse />,
  prevArrow: <CarouselArrow />,
  responsive: [
    {
      breakpoint: 1023,
      settings: {
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 2
      }
    },
    {
      breakpoint: 666,
      settings: {
        slidesToShow: 1
      }
    }
  ],
  slidesToScroll: 1,
  slidesToShow: 6,
  speed: 500
};
