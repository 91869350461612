import React from 'react';
import styled from 'styled-components';
import { media } from '../../global';

import { Callout } from './components';

const format = phone => {
  return phone.replace(/-|\s|\(|\)/g, '');
};

const Address = styled.address`
  flex: 1 1 auto;
  font-style: normal;
  line-height: 1.5;

  ${media.down.md`
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    margin-top: 56px;
  `}
`;

const Heading = styled(Callout)`
  display: block;
  line-height: 1;
  margin-bottom: 9px;
`;

const EmailAnchor = styled.a.attrs(p => ({ href: `mailto:${p.children}` }))`
  color: inherit;
  text-decoration: underline;
`;

const PhoneAnchor = styled.a.attrs(p => ({
  href: `tel:${format(p.children)}`
}))`
  color: inherit;
  text-decoration: underline;
`;

export default () => (
  <Address>
    <Heading>Salata Home Office</Heading>
    16720 Park Row Dr
    <br />
    Houston, TX 77084
    <br />
    <br />
    Phone: <PhoneAnchor>(844) 725-2821</PhoneAnchor>
    <br />
    <EmailAnchor>contact@salata.com</EmailAnchor>
  </Address>
);
