import { fontGen } from '../../global';

export const genButton = ({
  buttonExtraCss,
  buttonType,
  inline,
  inverted,
  small,
  theme
}) => {
  let styles = `
    ${fontGen(theme.type.button)}
    display: inline-block;
    position: relative;
  `;

  if (!inline) {
    if (buttonType === 'primary' || buttonType === 'secondary') {
      if (inverted) {
        // prettier-ignore
        styles += `
          color: ${buttonType === 'primary'
    ? theme.color.avocado
    : theme.color.redPepper};
        `;
      } else {
        styles += `color: ${theme.color.white};`;
      }

      styles += `
        a,
        span,
        button {
          color: inherit;
          font: inherit;
          text-transform: inherit;
          letter-spacing: inherit;
          padding: ${small ? '14px 33px' : '20px 20px 18px'};
        }
      `;
    }
  } else {
    // prettier-ignore
    styles += `
      color: ${buttonType === 'primary'
    ? theme.color.avocado
    : theme.color.redPepper};
      font-size: 16px;
      font-weight: ${theme.fontWeight.regular};
      letter-spacing: 2px;

      ${small && `
        button {
          padding: 14px 33px;
        }
      `}
    `;
  }

  if (buttonExtraCss) {
    styles += `
      button {
        ${buttonExtraCss}
      }
    `;
  }

  return styles;
};
