import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Intro } from '../../../components';
import { ButtonType } from '../../../elements';
import { media } from '../../../global';

const Content = styled(Intro)`
  text-align: left;
`;

const Wrapper = styled.div`
  display: block;
  padding: 110px 0 80px;

  ${media.down.sm`
    padding: 0 25px 80px;
  `}
`;

const Header = ({ link, linkProps, mainHeadline, wysiwyg }) => (
  <Wrapper>
    <Content
      linkProps={linkProps}
      links={[link]}
      mainHeadline={mainHeadline}
      wysiwyg={wysiwyg}
    />
  </Wrapper>
);

export const HeaderType = {
  link: PropTypes.shape(ButtonType).isRequired,
  linkProps: PropTypes.object,
  mainHeadline: PropTypes.string.isRequired,
  wysiwyg: PropTypes.string.isRequired
};

Header.propTypes = HeaderType;

export default Header;
