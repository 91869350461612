import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Hidden, Menu } from '../../../global';
import {
  NavContainer,
  NavGroup,
  SiteLogo,
  Spacer,
  Wrapper
} from './components';
import FindLocationButton from './FindLocationButton';
import Nav from './Nav';
import OrderNowButton from './OrderNowButton';

const TopNavigation = ({ menu, onFinderClick, order, withSpace = true }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [clicked, setClicked] = useState(false);

  const toggleExpanded = () => () => {
    setClicked(true);
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (!isExpanded && clicked) setClicked(false);

    if (window) {
      const body = document.body;
      const html = document.getElementsByTagName('html')[0];

      if (body && html) {
        if (isExpanded) {
          body.style.overflow = 'hidden';
          html.style.overflow = 'hidden';
        } else {
          body.style.overflow = '';
          html.style.overflow = '';
        }
      }
    }
  }, [clicked, isExpanded]);

  return (
    <>
      <Wrapper>
        <NavContainer>
          <Hidden up="lg">
            <Menu
              clicked={clicked}
              isExpanded={isExpanded}
              onClick={toggleExpanded()}
            />
          </Hidden>

          <NavGroup>
            <a href="/">
              <SiteLogo />
            </a>

            <Nav isExpanded={isExpanded} menu={menu} order={order} />
          </NavGroup>

          <NavGroup>
            <FindLocationButton onClick={onFinderClick} />
            <OrderNowButton order={order} />
          </NavGroup>
        </NavContainer>
      </Wrapper>

      {withSpace && <Spacer />}
    </>
  );
};

TopNavigation.propTypes = {
  // See Nav.js for usage of menu prop
  menu: PropTypes.array.isRequired,
  // Handle click to show Salata store finder dropdown
  onFinderClick: PropTypes.func.isRequired,
  order: PropTypes.array,
  // if true, adds an extra empty div so content doesn't go under the navigation
  withSpace: PropTypes.bool
};

export default TopNavigation;
