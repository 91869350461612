import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, ImageType } from '../../elements';
import { H4, media, P, toEms } from '../../global';

const Anchor = styled.a`
  width: 100%;
`;

// prettier-ignore
const ContentContainer = styled.div`
  ${media.down.md`
    padding-top: ${toEms(13)};
    width: 100%;
  `}

  ${p => !p.fullSize && `
    padding-top: ${toEms(13)};
  `}

  ${media.up.md`
    ${p => p.fullSize && `
      flex: 1 1 40%;
      padding-left: ${toEms(32)};
    `}
  `}
`;

const Excerpt = styled(P)`
  margin-top: ${toEms(8)};
`;

// prettier-ignore
const ImageContainer = styled.div`
  cursor: pointer;
  background: url(${p => p.imgSrc});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 200px;
  transition: ${({ theme }) =>
    `opacity ${theme.timing.default} ${theme.easing.ease}`};
  width: 100%;

  :hover {
    opacity: 0.7;
  }

  ${p => media.up.md`
    ${p.fullSize && `
      flex: 0 0 60%;
      height: 350px;
    `}
  `}

  ${media.down.md`
    height: 53vw;
  `}
`;

const ReadMoreButton = styled(Button).attrs({
  buttonType: 'secondary',
  inline: true,
  title: 'Read more'
})`
  margin-top: ${toEms(16)};
`;

// prettier-ignore
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${p => p.fullSize && media.up.md`
    flex-direction: row;
  `}
`;

const Post = ({ className, excerpt, fullSize, id, image, path, title }) => (
  <Wrapper fullSize={fullSize} className={className}>
    <Anchor href={path}>
      <ImageContainer fullSize={fullSize} imgSrc={image && image.url} />
    </Anchor>

    <ContentContainer fullSize={fullSize}>
      <Anchor href={path}>
        <H4 dangerouslySetInnerHTML={{ __html: title }} />
        {excerpt && <Excerpt dangerouslySetInnerHTML={{ __html: excerpt }} />}
      </Anchor>

      <ReadMoreButton url={path} />
    </ContentContainer>
  </Wrapper>
);

Post.propTypes = {
  className: PropTypes.string,
  excerpt: PropTypes.string,
  fullSize: PropTypes.bool,
  id: PropTypes.number.isRequired,
  image: PropTypes.shape(ImageType),
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default Post;
