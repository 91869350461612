import React from 'react';
import PropTypes from 'prop-types';

import { Intro, Section } from '../../components';
import { PageType } from '../../global';
import BaseLayout from '../../layouts/Base';
import { FeaturedContent, FeaturedContentType } from '../../sections';

const GiftCard = ({ acf, ...layoutProps }) => {
  const { giftCard } = acf;

  return (
    <BaseLayout withoutFooter {...layoutProps}>
      <Section>
        {giftCard && (
          <FeaturedContent {...giftCard.content}>
            <Intro alignment="left" {...giftCard.intro} />
          </FeaturedContent>
        )}
      </Section>
    </BaseLayout>
  );
};

GiftCard.propTypes = {
  ...PageType,
  acf: PropTypes.shape({
    giftCard: PropTypes.shape(FeaturedContentType)
  }).isRequired
};

export default GiftCard;
