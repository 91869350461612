import 'react-dates/initialize';

import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DayPickerSingleDateController } from 'react-dates';
import OutsideClickHandler from 'react-outside-click-handler';

import { Input } from './components';
import { datePickerCSS } from '../../global';

const ControlWrapper = styled.div`
  width: 100%;
  z-index: 1;
`;

const PickerWrapper = styled(Input).attrs({ as: 'div' })`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: absolute;
  transform: translateY(4px);

  ${datePickerCSS}

  .DayPicker {
    box-shadow: 0 0 8px 0 ${p => p.theme.color.peppercornLight};
    position: absolute;
    top: 0;
    left: 0;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background-color: ${p => p.theme.color.avocado};
    border-color: ${p => p.theme.color.avocado};
  }
`;

const DateInput = ({
  format = 'M/D/YYYY',
  name = '',
  onChange,
  setFieldValue,
  value,
  ...props
}) => {
  const [isPickerVisible, setPickerVisibility] = useState(false);

  // Try coercing the date to a valid input while also allowing the user to
  // change the value manually.
  const rawValue = moment(value, format);
  const parsedValue = rawValue.toJSON() === null ? moment() : rawValue;
  const formattedValue = parsedValue.format(format);

  return (
    <ControlWrapper>
      {/* Detect clicks outside so we can fake a blur event */}
      <OutsideClickHandler
        disabled={!isPickerVisible}
        onOutsideClick={() => setPickerVisibility(false)}
      >
        <Input
          name={name}
          onChange={onChange}
          onFocus={() => setPickerVisibility(true)}
          value={formattedValue}
          {...props}
          type="text"
        />

        {isPickerVisible && (
          <PickerWrapper>
            <DayPickerSingleDateController
              date={moment(value)}
              hideKeyboardShortcutsPanel
              noBorder
              numberOfMonths={1}
              onDateChange={date => {
                setPickerVisibility(false);
                const nextValue = date.format(format);
                setFieldValue
                  ? setFieldValue(name, nextValue)
                  : onChange(nextValue);
              }}
            />
          </PickerWrapper>
        )}
      </OutsideClickHandler>
    </ControlWrapper>
  );
};

DateInput.propTypes = {
  format: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.number,
    PropTypes.string
  ])
};

export default DateInput;
