import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Content from './Content';
import Nav, { TabType } from './Nav';

const ContentWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

class Tabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0
    };
  }

  handleTabChange = index => () => {
    this.setState({ activeTab: index });
  };

  render() {
    const { tabs } = this.props;
    const { activeTab } = this.state;
    return (
      <Wrapper>
        <Nav
          activeTab={activeTab}
          handleTabChange={this.handleTabChange}
          tabs={tabs}
        />
        <ContentWrapper>
          {tabs.map((tab, i) => {
            return (
              <Content
                active={activeTab === i}
                key={i}
                tabIndex={i}
                toppings={tab.toppings}
              />
            );
          })}
        </ContentWrapper>
      </Wrapper>
    );
  }
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape(TabType)).isRequired
};

export default Tabs;
