import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AnchorLink } from '../../elements';
import { H2, H5, media, P, PageType } from '../../global';
import ContentWithSidebar from '../../layouts/ContentWithSidebar';

const NavItem = styled(AnchorLink)`
  color: ${p => p.theme.color.redPepperLight};
  display: block;
  padding: 15px 0 15px 35px;
  position: relative;
  text-decoration: none;

  &::before {
    background: ${p => p.theme.color.redPepperLight};
    border-radius: 100%;
    content: '';
    height: 3px;
    left: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
  }
`;

const Paragraph = styled(P)`
  a {
    text-decoration: none;
  }
`;

const SubTitle = styled(H5)`
  margin: 25px 0 15px 0;
  text-transform: lowercase;
`;

const Title = styled(H2)`
  margin-bottom: 25px;
`;

const Wrapper = styled.div`
  padding: 120px 0 120px 100px;
  text-align: left;

  ${media.down.lg`
    padding: 60px 0;
  `}
`;

const SiteMap = ({ siteMapNavigation, ...layoutProps }) => {
  const mainContent = '';

  const sidebarContent = (
    <Wrapper>
      <Title>site map</Title>
      {siteMapNavigation.map((link, i) => {
        const hasChildren = link.children;

        return hasChildren ? (
          <div key={i}>
            <SubTitle>{link.title}</SubTitle>
            {link.children.map((child, i) => (
              <Paragraph key={i}>
                <NavItem path={child.path}>{child.title}</NavItem>
              </Paragraph>
            ))}
          </div>
        ) : (
          <Paragraph key={i}>
            <NavItem path={link.path}>{link.title}</NavItem>
          </Paragraph>
        );
      })}
    </Wrapper>
  );

  return (
    <ContentWithSidebar
      {...layoutProps}
      mainContent={mainContent}
      sidebarContent={sidebarContent}
    />
  );
};

SiteMap.propTypes = {
  siteMapNavigation: PropTypes.arrayOf(PropTypes.object),
  ...PageType
};

export default SiteMap;
