import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media, P } from '../../../../global';

const Name = styled(P)`
  text-align: left;
  flex: 0 1 ${(1 / 3) * 100}%;

  ${media.down.md`
    flex: 0 1 50%;
  `}
`;

const Toppings = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 30px;

  ${media.down.sm`
    padding: 25px;
  `}
`;

// prettier-ignore
const Wrapper = styled.div`
  border-top: 1px solid ${p => p.theme.color.peppercornLight};
  border-bottom: 1px solid ${p => p.theme.color.peppercornLight};
  flex: 1 0 100%;
  transform: ${p => `translateX(-${p.offsetMultiplier * 100}%)`};

  ${p => p.active ? `
    opacity: 1;
    visibility: visible;
  ` : `
    opacity: 0;
    visibility: hidden;
  `}
`;

const Content = ({ active, tabIndex, toppings }) => (
  <Wrapper active={active} offsetMultiplier={tabIndex}>
    <Toppings>
      {toppings.map((topping, i) => (
        <Name key={i}>{topping}</Name>
      ))}
    </Toppings>
  </Wrapper>
);

Content.propTypes = {
  active: PropTypes.bool,
  tabIndex: PropTypes.number,
  toppings: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Content;
