import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { darken, em, retinaImage, rgba, stripUnit } from 'polished';

// Constants for knowing if rendering is happening on the server or in the browser
export const isServer = typeof window === 'undefined';
export const isBrowser = !isServer;

export const sizes = {
  down: {
    lg: 1023,
    md: 959,
    sm: 666,
    xl: 1439,
    xs: 479,
    xxl: 1949
  },
  up: {
    lg: 1024,
    md: 960,
    sm: 667,
    xl: 1440,
    xs: 480,
    xxl: 1950
  }
};

// use em in breakpoints to work properly cross-browser and support users
// changing their browsers font-size: https://zellwk.com/blog/media-query-units
// prettier-ignore
export const media = {
  down: Object.keys(sizes.down).reduce((accumulator, label) => {
    const emSize = sizes.down[label] / 16;
    accumulator[label] = (...args) => css`
      @media (max-width: ${emSize}em) {
        ${css(...args)}
      }
    `;
    return accumulator;
  }, {}),
  up: Object.keys(sizes.up).reduce((accumulator, label) => {
    const emSize = sizes.up[label] / 16;
    accumulator[label] = (...args) => css`
      @media (min-width: ${emSize}em) {
        ${css(...args)}
      }
    `;
    return accumulator;
  }, {})
};

export const retinaBackground = filename =>
  retinaImage(filename, undefined, 'png', undefined, '@2x');

export const Hidden = styled.div`
  cursor: pointer;
  ${p => p.up && media.up[p.up]`display: none;`}
  ${p => p.down && media.down[p.down]`display: none;`}
`;

export const truncate = width => `
  width: ${width};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const toUnitless = value => Number(stripUnit(value));

export const toEms = value => em(value);

export const isPx = value => {
  return value.toString().indexOf('px') > -1;
};

export const convertLh = (value, fontSize) => {
  let result = value;

  if (isPx(fontSize)) {
    result = toUnitless(fontSize);
    result = toUnitless(value) / result;
    result = Math.round(result * 100) / 100;
    return result;
  }

  return toUnitless(result);
};

export const fontGen = font => `
  font-family: ${font.fontFamily};
  font-weight: ${font.fontWeight};
  font-size: ${toEms(font.fontSize)};
  line-height: ${convertLh(font.lineHeight, font.fontSize)};
  letter-spacing: ${font.letterSpacing};
  text-transform: ${font.textTransform};
`;

/**
 * A wrapper around `navigator.geolocation.getCurrentPosition()` that uses
 * Promises and returns only the coordinates.
 * @param {PositionOptions} options
 */
export const getCurrentGeolocation = options =>
  new Promise((resolve, reject) => {
    const GenericLocationError = Error('Could not get current location');

    if (!('geolocation' in navigator)) reject(GenericLocationError);

    navigator.geolocation.getCurrentPosition(
      ({ coords }) => resolve(coords),
      () => reject(GenericLocationError),
      options
    );
  });

export const getPercentValue = (size, container) => {
  return (size / container) * 100;
};

export const setWidth = (width, parentContext = 1210) => {
  return `${(width / parentContext) * 100}%`;
};

export const maintainRatio = (width, height) => {
  const padding = (height / width) * 100;

  return `
    position: relative;

    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: ${padding}%;
    }

    > * {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
    }
  `;
};

export const darkenColor = (intensity, color) => darken(intensity, color);

export const matchColor = (color, colors) => {
  const selectedColors = {
    blue: shadeOf(colors.blueCheeseLight, 0.8),
    blueDark: colors.blueCheeseDark,
    green: colors.avocado,
    greenLight: shadeOf(colors.avocado, 0.15),
    orange: shadeOf(colors.corn, 0.25),
    pink: shadeOf(colors.redPepperLight, 0.12),
    red: colors.redPepper,
    redLight: colors.redPepperLight,
    tan: colors.feta,
    transparent: colors.transparent,
    white: colors.white
  };
  return selectedColors[color];
};

export const setBackgroundColor = (backgroundColor, colors) => {
  const bgColor = matchColor(backgroundColor, colors);
  return `background-color: ${bgColor};`;
};

export const shadeOf = (color, opacity) => rgba(color, opacity);

export const parseContent = content => {
  return {
    __html: content
  };
};

export const getColumnSpanSize = (
  columnCount,
  { columnWidth, gutterWidth }
) => {
  const columnTotal = columnCount * columnWidth;
  const gutterTotal = (columnCount - 1) * gutterWidth;
  return columnTotal + gutterTotal;
};

export const setColumnWidth = (columns, gridSettings) => {
  const { maxWidth } = gridSettings;
  const totalWidth = getColumnSpanSize(columns, gridSettings);
  return `${getPercentValue(totalWidth, maxWidth)}%`;
};

export const setVerticalSpacing = ({
  halfBottomPadding,
  halfPadding,
  halfTopPadding,
  noBottomPadding,
  noPadding,
  noTopPadding,
  theme
}) => {
  const { spacing } = theme;
  let styles = `padding: ${spacing.verticalPadding} 20px;`;

  if (halfBottomPadding) {
    styles += `padding-bottom: ${spacing.verticalPaddingHalf};`;
  }
  if (halfTopPadding) {
    styles += `padding-top: ${spacing.verticalPaddingHalf};`;
  }
  if (halfPadding) {
    styles += `padding: ${spacing.verticalPaddingHalf} 20px;`;
  }
  if (noBottomPadding) {
    styles += `padding-bottom: 0;`;
  }
  if (noTopPadding) {
    styles += `padding-top: 0;`;
  }
  if (noPadding) {
    styles += `padding: 0 20px;`;
  }

  return styles;
};

/**
 * Allows you to curry an existing function without having to make a new const
 * @param {function} func - function to call
 * @param {any} arg - argument passed into func
 */
export const curryHandler = (func, arg) => () => {
  func(arg);
};

export const sendAnalytics = (action, category, label) => {
  /* eslint-disable no-undef */
  ga('send', {
    eventAction: action,
    eventCategory: category,
    eventLabel: label,
    hitType: 'event'
  });
  /* eslint-enable no-undef */
};

/**
 * Creates an Object from key/value pairs; opposite of Object.entries
 * @param {Array[]} pairs - key/value pairs to combine
 * @return {Object}
 */
export const fromEntries = pairs =>
  Array.from(pairs).reduce(
    (obj, [key, value]) => ({ ...obj, [key]: value }),
    {}
  );

/**
 * Returns a copy of an object with the specified properties removed
 * @param {String[]} blacklist - properties to remove
 * @param {Object} obj - the object to copy
 */
export const omit = (blacklist, obj) =>
  fromEntries(Object.entries(obj).filter(([key]) => !blacklist.includes(key)));

/**
 * Removes props from a component that blindly passes all props to the DOM
 * @param {String[]} blacklist - props to remove
 */
export const omitProps = blacklist => Component => props => {
  const enabledProps = omit(blacklist, props);

  return <Component {...enabledProps} />;
};

/**
 * Returns a state value representing if the component has mounted
 */
export const useHasMounted = () => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => setHasMounted(true), [setHasMounted]);
  return hasMounted;
};
