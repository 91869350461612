import React from 'react';

export default props => (
  <svg viewBox="0 0 261 70" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#8DBB3F"
        d="M95.438 69.163l-3.433.55-5.781-36.382 3.43-.55 5.784 36.382"
      />
      <path
        stroke="#8DBB3F"
        strokeWidth=".5"
        d="M95.438 69.163l-3.433.55-5.781-36.382 3.43-.55z"
      />
      <path
        fill="#8DBB3F"
        d="M108.922 68.091l-3.399-.725 5.694-26.862 3.402.718-5.697 26.869"
      />
      <path
        stroke="#8DBB3F"
        strokeWidth=".5"
        d="M108.922 68.091l-3.399-.725 5.694-26.862 3.402.718z"
      />
      <path
        d="M76.829 54.747c-4.22 6.77-11.331 10.328-19.465 10.328-13.233 0-23.178-9.987-23.178-23.101 0-13.121 9.973-23.094 23.178-23.094 4.763 0 9.422 1.33 13.385 3.99 2.348 1.581 4.604 3.761 6.08 6.276v-9.215h3.472v44.093h-3.472v-9.277zm.241-12.773c0-11.268-8.424-19.786-19.706-19.786-11.258 0-19.748 8.49-19.706 19.786.017 4.861 1.733 9.771 4.951 13.441 3.866 4.409 9.211 6.345 14.995 6.345 11.185 0 19.466-8.727 19.466-19.786z"
        fill="#0A0B09"
      />
      <path
        d="M76.829 54.747c-4.22 6.77-11.331 10.328-19.465 10.328-13.233 0-23.178-9.987-23.178-23.101 0-13.121 9.973-23.094 23.178-23.094 4.763 0 9.422 1.33 13.385 3.99 2.348 1.581 4.604 3.761 6.08 6.276v-9.215h3.472v44.093h-3.472v-9.277zm.241-12.773c0-11.268-8.424-19.786-19.706-19.786-11.258 0-19.748 8.49-19.706 19.786.017 4.861 1.733 9.771 4.951 13.441 3.866 4.409 9.211 6.345 14.995 6.345 11.185 0 19.466-8.727 19.466-19.786z"
        stroke="#0A0B09"
        strokeWidth=".5"
      />
      <path
        d="M162.604 54.789c-4.217 6.769-11.327 10.328-19.462 10.328-13.23 0-23.181-9.994-23.181-23.101 0-13.128 9.973-23.102 23.181-23.102 4.764 0 9.423 1.338 13.386 3.998 2.346 1.574 4.599 3.754 6.076 6.275v-9.22h3.475v44.099h-3.475v-9.277zm.244-12.773c0-11.269-8.42-19.786-19.706-19.786-11.262 0-19.748 8.482-19.706 19.786.018 4.854 1.734 9.771 4.952 13.434 3.861 4.409 9.214 6.352 14.997 6.352 11.186 0 19.463-8.727 19.463-19.786z"
        fill="#0A0B09"
      />
      <path
        d="M162.604 54.789c-4.217 6.769-11.327 10.328-19.462 10.328-13.23 0-23.181-9.994-23.181-23.101 0-13.128 9.973-23.102 23.181-23.102 4.764 0 9.423 1.338 13.386 3.998 2.346 1.574 4.599 3.754 6.076 6.275v-9.22h3.475v44.099h-3.475v-9.277zm.244-12.773c0-11.269-8.42-19.786-19.706-19.786-11.262 0-19.748 8.482-19.706 19.786.018 4.854 1.734 9.771 4.952 13.434 3.861 4.409 9.214 6.352 14.997 6.352 11.186 0 19.463-8.727 19.463-19.786z"
        stroke="#0A0B09"
        strokeWidth=".5"
      />
      <path
        d="M241.101 54.781c-4.217 6.77-11.331 10.336-19.466 10.336-13.228 0-23.181-9.994-23.181-23.101 0-13.128 9.973-23.102 23.181-23.102 4.764 0 9.423 1.338 13.386 3.998 2.348 1.574 4.6 3.754 6.08 6.275v-9.22h3.472v44.099h-3.472v-9.285zm.24-12.765c0-11.269-8.42-19.786-19.706-19.786-11.262 0-19.748 8.482-19.706 19.786.018 4.854 1.734 9.771 4.952 13.434 3.862 4.409 9.214 6.352 14.998 6.352 11.185 0 19.462-8.727 19.462-19.786z"
        fill="#0A0B09"
      />
      <path
        d="M241.101 54.781c-4.217 6.77-11.331 10.336-19.466 10.336-13.228 0-23.181-9.994-23.181-23.101 0-13.128 9.973-23.102 23.181-23.102 4.764 0 9.423 1.338 13.386 3.998 2.348 1.574 4.6 3.754 6.08 6.275v-9.22h3.472v44.099h-3.472v-9.285zm.24-12.765c0-11.269-8.42-19.786-19.706-19.786-11.262 0-19.748 8.482-19.706 19.786.018 4.854 1.734 9.771 4.952 13.434 3.862 4.409 9.214 6.352 14.998 6.352 11.185 0 19.462-8.727 19.462-19.786z"
        stroke="#0A0B09"
        strokeWidth=".5"
      />
      <path
        d="M3.751 51.592c0 6.254 4.123 10.238 10.322 10.238 5.648 0 9.83-4.165 9.67-9.813-.178-6.31-4.189-8.448-9.921-9.716-6.554-1.887-11.749-3.879-12.007-11.666 0-7.193 5.244-11.734 12.261-11.692 7.079.048 11.927 4.561 11.927 11.776v.6h-3.472v-.6c0-5.251-3.249-8.552-8.458-8.461-4.935 0-9.03 3.189-8.787 8.35.262 5.488 4.211 6.958 8.947 8.267 7.229 1.991 12.501 3.984 12.985 12.766.414 7.577-5.659 13.504-13.145 13.504-7.651 0-14.288-5.614-13.797-13.588l.038-.57h3.437v.605"
        fill="#0A0B09"
      />
      <path
        d="M3.751 51.592c0 6.254 4.123 10.238 10.322 10.238 5.648 0 9.83-4.165 9.67-9.813-.178-6.31-4.189-8.448-9.921-9.716-6.554-1.887-11.749-3.879-12.007-11.666 0-7.193 5.244-11.734 12.261-11.692 7.079.048 11.927 4.561 11.927 11.776v.6h-3.472v-.6c0-5.251-3.249-8.552-8.458-8.461-4.935 0-9.03 3.189-8.787 8.35.262 5.488 4.211 6.958 8.947 8.267 7.229 1.991 12.501 3.984 12.985 12.766.414 7.577-5.659 13.504-13.145 13.504-7.651 0-14.288-5.614-13.797-13.588l.038-.57h3.437v.605z"
        stroke="#0A0B09"
        strokeWidth=".5"
      />
      <path fill="#0A0B09" d="M99.119 64.051h3.476V.347h-3.476z" />
      <path
        stroke="#0A0B09"
        strokeWidth=".5"
        d="M99.119 64.051h3.476V.347h-3.476z"
      />
      <path
        fill="#0A0B09"
        d="M185.385 19.967h11.007v3.314h-11.007v40.785h-3.475V23.281h-9.468v-3.314h9.468V2.973h3.475v16.994"
      />
      <path
        stroke="#0A0B09"
        strokeWidth=".5"
        d="M185.385 19.967h11.007v3.314h-11.007v40.785h-3.475V23.281h-9.468v-3.314h9.468V2.973h3.475z"
      />
      <path
        fill="#1B1A19"
        d="M250.881 20.681h-1.986v-.717h4.835v.717h-1.996v5.814h-.853v-5.814M259.962 23.627c-.049-.911-.106-2.006-.097-2.82h-.029a36.972 36.972 0 0 1-.824 2.481l-1.152 3.168h-.64l-1.057-3.11c-.309-.92-.571-1.764-.755-2.539h-.019a87.516 87.516 0 0 1-.126 2.888l-.175 2.8h-.804l.456-6.531h1.075l1.114 3.159c.272.804.494 1.521.659 2.199h.03a24.58 24.58 0 0 1 .687-2.199l1.163-3.159h1.075l.408 6.531h-.825l-.164-2.868"
      />
    </g>
  </svg>
);
