import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Intro, IntroType } from '../../components';
import { media, setColumnWidth } from '../../global';

import Dressing, { DressingType } from './Dressing';

const DressingsIntro = styled(Intro)`
  ${p => media.up.md`
    max-width: ${setColumnWidth(8, p.theme.gridSettings)};
    padding-top: 25px;
  `}

  ${media.down.sm`
    text-align: left;
  `}
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: ${p => setColumnWidth(10, p.theme.gridSettings)};

  ${media.down.md`
    max-width: 100%;
  `}

  ${media.down.sm`
    display: block;
  `}
`;

const Dressings = ({ intro, names }) => (
  <Fragment>
    <DressingsIntro {...intro} />
    <Content>
      {names.map((name, i) => (
        <Dressing key={i} {...name} />
      ))}
    </Content>
  </Fragment>
);

Dressings.propTypes = {
  intro: PropTypes.shape(IntroType).isRequired,
  names: PropTypes.arrayOf(PropTypes.shape(DressingType)).isRequired
};

export default Dressings;
