import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// utilities
import { getPercentValue, media, shadeOf } from '../../../global';
import { setColumnWidth } from './utils';

// prettier-ignore
const Wrapper = styled.div`
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 ${p => setColumnWidth(p.columns, p.theme.gridSettings)};
  justify-content: center;
  padding: ${p => `${getPercentValue((p.theme.gridSettings.gutterWidth / 2), p.theme.gridSettings.maxWidth)}%`};
  position: relative;

  ${p => p.dividers && `
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: ${shadeOf(p.theme.color.peppercornLight, 0.5)};
      display: block;
      transform: translateX(-5px);
    }
  `}

  ${p => media.up.sm`
    ${p.offsetColumns && `
      &:nth-child(odd) {
        padding-top: 120px;
      }
    `}
  `}

  &:first-child {
    padding-left: 0;

    &::before {
      content: none;
    }
  }

  &:last-child {
    padding-right: 0;
  }

  ${p => p.center && `
    text-align: center;
  `}

  ${p => media.down.lg`

    ${p.columns === 4 && `
      padding: ${getPercentValue((p.theme.gridSettings.gutterWidth / 2), p.theme.gridSettings.maxWidth)};
    `}
  `}

  ${p => media.down.md`
    ${p.columns === 3 && `
      flex: 0 1 100%;
      padding: 40px ${getPercentValue((p.theme.gridSettings.gutterWidth), p.theme.gridSettings.maxWidth)}px;
    `}

    &::before {
      content: none;
    }
  `}

  ${p => media.down.sm`
    flex: 0 1 100%;
    padding: 40px 0;
  `}
`;

const Column = ({
  center,
  columns,
  component,
  data,
  dividers,
  linkProps,
  offsetColumns
}) => {
  const Component = component;
  return (
    <Wrapper
      center={center}
      columns={columns}
      dividers={dividers}
      offsetColumns={offsetColumns}
    >
      <Component {...data} linkProps={linkProps} />
    </Wrapper>
  );
};

export const componentType = [
  PropTypes.func,
  PropTypes.object,
  PropTypes.string
];

Column.propTypes = {
  center: PropTypes.bool,
  columns: PropTypes.number,
  component: PropTypes.oneOfType(componentType),
  data: PropTypes.object,
  dividers: PropTypes.bool,
  linkProps: PropTypes.object,
  offsetColumns: PropTypes.bool
};

export default Column;
