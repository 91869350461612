import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { darkenColor, Hidden, MapMarker, media } from '../../../global';

const Anchor = styled.a`
  align-items: center;
  color: ${p => p.theme.color.kaleLeaf};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  line-height: 2;
  user-select: none;

  &:hover,
  &:focus {
    color: ${p => darkenColor(0.04, p.theme.color.kaleLeaf)};
  }

  svg {
    pointer-events: none;
  }
`;

const LocationLogo = styled(MapMarker)`
  height: 21px;
  /* align logo with text */
  margin-bottom: 2px;
  width: 17px;

  ${media.up.lg`
    height: 15px;
    width: calc(21px / 17 * 15);
  `}
`;

const Text = styled(Hidden).attrs({
  as: 'span',
  down: 'lg'
})`
  padding-left: 0.4em;
  font-family: ${p => p.theme.fontFamily.primary};
  pointer-events: none;
  text-transform: uppercase;
`;

const FindLocationButton = ({ onClick }) => (
  <Anchor href="https://order.salata.com/locations">
    <LocationLogo />
    <Text>Find my Salata</Text>
  </Anchor>
);

FindLocationButton.propTypes = {
  onClick: PropTypes.func
};

export default FindLocationButton;
