import React from 'react';
import PropTypes from 'prop-types';

import { Card, Section } from '../../components';
import { PageType } from '../../global';
import BaseLayout from '../../layouts/Base';
import {
  EmployeeHighlights,
  FeaturedContent,
  Hero,
  MultiFeature,
  Positions
} from '../../sections';

const Careers = ({ acf, ...layoutProps }) => {
  const { applyNow, employeeHighlights, goodLife, hero, positions } = acf;

  return (
    <BaseLayout footerDivider {...layoutProps}>
      {hero && <Hero dividerBottomColor="white" {...hero} />}
      {applyNow && (
        <Section halfBottomPadding halfTopPadding>
          <FeaturedContent alignment="right" {...applyNow} reverseImageMobile />
        </Section>
      )}
      {goodLife && (
        <Section insetDividerBottom insetDividerTop noPadding>
          <MultiFeature {...goodLife} columns={2} component={Card} />
        </Section>
      )}
      {positions && (
        <Section
          bgColor="greenLight"
          dividerBottomColor="white"
          dividerTopColor="white"
          halfTopPadding
        >
          <Positions {...positions} />
        </Section>
      )}
      {employeeHighlights && (
        <Section intro={employeeHighlights.intro}>
          <EmployeeHighlights {...employeeHighlights.content} />
        </Section>
      )}
    </BaseLayout>
  );
};

Careers.propTypes = {
  ...PageType,
  acf: PropTypes.shape({
    applyNow: PropTypes.object,
    coreValues: PropTypes.object,
    employeeHighlights: PropTypes.object,
    goodLife: PropTypes.object,
    hero: PropTypes.object,
    positions: PropTypes.object
  }).isRequired
};

export default Careers;
