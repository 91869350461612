import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Image } from '../../elements';
import { media, P, setColumnWidth } from '../../global';

const imgPath = `${process.env.RAZZLE_ASSETS_CDN}/images/components/footer/`;

const Anchor = styled.a`
  margin: 20px 15px 0 0;
`;

const Img = styled(Image)`
  img {
    height: 50px;
    width: auto;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Wrapper = styled.div`
  flex: 0 0 100%;
  margin-top: ${p => (p.noMargin ? 0 : 56)}px;

  ${media.up.md`
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(
    ${p => setColumnWidth(5, p.theme.gridSettings)} +
      ${p => p.theme.gridSettings.gutterWidth}px
  );
    margin-top: 80px;
    margin-top: ${p => (p.noMargin ? 0 : 80)}px;
  `}
`;

const AppStore = ({ bodyText, noMargin = false }) => (
  <Wrapper noMargin={noMargin}>
    {bodyText && <P>{bodyText}</P>}
    <ImageWrapper>
      <Anchor href="https://apps.apple.com/us/app/salata-salad-kitchen/id1514026040">
        <Img alt="Apple Store" url={`${imgPath}applestore.png`} />
      </Anchor>
      <Anchor href="https://play.google.com/store/apps/details?id=com.paytronix.client.android.app.salata">
        <Img alt="Google Play Store" url={`${imgPath}googleplay.png`} />
      </Anchor>
    </ImageWrapper>
  </Wrapper>
);

AppStore.propTypes = {
  bodyText: PropTypes.string,
  noMargin: PropTypes.bool
};

export default AppStore;
