import styled from 'styled-components';
import { FormField, MultiPartForm } from '../../components';
import Success from '../../components/FormField/Success';
import { media, P } from '../../global';

// prettier-ignore
export const Category = styled(FormField)`
  padding: 48px 48px 0;
  transition: opacity 0.25s ease-out, transform 0.25s ease-out;

  ${p => p.isSubmitted && `
    opacity: 0;
    transform: translateX(-100%);
  `}

  ${media.down.md`
    padding: 48px 20px 0;
  `}
`;

export const Disclaimer = styled(P)`
  padding: 23px 0 20px;
`;

export const FormLayout = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

export const ExpandedFields = styled(FormLayout)`
  ${p => !p.visible && `display: none`};
  width: 100%;
`;

export const RadioContainer = styled.div`
  margin-bottom: 25px;
`;

export const Required = styled(P)`
  padding: 10px 0;
  width: 100%;

  span {
    color: ${p => p.theme.color.redPepper};
  }
`;

// prettier-ignore
export const StyledForm = styled(MultiPartForm)`
  opacity: ${p => p.isActive ? 1 : 0};
  padding: 0 48px 48px;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-out;

  div {
    padding: 0;
  }

  ${p => p.isSubmitted && `
    opacity: 0;
    pointer-events: none;
    transform: translateX(-100%);
  `}

  ${media.down.md`
    padding: 0 20px 48px;
  `}
`;

export const Subtitle = styled(P)`
  font-weight: ${p => p.theme.fontWeight.bold};
  letter-spacing: 3.56px;
  padding: 20px 0 10px;
  text-transform: uppercase;
`;

export const SuccessMessage = styled(Success)`
  border: none;
  transition-delay: 0.25s;
`;

// prettier-ignore
export const Wrapper = styled.div`
  border: 1px solid ${p => p.theme.color.peppercorn};
  max-height: ${p => (p.maxHeight === 0 ? 'none' : `${p.maxHeight}px`)};
  position: relative;
  transition: max-height 0.3s ease;

  ${p => p.submitted && `
    max-height: 365px;
  `}
`;
