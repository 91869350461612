import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Intro, IntroType } from '../../../components';
import { toEms, getColumnSpanSize } from '../../../global';

const DisclaimerIntro = styled(Intro)`
  max-width: ${p => getColumnSpanSize(12, p.theme.gridSettings)}px;
  width: 100%;

  p {
    font-size: ${toEms('17px')};
    color: ${p => p.theme.color.peppercornLight};
  }
`;

const Disclaimer = ({ intro }) => (
  <DisclaimerIntro {...intro} />
);

Disclaimer.propTypes = {
  intro: PropTypes.shape(IntroType)
};

export default Disclaimer;
