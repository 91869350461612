import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { startOfMonth } from 'date-fns';

import MonthInput from '../../../components/FormField/MonthInput';
import { Button } from '../../../elements';
import { media, P, shadeOf } from '../../../global';

import Article from './Article';
import { filterArticles } from './utils';

const LOAD_MORE = 6;

const DatePicker = styled(MonthInput)`
  border: 1px solid ${p => shadeOf(p.theme.color.peppercornLight, 0.5)};
  border-radius: 2px;
  height: auto;
  max-width: 250px;
  position: relative;

  input {
    color: ${p => shadeOf(p.theme.color.peppercornLight, 0.5)};
    cursor: pointer;
  }

  &::before {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid ${p => p.theme.color.peppercorn};
    content: '';
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }

  ${media.down.sm`
    max-width: 100%;
  `}
`;

const Label = styled(P)`
  font-size: ${p => p.theme.fontSize.label};
  font-weight: ${p => p.theme.fontWeight.bold};
`;

const LoadMore = styled(Button)`
  align-self: center;
  margin-top: 70px;
`;

const NoArticles = styled(P)`
  color: ${p => p.theme.color.peppercornLight};
  margin-top: 60px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 60px 0 60px 15px;
  text-align: left;

  ${media.down.md`
    padding: 60px 0;
  `}

  ${p => media.down.sm`
    border-bottom: 1px solid ${shadeOf(p.theme.color.peppercornLight, 0.5)};
  `}
`;

const Articles = ({ articles }) => {
  const initialDayOfMonth = startOfMonth(new Date());

  const [value, setValue] = useState(initialDayOfMonth);
  const [visible, setVisible] = useState(LOAD_MORE);

  const articleList = filterArticles(articles, initialDayOfMonth, value);
  const visibleArticles = articleList.slice(0, visible);

  const handleReset = () => {
    setValue(initialDayOfMonth);
    setVisible(LOAD_MORE);
  };

  return (
    <Wrapper>
      <Label>Filter Articles by Month and Year</Label>
      <DatePicker
        displayFormat="MMMM YYYY"
        onChange={setValue}
        onReset={() => handleReset()}
        value={value}
      />
      {visibleArticles.length > 0 ? (
        visibleArticles.map((article, i) => (
          <Article {...article.acf.newsroom} key={i} title={article.title} />
        ))
      ) : (
        <NoArticles>no articles available for the selected month</NoArticles>
      )}
      {articleList.length > visible && (
        <LoadMore
          onClick={() => setVisible(visible + LOAD_MORE)}
          title="Load More"
        />
      )}
    </Wrapper>
  );
};

Articles.propTypes = {
  articles: PropTypes.array
};

export default Articles;
