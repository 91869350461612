import React from 'react';
import IconBase from './IconBase';

export default props => (
  <IconBase height="30" viewBox="0 0 18 30" width="18" {...props}>
    <path
      strokeWidth=".875"
      d="M10.587 29c-2.555-.192-4.606-.87-6.331-2.321-.295-.248-.535-.722-.55-1.1-.117-2.993-.825-5.904-1.184-8.86-.23-1.889-.37-3.787-.58-5.678-.231-2.068-.44-4.141-.771-6.195-.145-.9.04-1.566.757-2.077.409-.293.855-.564 1.325-.738 1.51-.56 3.074-.859 4.695-.978 1.355-.1 2.692-.043 4.037.042 1.55.097 2.907.624 4.053 1.673.668.611.95 1.312.796 2.22-.556 3.296-.883 6.616-1.058 9.955-.182 3.476-.432 6.948-.66 10.422-.099 1.49-1.012 2.458-2.29 3.044-.776.355-1.675.45-2.239.591zm-5.931-3.279c1.315 1.226 2.53 1.786 4.384 2.17.994.206 1.958.188 2.915-.131 1.432-.478 2.177-1.481 2.247-2.966.078-1.65.157-3.298.264-4.946.107-1.646.257-3.29.368-4.936.031-.475-.01-.955-.02-1.454-.265.024-.379.013-.476.047-1.835.651-3.711.742-5.613.383-1.433-.272-2.858-.586-4.29-.872-.374-.074-.756-.11-1.294-.187.054 4.472 1.384 8.634 1.515 12.892zM2.093 4.103c.038.51.342.81.76.99.466.198.943.392 1.434.51 2.4.58 4.84.568 7.278.352.974-.087 1.942-.298 2.897-.52.643-.149 1.238-.439 1.481-1.27-.217-.258-.42-.609-.72-.832-1.03-.77-2.155-1.335-3.498-1.331-1.203.003-2.405-.007-3.608-.01-1.59-.004-3.102.381-4.583.904-.63.223-1.174.566-1.44 1.207zm.846 6.912c4.073-1.524 8.042-.782 12.135-.085l.601-4.853c-4.45 1.282-8.76 1.182-13.259.058l.523 4.88zM14.52 12.16c-.284-.48-.75-.6-1.21-.649-1.554-.165-3.109-.363-4.668-.415-1.191-.04-2.392.098-3.583.21-.36.034-.701.248-1.24.45.346.175.462.264.591.292 1.386.302 2.768.626 4.163.88 1.512.276 3.037.393 4.548-.022.506-.138 1.077-.19 1.4-.746z"
    />
  </IconBase>
);
