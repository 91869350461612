import PropTypes from 'prop-types';
import React from 'react';
import { NavWrapper, NavItem } from '../components';

const Nav = ({ menu }) => (
  <NavWrapper>
    {menu.map((item, i) => (
      <NavItem href={item.path} key={i}>
        {item.title}
      </NavItem>
    ))}
  </NavWrapper>
);

export const NavType = {
  id: PropTypes.number,
  order: PropTypes.number,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

Nav.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.shape(NavType))
};

export default Nav;
