import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Section } from '../../../components';
import { media } from '../../../global';

import AllergenFilter from './AllergenFilter';
import { CheckboxType } from './AllergenFilter/Filters/Checkbox';

import Tabs from './Tabs';

const Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  ${media.down.md`
    display: block;
  `}
`;

const Wrapper = styled(Section)`
  ${media.down.md`
    position: static;

    & > div {
      padding-left: 0;
      padding-right: 0;
      position: static;
    }
  `}
`;

const Navigation = ({
  activeRadio,
  activeTab,
  allergens,
  handleTabSelection,
  setActiveRadio,
  setAllergens,
  setIngredientsAnimating,
  tabs
}) => (
  <Wrapper halfBottomPadding noTopPadding>
    <Content>
      <Tabs
        activeRadio={activeRadio}
        activeTab={activeTab}
        handleTabSelection={handleTabSelection}
        setActiveRadio={setActiveRadio}
        setIngredientsAnimating={setIngredientsAnimating}
        tabs={tabs}
      />
      <AllergenFilter allergens={allergens} setAllergens={setAllergens} />
    </Content>
  </Wrapper>
);

Navigation.propTypes = {
  activeRadio: PropTypes.string,
  activeTab: PropTypes.string,
  allergens: PropTypes.arrayOf(PropTypes.shape(CheckboxType)),
  handleTabSelection: PropTypes.func,
  setActiveRadio: PropTypes.func,
  setAllergens: PropTypes.func,
  setIngredientsAnimating: PropTypes.func,
  tabs: PropTypes.arrayOf(PropTypes.string)
};

export default Navigation;
