import { gridSettings } from '../../../global/theme';

import { getColumnSpanSize, getPercentValue } from '../../../global/utils';

const { maxWidth, totalColumns } = gridSettings;

export const setColumnWidth = columns => {
  const columnCount = totalColumns / columns;
  const totalWidth = getColumnSpanSize(columnCount, gridSettings);
  return `${getPercentValue(totalWidth, maxWidth)}%`;
};
