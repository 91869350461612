import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, ImageType } from '../../elements';
import { H5, media, toEms } from '../../global';

const Anchor = styled.a`
  flex: 1 1 48%;

  ${media.down.md`
    flex-shrink: 0;
    margin-bottom: 4vw;
    margin-left: 0;
    width: 100%;
  `}
`;

// prettier-ignore
const ContentContainer = styled.div`
  flex: 1 1 48%;
  padding-top: 0;

  ${media.down.md`
    flex 1 0 100%;
  `}
`;

const ImageAnchor = styled(Anchor)`
  margin-right: 4%;

  ${media.down.md`
    margin-right: 0px;
  `}
`;

// prettier-ignore
const ImageContainer = styled.div`
  cursor: pointer;
  background: url(${p => p.imgSrc});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 130px;
  transition: ${({ theme }) =>
    `opacity ${theme.timing.default} ${theme.easing.ease}`};
  width: 100%;

  :hover {
    opacity: 0.7;
  }

  ${media.down.md`
    height: 39.2vw;
  `}
`;

// button label and arrow size get scaled down
// in order to prevent wrapping in this implementation
const ReadMoreButton = styled(Button).attrs({
  buttonType: 'secondary',
  inline: true,
  title: 'Read more'
})`
  font-size: ${toEms('14px')};
  margin-top: 16px;

  span {
    margin-left: 0px;
    transform: scale(0.875);
  }
`;

const Title = styled(H5)`
  color: ${p => p.theme.color.peppercorn};
  margin-top: -5px;
`;

// prettier-ignore
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
`;

const TinyPost = ({ className, id, image, path, title }) => (
  <Wrapper className={className}>
    <ImageAnchor href={path}>
      <ImageContainer imgSrc={image && image.url} />
    </ImageAnchor>

    <ContentContainer>
      <Anchor href={path}>
        <Title dangerouslySetInnerHTML={{ __html: title }} />
      </Anchor>

      <ReadMoreButton url={path} />
    </ContentContainer>
  </Wrapper>
);

TinyPost.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number.isRequired,
  image: PropTypes.shape(ImageType),
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default TinyPost;
