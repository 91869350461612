import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H5, P, shadeOf } from '../../../../../../global';
import { getSuffix } from '../../../../utils';

const Close = styled.div`
  border-top: 1px solid ${p => shadeOf(p.theme.color.peppercornLight, 0.5)};
  cursor: pointer;
  margin-top: auto;
  padding: 20px 0;
  text-align: center;
`;

const Content = styled.div`
  padding: 20px;
`;

const Header = styled.div`
  align-items: center;
  border-bottom: 1px solid ${p => shadeOf(p.theme.color.peppercornLight, 0.5)};
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`;

const Metrics = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0 10px;
`;

const Name = styled(H5)`
  color: ${p => p.theme.color.peppercorn};
  text-align: left;
`;

const Unit = styled(P)`
  padding: 15px 0;
  text-align: left;
`;

const Value = styled(P)`
  font-weight: ${p => p.theme.fontWeight.bold};
  padding: 15px 0;
  text-align: right;
`;

const Warning = styled(P)`
  font-weight: ${p => p.theme.fontWeight.bold};
  line-height: 1;
`;

const WarningWrapper = styled.div`
  align-items: center;
  background: ${p => p.theme.color.corn};
  border-radius: 100%;
  display: flex;
  height: 19px;
  justify-content: center;
  opacity: ${p => (p.containsAllergen ? 1 : 0)};
  padding-top: 1px;
  width: 19px;
`;

const Wrapper = styled.div`
  background: ${p => p.theme.color.white};
  box-shadow: 0 0 10px ${p => shadeOf(p.theme.color.peppercornLight, 0.5)};
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: none;
  min-width: 0;
  overflow: auto;
  position: fixed;
  transform: ${p => (p.visible ? 'translateX(0)' : 'translateX(110%)')};
  transition: transform 0.15s cubic-bezier(0.82, 0.17, 0.32, 0.33);
  top: 0;
  width: 100%;
  z-index: 11;
`;

const Modal = ({
  containsAllergen,
  ingredient,
  measurements,
  setVisible,
  values,
  visible
}) => {
  const empty = Object.keys(ingredient).length < 1;
  return (
    <Wrapper visible={visible}>
      {!empty && (
        <Content>
          <Header>
            <Name>{ingredient.name}</Name>
            <WarningWrapper containsAllergen={containsAllergen}>
              <Warning inverted>!</Warning>
            </WarningWrapper>
          </Header>
          <Metrics>
            <div>
              {measurements.map((measurement, i) => (
                <Unit key={i}>{measurement}</Unit>
              ))}
            </div>
            <div>
              {values.map((value, i) => {
                const suffix = getSuffix(i);
                return <Value key={i}>{value + suffix}</Value>;
              })}
            </div>
          </Metrics>
        </Content>
      )}
      <Close onClick={() => setVisible(false)}>
        <P>Close</P>
      </Close>
    </Wrapper>
  );
};

Modal.propTypes = {
  containsAllergen: PropTypes.bool,
  ingredient: PropTypes.object,
  measurements: PropTypes.arrayOf(PropTypes.string).isRequired,
  setVisible: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  visible: PropTypes.bool
};

export default Modal;
