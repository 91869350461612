import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CardType, Intro, IntroType } from '../../components';
import { Button, ButtonType, Image, ImageType } from '../../elements';
import { media, setColumnWidth, shadeOf } from '../../global';

import { ContentBreakdownType } from '../ContentBreakdown';

// prettier-ignore
const Column = styled.div`
  align-items: flex-start;
  flex: 1 1 ${p => (1 / p.columns) * 100}%;
  justify-content: flex-start;
  padding: 0 20px 25px 0;
  margin-top: 25px;
  max-width: ${p => (1 / p.columns) * 100}%;

  img {
    height: 60px;
    width: auto;
  }

  ${media.down.sm`
    flex: 0 0 100%;
    max-width: none;
  `}

  ${p => p.columns === 1 && `
    &:not(:last-of-type) {
      border-bottom: 1px solid ${shadeOf(p.theme.color.black, 0.25)};
    }
  `}

  ${p => p.columns > 1 && `
    > div {
      align-items: flex-start;
      display: flex;
      justify-content: flex-start;
  }
  `}
`;

const ContentIntro = styled(Intro)`
  text-align: left;

  & + div {
    margin-top: 40px;
  }
`;

const ContentWrapper = styled.div`
  max-width: ${p => setColumnWidth(7, p.theme.gridSettings)};
  text-align: left;
  width: 100%;

  ${media.down.md`
    max-width: ${p => setColumnWidth(9, p.theme.gridSettings)};
    padding: 100px 0;
  `}

  ${media.down.sm`
    max-width: 100%;
  `}
`;

const Link = styled(Button)`
  margin-top: 40px;
`;

const OffsetImage = styled(Image)`
  max-width: ${p => setColumnWidth(6, p.theme.gridSettings)};

  img {
    width: calc(${p => setColumnWidth(12, p.theme.gridSettings)} + 200px);
  }

  ${media.down.md`
    margin-right: -200px;
  `}

  ${media.down.sm`
    display: none;
  `}
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  ${media.down.sm`
    flex-direction: column;
  `}
`;

const MultiFeature = ({ columns, component, content, image, intro, link }) => (
  <Wrapper>
    <ContentWrapper>
      <ContentIntro {...intro} />
      <ColumnWrapper>
        {content.map((data, i) => {
          const Component = component;
          return (
            <Column columns={columns} key={i}>
              <Component {...data} />
            </Column>
          );
        })}
      </ColumnWrapper>
      {link && <Link {...link} buttonType="secondary" inline />}
    </ContentWrapper>
    <OffsetImage {...image} />
  </Wrapper>
);

export const MultiFeatureType = {
  columns: PropTypes.oneOf([1, 2, 3]),
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  content: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(CardType),
      PropTypes.shape(ContentBreakdownType)
    ])
  ),
  image: PropTypes.shape(ImageType),
  intro: PropTypes.shape(IntroType),
  link: PropTypes.shape(ButtonType)
};

MultiFeature.defaultPropTypes = {
  columns: 1
};

MultiFeature.propTypes = MultiFeatureType;

export default MultiFeature;
