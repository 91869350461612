import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import {
  AlignmentOptions,
  ColorOptions,
  media,
  setBackgroundColor,
  setVerticalSpacing
} from '../../global';
import Container from '../Container';
import Intro, { IntroType } from '../Intro';
import Divider from './Divider';
import Particles, { ParticlesType } from './Particles';

// prettier-ignore
const Inner = styled(Container)`
  ${p => setVerticalSpacing(p)}

  ${p => p.unContain && `
    padding-left: 0;
    padding-right: 0;
  `}

  ${p => p.above && `
    z-index: 1;
    position: relative;
  `}

  ${p => media.down.md`
    ${(!p.noBottomPadding && !p.noPadding) && !p.contentBelow && `
      padding-bottom: 60px;
    `}

    ${(!p.noTopPadding && !p.noPadding) && !p.contentBelow && `
      padding-top: 60px;
    `}

    ${(p.noBottomPadding || p.noPadding) && p.insetDividerBottom && !p.contentBelow && !p.unContain && `
      margin-bottom: 60px;
    `}
  `}
`;

// prettier-ignore
const Wrapper = styled.section`
  width: 100%;
  position: relative;
  text-align: ${p => p.align};

  ${p => setBackgroundColor(p.bgColor, p.theme.color)}
`;

const Section = ({
  align,
  bgColor,
  children,
  className,
  componentRef,
  contentBelow,
  dividerBottomColor,
  dividerBottomReverse,
  dividerTopColor,
  dividerTopReverse,
  halfBottomPadding,
  halfPadding,
  halfTopPadding,
  insetDividerBottom,
  insetDividerTop,
  intro,
  noBottomPadding,
  noPadding,
  noTopPadding,
  particlesData,
  unContain
}) => (
  <Wrapper
    align={align}
    bgColor={bgColor}
    className={className}
    ref={componentRef}
  >
    {particlesData &&
      particlesData.map((particles, i) => (
        <Particles
          component={particles.component}
          fill={particles.fill}
          key={i}
          noBottomPadding={noBottomPadding}
          noPadding={noPadding}
          noTopPadding={noTopPadding}
          position={particles.position}
        />
      ))}
    {dividerTopColor && (
      <Divider
        fillColor={dividerTopColor}
        insetDividerTop={insetDividerTop}
        reverse={dividerTopReverse}
        top
      />
    )}
    <Inner
      above={!contentBelow}
      contentBelow={contentBelow}
      halfBottomPadding={halfBottomPadding}
      halfPadding={halfPadding}
      halfTopPadding={halfTopPadding}
      insetDividerBottom={insetDividerBottom}
      insetDividerTop={insetDividerTop}
      noBottomPadding={noBottomPadding}
      noPadding={noPadding}
      noTopPadding={noTopPadding}
      unContain={unContain}
    >
      {intro && <Intro {...intro} />}
      {children}
    </Inner>
    {dividerBottomColor && (
      <Divider
        fillColor={dividerBottomColor}
        insetDividerBottom={insetDividerBottom}
        reverse={dividerBottomReverse}
      />
    )}
  </Wrapper>
);

Section.defaultProps = {
  align: 'center',
  bgColor: 'transparent',
  contentBelow: false,
  dividerBottomReverse: false,
  dividerTopReverse: false,
  insetDividerBottom: false,
  insetDividerTop: false,
  unContain: false
};

Section.propTypes = {
  align: PropTypes.oneOf(AlignmentOptions),
  bgColor: PropTypes.oneOf(ColorOptions),
  children: PropTypes.node,
  className: PropTypes.string,
  componentRef: PropTypes.object,
  contentBelow: PropTypes.bool,
  dividerBottomColor: PropTypes.oneOf(ColorOptions),
  dividerBottomReverse: PropTypes.bool,
  dividerTopColor: PropTypes.oneOf(ColorOptions),
  dividerTopReverse: PropTypes.bool,
  halfBottomPadding: PropTypes.bool,
  halfPadding: PropTypes.bool,
  halfTopPadding: PropTypes.bool,
  insetDividerBottom: PropTypes.bool,
  insetDividerTop: PropTypes.bool,
  intro: PropTypes.shape(IntroType),
  noBottomPadding: PropTypes.bool,
  noPadding: PropTypes.bool,
  noTopPadding: PropTypes.bool,
  particlesData: PropTypes.arrayOf(PropTypes.shape(ParticlesType)),
  unContain: PropTypes.bool
};

export default Section;
