import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, CardType } from '../../components';
import { Image } from '../../elements';
import { media, P, setColumnWidth } from '../../global';

const ImageWrapper = styled.div`
  text-align: center;

  &:first-child {
    padding-top: ${({ numImages }) => (numImages === 2 ? '127px' : '177px')};
    ${media.down.sm`
      margin: 0;
      padding: 0;
      transform: translate(0,0);
    `};
  }

  &:last-child {
    padding-top: ${({ numImages }) => (numImages === 2 ? '363px' : '263px')};
    transform: ${({ numImages }) =>
    numImages === 2 ? 'translate(0px, 0)' : 'translate(-45px, 0)'};

    ${media.down.sm`
      margin: ${({ numImages }) => (numImages === 2 ? '0' : '-97px 0 0')};
      padding: 0;
      transform: translate(0,0);
    `}
  }

  ${media.down.sm`
    margin-left: auto;
    transform: translateY(-77px);
    width: 45%;
  `}
`;

const InnerWrapper = styled.div`
  display: flex;
  max-width: ${p => setColumnWidth(7, p.theme.gridSettings)};
  width: 100%;

  ${p => media.down.md`
    margin: 0 auto;
    max-width: ${setColumnWidth(9, p.theme.gridSettings)};
  `}

  ${media.down.sm`
    flex-direction: column;
    max-width: 100%;


    ${({ images }) => {
    return `
    ${
  images &&
      `
    align-items:center;
    gap: 2rem;`
}`;
  }}
  `}
`;

const SoupsCard = styled(Card)`
  max-width: ${p => setColumnWidth(5, p.theme.gridSettings)};
  padding-top: 60px;
  text-align: left;
  width: 100%;

  ${p => media.down.md`
    margin: 0 auto;
    max-width: ${setColumnWidth(9, p.theme.gridSettings)};
    padding-bottom: 50px;
  `}

  ${media.down.sm`
    max-width: 100%;
  `}
`;

const Wrapper = styled.div`
  display: flex;

  ${media.down.md`
    flex-direction: column;
  `};
`;

const MaybeLink = styled.div`
`;

const Soups = ({ content, images }) => {
  return (
    <Wrapper>
      <SoupsCard {...content} />
      <InnerWrapper images={images.length < 3}>
        {images.map((image, i) => (
          <ImageWrapper
            key={i}
            numImages={images.length}
          >
            <MaybeLink
              as={image.soup_url && typeof image.soup_url === 'string' ? 'a' : 'div'}
              href={image.soup_url && typeof image.soup_url === 'string' ? image.soup_url : null}
            >
              <Image {...image.image} />
              <P>{image.name}</P>
            </MaybeLink>
          </ImageWrapper>
        ))}
      </InnerWrapper>
    </Wrapper>
  );
};

Soups.propTypes = {
  content: PropTypes.shape(CardType),
  images: PropTypes.arrayOf(PropTypes.object)
};

export default Soups;
