import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Intro, IntroType } from '../../components';
import { media, P } from '../../global';

import LocationGallery, { LocationGalleryType } from '../LocationGallery';

const ContentWrapper = styled.div``;

const Nav = styled.div`
  border-bottom: 1px solid ${p => p.theme.color.peppercorn};
  display: inline-flex;
  margin: 0 auto 112px;
  text-align: center;

  ${media.down.sm`
    display: flex;
    margin-bottom: 65px;
  `}
`;

// prettier-ignore
const NavItem = styled(P)`
  color: ${p => p.active ? p.theme.color.redPepper : p.theme.color.peppercorn};
  cursor: pointer;
  padding: 0 34px 18px;
  position: relative;
  text-align: center;

  &::after {
    background-color: ${p => p.theme.color.redPepper};
    bottom: 0;
    height: 4px;
    left: 0;
    position: absolute;
    transform: translateY(1px);
    width: 100%;
  }

  ${p => p.active && `
    &::after {
      content: '';
    }
  `}

  ${media.down.sm`
    padding: 0 0 18px;
    width: calc(100% / 3);
  `}
`;

const Wrapper = styled.div``;

const AtAGlance = ({ content, intro }) => {
  const [active, setActive] = useState(content[0]);

  return (
    <Wrapper>
      <Intro {...intro} />
      <ContentWrapper>
        <Nav>
          {content.map((spec, i) => (
            <NavItem
              active={active === spec}
              key={i}
              onClick={() => setActive(spec)}
            >
              {spec.navItem}
            </NavItem>
          ))}
        </Nav>
        <LocationGallery {...active.gallery} />
      </ContentWrapper>
    </Wrapper>
  );
};

const AtAGlanceContent = {
  gallery: PropTypes.shape(LocationGalleryType),
  navItem: PropTypes.string
};

AtAGlance.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape(AtAGlanceContent)),
  intro: PropTypes.shape(IntroType)
};

export default AtAGlance;
