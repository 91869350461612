import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from '../../../../../elements';
import {
  getColumnSpanSize,
  Hidden,
  media,
  P,
  shadeOf
} from '../../../../../global';

import Checkbox, { CheckboxType } from './Checkbox';

const Clear = styled(P)`
  cursor: pointer;
`;

// prettier-ignore
const Close = styled.div`
  height: 22px;
  margin-bottom: 20px;
  position: relative;
  width: 22px;

  &::before,
  &::after {
    background: ${p => p.theme.color.peppercorn};
    border-radius: 1px;
    content: '';
    height: 4px;
    position: absolute;
    top: 9px;
    transform: ${p => p.visible ? 'rotate(45deg)' : 'rotate(0)'};
    transition: transform 0.65s ease;
    width: 22px;
  }

  &::after {
    transform: ${p => p.visible ? 'rotate(-45deg)' : 'rotate(0)'};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 25px;
`;

const FilterItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
`;

const Footer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  ${media.down.md`
    margin-top: auto;
  `}
`;

// prettier-ignore
const Wrapper = styled.div`
  background: ${p => p.theme.color.white};
  box-shadow: 0 0 10px ${p => shadeOf(p.theme.color.black, 0.3)};
  max-height: ${p => p.maxHeight}px;
  min-width: ${p => getColumnSpanSize(4, p.theme.gridSettings)}px;
  overflow: hidden;
  position: absolute;
  right: 0;
  text-align: left;
  top: 100%;
  transition: max-height 0.25s ease;
  width: 100%;
  z-index: 10;

  ${p => !p.visible && `
    max-height: 0;
  `}

  ${p => media.down.md`
    height: 100%;
    max-height: none;
    min-width: 0;
    position: fixed;
    transform: ${p.visible ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform 0.15s cubic-bezier(0.82, 0.17, 0.32, 0.33);
    top: 0;
    z-index: 11;
  `}
`;

const Filters = ({ allergens, setAllergens, setVisible, visible }) => {
  const [maxHeight, setMaxHeight] = useState(0);

  const contentRef = useRef(null);

  const handleClear = () => {
    setAllergens({ payload: 'clear', type: 'clear' });
    setVisible(false);
  };

  useEffect(() => {
    if (visible) setMaxHeight(contentRef.current.clientHeight);
  }, [maxHeight, visible]);

  return (
    <Wrapper maxHeight={maxHeight} visible={visible}>
      <Content ref={contentRef}>
        <Hidden up="md">
          <Close onClick={() => setVisible(false)} visible={visible} />
        </Hidden>
        <P>
          Select any allergens of concern to have them flagged in the Nutrition
          Builder.
        </P>
        <FilterItems>
          {allergens.map((allergen, i) => (
            <Checkbox
              checked={allergen.checked}
              id={allergen.id}
              key={i}
              name={allergen.name}
              setAllergens={setAllergens}
            />
          ))}
        </FilterItems>
        <Footer>
          <Clear onClick={handleClear}>Clear Filters</Clear>
          <Button onClick={() => setVisible(false)} title="Apply" />
        </Footer>
      </Content>
    </Wrapper>
  );
};

Filters.propTypes = {
  allergens: PropTypes.arrayOf(PropTypes.shape(CheckboxType)),
  setAllergens: PropTypes.func,
  setVisible: PropTypes.func,
  visible: PropTypes.bool.isRequired
};

export default Filters;
