import React from 'react';
import PropTypes from 'prop-types';

const Arrow = ({ className, onClick }) => (
  <svg
    className={className}
    onClick={onClick}
    width="48"
    height="48"
    viewBox="0, 0, 48, 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="#FF864D" fill="none" fillRule="evenodd">
      <path strokeWidth="4" d="M26.014 11L15 22.014l11.014 11.49" />
      <circle strokeWidth="3" cx="22" cy="22" r="22" />
    </g>
  </svg>
);

Arrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default Arrow;
