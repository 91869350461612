import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H5, shadeOf } from '../../../../global';

import Ingredient from './Ingredient';

const Title = styled(H5)`
  text-transform: lowercase;
`;

const Wrapper = styled.div`
  border-bottom: 1px solid ${p => shadeOf(p.theme.color.peppercornLight, 0.5)};
  margin-top: 30px;
  padding-bottom: 30px;
`;

const Category = ({ category }) => {
  const compare = (a, b) => a.title.localeCompare(b.title);

  const ingredients = category[1].sort(compare);
  const title = category[0];

  return (
    <Wrapper>
      <Title dangerouslySetInnerHTML={{ __html: title }} />
      {ingredients.map((ingredient, i) => (
        <Ingredient key={i} {...ingredient} />
      ))}
    </Wrapper>
  );
};

Category.propTypes = {
  category: PropTypes.array
};

export default Category;
