import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import styled from 'styled-components';

import { Container, Intro, IntroType, Loading } from '../../components';
import { media, setColumnWidth, slickCSS } from '../../global';

import Tea, { TeaType } from './Tea';
import { sliderSettings } from './utils';

const LoadingWrapper = styled.div`
  min-height: 250px;
  position: relative;
`;

const TeasContainer = styled(Container)`
  ${media.down.lg`
    overflow: hidden;
    padding: 0;
  `}
`;

const TeasIntro = styled(Intro)`
  ${media.down.lg`
    padding: 0 25px;
  `}

  ${media.down.sm`
    text-align: left;
  `}

  & + div {
    margin-top: 40px;
  }
`;

const Wrapper = styled.div`
  width: 100%;

  ${slickCSS}

  .slick-slider {
    position: relative;

    .slick-track {
      display: flex;
      margin: 0 auto;
    }

    .slick-slide {
      height: auto;
    }
  }

  ${p => media.up.lg`
    .slick-track {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      max-width: ${setColumnWidth(11, p.theme.gridSettings)};
      width: 100% !important;


      &:hover div p:not(:last-child) {
        opacity: 0;

        div {
          opacity: 1;
        }
      }

      &:hover > div {
        opacity: 0.25;
      }

      &:hover > div:hover {
        opacity: 1;
      }
    }
  `}

  ${media.down.lg`
    margin: 0 auto;
    max-width: 100%;
    overflow: visible;

    .slick-slide {
      margin: 0 48px;
    }
  `}

  ${media.down.sm`
    .slick-list {
      margin: 0 auto;
    }

    .slick-slide {
      margin: 0;
      padding: 0 45px 0 20px;
    }
  `}
`;

const Teas = ({ intro, teas }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [loading]);

  return (
    <TeasContainer>
      <TeasIntro {...intro} />
      <Wrapper>
        {loading ? (
          <LoadingWrapper>
            <Loading isLoading={loading} />
          </LoadingWrapper>
        ) : (
          <Slider {...sliderSettings}>
            {teas.map((tea, index) => {
              // reverse is needed to switch the last two Tea component's description position from right to left
              const reverse =
                teas.length === index + 1 || teas.length === index + 2;
              return (
                <Tea
                  description={tea.description}
                  image={tea.image}
                  key={index}
                  name={tea.name}
                  reverse={reverse}
                />
              );
            })}
          </Slider>
        )}
      </Wrapper>
    </TeasContainer>
  );
};

Teas.propTypes = {
  intro: PropTypes.shape(IntroType),
  teas: PropTypes.arrayOf(PropTypes.shape(TeaType)).isRequired
};

export default Teas;
