import React from 'react';
import styled from 'styled-components';
import { media, Salata, setColumnWidth } from '../../global';

const Logo = styled(Salata)`
  height: 70px;
  width: calc(124px / 47 * 70);
`;

const Wrapper = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(
    ${p => setColumnWidth(3, p.theme.gridSettings)} +
      ${p => p.theme.gridSettings.gutterWidth}px
  );

  ${media.down.md`
    flex: 0 0 100%;
  `}
`;

const SiteLogo = () => (
  <Wrapper>
    <a href="/">
      <Logo />
    </a>
  </Wrapper>
);

export default SiteLogo;
