import React from 'react';
import PropTypes from 'prop-types';
import { get, getOr } from 'unchanged';

import { ErrorText, Label, Required, Wrapper } from './components';
import FormControl from './FormControl';
import { sizes } from '../../global';

const FormField = ({
  className,
  columns,
  form = {},
  field = {},
  label,
  required = true,
  ...inputProps
}) => {
  // Field name may be in `field`, passed to root, or empty.
  const fieldName = field.name || inputProps.name || '';
  const errorMessage = getOr('', ['errors', fieldName], form);
  const isTouched = getOr(false, ['touched', fieldName], form);
  const hasError = isTouched && Boolean(errorMessage);

  return (
    <Wrapper className={className} columns={columns} type={inputProps.type}>
      <Label>
        {label} {required && <Required>*</Required>}
        <FormControl
          hasError={hasError}
          setFieldValue={get('setFieldValue', form)}
          {...field}
          {...inputProps}
        />
      </Label>

      {hasError && <ErrorText>{errorMessage}</ErrorText>}
    </Wrapper>
  );
};

FormField.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.shape(
    Object.assign(
      { default: PropTypes.number },
      ...Object.keys(sizes.up).map(key => ({
        [key]: PropTypes.number
      }))
    )
  ),
  field: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.string
  }),
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object
  }),
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool
};

export default FormField;
