/**
 * Categorizes an array of objects based on a nested property inside each object.
 * It then generates an array for each of those unique properties
 * Wraps those arrays in an object and alphabetizes;
 */

export const getCategorizedArrays = arr => {
  const arrays = [];
  arr.forEach(obj => {
    const category = obj.terms[0].name;
    if (arrays[category]) arrays[category].push(obj);
    else arrays[category] = [obj];
  });
  return Object.entries(arrays).sort();
};
