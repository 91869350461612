import React from 'react';
import styled from 'styled-components';

import IconBase from './IconBase';

const Wrapper = styled(IconBase)`
  /* Center dropdown alongside text */
  height: calc(0.5em + 5px);
  width: 7px;
  ${p => p.afterText && 'margin-left: 0.35em;'}
  ${p => p.beforeText && 'margin-right: 0.35em;'};
`;

export default props => (
  <Wrapper viewBox="0 0 7 5" {...props}>
    <path d="M3.5 5L0 0h7z" fill="currentColor" fillRule="nonzero" />
  </Wrapper>
);
