import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PageType } from '../../global';
import BaseLayout from '../../layouts/Base';
import Product from './Product';
import Callouts from './Callouts';
import Divider from '../../components/Section/Divider';
import { Section } from '../../components';

const DividerBehind = styled(Divider)`
  z-index: -10;
`;

const MenuItem = ({ acf, title, content, media, themeSettings, ...additionalProps }) => {
  const {
    menu_item__calories: calories,
    menu_item__addons: addons
  } = acf;

  if (!themeSettings || typeof themeSettings !== 'object') {
    themeSettings = {};
  }

  const layoutProps = { title, ...additionalProps };

  return (
    <BaseLayout {...layoutProps}>
      <Section
        bgColor="blue"
        dividerBottomColor="white"
      >
        <Product
          title={title}
          content={content}
          calories={calories}
          addons={addons}
          image={media?.large}
        />
      </Section>

      <Callouts
        headline={themeSettings.menu_item__callout__headline}
        content={themeSettings.menu_item__callout__content}
        link={themeSettings.menu_item__callout__url}
      />

      <DividerBehind
        fillColor="tan"
        reverse
      />
    </BaseLayout>
  );
};

MenuItem.propTypes = {
  ...PageType,
  acf: PropTypes.shape({
    // eslint-disable-next-line camelcase
    menu_items__addons: PropTypes.object,
    // eslint-disable-next-line camelcase
    menu_items__calories: PropTypes.number
  }),
  content: PropTypes.string,
  media: PropTypes.object,
  title: PropTypes.string.isRequired
};

export default MenuItem;
