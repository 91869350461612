import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  AppStore,
  Card,
  CardType,
  Grid,
  IntroType,
  Section
} from '../../components';
import { Image, ImageType } from '../../elements';
import { getColumnSpanSize, PageType } from '../../global';
import BaseLayout from '../../layouts/Base';

import {
  Accordion,
  AccordionType,
  FeaturedContent,
  FeaturedContentType
} from '../../sections';

const ChartImage = styled(Image)`
  margin: 0 auto;
  max-width: ${p => getColumnSpanSize(8, p.theme.gridSettings)}px;
  width: 100%;
`;

const TastemakersCard = styled(Card)`
  justify-content: flex-start;

  h5 {
    color: ${p => p.theme.color.peppercorn};
  }
`;

const Rewards = ({ acf, ...layoutProps }) => {
  const { appPromo, chart, partyStarted, tastemakers, rewardsFaq } = acf;
  return (
    <BaseLayout footerDivider {...layoutProps}>
      <Section halfBottomPadding>
        <FeaturedContent {...appPromo}>
          <AppStore bodyText="What are you waiting for? Download the Salata app today." />
        </FeaturedContent>
      </Section>
      <Section intro={tastemakers.intro}>
        <Grid
          columns={tastemakers.content.length}
          component={TastemakersCard}
          componentData={tastemakers.content}
        />
      </Section>
      <Section noTopPadding>
        <ChartImage {...chart} />
      </Section>
      <Section align="left">
        <Accordion {...rewardsFaq} />
      </Section>
      <Section alignment="center" halfTopPadding intro={partyStarted.intro} />
    </BaseLayout>
  );
};

Rewards.propTypes = {
  ...PageType,
  acf: PropTypes.shape({
    appPromo: PropTypes.shape(FeaturedContentType),
    chart: PropTypes.shape(ImageType),
    rewardsFaq: PropTypes.shape(AccordionType),
    tastemakers: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape(CardType)),
      intro: PropTypes.shape(IntroType)
    })
  }).isRequired
};

export default Rewards;
