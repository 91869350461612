import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { Loading } from '../../components';

import { Category, StyledForm, SuccessMessage, Wrapper } from './components';
import Fields, { IntroType } from './Fields';
import { form, initialValues, options } from './data';

const ContactForm = ({ disclaimers, titles }) => {
  const [active, setActive] = useState(null);
  const [loading, setLoading] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const [showExpandedFields, setShowExpandedFields] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const contentRef = useRef(null);

  const handleChange = e => {
    setActive(e.target.value);
  };

  const handleSubmit = (values, actions) => {
    setLoading(true);
    const id = 'rves1k90fbonyl';
    // since my select field is outside of the form, I'm injecting the selected value here before submission
    values[form.CATEGORY] = active;
    // reformatting date and phone number for Wufoo
    values[form.EVENT_DATE] = moment(
      values[form.EVENT_DATE],
      'M/D/YYYY'
    ).format('YYYYMMDD');
    values[form.VISIT_DATE] = moment(
      values[form.VISIT_DATE],
      'M/D/YYYY'
    ).format('YYYYMMDD');
    values[form.PHONE] = values[form.PHONE].replace(/[()-\s]/g, '');

    axios
      .post(`/forms/${id}`, {
        body: values
      })
      .then(res => {
        setLoading(false);
        if (res.data.Success === 1) {
          setMaxHeight(contentRef.current.clientHeight);
          setTimeout(() => setSubmitted(true), 100);
          actions.setSubmitting(false);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Wrapper maxHeight={maxHeight} ref={contentRef} submitted={submitted}>
      <Category
        defaultValue="Choose an option..."
        isSubmitted={submitted}
        label="What can we help you with?"
        name="category"
        onChange={e => handleChange(e)}
        required={false}
        type="select"
      >
        {options.map((option, i) => {
          const { disabled, value } = option;
          return (
            <option disabled={disabled} key={i} value={value}>
              {value}
            </option>
          );
        })}
      </Category>
      <StyledForm
        initialValues={initialValues}
        isActive={active !== null}
        hideSubmit={active === null}
        isSubmitted={submitted}
        onSubmit={handleSubmit}
      >
        <Fields
          active={active}
          disclaimers={disclaimers}
          setShowExpandedFields={setShowExpandedFields}
          showExpandedFields={showExpandedFields}
          titles={titles}
        />
      </StyledForm>
      <Loading isLoading={loading} />
      <SuccessMessage
        bodyText="Your message has been sent. We’ll be sure to reply as soon as we can. Until then, just keep salading."
        headline="got it"
        submitted={submitted}
      />
    </Wrapper>
  );
};

export const ContactFormType = {
  disclaimers: PropTypes.arrayOf(PropTypes.shape(IntroType)),
  titles: PropTypes.arrayOf(PropTypes.shape(IntroType))
};

ContactForm.propTypes = ContactFormType;

export default ContactForm;
