import styled from 'styled-components';
import { Section as SectionBase } from '../../../components';
import { Button } from '../../../elements';
import {
  FranchiseDesktop,
  FranchiseMobile,
  getColumnSpanSize,
  H5,
  media,
  P,
  shadeOf,
  toEms
} from '../../../global';

export const LogoDesktop = styled(FranchiseDesktop)`
  height: 42px;
  margin: 7px 2.5vw 7px 0;
  width: 221px;
`;

export const LogoMobile = styled(FranchiseMobile)`
  height: 33px;
  margin: 11px 0;
  width: calc(100px / 44 * 33);
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  ${media.up.lg`
    max-width: ${p => getColumnSpanSize(3, p.theme.gridSettings)}px;
    text-align: left;
  `}
`;

export const MenuText = styled(H5)`
  padding: 30px 0;
  text-align: left;
`;

export const NavButton = styled(Button)`
  button {
    padding: 16px 20px 14px;
  }
`;

// prettier-ignore
export const NavItem = styled(P).attrs({ as: 'a' })`
  cursor: pointer;
  font-size: ${toEms('17px')};
  padding: 16px 1vw 14px;
  text-align: left;
  text-transform: uppercase;
  transition: color 0.5s;

  &:hover, &:focus {
    color: ${p => p.theme.color.kaleLeaf};
  }

  ${p => media.up.lg`
    ${p.title === 'Overview' && `
      display: none;
    `}
  `}

  ${p => media.down.lg`
    padding-left: 0;
  `}
`;

export const NavList = styled.div`
  display: flex;

  ${media.down.lg`
    flex-direction: column;
  `}
`;

// prettier-ignore
export const NavWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: ${p => getColumnSpanSize(8, p.theme.gridSettings)}px;
  width: 100%;

  ${media.down.lg`
    align-items: flex-start;
    background-color: ${p => p.theme.color.white};
    bottom: 0;
    box-shadow: 2px 0 15px 10px ${p => shadeOf(p.theme.color.peppercornLight, 0.5)};
    flex-direction: column;
    justify-content: flex-start
    left: 0;
    max-width: none;
    overflow: auto;
    padding: 76px 20px 76px 55px;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(-110%);
    transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);

    ${p => p.isExpanded && `
      transform: translateX(0);
    `}
  `}
`;

export const Section = styled(SectionBase)`
  box-shadow: 0 0 10px ${p => shadeOf(p.theme.color.black, 0.25)};
  height: 59px;
  position: fixed;
  top: 0;

  > div {
    height: 100%;
  }

  ${media.up.lg`
    height: 65px;
  `}
`;

// An optional empty space that pushes items down from under the navigation
export const Spacer = styled.div`
  display: block;
  height: 59px;

  ${media.up.lg`
    height: 65px;
  `}
`;
