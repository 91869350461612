import PropTypes from 'prop-types';
import React from 'react';

import { Card, CardType, Section } from '../../../components';
import { ImageType } from '../../../elements';
import { PageType } from '../../../global';
import BaseLayout from '../../../layouts/Base';
import {
  FeaturedContent,
  FeaturedForm,
  Hero,
  HeroType,
  LocationGallery,
  LocationGalleryType
} from '../../../sections';
import { Form } from '../../components';

const InsideLook = ({
  acf,
  franchisingFooter,
  franchisingHeader,
  ...layoutProps
}) => {
  const {
    choosingYourLocation,
    hero,
    letsDoThis,
    locationRequirements,
    restaurantDesign
  } = acf;

  return (
    <BaseLayout
      {...layoutProps}
      franchisingFooter={franchisingFooter}
      franchisingHeader={franchisingHeader}
    >
      <Hero
        {...hero}
        dividerBottomColor="white"
        halfBottomPadding
        linkProps={{ url: '/contact-form/' }}
      />
      <Section>
        <FeaturedContent
          alignment="right"
          image={choosingYourLocation.image}
          reverseImageMobile
        >
          <Card {...choosingYourLocation.content} />
        </FeaturedContent>
      </Section>
      <Section
        bgColor="tan"
        dividerBottomColor="white"
        dividerTopColor="white"
        halfPadding
      >
        <LocationGallery {...locationRequirements} />
      </Section>
      <Section>
        <LocationGallery {...restaurantDesign} alignContent="right" />
      </Section>
      <Section dividerBottomColor="tan" dividerBottomReverse noTopPadding>
        <FeaturedForm content={Card} contentData={letsDoThis} form={Form} />
      </Section>
    </BaseLayout>
  );
};

InsideLook.propTypes = {
  ...PageType,
  acf: PropTypes.shape({
    choosingYourLocation: PropTypes.shape({
      content: PropTypes.shape(CardType),
      image: PropTypes.shape(ImageType)
    }),
    hero: PropTypes.shape(HeroType),
    letsDoThis: PropTypes.shape(CardType),
    locationRequirements: PropTypes.shape(LocationGalleryType),
    restaurantDesign: PropTypes.shape({
      content: PropTypes.shape(CardType),
      image: PropTypes.shape(ImageType)
    })
  }).isRequired
};

export default InsideLook;
