import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { getColumnSpanSize, media } from '../../global';

const ContentWrapper = styled.div`
  flex: 1 1 auto;
  max-width: ${p => getColumnSpanSize(4, p.theme.gridSettings)}px;
  text-align: left;
  width: 100%;

  ${media.down.md`
    margin-bottom: 60px;
    max-width: none;
  `}

  ${media.up.md`
    padding-right: 15px;
  `}
`;

const FormWrapper = styled.div`
  flex: 0 0 auto;
  max-width: ${p => getColumnSpanSize(6, p.theme.gridSettings)}px;
  width: 100%;

  ${media.down.md`
    margin: 0 auto;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.down.md`
    display: block;
  `}
`;

const FeaturedForm = ({ content, contentData, form }) => {
  const Content = content;
  const Form = form;

  return (
    <Wrapper>
      <ContentWrapper>
        <Content {...contentData} />
      </ContentWrapper>
      <FormWrapper>
        <Form />
      </FormWrapper>
    </Wrapper>
  );
};

FeaturedForm.propTypes = {
  content: PropTypes.elementType.isRequired,
  contentData: PropTypes.object.isRequired,
  form: PropTypes.elementType.isRequired
};

export default FeaturedForm;
