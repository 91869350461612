import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Intro, IntroType } from '../../components';
import { Button } from '../../elements';
import { media, setColumnWidth } from '../../global';
import { PositionsType } from '../../sections/Positions/Tabs/Nav';

const PositionsIntro = styled(Intro)`
  ${media.down.sm`
    text-align: left;
  `}

  ${media.up.lg`
    max-width: ${p => setColumnWidth(8, p.theme.gridSettings)};
    width: 100%;
  `}
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Positions = ({ content, intro }) => {
  return (
    <Wrapper>
      <PositionsIntro {...intro} />
      <Button type="primary" {...content.restaurant.link} />
    </Wrapper>
  );
};

Positions.propTypes = {
  content: {
    corporate: PropTypes.shape(PositionsType),
    restaurant: PropTypes.shape(PositionsType)
  },
  intro: PropTypes.shape(IntroType)
};

export default Positions;
