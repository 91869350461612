import React from 'react';
import IconBase from './IconBase';

export default props => (
  <IconBase height="30" viewBox="0 0 30 30" width="30" {...props}>
    <path
      strokeWidth=".875"
      d="M29 14.399c-.373 5.222-2.13 10.134-7.294 13.073-3.741 2.13-7.735 1.826-11.63.358C7.21 26.749 4.8 24.972 3.1 22.347c-2.54-3.921-2.895-7.993-.408-12.01 3.045-4.92 7.317-8.237 13.172-9.21 3.128-.52 5.805.619 8.35 2.259 1.33.857 2.179 2.08 2.834 3.507.677 1.474 1.509 2.879 1.67 4.538.084.855.163 1.71.282 2.968zm-1.294-.955c.297-2.214-1.092-4.688-2.38-7.179-.255-.493-.662-.95-1.098-1.3-3.241-2.604-6.821-3.498-10.824-2.011-4.176 1.55-7.443 4.258-9.714 8.064-2.037 3.416-1.972 6.96.219 10.28 3.298 4.998 8.12 6.86 13.943 6.245 2.356-.248 4.386-1.398 5.951-3.146 2.576-2.876 3.872-6.302 3.903-10.953zm-4.598.43c-.025 1.144-1.34 2.331-2.525 2.28-1.123-.049-1.984-.976-1.928-2.075.06-1.155 1.352-2.438 2.445-2.427 1.045.01 2.033 1.102 2.008 2.222zM9.78 11.569c-1.1.008-1.937-.8-1.912-1.845.027-1.11 1.08-2.118 2.245-2.148 1.034-.026 1.976.87 1.945 1.849-.034 1.074-1.163 2.137-2.278 2.144zm7.804-2.078c-.93.01-1.889-.861-1.928-1.752-.048-1.083.993-2.072 2.243-2.13.941-.045 1.96.942 1.936 1.874-.025.96-1.187 1.997-2.251 2.008zm-4.542 7.892c-1.308-.007-2.009-.557-2.144-1.373-.129-.78.396-1.55 1.274-1.817.363-.11.744-.167 1.12-.224.764-.117 1.294.25 1.628.893.332.638.153 1.266-.373 1.67-.542.418-1.2.684-1.505.85zm-3.245 2.16c1.322.915 1.72 1.73 1.282 2.626-.377.77-1.297 1.137-2.173.868-.763-.235-1.294-1.059-1.205-1.869.11-.998.779-1.516 2.096-1.624zm9.728 2.535c.697.631.817 1.351.634 2.137-.125.533-.41 1.072-.988 1.065-.466-.007-1.017-.226-1.37-.534-.547-.479-.608-1.188-.252-1.828.407-.73 1.049-1.063 1.976-.84zm5.668-3.914c-.037.47-.012.9-.116 1.297-.166.629-.645.917-1.286.87-.543-.039-1.04-.222-1.217-.802-.193-.633.099-1.101.612-1.449.543-.368.935-.36 2.007.084zm-18.646-3.47c.438 1.705.3 2.036-.972 2.462-.943-.672-1.2-1.246-.878-1.954.3-.658.736-.788 1.85-.508z"
    />
  </IconBase>
);
