import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Card, CardType, Grid } from '../../components';
import { Button, ButtonType } from '../../elements';
import { media, P } from '../../global';

const CoreValuesCard = styled(Card)`
  img {
    width: 50%;
  }

  h5 {
    color: ${p => p.theme.color.peppercorn};

    ${media.down.md`
      padding-top: 20px;
    `}
  }
`;

const FooterText = styled(P)`
  padding-bottom: 35px;
`;

const FooterWrapper = styled.div`
  padding-top: 70px;
  text-align: center;
`;

const CoreValues = ({ bodyText, link, values }) => (
  <Fragment>
    <Grid
      center
      columns={3}
      component={CoreValuesCard}
      componentData={values}
    />
    <FooterWrapper>
      <FooterText>{bodyText}</FooterText>
      <Button {...link} />
    </FooterWrapper>
  </Fragment>
);

export const CoreValuesType = {
  bodyText: PropTypes.string,
  link: PropTypes.shape(ButtonType).isRequired,
  values: PropTypes.arrayOf(PropTypes.shape(CardType)).isRequired
};

CoreValues.propTypes = CoreValuesType;

export default CoreValues;
