import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  color,
  getColumnSpanSize,
  Hidden,
  maintainRatio,
  P,
  shadeOf,
  UnitedStates
} from '../../../../global';
import Cities, { CityType } from './Cities';

import MobileMaps, { MobileMapsType } from './MobileMaps';

const FranchisingMap = styled(UnitedStates)`
  cursor: auto;

  .state {
    fill: ${p => shadeOf(p.theme.color.peppercornLight, 0.35)};
  }

  path {
    transition: fill 0.25s;
  }
`;

const FranchisingMapWrapper = styled.div`
  /* these numbers are based on the child svg viewBox */
  ${maintainRatio(959, 593)}
`;

const Legend = styled(P)`
  margin-top: 35px;
  text-align: center;
`;

const MapWrapper = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: ${p => getColumnSpanSize(7, p.theme.gridSettings)}px;
  width: 100%;
`;

const MapFeature = ({ active, cities, mobile, states }) => {
  useEffect(() => {
    states.forEach(state => {
      const id = document.getElementById(state.id);
      if (state.type === active) {
        id.style.fill = color.kaleLeaf;
      } else {
        id.style.fill = shadeOf(color.peppercornLight, 0.35);
      }
    });
  }, [active]);

  return (
    <Wrapper>
      <MapWrapper>
        <Hidden down="lg">
          <FranchisingMapWrapper>
            <FranchisingMap />
          </FranchisingMapWrapper>
        </Hidden>
        {active === 'current' && <Cities cities={cities} />}
        <MobileMaps locations={mobile} />
      </MapWrapper>
      {active === 'current' && (
        <Hidden down="lg">
          <Legend>
            *Size of density bubble represents number of locations
          </Legend>
        </Hidden>
      )}
    </Wrapper>
  );
};

export const MapFeatureType = {
  cities: PropTypes.arrayOf(PropTypes.shape(CityType)),
  mobile: PropTypes.arrayOf(PropTypes.shape(MobileMapsType)),
  states: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string
    })
  )
};

MapFeature.propTypes = {
  active: PropTypes.string,
  ...MapFeatureType
};

export default MapFeature;
