import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { IntroType, Section } from '../../components';
import { P, PageType } from '../../global';
import BaseLayout from '../../layouts/Base';
import {
  FeaturedContent,
  FeaturedContentType,
  Hero,
  HeroType
} from '../../sections';
import {
  featuredContentLinkProps,
  featuredContentParticles,
  partyStartedParticles
} from './utils';

const Download = styled(P).attrs({ as: 'a' })`
  color: ${p => p.theme.color.redPepper};
  display: block;
  padding-top: 75px;
  text-decoration: underline;
`;

const Catering = ({ acf, ...layoutProps }) => {
  const { everyOccasion, hero, offerings, partyStarted } = acf;

  return (
    <BaseLayout footerDivider {...layoutProps}>
      <Hero dividerBottomColor="white" {...hero} />
      <Section align="center" intro={everyOccasion.intro} noBottomPadding>
        {everyOccasion.pdf && (
          <Download href={everyOccasion.pdf.url}>
            {everyOccasion.pdf.title}
          </Download>
        )}
      </Section>
      {offerings.map((offering, i) => {
        const alignment = i % 2 === 0 ? 'left' : 'right';
        const particles = i === 0 ? featuredContentParticles : null;
        return (
          <Section
            bgColor="white"
            halfPadding
            key={i}
            particlesData={particles}
          >
            <FeaturedContent
              alignment={alignment}
              linkProps={featuredContentLinkProps}
              reverseImageMobile
              {...offering}
            />
          </Section>
        );
      })}
      <Section
        align="center"
        intro={partyStarted.intro}
        particlesData={partyStartedParticles}
      >
        {partyStarted.pdf && (
          <Download href={partyStarted.pdf.url}>
            {partyStarted.pdf.title}
          </Download>
        )}
      </Section>
    </BaseLayout>
  );
};

Catering.propTypes = {
  ...PageType,
  acf: PropTypes.shape({
    everyOccasion: PropTypes.shape(IntroType),
    hero: PropTypes.shape(HeroType),
    offerings: PropTypes.arrayOf(PropTypes.shape(FeaturedContentType)),
    partyStarted: PropTypes.shape({
      intro: PropTypes.shape(IntroType),
      pdf: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string
      })
    })
  }).isRequired
};

export default Catering;
