import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

import { Card, CardType, Loading } from '../../../components';

import { Arrow, media, slickCSS } from '../../../global';

// prettier-ignore
const CarouselArrow = styled(Arrow)`
  cursor: pointer;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translate(-28px, -50%);
  z-index: 999;

  g {
    transform: translate(2px, 2px);
  }

  ${p => p.reverse && `
    left: auto;
    right: 0;
    transform: translate(28px, -50%);

    g {
      transform: rotate(180deg) translate(-46px, -46px);
    }
  `}
`;

const LeadershipCard = styled(Card)`
  text-align: center;

  img {
    max-width: 100px;
    width: 100%;
  }
`;

const LoadingWrapper = styled.div`
  min-height: 250px;
  position: relative;
`;

const Wrapper = styled.div`
  width: 100%;

  ${slickCSS}

  .slick-track {
    .slick-slide {
      height: auto;
      padding: 0 25px;
    }

    ${media.up.md`
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start
      margin: 0 auto;
      width: 100% !important;

      .slick-slide {
          flex: 0 0 33.3%;
          margin-bottom: 65px;
          max-width: 33.3%;
          width: 100%;
      }
    `}
  }

  ${media.up.md`
    transform: translateX(-25px);
    width: calc(100% + 50px);
  `}

  ${media.down.md`
    .slick-dots {
      align-items: center;
      display: flex !important;
      justify-content: center;
      margin-top: 35px;

      li {
        background: ${p => p.theme.color.peppercornLight};
        border-radius: 50%;
        height: 9px;
        margin: 0 1.5px;
        width: 9px;

        button {
          color: transparent;
        }
      }

      li.slick-active {
        background: ${p => p.theme.color.peppercorn};
        height: 12px;
        width: 12px;
      }
    }
  `}
`;

const Leadership = ({ cards }) => {
  const [loading, setLoading] = useState(true);

  const sliderSettings = {
    dots: false,
    nextArrow: <CarouselArrow reverse />,
    prevArrow: <CarouselArrow />,
    responsive: [
      {
        breakpoint: 959,
        settings: {
          dots: true,
          infinite: true,
          slidesToScroll: 1,
          slidesToShow: 1
        }
      }
    ],
    slidesToScroll: 1,
    slidesToShow: cards.length,
    speed: 500
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Wrapper>
      {/* needed so that the server knows which images to serve at the carousel breakpoint */}
      {loading ? (
        <LoadingWrapper>
          <Loading isLoading={loading} />
        </LoadingWrapper>
      ) : (
        <Slider {...sliderSettings}>
          {cards.map((card, i) => (
            <LeadershipCard {...card} key={i} />
          ))}
        </Slider>
      )}
    </Wrapper>
  );
};

export const LeadershipType = {
  cards: PropTypes.arrayOf(PropTypes.shape(CardType))
};

Leadership.propTypes = LeadershipType;

export default Leadership;
