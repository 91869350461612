import React from 'react';
import IconBase from './IconBase';

export default props => (
  <IconBase viewBox="0 0 20 20" {...props}>
    <path
      d="M17.255.55H2.804A2.254 2.254 0 0 0 .55 2.805v14.5a2.254 2.254 0 0 0 2.254 2.255h14.451a2.254 2.254 0 0 0 2.254-2.254V2.805A2.254 2.254 0 0 0 17.255.55zm-3.078 2.97c0-.328.265-.594.592-.594h1.778c.327 0 .592.266.592.594v1.782a.593.593 0 0 1-.592.594h-1.778a.593.593 0 0 1-.592-.594V3.52zm-4.125 2.91a3.647 3.647 0 1 1-.002 7.294 3.647 3.647 0 0 1 .002-7.294zm7.68 10.754a.594.594 0 0 1-.593.594H2.92a.594.594 0 0 1-.592-.594V8.273h2.37c-.308.445-.413 1.276-.413 1.804a5.776 5.776 0 0 0 5.768 5.77 5.776 5.776 0 0 0 5.767-5.77c0-.528-.075-1.346-.457-1.804h2.37v8.911z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </IconBase>
);
