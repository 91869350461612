import { format, lastDayOfMonth, startOfMonth } from 'date-fns';

const formatDate = date => {
  return format(date, 'YYYYMMDD');
};

/**
 * Returns one of two possible arrays:
 *  1 - Chronologically sorted array of articles with no filtering
 *  2 - A filtered array of articles from a given month (example: All articles from March of 2019)
 */
export const filterArticles = (articles, initialDayOfMonth, value) => {
  const finalDayOfMonth = formatDate(lastDayOfMonth(value));
  const firstDayOfMonth = formatDate(startOfMonth(value));

  articles.sort((a, b) => {
    a = a.acf.newsroom.externalDate;
    b = b.acf.newsroom.externalDate;
    return a > b ? -1 : a < b ? 1 : 0;
  });

  if (formatDate(value) === formatDate(initialDayOfMonth)) {
    return articles;
  }
  return articles.filter(article => {
    const date = formatDate(article.acf.newsroom.externalDate);
    return date >= firstDayOfMonth && date <= finalDayOfMonth;
  });
};
